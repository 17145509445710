import React, { createContext, useReducer, useContext } from 'react';
import axios from '../../../axios';
import bookingsReducer from '../../reducers/bookings/bookingsReducer';
import * as urlRequest from '../../urls';

const initialState = {
    bookings: [],
    total: 0,
    count: 0,
    error: null
};

export const BookingsContext = createContext(initialState);
export const BookingsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(bookingsReducer, initialState);

    const getBookingsHandler = async (query) => {
        dispatch({ type: 'PROCESS_START' })
        try {
            const { data } = await axios.get(urlRequest.Bookings + '?' + query);
            dispatch({ type: 'FETCH_RECORDS', payload: data })

        } catch (error) {
            console.error('err', error)
        }
    }
    return (
        <BookingsContext.Provider
            value={{
                loading: state.loading,
                error: state.error,
                total: state.total,
                count: state.count,
                bookings: state.bookings,
                getBookingsHandler
            }}
        >
            {children}
        </BookingsContext.Provider>
    )
}