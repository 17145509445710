export default (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...state,
        open: false,
        message: '',
      };

    case 'open':
      return {
        open: true,
        message: action.message,
      };

    default:
      throw new Error('Action not found');
  }
};
