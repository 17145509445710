import React, { useState, useEffect, useContext } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

import { useForm, Controller } from 'react-hook-form';
import { TermContext } from '../../../../../context/states/termState';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../../../../UI/Loader';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  appBar: {
    marginBottom: '12px',
  },
  title: {
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
}));

export const AddForm = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const { addTermHandler } = useContext(TermContext);

  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const { register, handleSubmit, errors, control, setValue } = useForm({ mode: 'onChange' });

  useEffect(() => {
    register('text');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    await addTermHandler(formData)
      .then((res) => {
        handleClose();
        backdropCloseHandler();
      })
      .catch((err) => {
        console.log(err);
        backdropCloseHandler();
      });
  };
  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        disableEnforceFocus
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Typography variant="h6" gutterBottom>
              New Terms and Conditions
            </Typography>

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="business-private-label">Select Term</InputLabel>
                    <Controller
                      as={
                        <Select
                          label="Select Term"
                          inputProps={{
                            name: 'Business',
                            id: 'select-term-label',
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          <MenuItem value={'Booking'}>Booking</MenuItem>
                          <MenuItem value={'Quotation'}>Quotation</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="businessType"
                      rules={{ required: true }}
                      error={!!errors.businessType}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="service-type-label">Service Type</InputLabel>
                    <Controller
                      as={
                        <Select
                          label="service type"
                          inputProps={{
                            name: 'service',
                            id: 'service-type-label',
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          <MenuItem value={'Telephone'}>Telephone</MenuItem>
                          <MenuItem value={'Video Call'}>Video Call</MenuItem>
                          <MenuItem value={'Onsite'}>Onsite</MenuItem>
                          <MenuItem value={'Translation'}>Translation</MenuItem>
                          <MenuItem value={'Transcription'}>Transcription</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="serviceType"
                      rules={{ required: true }}
                      error={!!errors.serviceType}
                      labelWidth={100}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  {/* <TextField
                    label="text"
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    name="text"
                    inputRef={register({
                      maxLength: { value: 255, message: 'Maximum characters limit is 255' },
                    })}
                    error={!!errors.text}
                    helperText={errors.text && errors.text.message}
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    onInit={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue('text', data);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
});
