import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Badge, Avatar, IconButton, CircularProgress, Tooltip } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import BackupIcon from '@material-ui/icons/Backup';

export const ProfileImage = (props) => {
  const { classes, refId, uploadApi, img, loading, isAccessTo } = props;
  const style = { display: 'none' };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);

  const fileInputRef = useRef();

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        // add a new property called invalid
        files[i]['invalid'] = true;
        // add to the same array so we can display the name of the file
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        // set error message
        setErrorMessage('File type not permitted');
        // unsupport
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  //   validate files
  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const uploadFiles = useCallback(() => {
    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', validFiles[i]);
      const data = { id: refId, file: formData };
      uploadApi(data);
    }
  }, [validFiles, refId, uploadApi]);

  const profImage = `${process.env.REACT_APP_BASE_URL}${img}`;

  // console.log(errorMessage);
  return (
    <React.Fragment>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <>
            <input id="icon-button-file" ref={fileInputRef} type="file" name="file" style={style} onChange={filesSelected} disabled={isAccessTo} />
            <label htmlFor="icon-button-file">
              <Tooltip title="Max 2MB" placement="right-start">
                <IconButton color="primary" aria-label="upload picture" component="span" style={{ background: 'white' }} size="small" disabled={isAccessTo}>
                  <PhotoCameraIcon fontSize="inherit" />
                </IconButton>

              </Tooltip>
            </label>
          </>
        }
      >
        {loading ? <CircularProgress style={{ margin: '2.5em' }} /> : <Avatar alt="Profile Image" src={profImage} className={classes?.profileLarge} />}
      </Badge>

      {/* {errorMessage} */}

      {unsupportedFiles.length === 0 && validFiles.length ? (
        <IconButton color="primary" aria-label="upload picture" component="span" size="small" onClick={() => uploadFiles()}>
          <BackupIcon fontSize="inherit" />
        </IconButton>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

ProfileImage.prototype = {
  classes: PropTypes.object.isRequired,
  refId: PropTypes.string.isRequired,
  uploadApi: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
