import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Chip,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  displayText: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: 0,
    textTransform: 'capitalize',
  },
  displayLabal: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
}));

export const CancelBooking = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose, data, user, handleOnSubmit, handleBookingNote } = props;

  const { register, handleSubmit, errors, control, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      status: data?.status,
    },
  });

  const { isSubmitting } = formState; // form submitting
  const statusWatch = watch('status');

  const onSubmit = async (formData) => {
    const { cancelReason, status } = formData;
    try {
      let noteData;

      if (status !== 'Cancel') noteData = { id: data?._id, text: `Cancelled to: ${status}`, updatedBy: user?.user };
      else noteData = { id: data?._id, text: `Cancelled: ${cancelReason}`, updatedBy: user?.user };

      await handleBookingNote(noteData)
      await handleOnSubmit({ _id: data?._id, status });
      await handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item>{data?.status === 'Cancel' ? 'Booking cancelled' : 'Cancel Booking'} &nbsp;</Grid>
              <Grid item>
                <Chip
                  color={data?.status === 'Cancel' ? 'secondary' : 'primary'}
                  clickable
                  size="small"
                  label={'Request reference: ' + data.booking_ref}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {data?.status === 'Cancel' ? (
              <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.displayText} gutterBottom>
                    {data?.cancelReason}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.displayLabal} gutterBottom>
                    Reason for cancellation
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* Status */}
                  <Typography variant="subtitle2" className={classes.displayText} gutterBottom>
                    {data?.status}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.displayLabal} gutterBottom>
                    Status
                  </Typography>
                  {/* Cancel by */}
                  <Typography variant="subtitle2" className={classes.displayText} gutterBottom>
                    {data?.cancelBy}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.displayLabal} gutterBottom>
                    Cancel By
                  </Typography>
                  {/* cancel date */}
                  <Typography variant="subtitle2" className={classes.displayText} gutterBottom>
                    {moment(data?.cancelDate).format('DD-MM-yyy')}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.displayLabal} gutterBottom>
                    Cancel Date
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%', maxWidth: '100%' }} fullWidth>
                    <InputLabel htmlFor="status-label">Move To</InputLabel>
                    <Controller
                      as={
                        <Select
                          label="Move To"
                          inputProps={{
                            name: 'status',
                            id: 'status-label',
                          }}
                        >
                          <MenuItem value={'Booking'}>Booking Request</MenuItem>
                          <MenuItem value={'Quotation'}>Quotation Request</MenuItem>
                          <MenuItem value={'Booked'}>Booked</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="status"
                      rules={{ required: true }}
                      error={!!errors.status}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={12}>
                  <TextField
                    label="Reason for cancellation"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    name="cancelReason"
                    inputRef={register({
                      maxLength: { value: 255, message: 'Maximum characters limit is 255' },
                    })}
                    error={!!errors.cancelReason}
                    helperText={errors.cancelReason && errors.cancelReason.message}
                    defaultValue={data?.cancelReason}
                  />
                </Grid>
                <input ref={register} name="status" defaultValue="Cancel" type="hidden" />
                <input ref={register} name="cancelBy" defaultValue={user?.user} type="hidden" />
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>

            <Button variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
              {/* {data?.status !== 'Cancel' ? 'Cancel Request' : `Move To ${statusWatch !== undefined ? statusWatch : ' Pending'}`} */}
              {isSubmitting
                ? 'Please wait...'
                : data?.status !== 'Cancel'
                  ? 'Cancel Request'
                  : `Move to ${statusWatch !== undefined ? statusWatch : ' Pending'}`}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </React.Fragment>
  );
});

// AddNotes.propTypes = {};
