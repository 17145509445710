import React, { createContext, useReducer } from 'react';
import DueReducer from '../reducers/interpreterDuesReducer';
import axios from '../../axios';

import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';

const initialState = {
  loading: false,
  load: false,
  dues: [],
  due: {},
  submitteData: {},
  total: 0,
  count: 0,
  totalAmount: 0
};

export const DuesContext = createContext(initialState);
export const DuesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DueReducer, initialState);

  const getInterpreterDuesHandler = async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Dues + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInterpreterDueHandler = async (id) => {
    dispatch({ type: 'LOAD_DUE' });
    try {
      const result = await axios.get(`${urlRequest.Due}${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const addRemittanceSlipHandler = async (data) => {
    console.log('State', data);
    const result = await axios.post(urlRequest.RemittanceSlip, data);
    dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      // alertDispatch({ type: 'open', message: 'Interpreter created' });
    }
    return await result;
  };

  return (
    <DuesContext.Provider
      value={{
        loading: state.loading,
        data: state.dues,
        total: state.total,
        load: state.load,
        dues: state.due,
        totalAmount: state.totalAmount,
        submitteData: state.submitteData,
        getInterpreterDuesHandler,
        getInterpreterDueHandler,
        addRemittanceSlipHandler,
      }}
    >
      {children}
    </DuesContext.Provider>
  );
};
