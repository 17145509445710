import React from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Grid,
  Paper,
  Typography,
  IconButton,
  CardContent,
  CardActions,
  Button,
  MenuItem,
  Input,
  FormControl,
  Select,
  InputLabel,
  TextField,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
// import FullscreenIcon from '@material-ui/icons/Fullscreen';

export const AdvanceSearch = (props) => {
  const {
    classes,
    isLanguages,
    isDialects,
    handleMinimize,
    isMinimize,
    clearAdvancedSearchHandler,
    setIsSearchObj,
    isSearchObj,
    isPage,
    setPage,
  } = props;
  const formValue = {
    fname: isSearchObj?.fname,
    language: isSearchObj?.language,
    dialect: isSearchObj?.dialect,
    gender: isSearchObj?.gender,
    service: isSearchObj?.service,
    postcode: isSearchObj?.postcode,
    active: isSearchObj?.active,
  };
  const { register, handleSubmit, errors, control, setValue, reset } = useForm({
    mode: "onChange",
    defaultValues: formValue,
  });

  const onSubmit = async (data) => {
    setPage(1);
    setIsSearchObj({ ...isSearchObj, ...data, page: isPage });
  };

  const handleChange = (e, targetName) => {
    setValue(
      targetName ? targetName : e.target.name,
      (e && e.target && e.target.value) || e,
      { shouldDirty: true }
    );
  };

  const clearFieldsHandler = () => {
    reset({
      fname: "",
      language: "",
      dialect: "",
      gender: "",
      service: "",
      postcode: "",
      active: "none",
    });
    clearAdvancedSearchHandler();
  };

  return (
    <React.Fragment>
      <div style={{ display: isMinimize ? "block" : "none" }}>
        <Paper className={classes.advanceCard} elevation={3}>
          <form onSubmit={(e) => e.preventDefault()} Autocomplete="off">
            <CardContent style={{ padding: "3px 10px" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleMinimize}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Input
                    placeholder="Enter Name"
                    inputProps={{ "aria-label": "name" }}
                    fullWidth
                    name="fname"
                    inputRef={register}
                    defaultValue={formValue?.fname}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Language
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) =>
                      handleChange(
                        value !== null
                          ? value
                            ? value.language || ""
                            : ""
                          : "",
                        "language"
                      )
                    }
                    defaultValue={{ language: formValue?.language }}
                    getOptionLabel={(option) =>
                      option ? option?.language : ""
                    }
                    renderOption={(option) => <>{option?.language}</>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        name="language"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: "none" }}
                    name="language"
                    ref={register}
                    defaultValue={""}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Dialect
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) =>
                      handleChange(
                        value !== null
                          ? value
                            ? value.dialect || undefined
                            : ""
                          : "",
                        "dialect"
                      )
                    }
                    defaultValue={{ dialect: formValue?.dialect }}
                    getOptionLabel={(option) => (option ? option?.dialect : "")}
                    renderOption={(option) => <>{option?.dialect}</>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: "none" }}
                    name="dialect"
                    ref={register}
                    defaultValue={""}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Gender
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl size="small" className={classes.formControl}>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Controller
                      as={
                        <Select labelId="gender-label" id="interpreter-gender">
                          <MenuItem value={"none"}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"male"}>Male</MenuItem>
                          <MenuItem value={"female"}>Female</MenuItem>
                          <MenuItem value={"other"}>Other</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="gender"
                      error={!!errors.gender}
                      labelWidth={55}
                      fullWidth
                      defaultValue={""}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Service Type
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl size="small" className={classes.formControl}>
                    <InputLabel id="gender-label">Service</InputLabel>
                    <Controller
                      as={
                        <Select
                          labelId="service-label"
                          id="interpreter-service"
                        >
                          <MenuItem value="none" selected>
                            All
                          </MenuItem>
                          <MenuItem value={"Interpretation"}>
                            Interpretation
                          </MenuItem>
                          <MenuItem value={"Translation"}>Translation</MenuItem>
                          <MenuItem value={"Transcription"}>
                            Transcription
                          </MenuItem>
                        </Select>
                      }
                      control={control}
                      name="service"
                      error={!!errors.service}
                      labelWidth={55}
                      defaultValue={""}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Postcode
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Input
                    placeholder="Enter postcode"
                    inputProps={{ "aria-label": "postcode" }}
                    className={classes.pincodeControl}
                    name="postcode"
                    inputRef={register}
                    defaultValue={""}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{
                      textAlign: "left",
                      color: "#757575",
                      fontWeight: 600,
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl size="small" className={classes.formControl}>
                    <InputLabel id="gender-label">
                      Filter active list
                    </InputLabel>
                    <Controller
                      as={
                        <Select labelId="active-label" id="interpreter-active">
                          <MenuItem value={"none"} selected>
                            All
                          </MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="active"
                      error={!!errors.active}
                      labelWidth={55}
                      defaultValue={""}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            {/* <div style={{ display: isMinimize ? 'none' : 'block' }}> */}
            <div>
              <CardActions>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  style={{ marginTop: "12px" }}
                >
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      type="button"
                      onClick={clearFieldsHandler}
                    >
                      Clear
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </div>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
};
