import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './FileUploads.css';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export const ClientUploads = React.memo((props) => {
  const { id, open, handleClose, handleFiles, validFiles, unsupportedFiles, handleOnSubmit, handleRemove, percentage, message } = props;

  const [isType, setIsType] = useState('');
  const [isDescription, setIsDescription] = useState('');
  const uploadModalRef = useRef();

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none';
  };

  // Files Handler
  const fileInputRef = useRef();

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const onSubmitHandler = () => {
    uploadModalRef.current.style.display = 'block';
    const data = { id, type: isType, description: isDescription };
    handleOnSubmit(data);
    setIsType('');
    setIsDescription('');
  };

  //   file size
  const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">File Upload</DialogTitle>
        <DialogContent className="fUpload_container">
          <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="upload-label">Select Document Type</InputLabel>
                <Select
                  labelId="upload-label"
                  id="upload-files"
                  labelWidth={165}
                  fullWidth
                  value={isType}
                  onChange={(e) => setIsType(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Contract'}>Contract</MenuItem>
                  <MenuItem value={'Terms of use'}>Terms of use</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={isDescription}
                onChange={(e) => setIsDescription(e.target.value)}
              />
            </Grid>
          </Grid>
          <div
            className="fUpload_drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            <div className="fUpload_drop-message">
              <input ref={fileInputRef} className="fUpload_file-input" type="file" multiple onChange={filesSelected} />

              <div className="fUpload_upload-icon"></div>
              <small>Drag & Drop files here or click to upload</small>
            </div>
          </div>

          <div className="fUpload_file-display-container">
            {validFiles.map((data, i) => (
              <div className="fUpload_file-status-bar" key={i}>
                <React.Fragment>
                  <div className="fUpload_file-type-logo"></div>
                  <div className="fUpload_file-type">{fileType(data.name)}</div>
                  <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                  <span className="fUpload_file-size">({fileSize(data.size)})</span>{' '}
                  {data.invalid && <span className="fUpload_file-error-message">({message})</span>}
                </React.Fragment>
                <div className="fUpload_file-remove" onClick={() => handleRemove(data.name)}>
                  <CancelIcon />
                </div>
              </div>
            ))}

            <div className="fUpload_modal" ref={uploadModalRef}>
              <div className="fUpload_overlay"></div>
              <div className="fUpload_close" onClick={() => closeUploadModal()}>
                <CancelIcon />
              </div>
              <div className="fUpload_progress-container">
                {/* <span ref={uploadRef}></span> */}
                <span>{message}</span>
                <div className="fUpload_progress">
                  <CircularProgressWithLabel value={percentage} />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ zIndex: 2 }}>
            Close
          </Button>
          {unsupportedFiles.length === 0 && validFiles.length && isType.length !== 0 ? (
            <Button
              color="primary"
              variant="contained"
              className="fUpload_file-upload-btn"
              startIcon={<CloudUploadIcon />}
              size="small"
              onClick={() => onSubmitHandler()}
            >
              Upload
            </Button>
          ) : (
            ''
          )}
          {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
