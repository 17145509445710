import React, { useState, useEffect, useContext } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./style";

import {
	EnhancedTableToolbar,
	CardItem,
} from "../../../components/pages/reports/statementOnAccounts/index";
import { SOAReportContext } from "../../../context/states/reports/SOAReportState";

const initialState = {
	isSearch: false,
	companyId: "",
	clientName: "",
	fileRef: "",
	paid: true,
	unpaid: true,
	confirmed: true,
	company: "",
	private: false,
	fromDate: null,
	toDate: null,
};

const StatementOnAccount = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:1920px)");

	const {
		reports,
		getReportsHandler,
		paidJobs,
		isloading,
		getClientsHandler,
		clients,
		clearStateHandler,
		totalPaidJobs,
		totalOutstanding,
		confirmedJobs,
		getPaidJobsHandler,
		getConfirmedJobsHandler,
		confirmedTotal,
	} = useContext(SOAReportContext);

	const [values, setValues] = useState({ ...initialState });
	const [dense] = useState(true);

	useEffect(() => {
		getReportsHandler("");
		getPaidJobsHandler("");
		getConfirmedJobsHandler("");
	}, []);

	const handleChange = (event) => {
		console.log("click", event);
		setValues({ ...values, [event.target.name]: event.target.checked });
	};

	const handleFromDateChange = (event) => {
		const date = new Date(event).toISOString();
		console.log("date", date);
		setValues({ ...values, fromDate: date });
	};

	const handleToDateChange = (event) => {
		const date = new Date(event).toISOString();
		console.log("date", date);
		setValues({ ...values, toDate: date });
	};

	const handleChangeAutoComplete = (name) => (event, value) => {
		console.log("event", name, value);
		if (value === null) {
			setValues({ ...initialState });
			// clearStateHandler()
			return;
		}
		if (name === "company") {
			if (value === "") return;
			setValues({ ...values, [name]: value, companyId: value._id });
		}
	};

	const handleInputChange = (event) => {
		if (event.target.name) {
			setValues({ ...values, [event.target.name]: event.target.value });
			return;
		}
		if (event.target.value === "") return;

		getClientsHandler(event.target.value);
	};

	const searchHandler = () => {
		setValues({ ...values, isSearch: true });
		let query = values.private ? `private=${values.private}` : `companyId=${values.companyId}`;
		let filteredDate = "";

		if (values.fromDate === null) {
			filteredDate = "";
		} else if (values.fromDate !== null) {
			filteredDate = `&fromDate=${values.fromDate}`;
			if (values.toDate !== null) {
				console.log("to date", values.toDate);
				filteredDate += `&toDate=${values.toDate}`;
			}
		}

		if (values.clientName !== "") {
			filteredDate += `&clientName=${values.clientName}`;
		}

		if (values.fileRef !== "") {
			filteredDate += `&fileRef=${values.fileRef}`;
		}

		if (values.unpaid) {
			console.log("Paid");
			getReportsHandler(`?${query}${filteredDate}`);
		} else {
			getReportsHandler("");
		}

		if (values.paid) {
			console.log("Unpaid");
			getPaidJobsHandler(`?${query}${filteredDate}`);
		} else {
			getPaidJobsHandler("");
		}

		if (values.confirmed) {
			console.log("Confirmed");
			getConfirmedJobsHandler(`?${query}${filteredDate}`);
		} else {
			getConfirmedJobsHandler("");
		}
	};

	const clearSearchHandler = () => {
		setValues({ ...initialState });
		getReportsHandler("");
		getPaidJobsHandler(``);
		getConfirmedJobsHandler(``);
	};

	return (
		<React.Fragment>
			<div
				classes={classes.rootM}
				style={{ width: "100%" }}
			>
				<Paper className={classes.paper}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<EnhancedTableToolbar
							handleChange={handleChange}
							handleFromDateChange={handleFromDateChange}
							handleToDateChange={handleToDateChange}
							values={values}
							isClients={clients}
							handleInputChange={handleInputChange}
							handleChangeAutoComplete={handleChangeAutoComplete}
							totalOutstandingAmount={totalOutstanding}
							totalPaidAmount={totalPaidJobs}
							title={"Statement On Account"}
							searchHandler={searchHandler}
							clearSearchHandler={clearSearchHandler}
						/>
					</MuiPickersUtilsProvider>
					{values.unpaid && (
						<CardItem
							title="Outstanding Invoices"
							active={classes.pendingStage}
							classes={classes}
							isLoading={isloading}
							data={reports}
							dense={dense}
							matches={matches}
							unpaid={true}
							paid={false}
							confirm={false}
							unpaidTotal={totalOutstanding}
						/>
					)}
					{values.paid && (
						<CardItem
							title="Paid Invoices"
							active={classes.paidStage}
							classes={classes}
							isLoading={isloading}
							data={paidJobs}
							dense={dense}
							matches={matches}
							unpaid={false}
							paid={true}
							confirm={false}
							paidTotal={totalPaidJobs}
						/>
					)}
					{values.confirmed && (
						<CardItem
							title="Confirmed Bookings"
							active={classes.confirmStage}
							classes={classes}
							isLoading={isloading}
							data={confirmedJobs}
							dense={dense}
							matches={matches}
							unpaid={false}
							paid={false}
							confirm={true}
							confirmTotal={confirmedTotal}
						/>
					)}
				</Paper>
			</div>
		</React.Fragment>
	);
};

export default StatementOnAccount;
