import React, { useMemo } from 'react'
import Preview from '../QuotationPreview/Preview';

const MemoizedQuotationPreview = ({ isOpen, handleClose, data }) => {
    return useMemo(
        () => <Preview open={isOpen} handleClose={handleClose} data={data} />
        , [isOpen, handleClose, data])
}

export default MemoizedQuotationPreview
