import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
// import { DevTool } from '@hookform/devtools';

import { BookingContext } from "../../../../../context/states/bookingState";
import { FileContext } from "../../../../../context/states/fileState";
import { FileUploads } from "../../../../fileUpload/UploadFiles/FileUploads";

import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
  DialogActions,
  Typography,
  Paper,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import BookIcon from "@material-ui/icons/Book";
import Loader from "../../../../UI/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  appBar: {
    marginBottom: "12px",
  },
  title: {
    textAlign: "center",
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: "100%",
    maxWidth: "100%",
  },
  input: {
    display: "none",
  },
  inputTitle: {
    fontWeight: "600",
    marginTop: ".1em",
    marginBottom: ".9em",
  },
}));

export const AddBooking = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const {
    addBookingHandler,
    getLangugagesHandler,
    getDialectsHandler,
    isLanguages,
    isDialects,
    fileList,
    getDepartmentOptionHandler,
    isDepartments,
    getCaseTyoeOptionHandler,
    isCaseTypes,
  } = useContext(BookingContext);

  const {
    handleFiles,
    unsupportedFiles,
    validFiles,
    newUploadFiles,
    removeFile,
    percentage,
    message,
  } = useContext(FileContext);

  const [isOpen, setIsOpen] = useState(false);
  const handleClickOpen = () => setIsOpen(true);
  const handleModalClose = () => setIsOpen(false);

  const formValues = {
    bookingType: "",
    businessType: "",
    serviceType: "",
    department: "",
    gender: "",
    caseType: "",
    purposeOfCall: "",
    place: "",
    documentType: "",
    formatType: "",
    transcriptionType: "",
    appointments: [{ date: null, startTime: null, endTime: null }],
  };

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: formValues,
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "appointments",
    }
  );

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const buT = watch("businessType");
  const sT = watch("serviceType");
  const showUrlLink = watch("formatType");

  const onSubmit = async (data) => {
    if (data.bookingType === "Booking") {
      data.status = "Booking";
    } else {
      data.status = "Quotation";
    }
    data.fileList = fileList;

    setIsLoading(true);
    try {
      await addBookingHandler(data);
      // console.log('add booking', data)
      await handleClose();
      backdropCloseHandler();
    } catch (er) {
      setIsLoading(false);
      backdropCloseHandler();
    }
  };

  useEffect(() => {
    getLangugagesHandler(); // eslint-disable-next-line
    getDialectsHandler(); // eslint-disable-next-line
  }, [setValue]);

  useEffect(() => {
    getDepartmentOptionHandler(); // eslint-disable-next-line
    getCaseTyoeOptionHandler(); // eslint-disable-next-line
  }, [open]);

  let serviceTitle = "";

  if (sT === "Telephone") {
    serviceTitle = "Telephone";
  } else if (sT === "Video Call") {
    serviceTitle = "Video Call";
  } else if (sT === "Onsite") {
    serviceTitle = "Onsite";
  } else if (sT === "Translation") {
    serviceTitle = "Translations of Document(s) ";
  } else if (sT === "Transcription") {
    serviceTitle = "Transcription";
  } else {
    serviceTitle = "No Title";
  }

  const handleChange = (e, targetName) => {
    console.log(e);
    setValue(
      targetName ? targetName : e.target.name,
      (e && e.target && e.target.value) || e,
      {
        shouldDirty: true,
      }
    );
  };

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <BookIcon color="primary" />
            <Typography variant="h6" gutterBottom>
              New Booking
            </Typography>

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="quotation-booking-label">
                      Quotation / Booking
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          label="Quotation / Booking"
                          inputProps={{
                            name: "quotation",
                            id: "quotation-booking-label",
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          <MenuItem value={"Quotation"}>Quotation</MenuItem>
                          <MenuItem value={"Booking"}>Booking</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="bookingType"
                      rules={{ required: true }}
                      error={!!errors.bookingType}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="business-private-label">
                      Business / Private
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          label="Business / Private"
                          inputProps={{
                            name: "Business",
                            id: "business-private-label",
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          <MenuItem value={"Business"}>Business</MenuItem>
                          <MenuItem value={"Private"}>Private</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="businessType"
                      rules={{ required: true }}
                      error={!!errors.businessType}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="service-type-label">
                      Service Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          label="service type"
                          inputProps={{
                            name: "service",
                            id: "service-type-label",
                          }}
                        >
                          <MenuItem aria-label="None" value="" />
                          <MenuItem value={"Telephone"}>Telephone</MenuItem>
                          <MenuItem value={"Video Call"}>Video Call</MenuItem>
                          <MenuItem value={"Onsite"}>Onsite</MenuItem>
                          <MenuItem value={"Translation"}>Translation</MenuItem>
                          <MenuItem value={"Transcription"}>
                            Transcription
                          </MenuItem>
                        </Select>
                      }
                      control={control}
                      name="serviceType"
                      rules={{ required: true }}
                      error={!!errors.serviceType}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={2}>
                {buT !== "Private" && (
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="company-name"
                      label="Company Name"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="companyName"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must have at least 2 letters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum characters limit is 50",
                        },
                      })}
                      error={!!errors.companyName}
                      helperText={
                        errors.companyName && errors.companyName.message
                      }
                      defaultValue=""
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="name"
                    label="Name"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="name"
                    inputRef={register({
                      required: true,
                      minLength: {
                        value: 2,
                        message: "Must have at least 2 letters",
                      },
                      maxLength: {
                        value: 40,
                        message: "Maximum characters limit is 40",
                      },
                    })}
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="department-label">
                      Department
                    </InputLabel>
                    <Controller
                      as={
                        <Select
                          label="Department"
                          inputProps={{
                            name: "service",
                            id: "department-label",
                          }}
                        >
                          {/* <MenuItem aria-label="None" value="" />
                          <MenuItem value={'Family'}>Family</MenuItem>
                          <MenuItem value={'Asylum'}>Asylum</MenuItem>
                          <MenuItem value={'Immigration'}>Immigration</MenuItem>
                          <MenuItem value={'Crime'}>Crime</MenuItem>
                          <MenuItem value={'Child Care'}>Child Care</MenuItem>
                          <MenuItem value={'Personal Injury'}>Personal Injury</MenuItem>
                          <MenuItem value={'Fraud'}>Fraud</MenuItem>
                          <MenuItem value={'Civil Litigation'}>Civil Litigation</MenuItem>
                          <MenuItem value={'Civil'}>Civil</MenuItem>
                          <MenuItem value={'Psychiatritic Assessment'}>Psychiatritic Assessment</MenuItem>
                          <MenuItem value={'Personal'}>Personal</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem> */}
                          {isDepartments.map((department) => (
                            <MenuItem
                              key={department._id}
                              value={department.name}
                            >
                              {department.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      control={control}
                      name="department"
                      rules={{ required: true }}
                      error={!!errors.department}
                      labelWidth={100}
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="phone"
                    label="Phone"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="phone"
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid phone number",
                      },
                      minLength: {
                        value: 10,
                        message: "Must have at least 10 digits",
                      },
                      maxLength: {
                        value: 15,
                        message: "Maximum digits limit is 15",
                      },
                    })}
                    error={!!errors.phone}
                    helperText={errors.phone && errors.phone.message}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="mobile"
                    label="Mobile"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="mobile"
                    inputRef={register({
                      required: true,
                      minLength: {
                        value: 10,
                        message: "Must have at least 10 digits",
                      },
                      maxLength: {
                        value: 15,
                        message: "Maximum digits limit is 15",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid mobile number",
                      },
                    })}
                    error={!!errors.mobile}
                    helperText={errors.mobile && errors.mobile.message}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="address"
                    label="Address"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    name="address"
                    inputRef={register({
                      required: true,
                      minLength: {
                        value: 5,
                        message: "Must have at least 5 letters",
                      },
                      maxLength: {
                        value: 150,
                        message: "Maximum characters limit is 150",
                      },
                    })}
                    error={!!errors.address}
                    helperText={errors.address && errors.address.message}
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    id="invoiceAddress"
                    label="Invoice Address"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    name="invoiceAddress"
                    inputRef={register({
                      required: false,
                      minLength: {
                        value: 5,
                        message: "Must have at least 5 letters",
                      },
                      maxLength: {
                        value: 150,
                        message: "Maximum characters limit is 150",
                      },
                    })}
                    error={!!errors.invoiceAddress}
                    helperText={
                      errors.invoiceAddress && errors.invoiceAddress.message
                    }
                    defaultValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      error={!!errors.paymentMethod}
                    >
                      Payment Method
                    </FormLabel>
                    {/* <Controller
                      as={
                        <RadioGroup aria-label="payment-method" row>
                          <FormControlLabel control={<Radio color="primary" value="BACS" />} label="By BACS Transfer" />
                          <FormControlLabel control={<Radio color="primary" value="Card" />} label="By Debit / Credit Card" />
                        </RadioGroup>
                      }
                      control={control}
                      inputRef={register({ required: true })}
                      name="paymentMethod"
                      defaultValue=""
                    /> */}

                    <RadioGroup name="paymentMethod" row defaultValue="BACS">
                      <FormControlLabel
                        value="BACS"
                        inputRef={register}
                        control={<Radio color="primary" />}
                        label="By BACS Transfer"
                      />
                      <FormControlLabel
                        value="Card"
                        inputRef={register}
                        control={<Radio color="primary" />}
                        label="By Debit / Credit Card"
                      />
                      <FormControlLabel
                        value="Invoice"
                        inputRef={register}
                        control={<Radio color="primary" />}
                        label="By Invoice"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  {/* <TextField
                    id="language"
                    label="Language"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="language"
                    inputRef={register({
                      required: true,
                      minLength: { value: 2, message: 'Must have at least 2 letters' },
                      maxLength: { value: 20, message: 'Maximum characters limit is 20' },
                    })}
                    error={!!errors.language}
                    helperText={errors.language && errors.language.message}
                  /> */}
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) =>
                      handleChange(
                        value !== null ? value.language : "",
                        "language"
                      )
                    }
                    getOptionLabel={(option) => option.language}
                    renderOption={(option) => <>{option.language}</>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        name="language"
                        error={!!errors.language}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: "none" }}
                    name="language"
                    ref={register({ required: true })}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) =>
                      handleChange(
                        value !== null ? value.dialect : "",
                        "dialect"
                      )
                    }
                    getOptionLabel={(option) => option.dialect}
                    renderOption={(option) => <>{option.dialect}</>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        name="dialect"
                        error={!!errors.dialect}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: "none" }}
                    name="dialect"
                    ref={register({ required: false })}
                  />
                  {/* <TextField
                    id="dialect"
                    label="Dialect"
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="dialect"
                    inputRef={register({
                      required: true,
                      minLength: { value: 2, message: 'Must have at least 2 letters' },
                      maxLength: { value: 20, message: 'Maximum characters limit is 20' },
                    })}
                    error={!!errors.dialect}
                    helperText={errors.dialect && errors.dialect.message}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" error={!!errors.location}>
                      Location
                    </FormLabel>
                    {/* <Controller
                      as={
                        <RadioGroup aria-label="location" row>
                          <FormControlLabel value="In London" control={<Radio color="primary" />} label="In London" />
                          <FormControlLabel value="Out of London" control={<Radio color="primary" />} label="Out of London" />
                        </RadioGroup>
                      }
                      control={control}
                      inputRef={register({ required: true })}
                      name="location"
                      defaultValue=""
                    /> */}
                    <RadioGroup name="location" row defaultValue="In London">
                      <FormControlLabel
                        value="In London"
                        inputRef={register}
                        control={<Radio color="primary" />}
                        label="In London"
                      />
                      <FormControlLabel
                        value="Out of London"
                        inputRef={register}
                        control={<Radio color="primary" />}
                        label="Out of London"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>

            {sT === "" ? null : (
              <Paper className={classes.paper} variant="outlined">
                {/* {interpretingList} */}
                <AppBar
                  position="static"
                  elevation={0}
                  className={classes.appBar}
                >
                  <Toolbar variant="dense">
                    <Typography variant="h6" className={classes.title}>
                      {serviceTitle}
                    </Typography>
                  </Toolbar>
                </AppBar>

                <React.Fragment>
                  <Grid container spacing={2}>
                    {/* Telephone and Video call */}
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="email"
                        label="Email"
                        size="small"
                        fullWidth
                        variant="outlined"
                        name="email"
                        inputRef={register({
                          required: true,
                          minLength: {
                            value: 5,
                            message: "Must have at least 5 letters",
                          },
                          maxLength: {
                            value: 60,
                            message: "Maximum characters limit is 60",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email-id",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="invoiceTo"
                        label="Invoice To"
                        size="small"
                        fullWidth
                        variant="outlined"
                        name="invoiceTo"
                        inputRef={register({
                          required: false,
                          minLength: {
                            value: 5,
                            message: "Must have at least 5 letters",
                          },
                          maxLength: {
                            value: 60,
                            message: "Maximum characters limit is 60",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email-id",
                          },
                        })}
                        error={!!errors.invoiceTo}
                        helperText={
                          errors.invoiceTo && errors.invoiceTo.message
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="clientName"
                        label="Client Name"
                        size="small"
                        fullWidth
                        variant="outlined"
                        name="clientName"
                        inputRef={register({
                          required: true,
                          minLength: {
                            value: 2,
                            message: "Must have at least 2 letters",
                          },
                          maxLength: {
                            value: 40,
                            message: "Maximum characters limit is 40",
                          },
                        })}
                        error={!!errors.clientName}
                        helperText={
                          errors.clientName && errors.clientName.message
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="fileRef"
                        label="File / Invoice Reference"
                        size="small"
                        fullWidth
                        variant="outlined"
                        name="fileRef"
                        inputRef={register({
                          required: true,
                          minLength: {
                            value: 2,
                            message: "Must have at least 2 letters",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum characters limit is 50",
                          },
                        })}
                        error={!!errors.fileRef}
                        helperText={errors.fileRef && errors.fileRef.message}
                      />
                    </Grid>

                    {sT === "Telephone" || sT === "Video Call" ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="gender-label">
                              Interpreter Gender
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="Interpreter Gender"
                                  inputProps={{
                                    name: "gender",
                                    id: "gender-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"male"}>Male</MenuItem>
                                  <MenuItem value={"female"}>Female</MenuItem>
                                  <MenuItem value={"any"}>Any</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="gender"
                              rules={{ required: true }}
                              error={!!errors.gender}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="purpose-call-label">
                              Purpose of Call
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="Purpose of Call"
                                  inputProps={{
                                    name: "purpose-call",
                                    id: "purpose-call-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"Convey a message"}>
                                    Convey a Message
                                  </MenuItem>
                                  <MenuItem value={"Translate a statement"}>
                                    Translate a statement
                                  </MenuItem>
                                  <MenuItem value={"Take a statement"}>
                                    Take a statement
                                  </MenuItem>
                                  <MenuItem value={"Medical Assessment"}>
                                    Medical assessment
                                  </MenuItem>
                                  <MenuItem value={"Court Hearing"}>
                                    Court Hearing
                                  </MenuItem>
                                  <MenuItem value={"Surgery call"}>
                                    Surgery call
                                  </MenuItem>
                                  <MenuItem
                                    value={"Explain the legal procedure"}
                                  >
                                    Explain the legal procedure
                                  </MenuItem>
                                  <MenuItem value={"Pre-hearing Consultation"}>
                                    Pre-hearing Consultation
                                  </MenuItem>
                                  <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="purposeOfCall"
                              rules={{ required: true }}
                              error={!!errors.purposeOfCall}
                            />
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    ) : null}

                    {buT !== "Private" && (
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="case-type-label">
                            Case / Matter Type
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Case / Matter Type"
                                inputProps={{
                                  name: "case-type",
                                  id: "case-type-label",
                                }}
                              >
                                {/* <MenuItem aria-label="None" value="" />
                                <MenuItem value={'Legal Aid'}>Legal Aid</MenuItem>
                                <MenuItem value={'Certificated'}>Certificated</MenuItem>
                                <MenuItem value={'Crime'}>Crime</MenuItem>
                                <MenuItem value={'Housing'}>Housing</MenuItem>
                                <MenuItem value={'Private'}>Private</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem> */}

                                {isCaseTypes.map((ct) => (
                                  <MenuItem key={ct._id} value={ct.name}>
                                    {ct.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                            control={control}
                            name="caseType"
                            rules={{ required: true }}
                            error={!!errors.caseType}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {sT === "Onsite" ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="gender-label">
                              Interpreter Gender
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="Interpreter Gender"
                                  inputProps={{
                                    name: "gender",
                                    id: "gender-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"male"}>Male</MenuItem>
                                  <MenuItem value={"female"}>Female</MenuItem>
                                  <MenuItem value={"any"}>Any</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="gender"
                              rules={{ required: true }}
                              error={!!errors.gender}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="place-label">
                              Place of Attendance
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="Place of Attendance"
                                  inputProps={{
                                    name: "place",
                                    id: "place-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"Court"}>Court</MenuItem>
                                  <MenuItem value={"Detention Centre"}>
                                    Detention Center
                                  </MenuItem>
                                  <MenuItem value={"Prison Centre"}>
                                    Prison Centre
                                  </MenuItem>
                                  <MenuItem value={"Ukba Offices"}>
                                    UKBA Offices
                                  </MenuItem>
                                  <MenuItem value={"Office"}>Office</MenuItem>
                                  <MenuItem value={"Hospital"}>
                                    Hospital
                                  </MenuItem>
                                  <MenuItem value={"Private Residence"}>
                                    Private Residence
                                  </MenuItem>
                                  <MenuItem value={"School"}>School</MenuItem>
                                  <MenuItem value={"GP Surgery"}>
                                    GP Surgery
                                  </MenuItem>
                                  <MenuItem value={"Chambers"}>
                                    Chambers
                                  </MenuItem>
                                  <MenuItem value={"Police station"}>
                                    Police Station
                                  </MenuItem>
                                  <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="place"
                              rules={{ required: true }}
                              error={!!errors.place}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="personAttending"
                            label="Person Attending"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="personAttending"
                            inputRef={register({
                              required: true,
                              minLength: {
                                value: 2,
                                message: "Must have at least 2 letters",
                              },
                              maxLength: {
                                value: 40,
                                message: "Maximum characters limit is 40",
                              },
                            })}
                            error={!!errors.personAttending}
                            helperText={
                              errors.personAttending &&
                              errors.personAttending.message
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="attendancePostcode"
                            label="Attendance Postcode"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="postcode"
                            inputRef={register({
                              required: true,
                              pattern: {
                                value:
                                  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                                message: "Invalid postcode",
                              },
                            })}
                            error={!!errors.postcode}
                            helperText={
                              errors.postcode && errors.postcode.message
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="placeOfAttendance"
                            label="Address"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="placeOfAttendance"
                            inputRef={register({
                              required: true,
                              minLength: {
                                value: 2,
                                message: "Must have at least 2 letters",
                              },
                              maxLength: {
                                value: 200,
                                message: "Maximum characters limit is 200",
                              },
                            })}
                            error={!!errors.placeOfAttendance}
                            helperText={
                              errors.placeOfAttendance &&
                              errors.placeOfAttendance.message
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="otherReference"
                            label="Other Reference"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="otherReference"
                            inputRef={register({
                              required: true,
                              minLength: {
                                value: 2,
                                message: "Must have at least 2 letters",
                              },
                              maxLength: {
                                value: 150,
                                message: "Maximum characters limit is 150",
                              },
                            })}
                            error={!!errors.otherReference}
                            helperText={
                              errors.otherReference &&
                              errors.otherReference.message
                            }
                          />
                        </Grid>
                      </React.Fragment>
                    ) : null}

                    {/* Onsite */}

                    {/* Translations */}
                    {sT === "Translation" ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="document-type-label">
                              Type of Document
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="document-type"
                                  inputProps={{
                                    name: "document-type",
                                    id: "document-type-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"Legal"}>Legal</MenuItem>
                                  <MenuItem value={"Housing"}>Housing</MenuItem>
                                  <MenuItem value={"Personal documents"}>
                                    Personal Documents
                                  </MenuItem>
                                  <MenuItem value={"IDs"}>IDs</MenuItem>
                                  <MenuItem value={"Medical"}>Medical</MenuItem>
                                  <MenuItem value={"Statement"}>
                                    Statement
                                  </MenuItem>
                                  <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="documentType"
                              rules={{ required: true }}
                              error={!!errors.documentType}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="format-type-label">
                              Format Type
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="format-type"
                                  inputProps={{
                                    name: "format-type",
                                    id: "format-type-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"PDF"}>PDF</MenuItem>
                                  <MenuItem value={"Jpeg"}>JPEG</MenuItem>
                                  <MenuItem value={"Ms-word"}>MS Word</MenuItem>
                                  <MenuItem value={"Picture"}>Picture</MenuItem>
                                  <MenuItem value={"Scan"}>Scan</MenuItem>
                                  <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="formatType"
                              rules={{ required: true }}
                              error={!!errors.formatType}
                            />
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    ) : null}

                    {/* Transcription */}
                    {sT === "Transcription" ? (
                      <React.Fragment>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="transcription-type-label">
                              Type of Transcription
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="Type of Transcription"
                                  inputProps={{
                                    name: "transcription-type",
                                    id: "transcription-type-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"Audio / Video"}>
                                    Audio / Video
                                  </MenuItem>
                                  <MenuItem value={"Audio"}>Audio</MenuItem>
                                  <MenuItem value={"Video"}>Video</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="transcriptionType"
                              rules={{ required: true }}
                              error={!!errors.transcriptionType}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            <InputLabel htmlFor="format-type-label">
                              Format Type
                            </InputLabel>
                            <Controller
                              as={
                                <Select
                                  label="format-type"
                                  inputProps={{
                                    name: "format-type",
                                    id: "format-type-label",
                                  }}
                                >
                                  <MenuItem aria-label="None" value="" />
                                  <MenuItem value={"Youtube"}>Youtube</MenuItem>
                                  <MenuItem value={"URL link"}>
                                    URL Link
                                  </MenuItem>
                                  <MenuItem value={"CD"}>CD</MenuItem>
                                  <MenuItem value={"DVD"}>Dvd</MenuItem>
                                  <MenuItem value={"Mp3"}>Mp3</MenuItem>
                                  <MenuItem value={"Mp4"}>mp4</MenuItem>
                                  <MenuItem value={"Wav"}>Wav</MenuItem>
                                  <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="formatType"
                              rules={{ required: true }}
                              error={!!errors.formatType}
                            />
                          </FormControl>
                        </Grid>

                        {(showUrlLink === "URL link" ||
                          showUrlLink === "Youtube") && (
                          <Grid item xs={12} sm={6} md={8}>
                            <TextField
                              id="url-link"
                              label="URL Link"
                              size="small"
                              fullWidth
                              variant="outlined"
                              name="urlLink"
                              inputRef={register({
                                required: true,
                                pattern: {
                                  value:
                                    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                                  message: "Invalid url",
                                },
                                minLength: {
                                  value: 2,
                                  message: "Must have at least 2 letters",
                                },
                                maxLength: {
                                  value: 150,
                                  message: "Maximum characters limit is 150",
                                },
                              })}
                              error={!!errors.urlLink}
                              helperText={
                                errors.urlLink && errors.urlLink.message
                              }
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ) : null}

                    <Grid item xs={12}>
                      <FormControlLabel
                        inputRef={register}
                        control={
                          <Checkbox name="isSplitInvoice" color="primary" />
                        }
                        label="Split Invoice"
                      />
                    </Grid>
                    {/* {sT === 'Translation' || sT === 'Transcription' ? ( */}
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={4}>
                        <FileUploads
                          type="bookingFile"
                          open={isOpen}
                          handleClose={handleModalClose}
                          handleFiles={handleFiles}
                          validFiles={validFiles}
                          unsupportedFiles={unsupportedFiles}
                          handleOnSubmit={newUploadFiles}
                          handleRemove={removeFile}
                          percentage={percentage}
                          message={message}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={handleClickOpen}
                        >
                          Upload Files
                        </Button>
                      </Grid>

                      {fileList.map((item, i) => {
                        // const docFile = `files[${i}]`;

                        return (
                          <Grid item xs={12}>
                            <List dense={true}>
                              <ListItem button key={i}>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      {item.fileName}
                                    </Typography>
                                  }
                                />
                                {/* <input type="text" name={`${docFile}`} ref={register} value={item} /> */}
                              </ListItem>
                            </List>
                          </Grid>
                        );
                      })}
                    </React.Fragment>
                    {/* ) : null} */}

                    {(sT === "Telephone" ||
                      sT === "Video Call" ||
                      sT === "Onsite") && (
                      <Grid item xs={12} sm={12} md={12}>
                        <FormLabel
                          component="legend"
                          error={!!errors.paymentMethod}
                        >
                          Schedules
                        </FormLabel>
                      </Grid>
                    )}

                    {(sT === "Telephone" ||
                      sT === "Video Call" ||
                      sT === "Onsite") &&
                      fields.map((item, i) => (
                        <>
                          <Grid item xs={12} sm={6} md={3}>
                            <Controller
                              as={
                                <KeyboardDatePicker
                                  autoOk
                                  format="dd/MM/yyyy"
                                  id="dateOfBooking"
                                  label={"Date of Attendance"}
                                  size="small"
                                  variant="inline"
                                  fullWidth
                                  inputVariant="outlined"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              }
                              control={control}
                              name={`appointments[${i}].date`}
                              rules={{ required: true }}
                              error={!!errors.date}
                              defaultValue={`${item.date}`}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <Controller
                              as={
                                <KeyboardTimePicker
                                  autoOk
                                  ampm={false}
                                  id="startTime"
                                  label="Start Time"
                                  size="small"
                                  variant="inline"
                                  fullWidth
                                  inputVariant="outlined"
                                />
                              }
                              control={control}
                              name={`appointments[${i}].startTime`}
                              rules={{ required: true }}
                              error={!!errors.startTime}
                              defaultValue={`${item.startTime || null}`}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <Controller
                              as={
                                <KeyboardTimePicker
                                  autoOk
                                  ampm={false}
                                  id="endTime"
                                  label="End Time"
                                  size="small"
                                  variant="inline"
                                  fullWidth
                                  inputVariant="outlined"
                                />
                              }
                              control={control}
                              name={`appointments[${i}].endTime`}
                              rules={{ required: true }}
                              error={!!errors.endTime}
                              defaultValue={`${item.endTime}`}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={1}>
                            <IconButton
                              aria-label="close"
                              size="small"
                              onClick={() => remove(i)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </>
                      ))}

                    {(sT === "Telephone" ||
                      sT === "Video Call" ||
                      sT === "Onsite") &&
                      fields.length !== 5 && (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                              append({
                                firstName: "appendBill",
                                lastName: "appendLuo",
                              });
                            }}
                          >
                            Add Appoinment
                          </Button>
                        </Grid>
                      )}

                    {(sT === "Translation" || sT === "Transcription") && (
                      <>
                        <Grid item xs={12}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Controller
                              as={
                                <KeyboardDatePicker
                                  autoOk
                                  format="dd/MM/yyyy"
                                  id="dateOfBooking"
                                  label={"Date of Return"}
                                  size="small"
                                  variant="inline"
                                  fullWidth
                                  inputVariant="outlined"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              }
                              control={control}
                              name="appointments[0].date"
                              rules={{ required: true }}
                              error={!!errors.date}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Type of Urgency
                            </FormLabel>

                            <RadioGroup
                              aria-label="typeOfUrgency"
                              name="urgency"
                              row
                              defaultValue="urgent"
                            >
                              <FormControlLabel
                                value="urgent"
                                inputRef={register}
                                control={<Radio color="primary" />}
                                label="Urgent - Within 3 days"
                              />
                              <FormControlLabel
                                value="non-urgent"
                                inputRef={register}
                                control={<Radio color="primary" />}
                                label="Non Urgent - After 5 days"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    {/* Notes */}
                    <Grid item xs={12}>
                      <TextField
                        id="notes"
                        label="Notes / Special instructions"
                        size="small"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={3}
                        name="notes"
                        inputRef={register({
                          required: true,
                          minLength: {
                            value: 2,
                            message: "Must have at least 2 letters",
                          },
                          // maxLength: { value: 255, message: 'Maximum characters limit is 255' },
                        })}
                        error={!!errors.notes}
                        helperText={errors.notes && errors.notes.message}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              </Paper>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Please wait..." : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Loader open={isLoading} handleClose={backdropCloseHandler} /> */}
    </React.Fragment>
  );
});
