import React, { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Divider, Typography, ListItemAvatar, Avatar } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ListItemLink } from '../../../libs/useLinkItem';

// import { MenuItems } from './MenuItems01';

import { menu } from "../../../menus/menu";
import { interpreterMenu } from "../../../menus/interpreterMenu";
import { hasChildren } from "./utils";

// SVG Import
import poundIcon from '../../../assets/images/icons/pound.svg'

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
    root: { display: 'flex' },
    hide: { display: 'none' },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    fullList: {
        width: 'auto',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logo: {
        display: 'flex',
        alighItems: 'center',
        jusitifyContent: 'center',
        width: '180px',
        margin: theme.spacing(1, 7),
    },
    ListItemIcon: {
        minWidth: '40px',
    },
    ListItemText: {
        fontFamily: 'Open Sans',
        fontSize: '14px'
    }
}));

const SideDrawer = (props) => {
    const classes = useStyles();
    const { matches, open, toggle, portalType } = props;
    const [isToggle, setIsToggle] = React.useState(false);
    const userType = [portalType]

    const handleSideToggle = () => {
        setIsToggle(!isToggle);
    };

    return (
        <React.Fragment>
            {!matches ? (
                <SwipeableDrawer role="presentation" open={open} anchor="left" onClose={toggle} onOpen={toggle}>
                    <div className={classes.toolbar}>{open && <img src={process.env.REACT_APP_LOGO} className={classes.logo} alt="logo" />}</div>
                    <Divider />
                    <div
                        className={clsx(classes.list, {
                            [classes.fullList]: 'left' === 'top' || 'left' === 'bottom',
                        })}
                        role="presentation"
                        // onClick={toggle}
                        onKeyDown={toggle}
                    >
                        {/* <MenuItem open={isToggle} toggle={handleSideToggle} classes={classes} dense={false} portalType={portalType} /> */}
                        {(portalType === 'superadmin' || portalType === 'admin') && menu.map((item, key) => <MenuItem key={key} item={item} userType={userType} />)}
                        {portalType === 'interpreter' && interpreterMenu.map((item, key) => <MenuItem key={key} item={item} userType={userType} />)}
                    </div>
                </SwipeableDrawer>
            ) : (
                <Drawer
                    variant="permanent"
                    elevation={0}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: props.open,
                            [classes.drawerClose]: !props.open,
                        }),
                    }}
                >
                    <div className={classes.toolbar} style={{ minHeight: '50px' }}>
                        {/* {props.open && <img src="https://www.linkedintranslations.com/assets/images/logo.jpg" className={classes.logo} alt="Logo" />} */}
                    </div>
                    <Divider />
                    {(portalType === 'superadmin' || portalType === 'ip-admin' || portalType === 'admin') && menu.map((item, key) => <MenuItem key={key} item={item} userType={userType} />)}
                    {portalType === 'interpreter' && interpreterMenu.map((item, key) => <MenuItem key={key} item={item} userType={userType} />)}
                </Drawer>
            )}
        </React.Fragment>
    );

}

const MenuItem = ({ item, userType }) => {
    var currentUser = userType;
    // const userFilterHandler = (val) => {
    //     if (currentUser.indexOf(val) > -1) return true
    // }

    const userFilterHandler = (val) => currentUser?.indexOf(val) > -1 ? true : false
    const accessValue = item?.user?.filter(userFilterHandler)
    const accessTo = accessValue.length === 0 ? true : false
    // console.log('navitem', item.user, 'access', accessTo, 'accessValue', accessValue, 'currentUser', currentUser)

    const Component = hasChildren(item) ? MultiLevel : SingleLevel
    return <Component item={item} accessTo={accessTo} userType={userType} />
}

const SingleLevel = ({ item, accessTo }) => {
    const classes = useStyles();

    return (
        <>
            {!accessTo || item.enabled ? <ListItemLink button to={{
                pathname: item.to,
                state: { page: true }
            }} disabled={accessTo || item.enabled} >
                {item.icon 
                    ? <ListItemIcon className={classes.ListItemIcon}>{item.icon}</ListItemIcon> 
                    : <ListItemAvatar style={{ minWidth: '40px' }}> <Avatar alt="Pound Icon" style={{ width: '23px', height: '23px' }} src={poundIcon} /> </ListItemAvatar> 
                }
                <ListItemText primary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            className={classes.ListItemText}
                        >
                            {item.title}
                        </Typography>
                    </React.Fragment>
                } />
            </ListItemLink> : null}
        </>
    )
}

const MultiLevel = ({ item, accessTo, userType }) => {
    const classes = useStyles();
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            {!accessTo &&
            <ListItem button onClick={handleClick} disabled={accessTo} style={{ visibility: accessTo ? 'hidden' : 'visible' }}>
                <ListItemIcon className={classes.ListItemIcon}>{item.icon}</ListItemIcon>
                <ListItemText primary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            className={classes.ListItemText}
                        >
                            {item.title}
                        </Typography>
                    </React.Fragment>
                } />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
}
            <Collapse in={open} timeout="auto" unmountOnExit style={{ background: '#1c1c1d08' }}>
                <List component="div" disablePadding>
                    {children.map((child, key) => (
                        <MenuItem key={key} item={child} userType={userType} />
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
}

SideDrawer.propTypes = {
    matches: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default React.memo(SideDrawer);
