import React from "react";
import { TableRow, TableCell, TableHead, TableSortLabel, Checkbox } from "@material-ui/core";
import PropTypes from "prop-types";

const headCells = [
	{ id: "Sl.No", numeric: false, disablePadding: true, label: "Sl No" },
	{ id: "BookingRef", numeric: false, disablePadding: true, label: "Booking Ref" },
	{ id: "invoiceNo", numeric: false, disablePadding: true, label: "Invoice No / Date" },
	{ id: "serviceType", numeric: false, disablePadding: true, label: "Service Type / Language" },
	{ id: "clientName", numeric: false, disablePadding: true, label: "Client Name" },
	{ id: "name", numeric: false, disablePadding: true, label: "Name" },
	// { id: 'language', numeric: false, disablePadding: true, label: 'Language' },
	// { id: 'service', numeric: false, disablePadding: true, label: 'Service' },
	// { id: 'interpreter', numeric: false, disablePadding: true, label: 'Interpreter' },
	{ id: "dateOfAttendance", numeric: false, disablePadding: true, label: "Attendance Date" },
	{ id: "invTotal", numeric: false, disablePadding: true, label: "Invoice Amount" },
	{ id: "grossTotal", numeric: false, disablePadding: true, label: "Outstanding" },
	{ id: "allocated", numeric: false, disablePadding: true, label: "Allocated" },
	{ id: "notes", numeric: false, disablePadding: false, label: "Notes" },
];

export const EnhancedTableHead = (props) => {
	const { classes, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell
					padding="checkbox"
					className={classes.cellHeaderWidth}
				>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ "aria-label": "select all desserts" }}
						color="primary"
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
						className={classes.cellHeaderWidth}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
