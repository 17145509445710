import React, { useMemo } from 'react';
import { getComparator, stableSort } from '../../../../libs/headerSortMethods'
import { BookingLists } from '../Lists/BookingLists';

function MemoizedList({ data, loading, order, orderBy, isPage, rowsPerPage, isSelected, handleClick, isCheckbox, viewBookingHandler, cancelBookingHandler, notesBookingHandler, sendQuoteHandler, TimesheetHandler, AssignToHandler, sendInvoiceHandler, PaymentHandler, viewQuoteHandler, sendProformaInvoice }) {
    return useMemo(() => {
        return (
            <BookingLists
                data={data}
                loading={loading}
                stableSort={stableSort}
                getComparator={getComparator}
                order={order}
                orderBy={orderBy}
                page={isPage}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleClick={handleClick}
                isCheckbox={isCheckbox}
                handleCancel={cancelBookingHandler}
                handleNoteRequest={notesBookingHandler}
                handleSendQuote={sendQuoteHandler}
                handleInvoice={sendInvoiceHandler}
                handleTimesheet={TimesheetHandler}
                handleAssignTo={AssignToHandler}
                handlePayment={PaymentHandler}
                handleQuoteView={viewQuoteHandler}
                proformaInvoice={sendProformaInvoice}
            />
        )
    }, [data,
        loading,
        order,
        orderBy,
        isPage,
        rowsPerPage,
        isSelected,
        handleClick,
        isCheckbox,
        cancelBookingHandler,
        notesBookingHandler,
        sendQuoteHandler,
        TimesheetHandler,
        AssignToHandler,
        viewQuoteHandler,
        sendInvoiceHandler,
        PaymentHandler,
        sendProformaInvoice])
}

export default MemoizedList;