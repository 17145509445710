import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// import { DevTool } from "@hookform/devtools";

import { Dialog, DialogActions, DialogContent, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DialogTitle from '@material-ui/core/DialogTitle';

export const AddClientPayment = (props) => {
    const { open, handleClose, isClients, getClientsHandler, approvedBy, submitHandler } = props;
    const { register, handleSubmit, errors, control, setValue, formState } = useForm();
    const { isSubmitting, isValid, isDirty } = formState;

    const [isPrivate, isSetPrivate] = useState(false);

    const handleChange = (e, targetName) => {
        // console.log(e);
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, { shouldDirty: true });
    };

    const handleFromChange = useCallback(
        async (event, value) => {
            const query = value;
            console.log(query, 'query');
            await getClientsHandler(query);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [isClients]
    );

    const onSubmit = async (data) => {
        const { totalAmount } = data
        data.approvedBy = approvedBy;
        data.totalAmount = +totalAmount;
        data.balance = +totalAmount;
        data.details = [];

        if(isPrivate) data.companyId = undefined;
        console.log('payCom', data)
        try {
            await submitHandler(data);
            await handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <form onSubmit={(e) => e.preventDefault()}>
                {/* <DevTool control={control} /> */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="add-client-payment-dialog-title"
                    aria-describedby="add-client-payment-dialog-description"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="add-client-payment-dialog-title">{'Add Client Payment'}</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    onChange={(e) => isSetPrivate(e.currentTarget.checked)}
                                    control={
                                        <Checkbox
                                            name="isPrivate"
                                            color="primary"
                                            defaultChecked={isPrivate ? true : false}
                                        />
                                    }
                                    label="Is Private"
                                />
                            </Grid>
                            {!isPrivate ? (<>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={isClients}
                                        onInputChange={(event, value) => handleFromChange(event, value)}
                                        onChange={(_, value) => {
                                            handleChange(value?._id, 'companyId');
                                            handleChange(value?.companyName, 'company');
                                        }}
                                        getOptionLabel={(option) => option?.companyName}
                                        getOptionSelected={(option, value) => option?.companyName === value?.companyName}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Company Name" name="company" size="small" fullWidth variant="outlined" error={!!errors.companyId} />
                                        )}
                                    />

                                    <input style={{ display: 'none' }} name="company" ref={register({ required: true })} />
                                    <input style={{ display: 'none' }} name="companyId" ref={register({ required: true })} defaultValue="" />
                                </Grid>
                            </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="companyName"
                                            label="Company Name"
                                            size="small"
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            name="company"
                                            inputRef={register({
                                                required: true
                                            })}
                                            error={!!errors.company}
                                            defaultValue={''}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    id="totalamount"
                                    label="Amount Received"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    name="totalAmount"
                                    inputRef={register({
                                        required: true
                                    })}
                                    error={!!errors.totalAmount}
                                    defaultValue={0}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Controller
                                        as={
                                            <KeyboardDatePicker
                                                autoOk
                                                format="dd/MM/yyyy"
                                                label="Payment Date"
                                                size="small"
                                                variant="inline"
                                                inputVariant="outlined"
                                                error={!!errors.paymentDate}
                                                helperText={errors.paymentDate && errors.paymentDate.message}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            // maxDate={new Date()}
                                            />
                                        }
                                        control={control}
                                        name="paymentDate"
                                        rules={{ required: true }}
                                        error={!!errors.paymentDate}
                                        defaultValue={null}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" size="small">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" size="small" disabled={!isDirty} autoFocus>
                            {isSubmitting ? 'Please wait' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
};
