import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RateContext } from '../../../../../context/states/rateState';
import Loader from '../../../../UI/Loader';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  appBar: {
    marginBottom: '12px',
  },
  title: {
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
}));

export const EditForm = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose, data } = props;
  const { updatedRateHandler } = useContext(RateContext);

  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const { register, handleSubmit } = useForm({ mode: 'onChange' });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    await updatedRateHandler(formData)
      .then((res) => {
        handleClose();
        backdropCloseHandler();
      })
      .catch((err) => {
        console.log(err);
        backdropCloseHandler();
      });
  };

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Chip size="small" label={data !== undefined ? data.rateName : ''} clickable color={data.enabled ? 'primary' : 'secondary'} />
            {/* <Typography variant="h6" gutterBottom>
              Edit {data !== undefined ? data.rateName : ''}
            </Typography> */}

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" ref={register} name="_id" readOnly value={data._id} />
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="column" spacing={1}>
                {/*  <Grid item xs={12}>
                  {data.clientID !== undefined ? (
                    <React.Fragment>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel id="rateName-label">Rates</InputLabel>
                        <Controller
                          as={
                            <Select labelId="rateName-label" id="company-status" name="rateName">
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={`Special Rate_${client}`}>{client} Special Rate</MenuItem>
                              <MenuItem value={`Other Rate_${client}`}>{client} Other Rate</MenuItem>
                            </Select>
                          }
                          control={control}
                          name="rateName"
                          rules={{ required: true }}
                          error={!!errors.rateName}
                          labelWidth={55}
                          defaultValue={data !== undefined ? data.rateName : ''}
                        />
                      </FormControl>
                      <TextField
                        style={{ display: 'none' }}
                        fullWidth
                        margin="normal"
                        label="Client ID"
                        variant="outlined"
                        size="small"
                        name="clientID"
                        inputRef={register}
                        defaultValue={data !== undefined ? data.clientID : ''}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextField
                        fullWidth
                        label="Rate Name"
                        variant="outlined"
                        size="small"
                        name="rateName"
                        inputRef={register}
                        defaultValue={data !== undefined ? data.rateName : ''}
                      />
                    </React.Fragment>
                  )}
                </Grid> */}
                <Grid item xs={12}>
                  <table style={{ padding: '1.5em 0' }}>
                    <tbody>
                      <tr>
                        <td className={classes.tbody}>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Attendance
                          </Typography>
                        </td>
                        <td>
                          <TextField
                            fullWidth
                            label="Per minute"
                            variant="filled"
                            size="small"
                            name="attendance"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.attendance : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Travel
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="travelPerhour"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.travelPerhour : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Waiting
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="waitingPerHour"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.waitingPerHour : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Mileage
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="mileage"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.mileage : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Translation Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per word"
                            variant="filled"
                            size="small"
                            name="translationUrgent"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.translationUrgent : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Tanslation Non Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per word"
                            variant="filled"
                            size="small"
                            name="translationNonUrgent"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.translationNonUrgent : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Telephone
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per minute"
                            variant="filled"
                            size="small"
                            name="telephoneInterpreting"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.telephoneInterpreting : ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Video
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="videoInterpreting"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.videoInterpreting : ''}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Transcription Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="transcriptionUrgent"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.transcriptionUrgent : ''}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Transcription Non Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="transcriptionNonUrgent"
                            inputRef={register}
                            type="number"
                            defaultValue={data !== undefined ? data.transcriptionNonUrgent : ''}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs>
                  <FormControlLabel
                    inputRef={register}
                    control={<Checkbox name="enabled" color="primary" defaultChecked={data !== undefined ? data.enabled : false} />}
                    label={data.enabled ? 'Enable' : 'Disable'}
                  />
                </Grid>
              </Grid>
            </Paper>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
});
