import React, { useContext, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, Paper, Hidden, Chip } from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { AuthContext } from '../../context/states/authState';
import { LoginForm } from '../../components/login/LoginForm';
// import Logo from '../../assets/images/logo.jpg';
import Icon from '../../assets/images/icon.png';

const useStyles = makeStyles((theme) => ({
  myback: {
    backgroundImage: 'radial-gradient( circle 760px at 50.7% 60.5%, rgba(76, 120, 183, 0.85) 0%, rgba(34, 70, 122, 0.74) 90% );',
    height: '100vh',
    paddingTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  root: {
    borderRadius: '4px',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  image: {
    backgroundColor: 'blue',
  },
  rightBanner: {
    backgroundImage: 'radial-gradient( circle 760px at 50.7% 60.5%,  rgba(76,120,183,1) 0%, rgba(34,70,122,1) 90% )',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '76vh',
  },
  rightBanner2: {
    backgroundImage: ' radial-gradient( circle 760px at 50.7% 60.5%, rgb(255 255 255) 0%, rgb(78 78 78) 90% )',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '76vh',
  },
  logo: {
    margin: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    // color: '#ffffff',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 500,
    marginTop: '.7em',
  },
  subTitle: {
    color: theme.palette.grey,
    fontWeight: 600,
  },
  light: {
    color: 'rgb(99 99 99)',
  },
  dark: {
    color: 'rgba(209, 209, 209, 0.52)',
  },
  formControl: {
    marginTop: theme.spacing(4),
    minWidth: 180,
  },
  browserSpan: {
    marginBottom: '20px',
    background: '#ffa500',
    padding: '4px 8px',
    fontWeight: '600',
    fontSize: '10px'
  }
}));

const Login = () => {
  const classes = useStyles();
  const { loading } = useContext(AuthContext);

  const [isShow, setIsShow] = useState(false)
  const [isBrowser, setIsBrowser] = useState('')

  function get_browser() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };
  }

  React.useEffect(() => {
    const { name, version } = get_browser();
    if (name.toLocaleLowerCase() !== 'chrome') {
      setIsBrowser('For better performance use chrome')
      setIsShow(true)
    } else if (name.toLocaleLowerCase() === 'chrome' && version <= 70) {
      setIsBrowser('Please update your chrome browser')
      setIsShow(true)
    }
  }, [])

  return (
    <div className={classes.myback}>
      <Container component="main" style={{ maxWidth: '700px' }}>
        {/* <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
          <img src={Logo} width="280" alt="Language Interpreters Logo" />
        </div> */}
        <CssBaseline />
        {loading && <LinearProgress />}
        <Grid container direction="row" justify="center" alignItems="center" component={Paper} className={classes.root} elevation={4}>
          <Grid item sm={12} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.paper}>
                <Hidden mdUp>
                  <Typography gutterBottom variant="h6" className={classes.subTitle}>
                    {process.env.REACT_APP_NAME}
                  </Typography>
                </Hidden>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h5" variant="h6">
                  Sign In
                </Typography>
                {/* <Hidden mdUp>
                  <Typography gutterBottom variant="body1">
                    Admin Portal
                  </Typography>
                </Hidden> */}
                <LoginForm />
              </div>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={false} md={6} lg={6} className={classes.rightBanner2}>
              <div className={classes.paper} style={{ paddingTop: '8rem' }}>
                <img src={Icon} width="100" alt="Language Interpreters Logo" title="Language Interpreters" />
                {/* <Typography gutterBottom variant="h5" className={classes.title}>
                  Admin Portal
                </Typography> */}
                <Typography gutterBottom variant="body2" className={classes.light}>
                  {process.env.REACT_APP_NAME}
                </Typography>
              </div>
            </Grid>
          </Hidden>
        </Grid>
        <small style={{ fontSize: '12px', color: '#fff', marginRight: '1em', marginTop: '0.5em', float: 'right' }}>
          v{process.env.REACT_APP_BUILD_VERSION}
        </small>
      </Container>
      {isShow && <Chip className={classes.browserSpan} color="warning" size="small" icon={<WarningRoundedIcon />} label={isBrowser} />}
    </div>
  );
};

export default Login;
