import React, { useRef, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, CardActions, TextField, Button } from '@material-ui/core';
import { UserContext } from '../../../context/states/userState';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ConfirmPassword = React.memo((props) => {
  const classes = useStyles();
  const { updatePasswordHandler } = useContext(UserContext);
  const userId = localStorage.getItem('loggedIn');

  const { register, handleSubmit, errors, watch, formState } = useForm({
    mode: 'onChange',
  });

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async (data) => {
    console.log(data);
    await updatePasswordHandler(data);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          {props.title}
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <input type="hidden" name="id" ref={register} defaultValue={userId} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="New Password"
                variant="outlined"
                name="password"
                type="password"
                inputRef={register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters',
                  },
                })}
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Repeat Password"
                variant="outlined"
                name="password_repeat"
                type="password"
                inputRef={register({
                  validate: (value) => value === password.current || 'The passwords do not match',
                })}
                error={!!errors.password_repeat}
                helperText={errors.password_repeat && errors.password_repeat.message}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <CardActions>
            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
              <Button size="small" color="primary" onClick={() => {}}>
                Cancel
              </Button>
              <Button type="submit" size="small" variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
                Update
              </Button>
            </Grid>
          </CardActions>
        </form>
      </Paper>
    </div>
  );
});

export default ConfirmPassword;
