import React, { useState, useContext } from 'react';

import { useForm } from 'react-hook-form';

import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../../../UI/Loader';
import { LanguageContext } from '../../../../context/states/languageState';

const AddItem = React.memo((props) => {
  const { open, handleClose } = props;
  const { addDialectsHandler } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const onSubmit = async (formData) => {
    await addDialectsHandler(formData);
    await modalClose();
  };

  const modalClose = () => {
    handleClose();
    reset({ dialect: '' });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Typography variant="h6" gutterBottom>
              New Dialect
            </Typography>

            <IconButton aria-label="close" size="small" onClick={modalClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <DevTool control={control} /> */}
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <TextField
                  label="Dialect"
                  size="small"
                  fullWidth
                  name="dialect"
                  variant="outlined"
                  inputRef={register({
                    required: true,
                    minLength: { value: 2, message: 'Must have at least 2 letters' },
                    maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                  })}
                  error={!!errors.dialect}
                  helperText={errors.dialect && errors.dialect.message}
                  autoFocus
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)} disabled={!isDirty && !isValid}>
            Save
          </Button>
        </DialogActions>

        <Loader open={isLoading} handleClose={backdropCloseHandler} />
      </Dialog>
    </React.Fragment>
  );
});

export default AddItem;
