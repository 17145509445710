import { makeStyles, lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    female:
        theme.palette.type === 'light' || 'dark'
            ? {
                textTransform: 'capitalize',
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.7),
            }
            : {
                textTransform: 'capitalize',
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.secondary.contrastText,
            },
    male:
        theme.palette.type === 'light' || 'dark'
            ? {
                textTransform: 'capitalize',
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.7),
            }
            : {
                textTransform: 'capitalize',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.contrastText,
            },
    updateNotify: {
        fontSize: '10px',
        fontWeight: 600,
        maxidth: '100%',
        padding: '4px 8px',
        borderRadius: '4px',
        color: theme.palette.warning.main,
        backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    newNotify: {
        fontSize: '10px',
        fontWeight: 600,
        maxidth: '100%',
        padding: '4px 8px',
        borderRadius: '4px',
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    tableBorder: {
        borderWidth: 0,
        borderRightWidth: 0.5,
        borderBottomWidth: 0.5,
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        fontSize: '12px',
        padding: '0px 2px'
    },
    crChip: {
        fontSize: '10px',
        fontWeight: 600,
        width: '100%',
        // padding: '4px 2px',
        padding: '3px',
        marginBottom: '1px',
        borderRadius: '4px',
        color: theme.palette.success.dark,
        backgroundColor: lighten(theme.palette.success.main, 0.7),
        cursor: 'pointer'
    },
    tableCellServiceStyle: {
        width: '100%',
        maxWidth: '210px',
        minWidth: '100px',
        display: 'grid',
        gridGap: 0,
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '95px',
            minWidth: '60px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    tableCellStyle: {
        width: '100%',
        maxWidth: '210px',
        minWidth: '100px',
        display: 'grid',
        gridGap: 0,
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100px',
            minWidth: '62px',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    tableCellActionStyle: {
        width: '100%',
        maxWidth: '210px',
        minWidth: '100px',
        display: 'grid',
        gridGap: 0,
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridGap: '8px 0px',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '0px',
            maxWidth: '30px',
            minWidth: '60px'
        }
    },
    root: {
        '& .Cancel': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            borderRadius: '4px',
            color: '#00b8d4',
        },
        '& .Pending': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            borderRadius: '4px',
            color: theme.palette.warning.main,
        },
        '& .New': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            borderRadius: '4px',
            color: theme.palette.info.main,
        },
        '& .Quotation': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            borderRadius: '4px',
            color: theme.palette.success.main,
        },
        '& .Booking': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            padding: '4px 2px',
            borderRadius: '4px',
            color: '#b388ff',
        },
        '& .Booked': {
            fontSize: '10px',
            fontWeight: 600,
            width: '100%',
            padding: '4px 2px',
            borderRadius: '4px',
            color: '#b388ff',
        },
    },
}));