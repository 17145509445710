import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, InputBase, Paper, Toolbar, Typography, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/Email';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterListIcon from '@material-ui/icons/FilterList';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { AdvanceSearch } from './AdvanceSearch';
import { BookingContext } from '../../../../context/states/bookingState';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paperRoot: {
    width: 650,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
  minPaperRoot: {
    width: 400,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  advanceCard: {
    margin: theme.spacing(0.4, 0),
    marginLeft: theme.spacing(4),
    padding: theme.spacing(1),
    position: 'absolute',
    width: 650,
    minWidth: 650,
    overflow: 'auto',
    zIndex: 3,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  MenuItem: {
    fontSize: '14px',
  },
  listCount: {
    fontSize: '12px',
    fontWeight: 600,
    maxidth: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    color: theme.palette.text.disabled,
    backgroundColor: lighten(theme.palette.action.disabled, 0.9),
  },
}));

export const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, tableTitle, listCount, isCheckbox, isEnable, handleRefresh, handleCheckbox, handleFormModal, clearAdvancedSearchHandler, setIsSearchObj, isSearchObj } = props;
  const { isLanguages, getLangugagesHandler, isDialects, getDialectsHandler } = useContext(BookingContext);
  // console.log(`this is from table`, isStatus);

  const [isMinimize, setIsMinimize] = useState(false)
  const minimizeHandler = () => setIsMinimize(!isMinimize)

  const [isOpen, setIsOpen] = useState(false);
  const advancedToggleHandler = () => {
    setIsOpen(true)
  }
  const closeAdvanceModelHandler = () => {
    setIsOpen(false)
  }

  const inputSearchRef = useRef();
  const [filterActive, setFilterActive] = useState(null);
  const handleFilterOpen = (event) => setFilterActive(event.currentTarget);
  const handleFilterClose = (event) => setFilterActive(null);

  const [isMenuList, setIsMenuList] = useState(null);
  const handleMenuList = (event) => setIsMenuList(event.currentTarget);
  const handleMenuListClose = () => setIsMenuList(null);

  useEffect(() => {
    if (localStorage.getItem('baSearch') !== null) {
      setIsMinimize(true)
    }
    let unmounted = false;

    if (!unmounted) {
      getLangugagesHandler();
      getDialectsHandler();
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickToSearchHandler = useCallback((event) => {
    var code = event.key
    if (code === 'Enter') {
      event.preventDefault()
      setTimeout(() => {
        setIsSearchObj({ ...isSearchObj, sn: inputSearchRef.current?.value })
      }, 1000)
    }
  }, [isSearchObj])

  const filterHandler = useCallback((value) => {
    setIsSearchObj({ ...isSearchObj, status: value })
  },
    [isSearchObj]
  );

  const clearMainSearchHandler = useCallback(() => {
    inputSearchRef.current.value = ''
    setIsSearchObj({ ...isSearchObj, sn: '' })
  }, [setIsSearchObj])

  const filterOptions = [
    { id: 0, title: 'All', option: '' },
    { id: 1, title: 'Pending', option: 'Pending' },
    { id: 2, title: 'Booking', option: 'Booking' },
    { id: 3, title: 'Quotation', option: 'Quotation' },
    { id: 4, title: 'Confirmed', option: 'Confirmed' },
    // { id: 5, title: 'Attended but no timesheet', option: 'Attended but no timesheet' },
    { id: 6, title: 'Timesheet Submitted', option: 'Timesheet Submitted' },
    { id: 7, title: 'Approved', option: 'Approved' },
    { id: 8, title: 'Paid', option: 'Paid' },
    { id: 9, title: 'Quote Supplied', option: 'Quote Supplied' },
    // { id: 10, title: 'Company Outstanding', option: 'Company Outstanding' },
    { id: 11, title: 'Cancelled', option: 'Cancel' },
  ];

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container direction="row" justify="space-between" alignItems="center">
          {/* Toolbar title */}
          <Grid item>
            {numSelected > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {tableTitle} | <span className={classes.listCount}>{listCount}</span>
              </Typography>
            )}
          </Grid>

          {numSelected > 0 ? (
            <Tooltip title="Email To">
              <IconButton aria-label="emailTo">
                <EmailIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <React.Fragment>
              {/* Search bar */}
              <Grid item>
                <Hidden only={['sm', 'xs']}>
                  <Paper component="form" className={classes.paperRoot}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item md={1} className={classes.sectionDesktop}>
                        <IconButton aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={10}>
                        <InputBase
                          placeholder="Search"
                          style={{ width: '100%' }}
                          inputProps={{ 'aria-label': 'search' }}
                          inputRef={inputSearchRef}
                          defaultValue={isSearchObj?.sn}
                          onKeyPress={clickToSearchHandler}
                        />
                      </Grid>
                      <Grid item md={1}>
                        {isSearchObj?.sn?.length >= 0 ?
                          <Tooltip title="Clear search">
                            <IconButton aria-label="maximize modal" color="primary" onClick={clearMainSearchHandler}>
                              <ClearAllIcon />
                            </IconButton>
                          </Tooltip>
                          : <IconButton aria-label="search" onClick={advancedToggleHandler}>
                            <ArrowDropDownIcon />
                          </IconButton>}

                      </Grid>
                    </Grid>
                  </Paper>
                  {/* Advance search */}
                  {isOpen && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <AdvanceSearch
                        classes={classes}
                        handleToggle={closeAdvanceModelHandler}
                        clearAdvancedSearchHandler={clearAdvancedSearchHandler}
                        isLanguages={isLanguages}
                        isDialects={isDialects}
                        isMinimize={isMinimize}
                        handleMinimize={minimizeHandler}
                        setIsSearchObj={setIsSearchObj}
                        isSearchObj={isSearchObj}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Hidden>
              </Grid>
              {/* Right action */}
              <Grid item>
                {/* Hide in small screen*/}
                <Hidden only={['sm', 'xs']}>
                  <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                    {/* Filter icon */}
                    <Grid item xs>
                      <Tooltip title="Filter status">
                        <IconButton aria-label="filter status list" aria-haspopup="true" onClick={handleFilterOpen}>
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>

                      <Menu id="filter-menu" anchorEl={filterActive} keepMounted open={Boolean(filterActive)} onClose={handleFilterClose}>
                        {filterOptions.map((item, index) => {
                          return (
                            <MenuItem
                              className={classes.MenuItem}
                              key={item.id}
                              selected={item.option === isSearchObj.status}
                              onClick={() => filterHandler(item.option, index)}
                            >
                              {item.title}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </Grid>
                    {/* Refresh icon */}
                    <Grid item xs>
                      <Tooltip title="Refresh">
                        <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={handleRefresh}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {/* Toggle icon */}
                    {isEnable && (
                      <Grid item xs>
                        <Tooltip title={isCheckbox ? 'Disable Checkbox' : 'Enable Checkbox'}>
                          {isCheckbox ? (
                            <IconButton aria-label="Checkbox" color="primary" onClick={handleCheckbox}>
                              <ToggleOnIcon />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="Checkbox" color="secondary" onClick={handleCheckbox}>
                              <ToggleOffIcon />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Grid>
                    )}
                    {/* Add icon */}
                    <Grid item xs>
                      <Tooltip title="Add">
                        <IconButton aria-label="Add" color="primary" aria-haspopup="true" onClick={handleFormModal}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Hidden>
                {/* Appear in small screen */}
                <Hidden mdUp>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={6}>
                      <Paper component="form" className={classes.minPaperRoot}>
                        <InputBase
                          placeholder="Search"
                          inputProps={{ 'aria-label': 'search' }}
                          inputRef={inputSearchRef}
                          defaultValue={isSearchObj.sn}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton aria-label="show more" aria-haspopup="true" color="inherit" onClick={handleMenuList}>
                        <MoreIcon />
                      </IconButton>
                      <Menu id="simple-menu" anchorEl={isMenuList} keepMounted open={Boolean(isMenuList)} onClose={handleMenuListClose}>
                        <MenuItem onClick={handleFormModal}>Add</MenuItem>
                        <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
    </React.Fragment>
  );
};

TableToolbar.propTypes = { numSelected: PropTypes.number.isRequired, handleFormModal: PropTypes.func.isRequired };
