import React, { useState, useContext, useCallback } from 'react';
import moment from 'moment';

import { List, ListItem, ListItemIcon, ListItemText, Grid, TextField, Button, Typography } from '@material-ui/core'
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddIcon from '@material-ui/icons/Add';
import StickyNote from '../icons/Note';

import { AuthContext } from '../../context/states/authState';

const Notes = ({ notes, data, onSave }) => {
  const { userData } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState('');

  const addNotes = () => {
    const notes = {
      id: data._id,
      text: inputValue,
      updatedBy: userData.user,
    };

    try {
      // await updateProfileNotesHandler(notes);
      onSave(notes)
      setInputValue('');
    } catch (error) {
      console.log(error);
    }
  };


  const notesList = notes.length < 1 ? <StickyNote /> : notes?.map((p, i) => (
    <ListItem key={i}>
      <ListItemIcon>
        <RecentActorsIcon />
      </ListItemIcon>
      <ListItemText primary={p.text} secondary={p.updatedBy + ' | ' + moment(p.updated).format('DD-MM-yyyy')} />
    </ListItem>
  ))

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
            Notes
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <TextField
            id="note"
            label="Notes"
            size="small"
            fullWidth
            variant="outlined"
            value={inputValue || ''}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={1}>
          <Button onClick={addNotes} disabled={inputValue.length === 0}>
            <AddIcon />
            Add
          </Button>
        </Grid>

        <Grid item xs={12}>
          <div style={{ height: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
            <List dense={true}>{notesList}</List>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Notes