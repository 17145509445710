import React from "react";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from '@hookform/devtools';

import {
	Grid,
	Typography,
	Paper,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const PersonalDetails = (props) => {
	const { classes, handleClose, handleOnSubmit, data, isRateStructures } = props;
	const formValue = {
		_id: data._id,
		companyName: data.companyName,
		contactPerson: data.contactPerson,
		dor: data.dor,
		email: data.email,
		location: data.location,
		mobile: data.mobile,
		phone: data.phone,
		address: data.address,
		postcode: data.postcode,
		position: data.position,
		companyStatus: data.companyStatus,
		acEmailOne: data.acEmailOne,
		acEmailTwo: data.acEmailTwo,
		acName: data.acName,
		acNumber: data.acNumber,
		renewalDueDate: data?.renewalDueDate,
		billingRate: data?.billingRate,
	};
	const { register, handleSubmit, errors, control, formState } = useForm({
		mode: "onChange",
		defaultValues: formValue,
	});

	const onSubmit = (data) => {
		handleOnSubmit(data);
	};

	return (
		<React.Fragment>
			{/* <DevTool control={control} /> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={1}
				>
					{/* Fields section */}
					<Grid
						item
						xs={12}
					>
						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingBottom: "5px" }}
									color="primary"
								>
									Client Details
								</Typography>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={12}
										sm={5}
										md={3}
									>
										<TextField
											label="Company Name"
											size="small"
											fullWidth
											name="companyName"
											variant="outlined"
											inputRef={register({
												required: true,
												minLength: {
													value: 2,
													message: "Must have at least 2 letters",
												},
												maxLength: {
													value: 100,
													message: "Maximum characters limit is 100",
												},
											})}
											error={!!errors.companyName}
											helperText={
												errors.companyName && errors.companyName.message
											}
											autoFocus
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={2}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="dor"
													label="Date of Registration"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.dor}
													helperText={errors.dor && errors.dor.message}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="dor"
											rules={{ required: true }}
											error={!!errors.dob}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={5}
										md={3}
									>
										<TextField
											label="Contact Person"
											size="small"
											fullWidth
											variant="outlined"
											name="contactPerson"
											inputRef={register({
												required: true,
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
											})}
											error={!!errors.contactPerson}
											helperText={
												errors.contactPerson && errors.contactPerson.message
											}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={5}
										md={2}
									>
										<TextField
											label="Position"
											size="small"
											fullWidth
											variant="outlined"
											name="position"
											inputRef={register({
												required: true,
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
											})}
											error={!!errors.position}
											helperText={errors.position && errors.position.message}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={2}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="renewalDueDate"
													label="Renewal Due Date"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.renewalDueDate}
													helperText={
														errors.renewalDueDate &&
														errors.renewalDueDate.message
													}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													disablePast
												/>
											}
											control={control}
											name="renewalDueDate"
											rules={{ required: true }}
											error={!!errors.renewalDueDate}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>

						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
								spacing={1}
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingBottom: "5px" }}
									color="primary"
								>
									Contact Details
								</Typography>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={12}
										sm={4}
										md={6}
									>
										<TextField
											label="Email"
											size="small"
											fullWidth
											variant="outlined"
											name="email"
											inputRef={register({
												required: true,
												minLength: {
													value: 5,
													message: "Must have at least 5 letters",
												},
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
												pattern: {
													value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: "Invalid email-id",
												},
											})}
											error={!!errors.email}
											helperText={errors.email && errors.email.message}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<TextField
											id="mobile"
											label="Mobile"
											size="small"
											fullWidth
											variant="outlined"
											name="mobile"
											inputRef={register({
												required: true,
												minLength: {
													value: 10,
													message: "Must have at least 10 digits",
												},
												maxLength: {
													value: 20,
													message: "Maximum digits limit is 20",
												},
												pattern: {
													value: /^[0-9]+$/,
													message: "Invalid mobile number",
												},
											})}
											error={!!errors.mobile}
											helperText={errors.mobile && errors.mobile.message}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<TextField
											id="phone"
											label="Phone"
											size="small"
											fullWidth
											variant="outlined"
											name="phone"
											inputRef={register({
												required: true,
												pattern: {
													value: /^[0-9]+$/,
													message: "Invalid phone number",
												},
												minLength: {
													value: 10,
													message: "Must have at least 10 digits",
												},
												maxLength: {
													value: 20,
													message: "Maximum digits limit is 20",
												},
											})}
											error={!!errors.phone}
											helperText={errors.phone && errors.phone.message}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={10}
									>
										<TextField
											id="address"
											label="Address"
											size="small"
											fullWidth
											variant="outlined"
											name="address"
											inputRef={register({
												required: true,
												minLength: {
													value: 5,
													message: "Must have at least 5 letters",
												},
												maxLength: {
													value: 100,
													message: "Maximum characters limit is 100",
												},
											})}
											error={!!errors.address}
											helperText={errors.address && errors.address.message}
										/>
									</Grid>
									<Grid
										item
										xs={10}
										sm={4}
										md={2}
									>
										<TextField
											label="Postcode"
											size="small"
											fullWidth
											variant="outlined"
											name="postcode"
											inputRef={register({
												required: true,
												pattern: {
													value: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
													message: "Invalid postcode",
												},
											})}
											error={!!errors.postcode}
											helperText={errors.postcode && errors.postcode.message}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<FormControl component="fieldset">
											<RadioGroup
												name="location"
												row
												defaultValue={
													data.location !== undefined ? data.location : ""
												}
											>
												<FormControlLabel
													value="In London"
													inputRef={register}
													control={<Radio color="primary" />}
													label="In London"
												/>
												<FormControlLabel
													value="Out of London"
													inputRef={register}
													control={<Radio color="primary" />}
													label="Out of London"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Paper>

						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingBottom: "5px" }}
									color="primary"
								>
									Accounts Contact:
								</Typography>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={12}
										sm={4}
										md={3}
									>
										<TextField
											label="Name"
											size="small"
											fullWidth
											variant="outlined"
											name="acName"
											inputRef={register({
												required: true,
												minLength: {
													value: 2,
													message: "Must have at least 2 letters",
												},
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
											})}
											error={!!errors.acName}
											helperText={errors.acName && errors.acName.message}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										md={4}
									>
										<TextField
											label="Email 1"
											size="small"
											fullWidth
											variant="outlined"
											name="acEmailOne"
											inputRef={register({
												required: true,
												minLength: {
													value: 5,
													message: "Must have at least 5 letters",
												},
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
												pattern: {
													value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: "Invalid email-id",
												},
											})}
											error={!!errors.acEmailOne}
											helperText={
												errors.acEmailOne && errors.acEmailOne.message
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										md={4}
									>
										<TextField
											label="Email 2"
											size="small"
											fullWidth
											variant="outlined"
											name="acEmailTwo"
											inputRef={register({
												required: false,
												minLength: {
													value: 5,
													message: "Must have at least 5 letters",
												},
												maxLength: {
													value: 50,
													message: "Maximum characters limit is 50",
												},
												pattern: {
													value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: "Invalid email-id",
												},
											})}
											error={!!errors.acEmailTwo}
											helperText={
												errors.acEmailTwo && errors.acEmailTwo.message
											}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										md={3}
									>
										<TextField
											label="Phone"
											size="small"
											fullWidth
											variant="outlined"
											name="acNumber"
											inputRef={register({
												required: true,
												// pattern: { value: /^\d+$/, message: 'Invalid account no' },
												minLength: {
													value: 10,
													message: "Must have at least 10 digits",
												},
												maxLength: {
													value: 20,
													message: "Maximum digits limit is 20",
												},
											})}
											error={!!errors.acNumber}
											helperText={errors.acNumber && errors.acNumber.message}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<FormControl
											variant="outlined"
											size="small"
											className={classes.formControl}
										>
											<InputLabel id="companyStatus-label">
												Company Status
											</InputLabel>
											<Controller
												as={
													<Select
														label="Company Status"
														labelId="companyStatus-label"
														id="company-status"
														name="companyStatus"
													>
														<MenuItem value="">
															<em>None</em>
														</MenuItem>
														<MenuItem value={"Private"}>
															Private
														</MenuItem>
														<MenuItem value={"Law Firm"}>
															Law Firm
														</MenuItem>
													</Select>
												}
												control={control}
												name="companyStatus"
												rules={{ required: true }}
												error={!!errors.companyStatus}
												labelWidth={55}
											/>
										</FormControl>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<FormControl
											variant="outlined"
											size="small"
											style={{ minWidth: 220 }}
											fullWidth
										>
											<InputLabel htmlFor="billing-rates-label">
												Billing Rates
											</InputLabel>
											<Controller
												as={
													<Select
														label="Billing Rates"
														inputProps={{
															name: "billing-rates",
															id: "billing-rates-label",
														}}
													>
														{isRateStructures?.map((data) => (
															<MenuItem
																key={data._id}
																value={data._id}
															>
																{data?.rateName}
															</MenuItem>
														))}
													</Select>
												}
												control={control}
												name="billingRate"
												rules={{ required: false }}
												error={!!errors.billingRate}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					{/* end right */}
					<Grid
						container
						direction="row"
						justify="flex-end"
						alignItems="flex-end"
						spacing={4}
					>
						<Grid
							item
							xs={1}
						>
							<Button
								size="small"
								color="primary"
								onClick={handleClose}
							>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={1}
						>
							<Button
								type="submit"
								size="small"
								variant="contained"
								color="primary"
								disabled={!formState.isDirty}
								onClick={handleSubmit(onSubmit)}
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</React.Fragment>
	);
};
