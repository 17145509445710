import { lighten, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  female:
    theme.palette.type === "light" || "dark"
      ? {
          textTransform: "capitalize",
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.7),
        }
      : {
          textTransform: "capitalize",
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.secondary.contrastText,
        },
  male:
    theme.palette.type === "light" || "dark"
      ? {
          textTransform: "capitalize",
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.7),
        }
      : {
          textTransform: "capitalize",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.contrastText,
        },
  updateNotify: {
    fontSize: "10px",
    fontWeight: 600,
    maxidth: "100%",
    padding: "4px 8px",
    borderRadius: "4px",
    color: theme.palette.warning.main,
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  newNotify: {
    fontSize: "10px",
    fontWeight: 600,
    maxidth: "100%",
    padding: "4px 8px",
    borderRadius: "4px",
    color: theme.palette.info.main,
    backgroundColor: lighten(theme.palette.info.light, 0.9),
  },
  tableBorder: {
    borderWidth: 0,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    fontSize: "12px",
    padding: "0px 2px",
  },
  crChip: {
    fontSize: "10px",
    fontWeight: 600,
    width: "100%",
    // padding: '4px 2px',
    padding: "3px",
    marginBottom: "1px",
    borderRadius: "4px",
    color: theme.palette.success.dark,
    backgroundColor: lighten(theme.palette.success.main, 0.7),
    cursor: "pointer",
  },
  piChip: {
    fontSize: "10px",
    fontWeight: 600,
    width: "100%",
    // padding: '4px 2px',
    padding: "3px",
    marginBottom: "1px",
    borderRadius: "4px",
    color: theme.palette.primary.dark,
    backgroundColor: lighten(theme.palette.primary.main, 0.7),
    cursor: "pointer",
  },
  isSplitChip: {
    fontSize: "10px",
    fontWeight: 600,
    width: "100%",
    // padding: '4px 2px',
    padding: "3px",
    marginBottom: "1px",
    borderRadius: "4px",
    color: theme.palette.secondary.dark,
    backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    cursor: "pointer",
  },
  tableCellServiceStyle: {
    width: "100%",
    maxWidth: "210px",
    minWidth: "100px",
    display: "grid",
    gridGap: 0,
    gridTemplateColumns: "repeat(2, 1fr)",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "95px",
      minWidth: "60px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  tableCellStyle: {
    width: "100%",
    maxWidth: "210px",
    minWidth: "100px",
    display: "grid",
    gridGap: 0,
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100px",
      minWidth: "62px",
      whiteSpace: "pre-line",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  tableCellActionStyle: {
    width: "100%",
    maxWidth: "210px",
    minWidth: "100px",
    display: "grid",
    gridGap: 0,
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: "8px 0px",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      gridGap: "0px",
      maxWidth: "30px",
      minWidth: "60px",
    },
  },
  advancePayment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    flexDirection: "column-reverse",
  },
  customerName: {
    display: "inline-block",
    width: "120px",
    whiteSpace: "break-spaces",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  root: {
    "& .Pending": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      // padding: '4px 8px',
      borderRadius: "4px",
      color: theme.palette.warning.main,
      cursor: "pointer",
      // backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .New": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      // padding: '4px 8px',
      borderRadius: "4px",
      color: "theme.palette.info.main",
      cursor: "pointer",
      // backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    "& .Quotation": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#3f51b5",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Booking": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#3f51b5",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Confirmed": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#f345f3", // 03a9f4
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Timesheet": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#00bcd4",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Approved": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#009688",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Paid": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#4caf50",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Cancel": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#f44336",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Quote": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#ff9800",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
    "& .Quote, .Supplied": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#616161",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
      cursor: "pointer",
    },
  },
  dot: {
    height: 5,
    width: 5,
    backgroundColor: lighten(theme.palette.secondary.light, 0.1),
    borderRadius: "50%",
    display: "inline-block",
  },
  serviceRoot: {
    "& .Telephone-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#2196f3",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .Video, .Call-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#8bc34a",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .Onsite-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#ff9800",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .Translation-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#f9a825",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .Transcription_Audio-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#ff5722",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .Transcription_Video-span": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "4px 8px",
      borderRadius: "4px",
      color: "#673ab7",
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
  },
  fileRef: {
    background: "rgb(148 148 151 / 40%)",
    fontWeight: 600,
    color: "#000",
    padding: "3px 6px",
    borderRadius: 4,
    fontSize: 10,
  },
}));
