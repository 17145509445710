export default (state, action) => {
	switch (action.type) {
		case "PROCESS_START":
			return {
				...state,
				loading: false,
				bookings: [],
				clients: [],
				totalInvs: 0,
				totalQuotes: 0,
				totalOutstanding: 0,
				error: null,
			};

		case "FETCH_RECORDS":
			return {
				...state,
				loading: false,
				bookings: action.payload.data,
				totalQuotes: action.payload.totalQuotes,
				totalInvs: action.payload.totalInvs,
				totalOutstanding: action.payload.totalOutstanding,
			};

		case "FETCH_CLIENT_RECORD":
			return {
				...state,
				clients: action.payload.data,
			};

		case "ERROR_CATCH":
			return {
				...state,
				error: action.payload,
			};

		default:
			return state;
	}
};
