import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        rates: action.payload,
        minRates: action.payload,
        loading: false,
      };

    case actionTypes.ADD_RECORD:
      return {
        ...state,
        rates: [action.payload.data, ...state.rates],
        clientRate: [action.payload.data, ...state.clientRate],
      };

    case actionTypes.UPDATE_RECORD:
      return {
        ...state,
        clientRate: state.clientRate.map((r) => {
          return r._id === action.payload._id ? action.payload : r;
        }),
        rates: state.rates.map((rate) => {
          return rate._id === action.payload._id ? action.payload : rate;
        }),
      };

    case 'SINGLE_PROCESS':
      return {
        ...state,
        load: true,
      };
    case actionTypes.FETCH_RECORD:
      return {
        ...state,
        load: false,
        rate: action.payload,
      };

    case 'GET_CLIENT_RATE':
      return {
        ...state,
        load: false,
        clientRate: action.payload,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        loading: false,
        load: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
