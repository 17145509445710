import React, { useEffect, useContext, useState, useCallback } from "react";
import { OutstandingDueContext } from "../../../../context/states/outstandingDueState";
import { AuthContext } from "../../../../context/states/authState";

import {
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  CircularProgress,
  Tooltip,
  IconButton,
  Chip,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHead";

import VisibilityIcon from "@material-ui/icons/Visibility";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ReceiptIcon from "@material-ui/icons/Receipt";

import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { DueDetails } from "./dueDetails/DueDetails";
import moment from "moment";
import CustomChip from "../../../customChip/customChip";

const headCells = [
  { id: "slno", numeric: false, disablePadding: false, label: "Sl.No" },
  {
    id: "paymentRef",
    numeric: false,
    disablePadding: false,
    label: "Payment ID",
  },
  {
    id: "allocatedDate",
    numeric: false,
    disablePadding: false,
    label: "Allocated on",
  },
  { id: "company", numeric: false, disablePadding: false, label: "Name" },
  { id: "totalAmount", numeric: false, disablePadding: false, label: "Amount" },
  { id: "balance", numeric: false, disablePadding: false, label: "Credit" },
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: false,
    label: "Paid on",
  },
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    label: "Allocated Booking Refs",
  },
  { id: "edit", numeric: false, disablePadding: false, label: "Action" },
];

const initialState = {
  isSearch: false,
  companyId: "",
  company: "",
  bookingRef: "",
  paymentRef: "",
  fromDate: null,
  toDate: null,
  isCredit: false,
};

export const DueLists = (props) => {
  console.log("OUTSTANDING CALLING");
  const { classes, stableSort, getComparator, title } = props;
  const {
    loading,
    getOutstandingDuesHandler,
    data,
    total,
    getOutstandingDueHandler,
    dues,
    load,
    makePaymentHandler,
    submitteData,
    getClientsHandler,
    clients,
  } = useContext(OutstandingDueContext);

  const { userData } = useContext(AuthContext);
  const matches = useMediaQuery("(min-width:1920px)");

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [dense, setDense] = useState(true);
  const [isDueModal, setIsDueModal] = useState(false);
  const [isName, setIsName] = useState({});

  const [isPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [values, setValues] = useState({ ...initialState });
  const [searchString, setSearchString] = useState(undefined);

  useEffect(() => {
    let query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;

    if (searchString) query += `&${searchString}`;

    getOutstandingDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, isPage, searchString]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [setSelected]
  );

  // DueDetail model
  const closeDueModalHandler = useCallback(() => {
    setIsDueModal(false);
  }, []);

  const closeSubmitHandle = useCallback(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getOutstandingDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PendingDues = React.useMemo(
    () => (
      <DueDetails
        open={isDueModal}
        handleClose={closeDueModalHandler}
        closeSubmitHandle={closeSubmitHandle}
        isLoading={load}
        pData={dues}
        companyData={isName}
        submitHandler={makePaymentHandler}
        approvedBy={userData.user}
        submitteData={submitteData}
      />
    ),
    [
      !isDueModal,
      dues,
      isName,
      makePaymentHandler,
      userData,
      submitteData,
      closeSubmitHandle,
      closeDueModalHandler,
    ]
  );
  const openDueModalHandler = useCallback(
    (id, comp) => {
      const company = {
        id,
        companyId: comp.companyId,
        name: comp.company,
      };
      setIsDueModal(true);
      setIsName(company);
      getOutstandingDueHandler(id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isName]
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage]
  );

  const handleChange = (event) => {
    console.log("click", event);
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleFromDateChange = (event) => {
    const date = new Date(event).toISOString();
    console.log("date", date);
    setValues({ ...values, fromDate: date });
  };

  const handleToDateChange = (event) => {
    const date = new Date(event).toISOString();
    console.log("date", date);
    setValues({ ...values, toDate: date });
  };

  const handleChangeAutoComplete = (name) => (event, value) => {
    console.log("event", name, value);
    if (value === null) {
      setValues({ ...initialState });
      // clearStateHandler()
      return;
    }
    if (name === "company") {
      setValues({ ...values, [name]: value, companyId: value._id });
    } else if (name === "bookingRef") {
      setValues({ ...values, [name]: value });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value === "") return;
    getClientsHandler(event.target.value);
  };

  const handleBooking = (e) => {
    console.log(e.target.name);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // const searchHandler = () => {
  //   setValues({ ...values, isSearch: true })
  //   // let query = values.private ? `private=${values.private}` : `companyId=${values.companyId}`
  //   let filteredDate = `?companyId=${values.companyId}`;

  //   if(values.bookingRef !== '') {
  //     filteredDate += `&bookingRef=${values.bookingRef}`
  //   }
  //   if (values.fromDate === null) {
  //     // filteredDate = ''
  //   } else if (values.fromDate !== null) {
  //     filteredDate += `&fromDate=${values.fromDate}`
  //     if (values.toDate !== null) {
  //       console.log('to date', values.toDate)
  //       filteredDate += `&toDate=${values.toDate}`
  //     }
  //   }

  //   if (values.isCredit) {
  //     filteredDate += `&isCredit=${values.isCredit}`
  //   }

  //   console.log('filteredDate', values.companyId)
  //   getOutstandingDuesHandler(filteredDate)
  // }

  const searchHandler = () => {
    const url = new URL("https://www.test.com/");
    setValues({ ...values, isSearch: true });

    if (values.companyId) {
      url.searchParams.set("companyId", values.companyId);
    }
    if (values.bookingRef !== "") {
      url.searchParams.set("bookingRef", values.bookingRef);
    }

    if (values.paymentRef !== "") {
      url.searchParams.set("paymentRef", values.paymentRef);
    }

    if (values.fromDate === null) {
      // filteredDate = ''
    } else if (values.fromDate !== null) {
      url.searchParams.set("fromDate", values.fromDate);
      if (values.toDate !== null) {
        url.searchParams.set("toDate", values.toDate);
      }
    }

    if (values.isCredit) {
      url.searchParams.set("isCredit", values.isCredit);
    }

    const params = decodeURIComponent(url.toString()).split("?")[1];
    setPage(0);
    setSearchString(params);
  };

  const clearSearchHandler = () => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getOutstandingDuesHandler(query);
    setValues({ ...initialState });
    setSearchString(undefined);
    setPage(0);
  };

  return (
    <React.Fragment>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleOpen={() => {}}
        title={title}
        handleInputChange={handleInputChange}
        handleChangeAutoComplete={handleChangeAutoComplete}
        handleChange={handleChange}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        values={values}
        isClients={clients}
        searchHandler={searchHandler}
        clearSearchHandler={clearSearchHandler}
        handleBooking={handleBooking}
      />
      <TableContainer
        className={matches ? classes.container2 : classes.container}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow hover>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  No record
                </TableCell>
              </TableRow>
            ) : (
              stableSort(data, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell align="left">
                        {index + 1 + isPage * rowsPerPage}
                      </TableCell>
                      <TableCell style={{ minWidth: 105, padding: "8px 14px" }}>
                        {row.paymentRef}
                      </TableCell>
                      <TableCell style={{ minWidth: 110, padding: "8px 4px" }}>
                        {moment(row.allocatedDate).format("DD-MM-Y")}
                      </TableCell>
                      <TableCell
                        style={{ width: "50%", padding: "8px 4px" }}
                        align="left"
                      >
                        {row?.company}
                        {row?.companyId === undefined ? (
                          <CustomChip title={"Private"} />
                        ) : null}
                      </TableCell>
                      <TableCell style={{ padding: "8px 4px" }} align="left">
                        £ {row?.totalAmount}
                      </TableCell>
                      <TableCell style={{ padding: "8px 4px" }} align="left">
                        £ {row?.balance}
                      </TableCell>
                      <TableCell
                        style={{ minWidth: 100, padding: "8px 4px" }}
                        align="left"
                      >
                        {moment(row.paymentDate).format("DD-MM-Y")}
                      </TableCell>
                      <TableCell
                        style={{ width: "100%", padding: "8px 4px" }}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {row?.details?.map((booking, i) => (
                          <>
                            <Chip
                              style={{
                                margin: "0.1rem",
                                lineHeight: "0.9",
                              }}
                              title="Booking"
                              key={`booking_${i}`}
                              variant="outlined"
                              // size="small"
                              label={
                                <>
                                  <span
                                    title={`Booking Ref: ${booking?.bookingRef}`}
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {booking?.bookingRef}
                                  </span>
                                  <br />
                                  <span
                                    title={`Invoice No: ${booking?.invoiceNo}`}
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: 600,
                                      color: "blue",
                                    }}
                                  >
                                    {booking?.invoiceNo}
                                  </span>
                                </>
                              }
                            />
                          </>
                        ))}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={`Pending Due: ${row.paymentRef}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => openDueModalHandler(row._id, row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[100, 150, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {load && PendingDues} {/* Interpreter Dues modal */}
    </React.Fragment>
  );
};
