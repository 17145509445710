import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';
import { FileProvider } from '../../../context/states/fileState';

import Grid from '@material-ui/core/Grid';

import { Clients } from '../../../components/pages/masters/Clients/Clients';
import { ClientProvider } from '../../../context/states/clientState';
import { RateProvider } from '../../../context/states/rateState';
// import EditInterpreter from '../../../components/pages/interpreters/interpreterForms/EditInterpreter';

const ClientMasters = () => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <RateProvider>
        <ClientProvider>
          <FileProvider>
            <Grid item xs={12}>
              <Switch>
                <AuthenticatedRoute exact path={path}>
                  <Clients title="Client Tracker" />
                </AuthenticatedRoute>
                {/* <AuthenticatedRoute path={`${path}/edit/:id`}>
                <EditInterpreter />
              </AuthenticatedRoute> */}
              </Switch>
            </Grid>
          </FileProvider>
        </ClientProvider>
      </RateProvider>
    </React.Fragment>
  );
};

export default ClientMasters;
