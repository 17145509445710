import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AuthenticatedRoute from "../../../middlewares/AuthenticatedRoute";
import { SOAReportProvider } from "../../../context/states/reports/SOAReportState";
import { AccountStatementProvider } from "../../../context/states/reports/accountStatementState";
import { InvoiceSummaryProvider } from "../../../context/states/reports/invoiceSummaryState";
import { InvoicePaymentProvider } from "../../../context/states/reports/invoicePaymentState";
import { BookingReportProvider } from "../../../context/states/reports/bookingReportState";

// pages
import StatementOnAccount from "./StatementOnAccount";
import AccountStatement from "./AccountStatement";
import InvoiceSummary from "./InvoiceStatement";
import InvoicePayment from "./InvoicePayment";
import BookingsReport from "./Bookings";

const Reports = () => {
	const { path } = useRouteMatch();

	return (
		<React.Fragment>
			<Switch>
				<AuthenticatedRoute
					exact
					path={`${path}/statementOnAccount`}
				>
					<SOAReportProvider>
						<StatementOnAccount />
					</SOAReportProvider>
				</AuthenticatedRoute>
				<AuthenticatedRoute
					exact
					path={`${path}/accountStatement`}
				>
					<AccountStatementProvider>
						<AccountStatement />
					</AccountStatementProvider>
				</AuthenticatedRoute>

				<AuthenticatedRoute
					exact
					path={`${path}/invoiceSummary`}
				>
					<InvoiceSummaryProvider>
						<InvoiceSummary />
					</InvoiceSummaryProvider>
				</AuthenticatedRoute>

				<AuthenticatedRoute
					exact
					path={`${path}/invoicePayment`}
				>
					<InvoicePaymentProvider>
						<InvoicePayment />
					</InvoicePaymentProvider>
				</AuthenticatedRoute>

				<AuthenticatedRoute
					exact
					path={`${path}/booking`}
				>
					<BookingReportProvider>
						<BookingsReport />
					</BookingReportProvider>
				</AuthenticatedRoute>
			</Switch>
		</React.Fragment>
	);
};

export default Reports;
