import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { DevTool } from '@hookform/devtools';

import { useForm } from 'react-hook-form';
import { Grid, Paper, TextField, Button, FormControl, Checkbox, Typography, FormGroup, FormControlLabel, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { InterpreterContext } from '../../../../../context/states/interpreterState';

import Loader from '../../../../UI/Loader';

export const Languages = (props) => {
  const { classes, data, handleClose, isAccessTo } = props;
  const { updateInterpreteLanguagesrHandler, isLanguages, isDialects, getLangugagesHandler, getDialectsHandler } = useContext(InterpreterContext);
  const today = moment().format();
  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const formValue = {
    _id: data._id,
    languages: data.languages,
  };

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      languages: formValue,
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await updateInterpreteLanguagesrHandler(data);
      backdropCloseHandler();
    } catch (error) {
      console.log(error);
      backdropCloseHandler();
    }
  };

  useEffect(() => {
    getLangugagesHandler(); // eslint-disable-next-line
    getDialectsHandler(); // eslint-disable-next-line
  }, [data]);

  const handleChange = (e, targetName) => {
    console.log(e);
    setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, { shouldDirty: true });
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        {/* <DevTool control={control} /> */}
        <input ref={register} name="_id" style={{ display: 'none' }} defaultValue={data._id} />
        <input type="hidden" name="updatedAt" ref={register} defaultValue={today} />
        <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.language : '', 'languages[0].language')}
                    defaultValue={{ language: data?.languages[0]?.language || '' }}
                    getOptionLabel={(option) => option.language}
                    renderOption={(option) => <>{option.language}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[0]?.language || ''}
                    name="languages[0].language"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  {/* <TextField
                    id="dialect0"
                    label="Dialect"
                    size="small"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="languages[0].dialect"
                    inputRef={register}
                    defaultValue={data.languages[0] !== undefined ? data.languages[0].dialect : ''}
                  /> */}
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.dialect : '', 'languages[0].dialect')}
                    defaultValue={{ dialect: data?.languages[0]?.dialect || '' }}
                    getOptionLabel={(option) => option.dialect}
                    renderOption={(option) => <>{option.dialect}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[0]?.dialect || ''}
                    name="languages[0].dialect"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText>Services</FormHelperText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[0].services[0]"
                            value="Telephone"
                            defaultChecked={data?.languages[0]?.services[0] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Telephone
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[0].services[1]"
                            value="Video Call"
                            defaultChecked={data?.languages[0]?.services[1] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Video Call
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[0].services[2]"
                            value="Onsite"
                            defaultChecked={data?.languages[0]?.services[2] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Onsite
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[0].services[3]"
                            value="Translation"
                            defaultChecked={data?.languages[0]?.services[3] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Translation
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[0].services[4]"
                            value="Transcription"
                            defaultChecked={data?.languages[0]?.services[4] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Transcription
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {/* section 1 */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.language : '', 'languages[1].language')}
                    defaultValue={{ language: data?.languages[1]?.language || '' }}
                    getOptionLabel={(option) => option.language}
                    renderOption={(option) => <>{option.language}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[1]?.language || ''}
                    name="languages[1].language"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.dialect : '', 'languages[1].dialect')}
                    defaultValue={{ dialect: data?.languages[1]?.dialect || '' }}
                    getOptionLabel={(option) => option.dialect}
                    renderOption={(option) => <>{option.dialect}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[1]?.dialect || ''}
                    name="languages[1].dialect"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText>Services</FormHelperText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[1].services[0]"
                            value="Telephone"
                            defaultChecked={data?.languages[1]?.services[0] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Telephone
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[1].services[1]"
                            value="Video Call"
                            defaultChecked={data?.languages[1]?.services[1] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Video Call
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[1].services[2]"
                            value="Onsite"
                            defaultChecked={data?.languages[1]?.services[2] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Onsite
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[1].services[3]"
                            value="Translation"
                            defaultChecked={data?.languages[1]?.services[3] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Translation
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[1].services[4]"
                            value="Transcription"
                            defaultChecked={data?.languages[1]?.services[4] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Transcription
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {/* section 2 */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.language : '', 'languages[2].language')}
                    defaultValue={{ language: data?.languages[2]?.language || '' }}
                    getOptionLabel={(option) => option.language}
                    renderOption={(option) => <>{option.language}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[2]?.language || ''}
                    name="languages[2].language"
                    ref={register}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.dialect : '', 'languages[2].dialect')}
                    defaultValue={{ dialect: data?.languages[2]?.dialect || '' }}
                    getOptionLabel={(option) => option.dialect}
                    renderOption={(option) => <>{option.dialect}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[2]?.dialect || ''}
                    name="languages[2].dialect"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText>Services</FormHelperText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[2].services[0]"
                            value="Telephone"
                            defaultChecked={data?.languages[2]?.services[0] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Telephone
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[2].services[1]"
                            value="Video Call"
                            defaultChecked={data?.languages[2]?.services[1] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Video Call
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[2].services[2]"
                            value="Onsite"
                            defaultChecked={data?.languages[2]?.services[2] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Onsite
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[2].services[3]"
                            value="Translation"
                            defaultChecked={data?.languages[2]?.services[3] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Translation
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[2].services[4]"
                            value="Transcription"
                            defaultChecked={data?.languages[2]?.services[4] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Transcription
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {/* section 3 */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isLanguages}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.language : '', 'languages[3].language')}
                    defaultValue={{ language: data?.languages[3]?.language || '' }}
                    getOptionLabel={(option) => option.language}
                    renderOption={(option) => <>{option.language}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a language"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[3]?.language || ''}
                    name="languages[3].language"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    options={isDialects}
                    filterSelectedOptions
                    onChange={(e, value) => handleChange(value !== null ? value.dialect : '', 'languages[3].dialect')}
                    defaultValue={{ dialect: data?.languages[3]?.dialect || '' }}
                    getOptionLabel={(option) => option.dialect}
                    renderOption={(option) => <>{option.dialect}</>}
                    disabled={isAccessTo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dialect"
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                  <input
                    style={{ display: 'none' }}
                    defaultValue={data?.languages[3]?.dialect || ''}
                    name="languages[3].dialect"
                    ref={register}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText>Services</FormHelperText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[3].services[0]"
                            value="Telephone"
                            defaultChecked={data?.languages[3]?.services[0] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Telephone
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[3].services[1]"
                            value="Video Call"
                            defaultChecked={data?.languages[3]?.services[1] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Video Call
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[3].services[2]"
                            value="Onsite"
                            defaultChecked={data?.languages[3]?.services[2] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Onsite
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[3].services[3]"
                            value="Translation"
                            defaultChecked={data?.languages[3]?.services[3] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Translation
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[3].services[4]"
                            value="Transcription"
                            defaultChecked={data?.languages[3]?.services[4] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Transcription
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              {/* section 4 */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="language3"
                    label="Other Language"
                    size="small"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="languages[4].language"
                    inputRef={register}
                    defaultValue={data?.languages[4]?.language || ''}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="dialect0"
                    label="Dialect"
                    size="small"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="languages[4].dialect"
                    inputRef={register}
                    defaultValue={data?.languages[4]?.dialect || ''}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText>Services</FormHelperText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[4].services[0]"
                            value="Telephone"
                            defaultChecked={data?.languages[4]?.services[0] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Telephone
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[4].services[1]"
                            value="Video Call"
                            defaultChecked={data?.languages[4]?.services[1] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Video Call
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[4].services[2]"
                            value="Onsite"
                            defaultChecked={data?.languages[4]?.services[2] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Onsite
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[4].services[3]"
                            value="Translation"
                            defaultChecked={data?.languages[4]?.services[3] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Translation
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="languages[4].services[4]"
                            value="Transcription"
                            defaultChecked={data?.languages[4]?.services[4] || ''}
                            disabled={isAccessTo}
                          />
                        }
                        inputRef={register}
                        label={
                          <React.Fragment>
                            <Typography variant="body2" gutterBottom>
                              Transcription
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!isAccessTo && (
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <div className={classes.actionBtn}>
              <Button size="small" color="primary" onClick={handleClose}>
                Cancel
            </Button>
              <Button type="submit" size="small" variant="contained" color="primary" disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
                Update
            </Button>
            </div>
          </Grid>
        )}
      </form>
      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
};

Languages.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
