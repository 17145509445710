import React, { useMemo } from 'react'
import { AddNotes } from '../Forms/AddNotes';

function MemoizedAddNotes({ isNotes, booking, notesCloseHandler, onNoteSubmitHandler }) {
    return useMemo(() => {
        return <AddNotes open={isNotes} handleClose={notesCloseHandler} data={booking} onSubmitHandler={onNoteSubmitHandler} />
    }, [isNotes,
        booking,
        notesCloseHandler,
        onNoteSubmitHandler])
}

export default MemoizedAddNotes;