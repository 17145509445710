import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        terms: action.payload,
        loading: false,
      };

    case actionTypes.ADD_RECORD:
      return {
        ...state,
        terms: [action.payload.data, ...state.terms],
      };

    case actionTypes.UPDATE_RECORD:
      return {
        ...state,
        terms: state.terms.map((term) => {
          return term._id === action.payload._id ? action.payload : term;
        }),
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
