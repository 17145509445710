import React, { useMemo } from 'react'
import { SendQuote } from '../Views/QuoteModal/sendQuote';

function MemoizedSendQuote({ isOpen, load, closeHandler, handleOnSubmit }) {
    return useMemo(
        () => <SendQuote open={isOpen} loading={load} handleClose={closeHandler} onSubmitHandler={handleOnSubmit} />,
        [isOpen, load, closeHandler, handleOnSubmit]
    )
}

export default MemoizedSendQuote;