import React, { createContext, useReducer, useContext } from 'react';
import RateReducer from '../reducers/rateReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  load: false,
  rates: [],
  clientRate: [],
  rate: {},
  minRates: [],
  error: null,
};

// creating context
export const RateContext = createContext(initialState);

// provider component
export const RateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RateReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All Rates data */
  const getRatesHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.RateStructures);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New Rates data */
  const addRateHandler = async (data) => {
    const result = await axios.post(urlRequest.RateStructures, data);
    dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Rate created' });
    }
    return await result;
  };

  /* Update interpreter by id */
  const updatedRateHandler = async (data) => {
    const result = await axios.put(`${urlRequest.RateStructures}/${data._id}`, data);
    dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Rate updated' });
    }

    return await result;
  };

  /* Add New Rates data */
  const getClientRateHandler = async (clientRef) => {
    dispatch({ type: 'SINGLE_PROCESS' });
    try {
      const result = await axios.get(`${urlRequest.ClientRate}/${clientRef}`);
      dispatch({ type: 'GET_CLIENT_RATE', payload: result.data.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New Rates data */
  const getRateHandler = async (id) => {
    dispatch({ type: 'SINGLE_PROCESS' });
    try {
      const result = await axios.get(`${urlRequest.RateStructures}/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getMinRatesHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.minRates);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <RateContext.Provider
      value={{
        loading: state.loading,
        isRates: state.rates,
        load: state.load,
        isClientRate: state.clientRate,
        isRate: state.rate,
        isMinRates: state.minRates,
        getRatesHandler,
        addRateHandler,
        updatedRateHandler,
        getRateHandler,
        getClientRateHandler,
        getMinRatesHandler,
      }}
    >
      {children}
    </RateContext.Provider>
  );
};
