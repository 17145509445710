import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { HashRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/states/authState';
import { AlertProvider } from './context/states/alertState';


const app = (
  <AlertProvider>
    <AuthProvider>
      <Router >
        <App />
      </Router>
    </AuthProvider>
  </AlertProvider>
);

ReactDOM.render(app, document.getElementById('root'));
