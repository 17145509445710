import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

// importing context
import { LanguageContext } from '../../../../context/states/languageState';
import { DataSource } from '../../../UI/Datasource/DataSource';
import AddItem from './AddItem';
import EditItem from './EditItem';

export const Dialects = React.memo((props) => {
  console.log('RENDER Dialects');
  const { title } = props;
  const { getAllDialectsHandler, loading, isDialects } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [rowData, isRowData] = useState({});

  const addItemHandler = useCallback(async () => {
    setIsLoading(true);
    try {
      await setIsAddOpen(true);
      await setIsLoading(false);
      await isRowData('');
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  const editHandler = useCallback(async (row) => {
    setIsLoading(true);
    try {
      await setIsEditOpen(true);
      await isRowData(row);
      await setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const modalAddCloseHandle = () => setIsAddOpen(false);
  const modalEditCloseHandle = () => setIsEditOpen(false);
  return (
    <>
      <DataSource
        tableName={title}
        source={getAllDialectsHandler}
        data={isDialects}
        loading={loading}
        handleEdit={editHandler}
        handleAdd={addItemHandler}
        searchItem="dialect"
      />
      <AddItem open={isAddOpen} handleClose={modalAddCloseHandle} />
      {!isLoading ? <EditItem open={isEditOpen} handleClose={modalEditCloseHandle} data={rowData} /> : 'loading'}
    </>
  );
});

Dialects.propTypes = {
  title: PropTypes.string.isRequired,
};
