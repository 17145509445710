// Interpreter urls
export const Interpreters = '/api/v1/interpreters';
export const InterpreterProNotes = '/api/v1/interpreters/profilenote';
export const InterpreterLanguages = '/api/v1/interpreters/languages';
export const interpreterHours = '/api/v1/bookings/iphours'
export const interpreterIPLanguages = '/api/v1/bookings/iplanguages'

// Booking urls
export const Bookings = '/api/v1/bookings';
export const BookingDocUploads = '/api/v1/bookings/upload';
export const BookingNewDocUploads = '/api/v1/bookings/newupload';
export const BookingNotes = '/api/v1/bookings/bookingNotes';
export const BookingApprove = '/api/v1/bookings/approvetimesheet';
export const BookingTimesheetSave = '/api/v1/bookings/savetimesheet';
export const Department = '/api/v1/departments';
export const CaseType = '/api/v1/casemattertypes';

// Rates url
export const RateStructures = '/api/v1/ratestructures';
export const ClientRate = '/api/v1/ratestructures/client';
export const minRates = '/api/v1/minrates';

// Language urls
export const AllLanguages = '/api/v1/languages/all';
export const AllDialects = '/api/v1/dialects/all';
export const Languages = '/api/v1/languages';
export const Dialects = '/api/v1/dialects';

// Term urls
export const Terms = '/api/v1/termsconditions';

// Client urls
export const Clients = '/api/v1/clients';
export const ClientsName = '/api/v1/clients/name';

// Auth urls
export const Login = 'api/v1/auth/login';
export const InterpreterLogin = 'api/v1/auth/intlogin';
export const InterpreterMe = 'api/v1/auth/ime';
export const LoggedInMe = 'api/v1/auth/me';

// User urls
export const Users = '/api/v1/auth/users';
export const UserCreate = '/api/v1/auth/create';
export const UserUpdate = '/api/v1/auth/update';
export const User = '/api/v1/users';
export const UserNote = '/api/v1/users/notes';

// sendgrid url
export const mailSend = '/api/v1/mailsend';

// timesheet
export const Timesheets = '/api/v1/bookings/intbooking/';
export const Timesheet = '/api/v1/bookings/timesheet/';

// timesheet
export const Invoice = '/api/v1/invoice';
export const IntInvoice = '/api/v1/invoice/int';
export const InvoiceCreditNote = '/api/v1/invoice/credit';
export const PutInvoice = '/api/v1/invoice/';

// Payments
export const Dues = '/api/v1/bookings/duesint';
export const Due = '/api/v1/bookings/intdueinv/';
export const RemittanceSlip = '/api/v1/bookings/createremittanceslip/';

// Remittances
export const Remittances = '/api/v1/remittances';

// Client Payments
export const ClientDues = '/api/v1/bookings/clientdues';
export const companyDues = '/api/v1/bookings/cldueinvoices/';
export const ClientAllocatePayment = '/api/v1/bookings/allocatepayment';
export const CreateClientPayment = '/api/v1/bookings/makepayment';
export const OutstandingOverCPR = '/api/v1/payments';
// export const MakePaymentOutstanding = '/api/v1/makepayment';

// Private Client Payment
export const PrivateClientDues = '/api/v1/bookings/PvtClDueInvoices'