import React from 'react';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { getComparator, stableSort } from '../../../libs/headerSortMethods'

import { RemittanceProvider } from '../../../context/states/remittanceState';
import { RemittanceLists } from '../../../components/pages/payments/iRemittance/RemittanceLists';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  container: {
    height: 'calc(70vh - 20px)',
  },
  container2: {
    height: 'calc(80vh - 20px)',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  countSpan: {
    backgroundColor: lighten(theme.palette.info.dark, 0.3),
    padding: '2px 4px',
    borderRadius: '2px',
    color: '#fff',
    fontSize: '10px',
  },
}));

const Remittance = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <RemittanceProvider>
        <div classes={classes.root}>
          <Paper className={classes.paper}>
            <RemittanceLists title="Remittances" classes={classes} getComparator={getComparator} stableSort={stableSort} adminUser={false} />
          </Paper>
        </div>
      </RemittanceProvider>
    </React.Fragment>
  );
};

export default Remittance;
