import React, { createContext, useReducer, useContext } from 'react';
import LanguageReducer from '../reducers/LanguageReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  total: 0,
  languages: [],
  dialects: [],
  error: null,
};

// creating context
export const LanguageContext = createContext(initialState);

// provider component
export const LanguageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LanguageReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All Languages data */
  const getAllLangugagesHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.AllLanguages);
      dispatch({ type: actionTypes.GET_ALL_LANGUGAGES, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch only enable Languages data */
  const getLangugagesHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Languages);
      dispatch({ type: actionTypes.GET_ALL_LANGUGAGES, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New Languages data */
  const addLanguageHandler = async (data) => {
    const result = await axios.post(urlRequest.Languages, data);
    dispatch({ type: 'ADD_LANGUAGE', payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Language created' });
    }
    return await result;
  };

  /* Update interpreter by id */
  const updatedLanguageHandler = async (data) => {
    try {
      const result = await axios.put(`${urlRequest.Languages}/${data._id}`, data);
      dispatch({ type: 'UPDATE_LANGUAGE', payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Language updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch All Dialects data */
  const getAllDialectsHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.AllDialects);
      dispatch({ type: actionTypes.GET_ALL_DIALECTS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch Only enable Dialects data */
  const getDialectsHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Dialects);
      dispatch({ type: actionTypes.GET_ALL_DIALECTS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New Diacte data */
  const addDialectsHandler = async (data) => {
    const result = await axios.post(urlRequest.Dialects, data);
    dispatch({ type: 'ADD_DIALECT', payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Dialect created' });
    }
    return await result;
  };

  /* Update interpreter by id */
  const updatedDialectHandler = async (data) => {
    try {
      const result = await axios.put(`${urlRequest.Dialects}/${data._id}`, data);
      dispatch({ type: 'UPDATE_DIALECT', payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Dialect updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        loading: state.loading,
        total: state.total,
        isLanguages: state.languages,
        isDialects: state.dialects,
        getAllLangugagesHandler,
        getLangugagesHandler,
        addLanguageHandler,
        updatedLanguageHandler,
        getAllDialectsHandler,
        getDialectsHandler,
        addDialectsHandler,
        updatedDialectHandler,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
