import React, { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    Clip,
    TextField
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { InterpreterContext } from '../../context/states/interpreterState'

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        margin: theme.spacing(1, 0),
    },
    appBar: {
        marginBottom: "12px",
    },
    title: {
        textAlign: "center",
    },
    formControl: {
        width: "100%",
        maxWidth: "100%",
    },
    input: {
        display: "none",
    },
    inputTitle: {
        fontWeight: "600",
        marginTop: ".1em",
        marginBottom: ".9em",
    },
}));

const MailerModal = ({
    title,
    message,
    isOpen,
    handleClose,
    confirmHandler,
    selectedEmails
}) => {
    console.log("interpreter mail modal");
    const classes = useStyles();
    const { sendMailToInterpretersHandler } = useContext(InterpreterContext);

    const { register, handleSubmit, errors, control, setValue } = useForm({
        mode: "onChange",
    });

    useEffect(() => {
        register('html');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);

    const handleChange = (e, targetName) => {
        console.log(e, targetName)
        setValue([targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e], { shouldDirty: true });
    };

    const onSubmit = async (formData) => {
       try {
        // var b = formData.toArr.replace(/\[|\]/g,'').split(',')
        var b = formData.toArr.split(",")
        sendMailToInterpretersHandler({...formData, toArr: b })
        handleClose()
       } catch (err) {
        throw new Error(err)
       }

    };

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Paper className={classes.paper} variant="outlined">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel htmlFor="business-private-label">
                                            From
                                        </InputLabel>
                                        <Controller
                                            as={
                                                <Select
                                                    label="Select Term"
                                                    inputProps={{
                                                        name: "from",
                                                        id: "select-term-label",
                                                    }}
                                                >
                                                    <MenuItem aria-label="None" value="" />
                                                    <MenuItem value={"info@language-interpreters.com"}>LIL Info&nbsp;-&nbsp;<small><i>info@language-interpreters.com</i></small></MenuItem>
                                                    <MenuItem value={"bookings@language-interpreters.com"}>LIL Booking&nbsp;-&nbsp;<small><i>bookings@language-interpreters.com</i></small></MenuItem>
                                                </Select>
                                            }
                                            control={control}
                                            name="from"
                                            rules={{ required: true }}
                                            error={!!errors.from}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        limitTags={4}
                                        id="tags-standard"
                                        options={[]}
                                        // getOptionLabel={(option) => option.title}
                                        onChange={(e, value) => handleChange(value, 'toArr')}
                                        defaultValue={selectedEmails}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="To Emails"
                                                size="small"
                                                placeholder="Interpreter mail ids"
                                            />
                                        )}
                                    />
                                    <input style={{ display: 'none' }} name="toArr" ref={register} defaultValue={selectedEmails} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="fore-name"
                                        label="Subject"
                                        size="small"
                                        fullWidth
                                        name="subject"
                                        variant="outlined"
                                        inputRef={register({
                                            required: true,
                                            minLength: { value: 2, message: 'Must have at least 2 letters' },
                                            maxLength: { value: 250, message: 'Maximum characters limit is 250' },
                                        })}
                                        error={!!errors.subject}
                                        helperText={errors.subject && errors.subject.message}
                                        autoFocus
                                    />

                                </Grid>

                                <Grid item xs={12}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        onInit={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log("Editor is ready to use!", editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setValue("html", data);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MailerModal;
