import React, { createContext, useReducer, useContext } from 'react';
import InvoiceReducer from '../reducers/invoiceReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  load: false,
  invoice: {},
};

// creating context
export const InvoiceContext = createContext(initialState);

// provider component
export const InvoiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InvoiceReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  const getInterpreterInvoiceHandler = async (id) => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(`${urlRequest.IntInvoice}/${id}`);
      dispatch({ type: 'FETCH_INVOICE', payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const getInvoiceHandler = async (id) => {
    dispatch({ type: 'LOAD_DATA' });
    console.log(id);
    try {
      const result = await axios.get(`${urlRequest.Invoice}/${id}`);
      dispatch({ type: 'FETCH_INVOICE', payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const updateCreditNoteHandler = async (formData) => {
    try {
      const result = await axios.post(`${urlRequest.InvoiceCreditNote}/${formData.id}`, formData);
      dispatch({ type: 'POST_CREDIT_NOTE', payload: result.data });
      alertDispatch({ type: 'open', message: 'Credit Note Generated' });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const updateInvoiceHandler = async (formData, bookingID) => {
    try {
      await axios.put(`${urlRequest.PutInvoice}${formData._id}`, formData);
      // dispatch({ type: 'POST_CREDIT_NOTE', payload: result.data });
      await alertDispatch({ type: 'open', message: 'Updating Invoice' });
      await getInvoiceHandler(bookingID);
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  return (
    <InvoiceContext.Provider
      value={{
        load: state.load,
        isInvoice: state.invoice,
        getInterpreterInvoiceHandler,
        getInvoiceHandler,
        updateCreditNoteHandler,
        updateInvoiceHandler,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};
