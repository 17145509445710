import React from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
	TableRow,
	TableCell,
	Checkbox,
	IconButton,
	Tooltip,
	Typography,
	CircularProgress,
	Chip,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .female": {
			textTransform: "capitalize",
			color: theme.palette.secondary.main,
			backgroundColor: lighten(theme.palette.secondary.light, 0.7),
		},
		"& .male": {
			textTransform: "capitalize",
			color: theme.palette.primary.main,
			backgroundColor: lighten(theme.palette.primary.light, 0.7),
		},
		"& .other": {
			textTransform: "capitalize",
			color: "#fafafa",
			backgroundColor: "rgba(0, 0, 0, 0.38)",
		},
	},
	female:
		theme.palette.type === "light" || "dark"
			? {
					textTransform: "capitalize",
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.7),
			  }
			: {
					textTransform: "capitalize",
					color: theme.palette.text.secondary,
					backgroundColor: theme.palette.secondary.contrastText,
			  },
	male:
		theme.palette.type === "light" || "dark"
			? {
					textTransform: "capitalize",
					color: theme.palette.primary.main,
					backgroundColor: lighten(theme.palette.primary.light, 0.7),
			  }
			: {
					textTransform: "capitalize",
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.primary.contrastText,
			  },
	updateNotify:
		theme.palette.type === "light" || "dark"
			? {
					fontSize: "10px",
					fontWeight: 600,
					maxidth: "100%",
					padding: "4px 8px",
					borderRadius: "4px",
					color: theme.palette.warning.main,
					backgroundColor: lighten(theme.palette.warning.light, 0.9),
			  }
			: {
					fontSize: "10px",
					fontWeight: 600,
					maxidth: "100%",
					padding: "4px 8px",
					borderRadius: "4px",
					color: theme.palette.text.secondary,
					backgroundColor: theme.palette.secondary.contrastText,
			  },
	activeStatus: {
		backgroundColor: lighten(theme.palette.success.light, 0.3),
	},
	inActiveStatus: {
		backgroundColor: lighten(theme.palette.secondary.light, 0.3),
	},
	activeInterpreter: {
		borderLeft: "blue",
	},
	list: {
		listStyleType: "none",
		overflow: "hidden",
		width: "6.5rem",
		textOverflow: "ellipsis",
		cursor: "pointer",
	},
	spanLang: {
		fontSize: "10px",
		fontWeight: 600,
		width: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: "2px 4px",
		marginLeft: "1px",
		marginBottom: "1.5px",
		textTransform: "capitalize",
		borderRadius: "4px",
		color: theme.palette.info.dark,
		backgroundColor: lighten(theme.palette.info.light, 0.2),
	},
	spanDia: {
		fontSize: "10px",
		fontWeight: 600,
		width: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: "2px 4px",
		marginLeft: "1px",
		marginBottom: "1.5px",
		textTransform: "capitalize",
		borderRadius: "4px",
		color: theme.palette.info.dark,
		backgroundColor: lighten(theme.palette.info.light, 0.8),
	},
	activeDate: {
		fontSize: "10px",
		fontWeight: 600,
		width: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: "2px 4px",
		marginLeft: "1px",
		marginBottom: "1.5px",
		textTransform: "capitalize",
		borderRadius: "4px",
		color: theme.palette.secondary.main,
		backgroundColor: lighten(theme.palette.secondary.light, 0.8),
	},
	origin: {
		fontSize: "10px",
		fontWeight: 600,
		width: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: "2px 4px",
		marginLeft: "1px",
		marginBottom: "1.5px",
		textTransform: "capitalize",
		borderRadius: "4px",
		color: theme.palette.success.dark,
		backgroundColor: lighten(theme.palette.success.light, 0.8),
	},
}));

export const InterpreterLists = React.memo((props) => {
	console.log("RENDER LISTS");
	const classes = useStyles();
	const history = useHistory();
	let { url } = useRouteMatch();
	const {
		data,
		loading,
		stableSort,
		getComparator,
		order,
		orderBy,
		isSelected,
		handleClick,
		isCheckbox,
	} = props;

	const styles = {
		columns: {
			id: {
				width: "7.2%",
				fontSize: "12.5px",
			},
			name: {
				width: "5%",
				fontSize: "12.5px",
				textTransform: "capitalize",
			},
			email: {
				width: "10%",
				fontSize: "12.5px",
			},
			mobile: {
				width: "7.5%",
				fontSize: "12.5px",
			},
			postcode: {
				width: "7.5%",
				fontSize: "12.5px",
			},
			interpretation: {
				width: "8%",
				fontSize: "12.5px",
				margin: 0,
				padding: 0,
			},
			translation: {
				width: "8%",
				fontSize: "12.5px",
				margin: 0,
				padding: 0,
			},
			transcription: {
				width: "8%",
				fontSize: "12.5px",
				margin: 0,
				padding: 0,
			},
			gender: {
				width: "7.5%",
				fontSize: "12.5px",
			},
			qualificationSummary: {
				width: "23.5%",
				textAlign: "justify",
				fontSize: "12px",
				margin: "4px",
				padding: 1,
				top: 0,
			},
			edit: {
				width: "7.5%",
			},
		},
	};

	let tableLists = (
		<TableRow>
			<TableCell
				colSpan={12}
				style={{ textAlign: "center" }}
			>
				<CircularProgress />
			</TableCell>
		</TableRow>
	);

	if (!loading) {
		tableLists = (
			<>
				{data.length === 0 ? (
					<TableRow>
						<TableCell
							colSpan={12}
							style={{ textAlign: "center" }}
						>
							<span>No Record</span>
						</TableCell>
					</TableRow>
				) : (
					stableSort(data, getComparator(order, orderBy)).map((row, index) => {
						const isItemSelected = isSelected(row.email);
						const labelId = `enhanced-table-checkbox-${index}`;
						let activeClass =
							row.active === true ? classes.activeStatus : classes.inActiveStatus;

						return (
							<TableRow
								hover
								role="checkbox"
								key={row.email}
								tabIndex={-1}
								aria-checked={isItemSelected}
								selected={isItemSelected}
								className={classes.root}
							>
								<TableCell
									padding="checkbox"
									className={activeClass}
								>
									{isCheckbox && (
										<Checkbox
											onClick={(event) => handleClick(event, row.email)}
											checked={isItemSelected}
											inputProps={{ "aria-labelledby": labelId }}
										/>
									)}
								</TableCell>
								<TableCell
									scope="row"
									align="left"
									style={styles.columns.id}
									id={labelId}
								>
									{row.int_id} <br />
									{row?.active_date ? (
										<span className={classes.activeDate}>
											{moment(row.active_date).format("DD-MM-yyyy")}
										</span>
									) : (
										""
									)}
								</TableCell>
								<TableCell style={styles.columns.name}>
									{row.fullname} <br />{" "}
									<span className={classes.origin}>{row?.origin}</span>
								</TableCell>
								<TableCell style={styles.columns.email}>{row.email}</TableCell>
								<TableCell style={styles.columns.mobile}>{row.mobile}</TableCell>
								<TableCell style={styles.columns.postcode}>
									{row.postcode} <br />
									{row.distance !== undefined && (
										<Chip
											icon={<PersonPinCircleIcon />}
											label={row?.distance.toFixed(2)}
											size="small"
											clickable
											color="primary"
										/>
									)}
								</TableCell>
								<TableCell style={styles.columns.interpretation}>
									{row.services.Interpretation.length === 0 ? (
										<span className={classes.updateNotify}>N/A</span>
									) : (
										row.services.Interpretation.map((d, i) => (
											<Tooltip
												key={i}
												title={
													<React.Fragment>
														<Typography variant="body2">
															Interpretation
														</Typography>
														<em>{"Language"}</em> <b>{d.language}</b>{" "}
														<br />
														<em>{"Dialect"}</em> <b>{d.dialect}</b>
													</React.Fragment>
												}
											>
												<li
													className={classes.list}
													key={i}
												>
													<span className={classes.spanLang}>
														{d.language}
													</span>{" "}
													<br />
													<span className={classes.spanDia}>
														{d.dialect}
													</span>
												</li>
											</Tooltip>
										))
									)}
								</TableCell>
								<TableCell style={styles.columns.translation}>
									{row.services.Translation.length === 0 ? (
										<span className={classes.updateNotify}>N/A</span>
									) : (
										row.services.Translation.map((d, i) => (
											<Tooltip
												key={i}
												title={
													<React.Fragment>
														<Typography variant="body2">
															Translation
														</Typography>
														<em>{"Language"}</em> <b>{d.language}</b>{" "}
														<br />
														<em>{"Dialect"}</em> <b>{d.dialect}</b>
													</React.Fragment>
												}
											>
												<li
													className={classes.list}
													key={i}
												>
													<span className={classes.spanLang}>
														{d.language}
													</span>{" "}
													<br />
													<span className={classes.spanDia}>
														{d.dialect}
													</span>
												</li>
											</Tooltip>
										))
									)}
								</TableCell>
								<TableCell style={styles.columns.transcription}>
									{row.services.Transcription.length === 0 ? (
										<span className={classes.updateNotify}>N/A</span>
									) : (
										row.services.Transcription.map((d, i) => (
											<Tooltip
												key={i}
												title={
													<React.Fragment>
														<Typography variant="body2">
															Transcription
														</Typography>
														<em>{"Language"}</em> <b>{d.language}</b>{" "}
														<br />
														<em>{"Dialect"}</em> <b>{d.dialect}</b>
													</React.Fragment>
												}
											>
												<li
													className={classes.list}
													key={i}
												>
													<span className={classes.spanLang}>
														{d.language}
													</span>{" "}
													<br />
													<span className={classes.spanDia}>
														{d.dialect}
													</span>
												</li>
											</Tooltip>
										))
									)}
								</TableCell>
								<TableCell
									style={styles.columns.gender}
									className={row.gender}
								>
									{row.gender}
								</TableCell>
								<TableCell style={styles.columns.qualificationSummary}>
									{row.qual_summary ? (
										row.qual_summary
									) : (
										<span className={classes.updateNotify}>N/A</span>
									)}
								</TableCell>
								<TableCell
									style={styles.columns.edit}
									className={classes.activeInterpreter}
								>
									<Tooltip title={`Edit: ${row.fullname}`}>
										<IconButton
											aria-label="edit"
											size="small"
											onClick={() => history.push(`${url}/edit/${row._id}`)}
										>
											<EditIcon
												fontSize="small"
												color="primary"
											/>
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						);
					})
				)}
			</>
		);
	}
	return <>{tableLists}</>;
});

InterpreterLists.propTypes = {
	data: PropTypes.array.isRequired,
	isCheckbox: PropTypes.bool.isRequired,
	stableSort: PropTypes.func.isRequired,
	getComparator: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	isSelected: PropTypes.func.isRequired,
	handleClick: PropTypes.func.isRequired,
};
