import React, { useState, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { makeStyles, lighten } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Tooltip,
  Popper,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress, DialogContent
} from '@material-ui/core';

import { BookingContext } from '../../../../context/states/bookingState';

import { TableSpanLoader } from '../../../tableSpanLoader/TableSpanLoader';

import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import RestoreIcon from '@material-ui/icons/Restore';

const table = document.querySelector('#table-id')



const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontWeight: '600',
    fontSize: '12px',
  },
  notAvail: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.9),
  },
  availSpan: {
    fontSize: '12px',
    fontWeight: 600,
    maxidth: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    backgroundColor: lighten(theme.palette.action.selected, 0.9),
  },
  todays: {
    fontSize: '10px',
    fontWeight: 600,
    maxidth: '100%',
    padding: '2px 4px',
    margin: '2px 0px',
    borderRadius: '4px',
    color: theme.palette.primary.dark,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    cursor: 'pointer',
  },
  cardRoot: {
    minWidth: 400,
    // minWidth: 350,
  },
}));

export const Assign = React.memo((props) => {
  const { open, handleClose, data, booking, load } = props;
  const classes = useStyles();
  const { updateAssginToHandler } = useContext(BookingContext);

  const inputRef = React.useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isAssign, setIsAssign] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [isScrollCount, setIsScrollCount] = useState(0)
  const handleConfirm = (event, formData) => {
    setIsAssign(formData);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // const scrollHandler = _ => {
  //   console.log(inputRef.current.getBoundingClientRect());
  // };

  // React.useEffect(() => {
  //   window.addEventListener("scroll", scrollHandler, true);
  //   return () => {
  //     window.removeEventListener("scroll", scrollHandler, true);
  //   };
  // }, [])

  // function scrollTo(position) {
  //   const project = document.getElementById(position);
  //   if(project === null) return
  //   project.scrollIntoView();
  // }

  // const handleScrollDown = () => {
  //   setIsScrollCount(isScrollCount + 1)
  //   scrollTo(isScrollCount)
  // }

  // const handleScrollUp = () => {
  //   console.log(isScrollCount)
  //   if(isScrollCount <= 0) {
  //     setIsScrollCount(0)
  //     scrollTo(isScrollCount)
  //   } else {
  //     setIsScrollCount(isScrollCount - 1)

  //   }
  // }

  const handleSubmit = async (data) => {
    const { fullname, mobile, phone, postcode, int_id, _id } = isAssign;
    setIsLoading(true);
    try {
      const assignTo = {
        id: booking.bookingId,
        interpreter: _id,
        interpreterName: fullname,
        interpreterMobile: mobile,
        interpreterPhone: phone,
        interpreterPostcode: postcode,
        interpreterRef: int_id,
      };

      await updateAssginToHandler(assignTo);
      await setIsLoading(false);
      await handleClosePop();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClosePop = () => setAnchorEl(null);

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'transitions-popper' : undefined;

  // const handleMe = (formData) => {
  //   console.log(formData);
  // };
  var interpreter = <TableSpanLoader col={9} loader={true} />;

  if (!load) {
    interpreter =
      data.length !== 0 ? (
        data.map((item, i) => (
          <TableRow key={item._id} className={item.avail?.length !== 0 ? classes.notAvail : ''}  >
            <TableCell align="left">{item.int_id}</TableCell>
            <TableCell component="th" scope="row">
              {item.fullname}
            </TableCell>
            <TableCell align="left">{item.mobile}/
            <Chip
                // icon={<RestoreIcon />}
                label={item?.gender}
                style={{ textTransform: 'capitalize'}}
                size="small"
                variant="outlined" color="primary"
              />
              {/* <span style={{ textTransform: 'capitalize'}}>{item?.gender}</span> */}
            </TableCell>
            <TableCell align="left">
              {item.postcode}
              <Chip
                icon={<RestoreIcon />}
                label={item?.origin}
                size="small"
                variant="outlined" color="primary"
              />
            </TableCell>

            <TableCell align="left">
              <Chip
                icon={<DirectionsRunIcon />}
                label={item.distance !== undefined ? item.distance.toFixed(2) : 0}
                size="small"
                clickable
                color={item.avail?.length !== 0 ? 'secondary' : 'primary'}
              />
            </TableCell>
            <TableCell align="left">
              {item.avail?.length === 0 ? (
                <span className={classes.availSpan}>Available</span>
              ) : (
                <>
                  <span className={classes.availSpan}>{item.avail?.split('-')[0]}</span> <br />{' '}
                  <center>
                    <small>To</small>
                  </center>
                  <span className={classes.availSpan}>{item.avail?.split('-')[1]}</span>
                </>
              )}
            </TableCell>
            <TableCell style={{ width: '10%', textAlign: 'justify' }} align="left">
              {item.bookingNotes?.text}
              {item.todays?.map((data, i) => (
                <Tooltip
                  key={i}
                  title={
                    <React.Fragment>
                      <Typography variant="body2">Today appointment</Typography>
                      <em>{'Service '}</em>{' '}
                      <b>
                        {data.serviceType === 'Telephone' || data.serviceType === 'Video Call' || data.serviceType === 'Onsite'
                          ? data.serviceType + ' Interpretation'
                          : data.serviceType}
                      </b>{' '}
                      <br />
                      <em>{'Booking Reference'}</em> <b>{data.booking_ref}</b>
                    </React.Fragment>
                  }
                >
                  <p className={classes.todays} style={{ textAlign: 'center' }}>
                    {moment(data.date).format('DD/MM/Y')}&nbsp;&nbsp;
                    {moment(data.startTime).format('HH:mm')} To {moment(data.endTime).format('HH:mm')}
                  </p>
                </Tooltip>
              ))}
            </TableCell>
            <TableCell style={{ width: '10%', textAlign: 'justify' }} align="left">
              {item.qual_summary}
            </TableCell>
            <TableCell align="right">
              <>
                {item.avail?.length === 0 && (
                  <>
                    {/* <Tooltip title={`Assign To ${item.fullname}`}> */}
                    <IconButton
                      aria-label="assign"
                      className={classes.margin}
                      color="primary"
                      aria-describedby={id}
                      rowdata={item}
                      onClick={(event) => handleConfirm(event, item)}
                    >
                      <LibraryAddCheckIcon fontSize="inherit" />
                    </IconButton>
                    <Popper id={id} open={openPop} anchorEl={anchorEl} style={{ zIndex: 1500 }} placement="left">
                      <Card className={classes.cardRoot}>
                        {!isLoading ? (
                          <>
                            <CardContent>
                              <Typography variant="body2" color="textSecondary" component="p">
                                Booking Reference:
                                <i>
                                  <strong>{booking.bookingRef}</strong>
                                </i>
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p">
                                Assigning to{' '}
                                <i>
                                  <strong>{isAssign.fullname}</strong>
                                </i>
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                <Button size="small" color="primary" onClick={handleClosePop}>
                                  Close
                                </Button>
                                <Button size="small" color="primary" variant="contained" onClick={handleSubmit}>
                                  Confirm
                                </Button>
                              </Grid>
                            </CardActions>
                          </>
                        ) : (
                          <Grid container direction="row" justify="center" alignItems="center" style={{ padding: '1.5rem 0' }}>
                            <CircularProgress />
                          </Grid>
                        )}
                      </Card>
                    </Popper>
                    {/* </Tooltip> */}
                  </>
                )}
              </>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableSpanLoader col={9} loader={false} message="Interpreter is not available!" />
      );
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll='body'
      >
        <DialogTitle id="alert-dialog-title">Assign To: {booking.bookingRef}</DialogTitle>
        <DialogContent dividers={false}>

        <TableContainer >
          <Table size="small" aria-label="interpreter table" >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">
                  Interpreter ID
                </TableCell>
                <TableCell className={classes.tableHeader}>Name</TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  Mobile / Gender
                </TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  Postcode
                </TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  Distance
                </TableCell>
                <TableCell className={classes.tableHeader} align="left">
                  Not available
                </TableCell>
                <TableCell className={classes.tableHeader} style={{ width: '30%' }} align="left">
                  Booking Notes
                </TableCell>
                <TableCell className={classes.tableHeader} style={{ width: '30%' }} align="left">
                  Qualification
                </TableCell>
                <TableCell className={classes.tableHeader} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref={inputRef}>{interpreter}</TableBody>
          </Table>
        </TableContainer>
        {/* </DialogContent> */}
        </DialogContent>
        <DialogActions>
        {/* <button onClick={handleScrollUp}>Top</button> */}
        {/* <button onClick={handleScrollDown}>Down</button> */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

Assign.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  booking: PropTypes.object.isRequired,
};
