import React, { useCallback, useState } from "react";
import moment from "moment";
import {
	TableRow,
	TableCell,
	Table,
	TableContainer,
	TableBody,
	Typography,
	CircularProgress,
	Card,
	CardContent,
	Chip,
} from "@material-ui/core";
import { EnhancedTableHead } from "./index";
import CustomChip from "../../../customChip/customChip";

const invoiceSummaryHeadCells = [
	{ id: "i", numeric: false, disablePadding: false, label: "Sl.No" },
	{ id: "company", numeric: false, disablePadding: false, label: "Company Name" },
	{ id: "booking_ref", numeric: false, disablePadding: false, label: "Booking Ref" },
	{ id: "customer", numeric: false, disablePadding: false, label: "Invoice No / Date" },
	{ id: "subTotal", numeric: false, disablePadding: false, label: "Net Invoice" },
	{ id: "invTotal", numeric: false, disablePadding: false, label: "Gross Total" },
	{ id: "vat", numeric: false, disablePadding: false, label: "VAT" },
	{ id: "hvatp", numeric: false, disablePadding: false, label: "HVAT 12%" },
	{ id: "outstanding", numeric: false, disablePadding: false, label: "Outstanding" },
	{ id: "paymentRef", numeric: false, disablePadding: false, label: "Payment Ref" },
	{ id: "paymentDate", numeric: false, disablePadding: false, label: "Payment Date" },
	{ id: "paidAmount", numeric: false, disablePadding: false, label: "Paid Amount" },
	{ id: "paidAmount", numeric: false, disablePadding: false, label: "Notes" },
];

const styles = {
	fontSize: "13px",
	padding: "6px",
};

const CardItem = ({ title, active, classes, data, isLoading, matches }) => {
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("company");

	const handleRequestSort = useCallback(
		(event, property) => {
			const isAsc = orderBy === property && order === "asc";
			setOrder(isAsc ? "desc" : "asc");
			setOrderBy(property);
		},
		[setOrder, setOrderBy, orderBy]
	);

	const handleSelectAllClick = useCallback(
		(event) => {
			if (event.target.checked) {
				const newSelecteds = data.map((n) => n.name);
				setSelected(newSelecteds);
				return;
			}
			setSelected([]);
		},
		[setSelected]
	);

	return (
		<>
			<Card className={active}>
				<CardContent style={{ padding: "8px 6px" }}>
					<TableContainer className={matches ? classes.container2 : classes.container}>
						<Table
							stickyHeader
							aria-label="sticky table"
							className={classes.table}
							size={"small"}
						>
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={data.length}
								headCells={invoiceSummaryHeadCells}
							/>

							<TableBody>
								{isLoading}
								{isLoading ? (
									<TableRow>
										<TableCell
											style={{ textAlign: "center" }}
											rowSpan={6}
											colSpan={13}
										>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : data.length === 0 ? (
									<TableRow hover>
										<TableCell
											style={{ textAlign: "center" }}
											rowSpan={6}
											colSpan={13}
										>
											No record
										</TableCell>
									</TableRow>
								) : (
									data.map((row, index) => {
										let amount = row?.invTotal - row?.balanceAmount;
										let lilamount = row?.invTotal - row?.IPPaid;
										return (
											<TableRow
												hover
												key={index}
											>
												<TableCell style={styles}>{index + 1}</TableCell>
												<TableCell
													style={styles}
													component="th"
													scope="row"
												>
													<b>{row.company || <CustomChip title={"Private"} />}</b>
												</TableCell>
												<TableCell style={styles}>{row._id}</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row.invoiceNo} <br />
													<small>{row.invoiceDate}</small>
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{parseFloat(row?.subTotal).toFixed(2)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{parseFloat(row?.invAmount).toFixed(2)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.vat}
												</TableCell>
												{/* <TableCell style={styles} align="left">{(row?.vat * 0.6).toFixed(2)}</TableCell> */}
												<TableCell
													style={styles}
													align="left"
												>
													{(row?.invAmount * 0.12).toFixed(2)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.outstanding.toFixed(2)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.pays.map((pay) => `${pay.paymentRef}, `)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.pays.map(
														(pay) => `${moment(pay.paymentDate).format("DD/MM/YYYY")}, `
													)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.pays.map((pay) => `${pay.paidAmount}, `)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.pays.map((pay) => `${pay.notes}, `)}
												</TableCell>
												{/* <TableCell style={styles} align="left">{row?.paymentRef}</TableCell>
                                                <TableCell style={styles} align="left">{moment(row?.paymentDate).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell style={styles} align="left">{row?.paidAmount}</TableCell> */}
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</>
	);
};

export default React.memo(CardItem);
