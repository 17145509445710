import React, { createContext, useReducer } from 'react';
import AlertReducer from '../reducers/alertReducer';

// InitialState
const initialState = {
  type: 'close',
  open: false,
  message: '',
};

// creating context
export const AlertContext = createContext(initialState);

// provider component
export const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AlertReducer, initialState);

  const alertSnackbarCloseHandle = () => {
    dispatch({ type: 'close' });
  };

  return (
    <AlertContext.Provider value={{ alertDispatch: dispatch, open: state.open, message: state.message, type: state.type, alertSnackbarCloseHandle }}>
      {children}
    </AlertContext.Provider>
  );
};
