import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import NoDocumentImage from '../../assets/images/icons/document-not-available.png';


const useStyles = makeStyles((theme) => ({
    paper: {
        height: '24vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f1f1f1'
    },
    image: {
        width: '4rem'
    }
}));


const NoDocument = () => {
    const classes = useStyles()
    return (
        <div className={classes.paper}>
            <img src={NoDocumentImage} alt="Document Not Available" className={classes.image} />
        </div>
    )
}

export default NoDocument