import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Users } from '../../../components/pages/masters/Users/Users';
import { UserProvider } from '../../../context/states/userState';

const UserMaster = () => {
  return (
    <React.Fragment>
      <UserProvider>
        <Grid item xs={12} xl={7}>
          <Users title="Users Master" />
        </Grid>
      </UserProvider>
    </React.Fragment>
  );
};

export default UserMaster;
