import React, { createContext, useReducer, useContext } from 'react';
import InvoiceSummaryReducer from '../../reducers/reports/invoiceSummaryReducer';
import axios from '../../../axios';
import { AlertContext } from '../alertState';

const initialState = {
    loading: false,
    invoices: [],
    totalInvoices: 0,
    chgTotal: 0,
    totalvat: 0, 
    totalhavt: 0,
    totalService : 0,
    totalPaid: 0,
    clients: [],
    error: null
}

export const InvoicePaymentContext = createContext(initialState);
export const InvoicePaymentProvider = ({ children }) => {
    const [state, dispatch] = useReducer(InvoiceSummaryReducer, initialState);
    // const { alertDispatch } = useContext(AlertContext);

    const getClientsHandler = async (company) => {
        try {
            const result = await axios.get(`/api/v1/clients?companyName=${company}`);
            dispatch({ type: 'FETCH_CLIENT_RECORD', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    const clearStateHandler = () => {
        dispatch({ type: 'PROCESS_START' })
    }

    const getPaymentInvoicesHandler = async (filter) => {
        // dispatch({ type: 'PROCESS_START' })
        try {
            const result = await axios.get(`/api/v1/payments/invpayments?${filter}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_RECORDS', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <InvoicePaymentContext.Provider
            value={{
                isloading: state.loading,
                invoices: state.invoices,
                totalInvoices: state.totalInvoices,
                count: state.count,
                totalvat: state.totalvat,
                totalhavt: state.totalhavt, 
                totalPaid: state.totalPaid,
                totalService : state.totalService,
                chgTotal: state.chgTotal,
                clients: state.clients,
                error: state.error,
                getClientsHandler,
                clearStateHandler,
                getPaymentInvoicesHandler
            }}>
            {children}
        </InvoicePaymentContext.Provider>
    )
}