import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    List, ListItem, ListItemText, Typography, IconButton, Divider, ListItemIcon
} from '@material-ui/core';

import NoteIcon from '@material-ui/icons/Note';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0px 5px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(0.5),
    },
    listItem: {
        background: '#fcfcfc',
        marginBottom: theme.spacing(1)
    }
}));

export const PaymentNotes = React.memo((props) => {
    const { open, handleClose, notes } = props;
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item xs>
                        Payment Notes
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="Notes" aria-haspopup="true" onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <Paper className={classes.paper}>
                    <List>
                        {notes.length === 0 ? (
                            <ListItem key={1} alignItems="flex-start" className={classes.listItem}>
                                <ListItemText
                                    primary={'No Notes'}
                                />
                            </ListItem>
                        ) : notes.map((note, index) => (
                            <React.Fragment key={index}>
                                <ListItem key={note._id} alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                        <NoteIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={note.text}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {note.updatedBy}
                                                </Typography>
                                                {" — "} {moment(note.updated).format('dddd, MMMM Do YYYY, HH:mm:ss')}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            </DialogContent>
        </Dialog>
    );
});
