import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip, IconButton, Badge } from '@material-ui/core';

// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import PropTypes from 'prop-types';

import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, numSelected, paymentToggleHandler, notesCounts } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) :
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>}

      <Tooltip title="Payment Notes">
        <IconButton aria-label="Notes" color="primary" aria-haspopup="true" onClick={paymentToggleHandler}>
          <Badge badgeContent={notesCounts} color="secondary">
            <SpeakerNotesIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
