import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { FileLists } from './FileLists';
// import { DropZone } from '../../../../fileUpload/DropZone';
import { InterpreterContext } from '../../../../../context/states/interpreterState';

import { InterpreterUploads } from '../../../../fileUpload/UploadFiles/InterpreterUploads';
import { FileContext } from '../../../../../context/states/fileState';

import Loader from '../../../../UI/Loader';

export const Qualifications = React.memo((props) => {
  const {
    updateInterpreterHandler,
    fileList,
    // uploadDocumentHandler,
    // progressValue
  } = useContext(InterpreterContext);
  const { handleFiles, unsupportedFiles, validFiles, uploadDocumentHandler, removeFile, percentage, message, interpreterFileDeleteHandler } = useContext(FileContext);
  const { classes, data, handleClose, isAccessTo } = props;
  const today = moment().format();

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const formValue = {
    _id: data._id,
    photo: data.qual_summary,
  };

  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: formValue,
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await updateInterpreterHandler(data);
      backdropCloseHandler();
    } catch (error) {
      console.log(error);
      backdropCloseHandler();
    }
  };

  const deleteFileHandler = (file) => {
    const fdata = { id: data?._id, filename: file.fileName };
    interpreterFileDeleteHandler(fdata)
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <input ref={register} name="_id" style={{ display: 'none' }} />
        <input type="hidden" name="updatedAt" ref={register} defaultValue={today} />
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="qulifications"
                    label="Qualification Summary"
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                    name="qual_summary"
                    inputRef={register}
                    fullWidth
                    defaultValue={data.qual_summary}
                    error={!!errors.qual_summary}
                    helperText={errors.qual_summary && errors.qual_summary.message}
                    disabled={isAccessTo}
                  />
                </Grid>

                <Grid item xs={12}>
                  <React.Fragment>
                    {/* <DropZone
                      classes={classes}
                      open={open}
                      id={data._id}
                      handleFileUpload={uploadDocumentHandler}
                      progressValue={progressValue}
                      handleClose={handleModalClose}
                    /> */}
                    <InterpreterUploads
                      open={open}
                      classes={classes}
                      id={data._id}
                      handleClose={handleModalClose}
                      handleFiles={handleFiles}
                      validFiles={validFiles}
                      unsupportedFiles={unsupportedFiles}
                      handleOnSubmit={uploadDocumentHandler}
                      handleRemove={removeFile}
                      percentage={percentage}
                      message={message}
                    />
                    {!isAccessTo &&
                      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                        Upload Doc
                    </Button>
                    }
                  </React.Fragment>
                </Grid>
              </Grid>
            </Paper>
            <FileLists items={fileList} documentDeleteHandler={deleteFileHandler}/>
            <br />
          </Grid>
          {!isAccessTo &&
            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
              <div className={classes.actionBtn}>
                <Button size="small" color="primary" onClick={handleClose}>
                  Cancel
              </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!formState.isDirty || (formState.isDirty && !formState.isValid)}
                  onClick={handleSubmit(onSubmit)}
                >
                  Update
              </Button>
              </div>
            </Grid>
          }
        </Grid>
      </form>
      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment >
  );
});

Qualifications.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
