import React, { useCallback, useState } from 'react'
import moment from 'moment';
import { TableRow, TableCell, Table, TableContainer, TableBody, Typography, CircularProgress, Card, CardContent, Chip } from '@material-ui/core';
import { EnhancedTableHead } from './index';

const invoiceSummaryHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'company', numeric: false, disablePadding: false, label: 'Company Name' },
    { id: 'booking_ref', numeric: false, disablePadding: false, label: 'Booking Ref' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'customer', numeric: false, disablePadding: false, label: 'Invoice No / Invoice Date' },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language / Service' },
    { id: 'interpreterName', numeric: false, disablePadding: false, label: 'Interpreter' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
    { id: 'adminCharges', numeric: false, disablePadding: false, label: 'Service Charges' },
    { id: 'vatAmount', numeric: false, disablePadding: false, label: 'VAT' },
    { id: 'invTotal', numeric: false, disablePadding: false, label: 'Invoice Total' },
    { id: 'balanceAmount', numeric: false, disablePadding: false, label: 'Balance Amount' },
    { id: 'hvat', numeric: false, disablePadding: false, label: 'H VAT' },
    // { id: 'paymentDate', numeric: false, disablePadding: false, label: 'Payment Ref & Date' },
    { id: 'paymentRef', numeric: false, disablePadding: false, label: 'Payment Ref' },
    { id: 'paymentDate', numeric: false, disablePadding: false, label: 'Payment Date' },

];

const styles = {
    fontSize: '13px',
    padding: '6px'
}

const CardItem = ({ title, active, classes, data, isLoading, matches }) => {

    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('company');

    const handleRequestSort = useCallback((event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [setOrder, setOrderBy, orderBy]);

    const handleSelectAllClick = useCallback((event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }, [setSelected]);

    return (
        <>
            <Card className={active}>
                <CardContent style={{ padding: '8px 6px' }}>
                    <TableContainer className={matches ? classes.container2 : classes.container} >
                        <Table stickyHeader aria-label="sticky table" className={classes.table} size={'small'}>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={invoiceSummaryHeadCells}
                            />

                            <TableBody>
                                {isLoading}
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : data.length === 0 ? (
                                    <TableRow hover>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            No record
                                        </TableCell>
                                    </TableRow>
                                ) : (

                                    data.map((row, index) => {
                                        let amount = row?.invTotal - row?.balanceAmount
                                        let lilamount = row?.invTotal - row?.IPPaid
                                        return (
                                            <TableRow hover key={row._id + index}>
                                                <TableCell style={styles}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell style={styles} component="th" scope="row">
                                                    <b>{row.companyName}</b>
                                                </TableCell>
                                                <TableCell style={styles}>
                                                    {row.booking_ref}
                                                </TableCell>
                                                <TableCell style={styles} align="left">{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell style={styles} align="left">
                                                    {row.invoiceNo} <br />
                                                    <small>{moment(row.invoiceDate).format('DD/MM/YYYY')}</small>
                                                </TableCell>
                                                <TableCell style={styles} align="left">
                                                    <strong>{row.language}</strong> <br />
                                                    <small>{row.serviceType}</small>
                                                </TableCell>
                                                <TableCell style={styles} align="left">{row?.interpreterName}</TableCell>
                                                <TableCell style={styles} align="left">{row?.clientName}</TableCell>
                                                <TableCell style={styles} align="left">{row?.adminCharges?.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.vatAmount.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.invTotal?.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.balanceAmount?.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.hvat.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.paymentRef[0] ? row?.paymentRef[0] : 'N/A'}</TableCell>
                                                <TableCell style={styles} align="left">{row?.paymentDate[0] ? moment(row?.paymentDate[0]).format('DD/MM/YYYY') : 'N/A'}</TableCell>
                                                {/* <TableCell style={styles} align="left">{row.paymentRef.map((pf, i) => <Chip key={i} variant="outlined" size="small" label={pf + ' - ' + moment(row?.paymentDate[i]).format('DD/MM/YYYY')} />)}</TableCell> */}
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </>
    )
}

export default React.memo(CardItem)
