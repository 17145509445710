import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      console.log('loaded');
      return {
        ...state,
        load: true,
        error: null,
      };

    case 'FETCH_INVOICE':
      return {
        ...state,
        load: false,
        error: null,
        invoice: action.payload.data,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        load: false,
        loading: false,
        error: action.payload,
      };

    case 'POST_CREDIT_NOTE':
      return {
        ...state,
        //state.invoice.map((invoice) => {
        //console.log(invoice);
        // return invoice.id === action.payload.id ? { ...invoice, cancelled: action.payload.data.cancelled } : invoice;
        // }),
      };

    default:
      return state;
  }
};
