import React, { useContext } from 'react';

import { Dialog } from '@material-ui/core';
import { Field } from './Field';
import { LanguageContext } from '../../../../context/states/languageState';

const AddItem = React.memo((props) => {
  const { open, handleClose, data } = props;
  const { updatedDialectHandler } = useContext(LanguageContext);

  const onSubmitHandler = (data) => {
    updatedDialectHandler(data);
  };
  const modalClose = () => {
    handleClose();
    // reset({ _id: '', dialect: '' });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Field data={data} handleClose={modalClose} handleOnSubmit={onSubmitHandler} />
      </Dialog>
    </React.Fragment>
  );
});

export default AddItem;
