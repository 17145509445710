import React, { useMemo } from 'react'
import { Timesheet } from '../../ibookings/Forms/Timesheet';

const MemoizedTimesheet = ({ isOpen, handleClose, data, handleOnSubmit, type, view, fileList, handeOnUpdate }) => {
    return useMemo(() => <Timesheet
        open={isOpen}
        handleClose={handleClose}
        data={data}
        handleOnSubmit={handleOnSubmit}
        submitType={type}
        clientView={view}
        fileList={fileList}
        handeOnUpdate={handeOnUpdate}
    />, [])
}

export default MemoizedTimesheet
