import React, { useRef, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './FileUploads.css';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Box, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// Auth Context
import { AuthContext } from '../../../context/states/authState';

export const FileUploads = React.memo((props) => {
  const { open, handleClose, id, type, handleFiles, validFiles, unsupportedFiles, handleOnSubmit, handleRemove, percentage, message } = props;
  const { userData } = useContext(AuthContext);

  const [isComment, setIsComment] = useState('');
  const uploadModalRef = useRef();
  // const modalImageRef = useRef();
  // const modalRef = useRef();
  // const uploadRef = useRef();
  // const progressRef = useRef();

  // const openImageModal = (file) => {
  //   const reader = new FileReader();
  //   modalRef.current.style.display = 'block';
  //   reader.readAsDataURL(file);
  //   reader.onload = function (e) {
  //     modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
  //   };
  // };

  // const closeModal = () => {
  //   modalRef.current.style.display = 'none';
  //   modalImageRef.current.style.display = 'none';
  // };

  const closeUploadModal = useCallback(() => {
    uploadModalRef.current.style.display = 'none';
  }, [uploadModalRef]);

  // Files Handler
  const fileInputRef = useRef();

  const fileInputClicked = useCallback(() => {
    fileInputRef.current.click();
  }, [fileInputRef]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  }, []);

  const filesSelected = useCallback(() => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  }, []);

  const onSubmitHandler = useCallback(() => {
    uploadModalRef.current.style.display = 'block';
    const data = { id, type, comment: isComment };
    handleOnSubmit(data, userData.role);
    setIsComment('')
  }, [setIsComment, handleOnSubmit]);

  //   file size
  const fileSize = useCallback((size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }, []);

  const fileType = useCallback((fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }, []);

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">File Upload</DialogTitle>
        <DialogContent className="fUpload_container">
          <TextField id="outlined-basic" label="Comment" fullWidth size="small" variant="outlined" onChange={e => setIsComment(e.target.value)} />

          <div
            className="fUpload_drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
          >
            <div className="fUpload_drop-message">
              <input ref={fileInputRef} className="fUpload_file-input" type="file" multiple onChange={filesSelected} />

              <div className="fUpload_upload-icon"></div>
              <small>Drag & Drop files here or click to upload</small>
            </div>
          </div>

          <div className="fUpload_file-display-container">
            {validFiles.map((data, i) => (
              <div className="fUpload_file-status-bar" key={i}>
                <React.Fragment>
                  <div className="fUpload_file-type-logo"></div>
                  <div className="fUpload_file-type">{fileType(data.name)}</div>
                  <span className={`fUpload_file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                  <span className="fUpload_file-size">({fileSize(data.size)})</span>{' '}
                  {data.invalid && <span className="fUpload_file-error-message">({message})</span>}
                </React.Fragment>
                <div className="fUpload_file-remove" onClick={() => handleRemove(data.name)}>
                  <CancelIcon />
                </div>
              </div>
            ))}

            <div className="fUpload_modal" ref={uploadModalRef}>
              <div className="fUpload_overlay"></div>
              <div className="fUpload_close" onClick={() => closeUploadModal()}>
                <CancelIcon />
              </div>
              <div className="fUpload_progress-container">
                {/* <span ref={uploadRef}></span> */}
                <span>{message}</span>
                <div className="fUpload_progress">
                  <CircularProgressWithLabel value={percentage} />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ zIndex: 2 }}>
            Close
          </Button>
          {unsupportedFiles.length === 0 && validFiles.length ? (
            <Button
              color="primary"
              variant="contained"
              className="fUpload_file-upload-btn"
              startIcon={<CloudUploadIcon />}
              size="small"
              onClick={() => onSubmitHandler()}
            >
              Upload
            </Button>
          ) : (
            ''
          )}
          {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
