import React, { useState, useContext, useCallback } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from '@hookform/devtools';

import { onlyNumbersWithColon } from "../../../../libs/colon";
import { TimesheetContext } from "../../../../context/states/timeSheetState";
import { FileContext } from "../../../../context/states/fileState";
import { AuthContext } from "../../../../context/states/authState";
import { FileUploads } from "../../../fileUpload/UploadFiles/FileUploads";
import { ViewFile } from "../../../fileUpload/ViewFile";
import NoDocument from "../../../icons/NoDocument";

import { makeStyles, lighten } from "@material-ui/core/styles";
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Paper,
	TextField,
	Select,
	FormControl,
	MenuItem,
	InputLabel,
	IconButton,
	Button,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from "@material-ui/pickers";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachmentIcon from "@material-ui/icons/Attachment";

import Loader from "../../../UI/Loader";

const useStyles = makeStyles((theme) => ({
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1),
	},
	clientPaper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		marginBottom: theme.spacing(1),
		color: theme.palette.text.primary,
		backgroundColor: lighten(theme.palette.info.light, 0.7),
	},
	profileLarge: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	formControl: {
		width: "100%",
		maxWidth: "100%",
	},
	input: {
		display: "none",
	},
	inputTitle: {
		fontWeight: "600",
		marginTop: ".1em",
		marginBottom: ".9em",
	},
	pos: {
		fontSize: "14px",
	},
	subtitle: {
		fontWeight: 600,
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(1),
	},
	clientSubtitle: {
		fontWeight: 600,
		color: theme.palette.info.main,
		marginBottom: theme.spacing(1),
	},
	multipleRates: {
		fontSize: 12,
		fontWeight: 600,
		padding: "4px 8px",
		borderRadius: 4,
		marginLeft: 4,
		color: "#fff",
		backgroundColor: lighten(theme.palette.secondary.light, 0.1),
	},
	vat: {
		fontSize: 10,
		fontWeight: 600,
		padding: "3px 6px",
		borderRadius: 2,
		marginLeft: 4,
		color: theme.palette.secondary.main,
		backgroundColor: lighten(theme.palette.secondary.light, 0.9),
		width: 45,
		position: "relative",
		botton: "-13px",
		textAlign: "center",
	},
}));

export const Timesheet = React.memo((props) => {
	const classes = useStyles();
	const {
		open,
		handleClose,
		data,
		loading,
		handleOnSubmit,
		handeOnUpdate,
		submitType,
		clientView,
		fileList,
	} = props;

	const { userData } = useContext(AuthContext);
	const { viewDoc, getDocumentHandler } = useContext(TimesheetContext);
	const {
		handleFiles,
		unsupportedFiles,
		validFiles,
		uploadFiles,
		removeFile,
		percentage,
		message,
		isFileUpload,
	} = useContext(FileContext);

	// upload button
	const [isOpen, setIsOpen] = useState(false);
	const handleClickOpen = () => setIsOpen(true);
	const handleModalClose = () => setIsOpen(false);

	// view documents
	const [isModal, setIsModal] = useState(false);
	const toggleModal = () => setIsModal(false);

	// view image button
	const openImageModal = useCallback(
		(file) => {
			getDocumentHandler("getdoc", file);
			setIsModal(true);
		},
		[getDocumentHandler]
	);

	// Backdrop loader
	const [isLoading, setIsLoading] = useState(false);
	const backdropCloseHandler = () => setIsLoading(false);
	// console.log(data?.clientSheet === undefined ? data?.timesheet : data?.clientSheet);
	const formValue = {
		_id: data !== undefined ? data._id : "",
		serviceType: data !== undefined ? data.serviceType : "",
		date: data?.date,
		timesheet: data !== undefined ? (data.timesheet !== undefined ? data.timesheet : "") : "",
		// timesheet: data !== undefined ? (data.clientSheet !== undefined ? data.clientSheet : data.timesheet) : '',
		clientSheet: data?.clientSheet === undefined ? data?.timesheet : data?.clientSheet,
	};

	const { register, handleSubmit, errors, control, watch, formState } = useForm({
		mode: "onChange",
		defaultValues: formValue,
	});

	const { isDirty, isValid, isSubmitting } = formState;

	const calDurationHandler = useCallback((st, et) => {
		// const diff = et.diff(st);
		// const diffDuration = moment.duration(diff);
		// return diffDuration.hours() + ":" + diffDuration.minutes();

		const diff = et.diff(st);
		const diffDuration = moment.duration(diff);

		// Round the duration to the nearest minute
		const roundedDuration = moment.duration({
			minutes: Math.round(diffDuration.asMinutes()),
		});

		// Use padStart to ensure two digits for hours and minutes
		const formattedHours = String(roundedDuration.hours()).padStart(2, "0");
		const formattedMinutes = String(roundedDuration.minutes()).padStart(2, "0");

		return `${formattedHours}:${formattedMinutes}`;
	}, []);

	// Timesheet Watching variables
	const telephoneStartTimeWatch = watch("timesheet.teleAndVideoStartTime");
	const telephoneEndTimeWatch = watch("timesheet.teleAndVideoEndTime");

	const waitingStartTimeWatch = watch("timesheet.waitingStartTime");
	const waitingEndTimeWatch = watch("timesheet.waitingEndTime");

	const attendanceOneStartTimeWatch = watch("timesheet.attendanceOneStartTime");
	const attendanceOneEndTimeWatch = watch("timesheet.attendanceOneEndTime");

	const attendanceTwoStartTimeWatch = watch("timesheet.attendanceTwoStartTime");
	const attendanceTwoEndTimeWatch = watch("timesheet.attendanceTwoEndTime");

	const travelInStartTimeWatch = watch("timesheet.travelInStartTime");
	const travelInEndTimeWatch = watch("timesheet.travelInEndTime");

	const travelOutStartTimeWatch = watch("timesheet.travelOutStartTime");
	const travelOutEndTimeWatch = watch("timesheet.travelOutEndTime");
	const travellingWatch = watch(
		"timesheet.travelling",
		data !== undefined ? (data.timesheet !== undefined ? data.timesheet.travelling : "") : null
	);

	// Clientsheet watching variables
	const telephoneStartTimeClientWatch = watch("clientSheet.teleAndVideoStartTime");
	const telephoneEndTimeClientWatch = watch("clientSheet.teleAndVideoEndTime");

	const waitingStartTimeClientWatch = watch("clientSheet.waitingStartTime");
	const waitingEndTimeClientWatch = watch("clientSheet.waitingEndTime");

	const attendanceOneStartTimeClientWatch = watch("clientSheet.attendanceOneStartTime");
	const attendanceOneEndTimeClientWatch = watch("clientSheet.attendanceOneEndTime");

	const attendanceTwoStartTimeClientWatch = watch("clientSheet.attendanceTwoStartTime");
	const attendanceTwoEndTimeClientWatch = watch("clientSheet.attendanceTwoEndTime");

	const travelInStartTimeClientWatch = watch("clientSheet.travelInStartTime");
	const travelInEndTimeClientWatch = watch("clientSheet.travelInEndTime");

	const travelOutStartTimeClientWatch = watch("clientSheet.travelOutStartTime");
	const travelOutEndTimeClientWatch = watch("clientSheet.travelOutEndTime");
	const travellingClientWatch = watch(
		"clientSheet.travelling",
		data !== undefined ? (data.clientSheet !== undefined ? data.clientSheet.travelling : "") : null
	);

	const totalDurationHandler = useCallback(() => {
		let totalDuration = "0:0";
		let totalAttendanceOneDuration = "0:0";
		let totalAttendanceTwoDuration = "0:0";
		let totalTravelIn = "0:0";
		let totalTravelOut = "0:0";
		let totalWaiting = "0:0";

		// TELEPHONE AND VIDEO CALL DURATION
		if (data.serviceType === "Telephone" || data.serviceType === "Video Call") {
			// console.log('TELEPHONE', moment(data?.timesheet?.teleAndVideoStartTime).format('hh:mm'), moment(data?.timesheet?.teleAndVideoEndTime).format('hh:mm'))
			const teleAndVideoStartTime = moment(
				telephoneStartTimeWatch !== undefined
					? telephoneStartTimeWatch
					: data?.timesheet?.teleAndVideoStartTime
			);
			const teleAndVideoEndTime = moment(
				telephoneEndTimeWatch !== undefined
					? telephoneEndTimeWatch
					: data?.timesheet?.teleAndVideoEndTime
			);
			totalDuration = calDurationHandler(teleAndVideoStartTime, teleAndVideoEndTime);

			/* TELEPHONE AND VIDEO CALL WAITING DURATION */
			const waitingStartTime = moment(
				waitingStartTimeWatch !== undefined
					? waitingStartTimeWatch
					: data.timesheet.waitingStartTime
			);
			const waitingEndTime = moment(
				waitingEndTimeWatch !== undefined ? waitingEndTimeWatch : data.timesheet.waitingEndTime
			);
			totalWaiting = calDurationHandler(waitingStartTime, waitingEndTime);
		}

		// ONSITE (FACE TO FACE) DURATION
		if (data.serviceType === "Onsite") {
			/* Attendance One */
			const attendanceOneStartTime = moment(
				attendanceOneStartTimeWatch !== undefined
					? attendanceOneStartTimeWatch
					: data?.timesheet?.attendanceOneStartTime
			);
			const attendanceOneEndTime = moment(
				attendanceOneEndTimeWatch !== undefined
					? attendanceOneEndTimeWatch
					: data?.timesheet?.attendanceOneEndTime
			);
			totalAttendanceOneDuration = calDurationHandler(attendanceOneStartTime, attendanceOneEndTime);

			/* Attendance Two */
			const attendanceTwoStartTime = moment(
				attendanceTwoStartTimeWatch !== undefined
					? attendanceTwoStartTimeWatch
					: data?.timesheet?.attendanceTwoStartTime
			);
			const attendanceTwoEndTime = moment(
				attendanceTwoEndTimeWatch !== undefined
					? attendanceTwoEndTimeWatch
					: data?.timesheet?.attendanceTwoEndTime
			);
			totalAttendanceTwoDuration = calDurationHandler(attendanceTwoStartTime, attendanceTwoEndTime);

			/* Travel Inward */
			const travelInStartTime = moment(
				travelInStartTimeWatch !== undefined
					? travelInStartTimeWatch
					: data?.timesheet?.travelInStartTime
			);
			const travelInEndTime = moment(
				travelInEndTimeWatch !== undefined ? travelInEndTimeWatch : data?.timesheet?.travelInEndTime
			);
			totalTravelIn = calDurationHandler(travelInStartTime, travelInEndTime);

			/* Travel Outward */
			const travelOutStartTime = moment(
				travelOutStartTimeWatch !== undefined
					? travelOutStartTimeWatch
					: data?.timesheet?.travelOutStartTime
			);
			const travelOutEndTime = moment(
				travelOutEndTimeWatch !== undefined
					? travelOutEndTimeWatch
					: data?.timesheet?.travelOutEndTime
			);
			totalTravelOut = calDurationHandler(travelOutStartTime, travelOutEndTime);

			/* waiting */
			const waitingStartTime = moment(
				waitingStartTimeWatch !== undefined
					? waitingStartTimeWatch
					: data?.timesheet?.waitingStartTime
			);
			const waitingEndTime = moment(
				waitingEndTimeWatch !== undefined ? waitingEndTimeWatch : data?.timesheet?.waitingEndTime
			);
			totalWaiting = calDurationHandler(waitingStartTime, waitingEndTime);
		}

		return {
			totalDuration,
			totalWaiting,
			totalAttendanceOneDuration,
			totalAttendanceTwoDuration,
			totalTravelIn,
			totalTravelOut,
		};
	}, [
		data,
		calDurationHandler,
		telephoneStartTimeWatch,
		telephoneEndTimeWatch,
		waitingStartTimeWatch,
		waitingEndTimeWatch,
		attendanceOneStartTimeWatch,
		attendanceOneEndTimeWatch,
		attendanceTwoStartTimeWatch,
		attendanceTwoEndTimeWatch,
		travelInStartTimeWatch,
		travelInEndTimeWatch,
		travelOutStartTimeWatch,
		travelOutEndTimeWatch,
	]);

	const totalDurationClientHandler = useCallback(() => {
		let totalDuration = "00:00";
		let totalAttendanceOneDuration = "00:00";
		let totalAttendanceTwoDuration = "00:00";
		let totalTravelIn = "00:00";
		let totalTravelOut = "00:00";
		let totalWaiting = "00:00";

		if (data.serviceType === "Telephone" || data.serviceType === "Video Call") {
			const teleAndVideoStartTime = moment(
				telephoneStartTimeClientWatch !== undefined
					? telephoneStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.teleAndVideoStartTime
					: data?.timesheet?.teleAndVideoStartTime
			);
			const teleAndVideoEndTime = moment(
				telephoneEndTimeClientWatch !== undefined
					? telephoneEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.teleAndVideoEndTime
					: data?.timesheet?.teleAndVideoEndTime
			);
			// console.log('tele dura: ', teleAndVideoStartTime, teleAndVideoEndTime);
			totalDuration = calDurationHandler(teleAndVideoStartTime, teleAndVideoEndTime);

			// waiting
			const waitingStartTime = moment(
				waitingStartTimeClientWatch !== undefined
					? waitingStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.waitingStartTime
					: data?.timesheet?.waitingStartTime
			);
			const waitingEndTime = moment(
				waitingEndTimeClientWatch !== undefined
					? waitingEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.waitingEndTime
					: data?.timesheet?.waitingEndTime
			);
			totalWaiting = calDurationHandler(waitingStartTime, waitingEndTime);
		} else if (data.serviceType === "Onsite") {
			const attendanceOneStartTime = moment(
				attendanceOneStartTimeClientWatch !== undefined
					? attendanceOneStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.attendanceOneStartTime
					: data?.timesheet?.attendanceOneStartTime
			);

			const attendanceOneEndTime = moment(
				attendanceOneEndTimeClientWatch !== undefined
					? attendanceOneEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.attendanceOneEndTime
					: data?.timesheet?.attendanceOneEndTime
			);

			totalAttendanceOneDuration = calDurationHandler(attendanceOneStartTime, attendanceOneEndTime);
			// console.log(totalAttendanceOneDuration);

			// Attendance Two
			const attendanceTwoStartTime = moment(
				attendanceTwoStartTimeClientWatch !== undefined
					? attendanceTwoStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.attendanceTwoStartTime
					: data?.timesheet?.attendanceTwoStartTime
			);

			const attendanceTwoEndTime = moment(
				attendanceTwoEndTimeClientWatch !== undefined
					? attendanceTwoEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.attendanceTwoEndTime
					: data?.timesheet?.attendanceTwoEndTime
			);

			totalAttendanceTwoDuration = calDurationHandler(attendanceTwoStartTime, attendanceTwoEndTime);

			// Travel Inward
			const travelInStartTime = moment(
				travelInStartTimeClientWatch !== undefined
					? travelInStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.travelInStartTime
					: data?.timesheet?.travelInStartTime
			);
			const travelInEndTime = moment(
				travelInEndTimeClientWatch !== undefined
					? travelInEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.travelInEndTime
					: data?.timesheet?.travelInEndTime
			);
			totalTravelIn = calDurationHandler(travelInStartTime, travelInEndTime);

			// Travel Outward
			const travelOutStartTime = moment(
				travelOutStartTimeClientWatch !== undefined
					? travelOutStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.travelOutStartTime
					: data?.timesheet?.travelOutStartTime
			);
			const travelOutEndTime = moment(
				travelOutEndTimeClientWatch !== undefined
					? travelOutEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.travelOutEndTime
					: data?.timesheet?.travelOutEndTime
			);
			totalTravelOut = calDurationHandler(travelOutStartTime, travelOutEndTime);

			// waiting
			const waitingStartTime = moment(
				waitingStartTimeClientWatch !== undefined
					? waitingStartTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.waitingStartTime
					: data?.timesheet?.waitingStartTime
			);
			const waitingEndTime = moment(
				waitingEndTimeClientWatch !== undefined
					? waitingEndTimeClientWatch
					: data.clientSheet !== undefined
					? data.clientSheet.waitingEndTime
					: data?.timesheet?.waitingEndTime
			);
			totalWaiting = calDurationHandler(waitingStartTime, waitingEndTime);
		}

		return {
			totalDuration,
			totalWaiting,
			totalAttendanceOneDuration,
			totalAttendanceTwoDuration,
			totalTravelIn,
			totalTravelOut,
		};
	}, [
		data,
		calDurationHandler,
		telephoneStartTimeClientWatch,
		telephoneEndTimeClientWatch,
		waitingStartTimeClientWatch,
		waitingEndTimeClientWatch,
		attendanceOneStartTimeClientWatch,
		attendanceOneEndTimeClientWatch,
		attendanceTwoStartTimeClientWatch,
		attendanceTwoEndTimeClientWatch,
		travelInStartTimeClientWatch,
		travelInEndTimeClientWatch,
		travelOutStartTimeClientWatch,
		travelOutEndTimeClientWatch,
	]);

	const onSubmit = async (data) => {
		setIsLoading(true);
		data.timesheet.isTimesheet = true;

		const formdata = { id: data.id, timesheet: data.timesheet, clientSheet: data.clientSheet };
		try {
			setIsLoading(false);
			await handleOnSubmit(formdata);
			// console.log('formdata', formdata)
			await handleClose();
		} catch (error) {
			setIsLoading(false);
		}
	};

	const onUpdate = async (data) => {
		try {
			const formdata = { id: data.id, timesheet: data.timesheet, clientSheet: data.clientSheet };
			handeOnUpdate(formdata);
		} catch (error) {
			console.log("update timesheet form", error);
		}
	};

	const rightsToEdit = userData.role === "interpreter" && data.isTimesheet ? true : false;

	let multipleRates;
	if (userData.role !== "interpreter" && clientView === true) {
		if (data?.interpreterDetails?.multipleRates) {
			multipleRates = "This IP has multiple rates";
			// return;
		}
		// return
	}

	let documentList;

	if (!isFileUpload) {
		documentList = "Uploading";
		if (fileList?.length === 0) {
			documentList = <NoDocument />;
		} else {
			documentList = (
				<List style={{ height: "auto", maxHeight: "300px", overflowX: "auto" }}>
					{fileList
						?.map((d) => {
							return (
								<ListItem
									button
									key={d.fileName}
									onClick={() => openImageModal(d.fileName)}
									filetype={d.fileType}
								>
									<ListItemAvatar>
										<Avatar>
											<AttachmentIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<Typography
												variant="caption"
												display="block"
												gutterBottom
											>
												{d.orginalFile}
											</Typography>
										}
									/>
								</ListItem>
							);
						})
						.filter((d) => d.props.filetype === "timesheetFile")}
				</List>
			);
		}
	}

	return (
		<React.Fragment>
			<Dialog
				maxWidth={userData.role === "superadmin" || userData.role === "admin" ? "lg" : "md"}
				open={open}
				onClose={handleClose}
				fullWidth={true}
				aria-labelledby="form-dialog-title"
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogTitle>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<div style={{ display: "flex" }}>
							<AccessTimeIcon color="primary" />
							{multipleRates && <span className={classes.multipleRates}>{multipleRates}</span>}
						</div>
						<Typography
							variant="h6"
							gutterBottom
						>
							Timesheet
						</Typography>

						<IconButton
							aria-label="close"
							size="small"
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						{/* <DevTool control={control} /> */}
						{loading ? (
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
							>
								<CircularProgress />
							</Grid>
						) : (
							<>
								<Grid container>
									{/* Booking Details */}
									<Grid
										item
										xs={12}
									>
										<Paper
											className={classes.paper}
											variant="outlined"
										>
											<Grid
												container
												direction="column"
												justifyContent="flex-start"
												alignItems="flex-start"
											>
												<Typography
													variant="subtitle2"
													gutterBottom
													style={{ fontWeight: "600", paddingBottom: "5px" }}
													color="primary"
												>
													Timesheet Details
												</Typography>
											</Grid>
											<Grid
												container
												spacing={2}
											>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="Reference ID"
														size="small"
														fullWidth
														inputRef={register}
														name="booking_ref"
														variant="outlined"
														defaultValue={data?.booking_ref}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="Interpreter"
														size="small"
														fullWidth
														inputRef={register}
														name="interpreterName"
														variant="outlined"
														defaultValue={data?.interpreterName}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="Interpreter ID"
														size="small"
														fullWidth
														inputRef={register}
														name="int_id"
														variant="outlined"
														defaultValue={data?.interpreterRef}
														InputProps={{ readOnly: true }}
													/>
													<input
														type="hidden"
														ref={register}
														name="interpreterId"
														defaultValue={localStorage.getItem("intID")}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="Service Type"
														size="small"
														fullWidth
														name="serviceType"
														inputRef={register}
														variant="outlined"
														defaultValue={data?.serviceType}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="File Reference"
														size="small"
														fullWidth
														inputRef={register}
														name="fileRef"
														variant="outlined"
														defaultValue={data?.fileRef}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={2}
												>
													<TextField
														label="Client Name"
														size="small"
														fullWidth
														inputRef={register}
														name="clientName"
														variant="outlined"
														defaultValue={data?.clientName}
														InputProps={{ readOnly: true }}
													/>
												</Grid>
											</Grid>
										</Paper>
									</Grid>

									<Grid
										item
										xs={12}
									>
										<form onSubmit={(e) => e.preventDefault()}>
											<Paper
												className={classes.paper}
												variant="outlined"
											>
												<input
													type="hidden"
													name="id"
													ref={register}
													defaultValue={data !== undefined ? data._id : ""}
												/>
												<input
													type="hidden"
													name="timesheet.status"
													ref={register}
													value="Timesheet Submitted"
												/>
												{/* interpreter attendance date */}
												<Grid
													container
													spacing={2}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={2}
													>
														<Typography
															variant="body2"
															gutterBottom
															style={{
																fontWeight: "600",
																display: "flex",
																justifyContent: "start",
																alignItems: "center",
															}}
														>
															Date of Attendance
														</Typography>
													</Grid>
													<Grid
														item
														xs={12}
														sm={6}
														md={3}
													>
														<Controller
															as={
																<KeyboardDatePicker
																	autoOk
																	format="dd/MM/yyyy"
																	label="Date of Attendance"
																	size="small"
																	variant="inline"
																	fullWidth
																	inputVariant="outlined"
																/>
															}
															control={control}
															name="date"
															rules={{ required: true }}
															error={errors["date"]}
															defaultValue={data?.date}
															disabled={userData.role !== "superadmin" ? true : false}
														/>
													</Grid>
												</Grid>
											</Paper>

											{/* Telephone , Video Call */}
											{(data.serviceType === "Telephone" || data.serviceType === "Video Call") && (
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={1}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={userData.role !== "interpreter" && clientView ? 6 : 12}
													>
														<Paper
															className={classes.paper}
															variant="outlined"
														>
															<Typography
																variant="subtitle2"
																gutterBottom
																className={classes.subtitle}
															>
																Interpreter
															</Typography>
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		{data.serviceType === "Telephone" ? "Telephone" : "Video Call"}
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.teleAndVideoStartTime"
																		rules={{ required: true }}
																		error={!!errors.teleAndVideoStartTime}
																		defaultValue={data?.timesheet?.teleAndVideoStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.teleAndVideoEndTime"
																		rules={{ required: true }}
																		error={!!errors.teleAndVideoEndTime}
																		defaultValue={data?.timesheet?.teleAndVideoEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		inputProps={{ readOnly: true }}
																		name="timesheet.teleAndVideoTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalDuration}
																	/>
																</Grid>
															</Grid>
															{/* Waiting */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Waiting
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.waitingStartTime"
																		rules={{ required: false }}
																		error={!!errors.waitingStartTime}
																		defaultValue={data?.timesheet?.waitingStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.waitingEndTime"
																		rules={{ required: false }}
																		error={!!errors.waitingEndTime}
																		defaultValue={data?.timesheet?.waitingEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		inputProps={{ readOnly: true }}
																		name="timesheet.waitingTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalWaiting}
																	/>
																</Grid>
															</Grid>
														</Paper>
													</Grid>
													{userData.role !== "interpreter" && clientView === true && (
														<Grid
															item
															xs={12}
															sm={6}
															md={6}
														>
															<Paper
																className={classes.clientPaper}
																variant="outlined"
															>
																<Typography
																	variant="subtitle2"
																	gutterBottom
																	className={classes.clientSubtitle}
																>
																	Client
																</Typography>
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			{data.serviceType === "Telephone"
																				? "Telephone"
																				: "Video Call"}
																		</Typography>
																	</Grid>
																	{/* client fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.teleAndVideoStartTime"
																			rules={{ required: true }}
																			error={!!errors.teleAndVideoStartTime}
																			defaultValue={formValue.clientSheet?.teleAndVideoStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.teleAndVideoEndTime"
																			rules={{ required: true }}
																			error={!!errors.teleAndVideoEndTime}
																			defaultValue={formValue.clientSheet?.teleAndVideoEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			inputProps={{ readOnly: true }}
																			name="clientSheet.teleAndVideoTotalDuration"
																			variant="outlined"
																			value={totalDurationClientHandler().totalDuration}
																		/>
																	</Grid>
																</Grid>
																{/* Waiting */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Waiting
																		</Typography>
																	</Grid>
																	{/* Client fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.waitingStartTime"
																			rules={{ required: false }}
																			error={!!errors.waitingStartTime}
																			defaultValue={formValue.clientSheet?.waitingStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.waitingEndTime"
																			rules={{ required: false }}
																			error={!!errors.waitingEndTime}
																			defaultValue={formValue.clientSheet?.waitingEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			inputProps={{ readOnly: true }}
																			name="clientSheet.waitingTotalDuration"
																			variant="outlined"
																			value={totalDurationClientHandler().totalWaiting}
																		/>
																	</Grid>
																</Grid>
															</Paper>
														</Grid>
													)}
												</Grid>
											)}
											{/* Onsite */}
											{data.serviceType === "Onsite" && (
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={1}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={userData.role !== "interpreter" && clientView ? 6 : 12}
													>
														<Paper
															className={classes.paper}
															variant="outlined"
														>
															<div>
																<Typography
																	variant="subtitle2"
																	gutterBottom
																	className={classes.subtitle}
																>
																	Interpreter{" "}
																	<span
																		style={{ fontSize: "10px", color: "#333", fontWeight: 400 }}
																	>
																		(Min Charges will be paid irrespective of the time you are
																		entering here)
																	</span>
																</Typography>
															</div>
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Attendance 1
																	</Typography>
																	{/* <small>Min Charges will be paid irrespective of the time you are entering here</small> */}
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.attendanceOneStartTime"
																		rules={{ required: true }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["attendanceOneStartTime"]
																		}
																		defaultValue={data?.timesheet?.attendanceOneStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.attendanceOneEndTime"
																		rules={{ required: true }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["attendanceOneEndTime"]
																		}
																		defaultValue={data?.timesheet?.attendanceOneEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.attendanceOneTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalAttendanceOneDuration}
																	/>
																</Grid>
															</Grid>

															{/* Att 2 */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Attendance 2
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.attendanceTwoStartTime"
																		rules={{ required: false }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["attendanceTwoStartTime"]
																		}
																		defaultValue={data?.timesheet?.attendanceTwoStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.attendanceTwoEndTime"
																		rules={{ required: false }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["attendanceTwoEndTime"]
																		}
																		defaultValue={data?.timesheet?.attendanceTwoEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.attendanceTwoTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalAttendanceTwoDuration}
																	/>
																</Grid>
															</Grid>
															{/* Travel In */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Travel Inward
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.travelInStartTime"
																		rules={{ required: true }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["travelInStartTime"]
																		}
																		defaultValue={data?.timesheet?.travelInStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.travelInEndTime"
																		rules={{ required: true }}
																		error={!!errors.travelInEndTime}
																		defaultValue={data?.timesheet?.travelInEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.travelInTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalTravelIn}
																	/>
																</Grid>
															</Grid>
															{/* Travel Out */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Travel Outward
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.travelOutStartTime"
																		rules={{ required: true }}
																		error={
																			errors["timesheet"] &&
																			errors["timesheet"]["travelOutStartTime"]
																		}
																		defaultValue={data?.timesheet?.travelOutStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.travelOutEndTime"
																		rules={{ required: true }}
																		error={!!errors.travelOutEndTime}
																		defaultValue={data?.timesheet?.travelOutEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.travelOutTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalTravelOut}
																	/>
																</Grid>
															</Grid>
															{/* Waiting */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Waiting
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="Start Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.waitingStartTime"
																		rules={{ required: false }}
																		error={!!errors.waitingStartTime}
																		defaultValue={data?.timesheet?.waitingStartTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Controller
																		as={
																			<KeyboardTimePicker
																				autoOk
																				ampm={false}
																				label="End Time"
																				size="small"
																				variant="inline"
																				fullWidth
																				inputVariant="outlined"
																			/>
																		}
																		control={control}
																		name="timesheet.waitingEndTime"
																		rules={{ required: false }}
																		error={!!errors.waitingEndTime}
																		defaultValue={data?.timesheet?.waitingEndTime}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		inputRef={register}
																		inputProps={{ readOnly: true }}
																		name="timesheet.waitingTotalDuration"
																		variant="outlined"
																		value={totalDurationHandler().totalWaiting}
																	/>
																</Grid>
															</Grid>
															{/* Travelling */}
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={2}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={6}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Transport By Own Transport
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																{/* <Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	
																	<FormControl
																		variant="outlined"
																		className={classes.formControl}
																		size="small"
																	>
																		<InputLabel id="transport-label">Transport By Own Transport</InputLabel>
																		<InputLabel id="transport-label">Transport By Public Transport</InputLabel>

																		<Controller
																			as={
																				<Select
																					label="Transport"
																					inputProps={{
																						name: "timesheet.travelling",
																						id: "travelling-interpreter-label",
																					}}
																				>
																					<MenuItem value={"By Public Transport"}>
																						By Public Transport
																					</MenuItem>
																					<MenuItem value={"By Own Transport"}>
																						By Own Transport
																					</MenuItem>
																				</Select>
																			}
																			control={control}
																			name="timesheet.travelling"
																			rules={{ required: true }}
																			defaultValue={data?.timesheet?.travelling || 0}
																			error={
																				errors["timesheet"] && errors["timesheet"]["travelling"]
																			}
																			disabled={rightsToEdit}
																		/>
																	</FormControl>
																</Grid> */}
																{/* {travellingWatch === "By Own Transport" ? ( */}
																<>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={3}
																	>
																		<TextField
																			label="Mileage"
																			size="small"
																			fullWidth
																			inputRef={register({ required: true })}
																			name="timesheet.mileage"
																			type="number"
																			variant="outlined"
																			defaultValue={data?.timesheet?.mileage || 0}
																			error={errors["timesheet"] && errors["timesheet"]["mileage"]}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={3}
																	>
																		<TextField
																			label="Parking"
																			size="small"
																			fullWidth
																			inputRef={register({ required: true })}
																			name="timesheet.parking"
																			type="number"
																			variant="outlined"
																			defaultValue={data?.timesheet?.parking || 0}
																			error={errors["timesheet"] && errors["timesheet"]["parking"]}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={6}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Transport By Public Transport
																		</Typography>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={3}
																	>
																		<TextField
																			label="Travel cost"
																			size="small"
																			fullWidth
																			inputRef={register({ required: true })}
																			name="timesheet.travelCost"
																			error={
																				errors["timesheet"] && errors["timesheet"]["travelCost"]
																			}
																			variant="outlined"
																			defaultValue={data?.timesheet?.travelCost || 0}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																</>
																{/* ) : ( */}
																{/* <Grid
																	item
																	xs={12}
																	sm={6}
																	md={3}
																>
																	<TextField
																		label="Travel cost"
																		size="small"
																		fullWidth
																		inputRef={register({ required: true })}
																		name="timesheet.travelCost"
																		error={errors["timesheet"] && errors["timesheet"]["travelCost"]}
																		variant="outlined"
																		defaultValue={data?.timesheet?.travelCost || 0}
																		disabled={rightsToEdit}
																	/>
																</Grid> */}
																{/* )} */}
															</Grid>
															{data?.interpreterDetails.vat && (
																<>
																	<div className={classes.vat}>VAT</div>
																	<input
																		type="checkbox"
																		ref={register}
																		style={{ display: "none" }}
																		name="timesheet.vat"
																		defaultChecked={
																			data?.timesheet?.vat
																				? data?.timesheet?.vat
																				: data?.interpreterDetails.vat
																		}
																	/>
																</>
															)}
														</Paper>
													</Grid>
													{userData.role !== "interpreter" && clientView && (
														<Grid
															item
															xs={12}
															sm={6}
															md={6}
														>
															<Paper
																className={classes.clientPaper}
																variant="outlined"
															>
																<Typography
																	variant="subtitle2"
																	gutterBottom
																	className={classes.clientSubtitle}
																>
																	Client
																</Typography>
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Attendance 1
																		</Typography>
																		{/* <small>Min Charges will be paid irrespective of the time you are entering here</small> */}
																	</Grid>
																	{/* Client fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.attendanceOneStartTime"
																			rules={{ required: true }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["attendanceOneStartTime"]
																			}
																			defaultValue={formValue?.clientSheet?.attendanceOneStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.attendanceOneEndTime"
																			rules={{ required: true }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["attendanceOneEndTime"]
																			}
																			defaultValue={formValue?.clientSheet?.attendanceOneEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.attendanceOneTotalDuration"
																			variant="outlined"
																			value={
																				totalDurationClientHandler().totalAttendanceOneDuration
																			}
																		/>
																	</Grid>
																</Grid>

																{/* Att 2 */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Attendance 2
																		</Typography>
																	</Grid>
																	{/* Interpreter fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.attendanceTwoStartTime"
																			rules={{ required: false }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["attendanceTwoStartTime"]
																			}
																			defaultValue={formValue.clientSheet?.attendanceTwoStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.attendanceTwoEndTime"
																			rules={{ required: false }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["attendanceTwoEndTime"]
																			}
																			defaultValue={formValue.clientSheet?.attendanceTwoEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.attendanceTwoTotalDuration"
																			variant="outlined"
																			value={
																				totalDurationClientHandler().totalAttendanceTwoDuration
																			}
																		/>
																	</Grid>
																</Grid>
																{/* Travel In */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Travel Inward
																		</Typography>
																	</Grid>
																	{/* Interpreter fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.travelInStartTime"
																			rules={{ required: true }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["travelInStartTime"]
																			}
																			defaultValue={formValue.clientSheet?.travelInStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.travelInEndTime"
																			rules={{ required: true }}
																			error={!!errors.travelInEndTime}
																			defaultValue={formValue.clientSheet?.travelInEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.travelInTotalDuration"
																			variant="outlined"
																			value={totalDurationClientHandler().totalTravelIn}
																		/>
																	</Grid>
																</Grid>
																{/* Travel Out */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Travel Outward
																		</Typography>
																	</Grid>
																	{/* Interpreter fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.travelOutStartTime"
																			rules={{ required: true }}
																			error={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["travelOutStartTime"]
																			}
																			defaultValue={formValue.clientSheet?.travelOutStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.travelOutEndTime"
																			rules={{ required: true }}
																			error={!!errors.travelOutEndTime}
																			defaultValue={formValue.clientSheet?.travelOutEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.travelOutTotalDuration"
																			variant="outlined"
																			value={totalDurationClientHandler().totalTravelOut}
																		/>
																	</Grid>
																</Grid>
																{/* Waiting */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Waiting
																		</Typography>
																	</Grid>
																	{/* Interpreter fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="Start Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.waitingStartTime"
																			rules={{ required: false }}
																			error={!!errors.waitingStartTime}
																			defaultValue={formValue.clientSheet?.waitingStartTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Controller
																			as={
																				<KeyboardTimePicker
																					autoOk
																					ampm={false}
																					label="End Time"
																					size="small"
																					variant="inline"
																					fullWidth
																					inputVariant="outlined"
																				/>
																			}
																			control={control}
																			name="clientSheet.waitingEndTime"
																			rules={{ required: false }}
																			error={!!errors.waitingEndTime}
																			defaultValue={formValue.clientSheet?.waitingEndTime}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={2}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			inputRef={register}
																			inputProps={{ readOnly: true }}
																			name="clientSheet.waitingTotalDuration"
																			variant="outlined"
																			value={totalDurationClientHandler().totalWaiting}
																		/>
																	</Grid>
																</Grid>
																{/* Travelling */}
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={2}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={6}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Travelling By Own Transport
																		</Typography>
																	</Grid>
																	{/* Interpreter fields */}
																	{/* <Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<FormControl
																			variant="outlined"
																			className={classes.formControl}
																			size="small"
																		>
																			<InputLabel id="transport-label">Transport</InputLabel>

																			<Controller
																				as={
																					<Select
																						label="Transport"
																						inputProps={{
																							name: "travelling",
																							id: "travelling-client-label",
																						}}
																					>
																						<MenuItem value={"By Public Transport"}>
																							By Public Transport
																						</MenuItem>
																						<MenuItem value={"By Own Transport"}>
																							By Own Transport
																						</MenuItem>
																					</Select>
																				}
																				control={control}
																				name="clientSheet.travelling"
																				rules={{ required: true }}
																				defaultValue={formValue.clientSheet?.travelling || 0}
																				error={
																					errors["clientSheet"] &&
																					errors["clientSheet"]["travelling"]
																				}
																				disabled={rightsToEdit}
																			/>
																		</FormControl>
																	</Grid> */}
																	{/* {travellingClientWatch === "By Own Transport" ? ( */}
																	<>
																		<Grid
																			item
																			xs={12}
																			sm={6}
																			md={3}
																		>
																			<TextField
																				label="Mileage"
																				size="small"
																				fullWidth
																				inputRef={register({ required: true })}
																				name="clientSheet.mileage"
																				type="number"
																				variant="outlined"
																				defaultValue={formValue.clientSheet?.mileage || 0}
																				error={
																					errors["clientSheet"] && errors["clientSheet"]["mileage"]
																				}
																				disabled={rightsToEdit}
																			/>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			sm={6}
																			md={3}
																		>
																			<TextField
																				label="Parking"
																				size="small"
																				fullWidth
																				inputRef={register({ required: true })}
																				name="clientSheet.parking"
																				type="number"
																				variant="outlined"
																				defaultValue={formValue.clientSheet?.parking || 0}
																				error={
																					errors["clientSheet"] && errors["clientSheet"]["parking"]
																				}
																				disabled={rightsToEdit}
																			/>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			sm={6}
																			md={6}
																		>
																			<Typography
																				variant="body2"
																				gutterBottom
																				style={{
																					fontWeight: 500,
																					display: "flex",
																					justifyContent: "start",
																					alignItems: "center",
																				}}
																			>
																				Transport By Public Transport
																			</Typography>
																		</Grid>
																		<Grid
																			item
																			xs={12}
																			sm={6}
																			md={3}
																		>
																			<TextField
																				label="Travel cost"
																				size="small"
																				fullWidth
																				inputRef={register({ required: true })}
																				name="clientSheet.travelCost"
																				error={
																					errors["clientSheet"] &&
																					errors["clientSheet"]["travelCost"]
																				}
																				variant="outlined"
																				defaultValue={formValue.clientSheet?.travelCost || 0}
																				disabled={rightsToEdit}
																			/>
																		</Grid>
																	</>
																	{/* ) : ( */}
																	{/* <Grid
																		item
																		xs={12}
																		sm={6}
																		md={3}
																	>
																		<TextField
																			label="Travel cost"
																			size="small"
																			fullWidth
																			inputRef={register({ required: true })}
																			name="clientSheet.travelCost"
																			error={
																				errors["clientSheet"] && errors["clientSheet"]["travelCost"]
																			}
																			variant="outlined"
																			defaultValue={formValue.clientSheet?.travelCost}
																			disabled={rightsToEdit}
																		/>
																	</Grid> */}
																	{/* )} */}
																</Grid>
															</Paper>
														</Grid>
													)}
												</Grid>
											)}
											{/* Translation */}
											{data.serviceType === "Translation" && (
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={1}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={userData.role !== "interpreter" && clientView ? 6 : 12}
													>
														<Paper
															className={classes.paper}
															variant="outlined"
														>
															<Typography
																variant="subtitle2"
																gutterBottom
																className={classes.subtitle}
															>
																Interpreter
															</Typography>
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={1}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Translation
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<TextField
																		label="Number of Words"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.wordCount"
																		variant="outlined"
																		defaultValue={data?.timesheet?.wordCount}
																		disabled={rightsToEdit}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<TextField
																		label="Number of Pages"
																		size="small"
																		fullWidth
																		inputRef={register}
																		name="timesheet.pageCount"
																		variant="outlined"
																		defaultValue={data?.timesheet?.pageCount}
																		disabled={rightsToEdit}
																	/>
																</Grid>
															</Grid>
														</Paper>
													</Grid>
													{userData.role !== "interpreter" && clientView && (
														<Grid
															item
															xs={12}
															sm={6}
															md={6}
														>
															<Paper
																className={classes.clientPaper}
																variant="outlined"
															>
																<Typography
																	variant="subtitle2"
																	gutterBottom
																	className={classes.clientSubtitle}
																>
																	Client
																</Typography>
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={1}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Translation
																		</Typography>
																	</Grid>
																	{/* Client fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<TextField
																			label="Number of Words"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.wordCount"
																			variant="outlined"
																			defaultValue={formValue.clientSheet.wordCount}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<TextField
																			label="Number of Pages"
																			size="small"
																			fullWidth
																			inputRef={register}
																			name="clientSheet.pageCount"
																			variant="outlined"
																			defaultValue={formValue.clientSheet.pageCount}
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																</Grid>
															</Paper>
														</Grid>
													)}
												</Grid>
											)}
											{/* Trascription */}
											{data.serviceType === "Transcription" && (
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={1}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={userData.role !== "interpreter" && clientView ? 6 : 12}
													>
														<Paper
															className={classes.paper}
															variant="outlined"
														>
															<Typography
																variant="subtitle2"
																gutterBottom
																className={classes.subtitle}
															>
																Interpreter
															</Typography>
															<Grid
																container
																direction="row"
																justifyContent="flex-start"
																alignItems="flex-start"
																spacing={1}
															>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={clientView ? 4 : 2}
																>
																	<Typography
																		variant="body2"
																		gutterBottom
																		style={{
																			fontWeight: 500,
																			display: "flex",
																			justifyContent: "start",
																			alignItems: "center",
																		}}
																	>
																		Transcription
																	</Typography>
																</Grid>
																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<TextField
																		label="Duration"
																		size="small"
																		fullWidth
																		onKeyUp={onlyNumbersWithColon}
																		inputRef={register}
																		name="timesheet.duration"
																		variant="outlined"
																		defaultValue={data?.timesheet?.duration || "00:00"}
																		disabled={rightsToEdit}
																		helperText="hh:mm"
																	/>
																</Grid>

																{/* Interpreter fields */}
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={4}
																>
																	<TextField
																		label="Word Count"
																		size="small"
																		fullWidth
																		inputRef={register({
																			pattern: {
																				value: /^[0-9]*(\.[0-9]{0,2})?$/,
																				message: "Word Count",
																			},
																		})}
																		name="timesheet.wordCount"
																		error={errors["timesheet"] && errors["timesheet"]["wordCount"]}
																		helperText={
																			errors["timesheet"] &&
																			errors["timesheet"]["wordCount"]?.message
																		}
																		defaultValue={data?.timesheet?.wordCount || 0}
																		variant="outlined"
																		disabled={rightsToEdit}
																	/>
																</Grid>
															</Grid>
														</Paper>
													</Grid>
													{userData.role !== "interpreter" && clientView && (
														<Grid
															item
															xs={12}
															sm={6}
															md={6}
														>
															<Paper
																className={classes.clientPaper}
																variant="outlined"
															>
																<Typography
																	variant="subtitle2"
																	gutterBottom
																	className={classes.clientSubtitle}
																>
																	Client
																</Typography>
																<Grid
																	container
																	direction="row"
																	justifyContent="flex-start"
																	alignItems="flex-start"
																	spacing={1}
																>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<Typography
																			variant="body2"
																			gutterBottom
																			style={{
																				fontWeight: 500,
																				display: "flex",
																				justifyContent: "start",
																				alignItems: "center",
																			}}
																		>
																			Transcription
																		</Typography>
																	</Grid>
																	{/* client fields */}
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<TextField
																			label="Duration"
																			size="small"
																			fullWidth
																			onKeyUp={onlyNumbersWithColon}
																			inputRef={register}
																			name="clientSheet.duration"
																			variant="outlined"
																			defaultValue={formValue.clientSheet.duration}
																			disabled={rightsToEdit}
																			helperText="hh:mm"
																		/>
																	</Grid>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		md={4}
																	>
																		<TextField
																			label="Word Count"
																			size="small"
																			fullWidth
																			inputRef={register({
																				pattern: {
																					value: /^[0-9]*(\.[0-9]{0,2})?$/,
																					message: "Word Count",
																				},
																			})}
																			name="clientSheet.wordCount"
																			error={
																				errors["clientSheet"] && errors["clientSheet"]["wordCount"]
																			}
																			helperText={
																				errors["clientSheet"] &&
																				errors["clientSheet"]["wordCount"]?.message
																			}
																			defaultValue={data?.clientSheet?.wordCount || 0}
																			variant="outlined"
																			disabled={rightsToEdit}
																		/>
																	</Grid>
																</Grid>
															</Paper>
														</Grid>
													)}
												</Grid>
											)}

											{/* Only for admin */}
											{userData.role === "superadmin" && (
												<Paper
													className={classes.paper}
													variant="outlined"
												>
													<Grid
														container
														spacing={1}
													>
														<Grid
															item
															xs={12}
															sm={6}
															md={2}
														>
															<Typography
																variant="body2"
																gutterBottom
																style={{
																	fontWeight: 500,
																	display: "flex",
																	justifyContent: "start",
																	alignItems: "center",
																}}
															>
																Additional Item
															</Typography>
														</Grid>
														<Grid
															item
															xs={12}
															sm={6}
															md={8}
														>
															<TextField
																label="Description"
																size="small"
																fullWidth
																inputRef={register}
																name="timesheet.additionalItem"
																variant="outlined"
																disabled={rightsToEdit}
																defaultValue={data?.timesheet?.additionalItem}
															/>
														</Grid>
														<Grid
															item
															xs={12}
															sm={6}
															md={2}
														>
															<TextField
																label="Amount"
																size="small"
																fullWidth
																inputRef={register({
																	pattern: {
																		value: /^[0-9]*(\.[0-9]{0,2})?$/,
																		message: "Amount",
																	},
																})}
																name="timesheet.additionalAmount"
																error={
																	errors["timesheet"] && errors["timesheet"]["additionalAmount"]
																}
																helperText={
																	errors["timesheet"] &&
																	errors["timesheet"]["additionalAmount"]?.message
																}
																defaultValue={data?.timesheet?.additionalAmount || 0}
																variant="outlined"
																disabled={rightsToEdit}
															/>
														</Grid>
													</Grid>
												</Paper>
											)}

											{/* Timesheet notes */}
											<Paper
												className={classes.paper}
												variant="outlined"
											>
												<Grid
													container
													spacing={1}
												>
													<Grid
														item
														xs={12}
														sm={6}
														md={2}
													>
														<Typography
															variant="body2"
															gutterBottom
															style={{
																fontWeight: 500,
																display: "flex",
																justifyContent: "start",
																alignItems: "center",
															}}
														>
															Notes
														</Typography>
													</Grid>
													<Grid
														item
														xs={12}
														sm={6}
														md={10}
													>
														<TextField
															label="Interpreter's Note"
															size="small"
															fullWidth
															inputRef={register}
															name="timesheet.note"
															variant="outlined"
															disabled={rightsToEdit}
															multiline
															rows={3}
															defaultValue={data?.timesheet?.note}
														/>
													</Grid>
												</Grid>
												{/* uploads list - Telephone, Video call, Onsite */}
												{(data.serviceType === "Telephone" ||
													data.serviceType === "Video Call" ||
													data.serviceType === "Onsite") && (
													<Grid
														container
														spacing={2}
													>
														{submitType !== "Approve" && (
															<>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<small>
																		Attach Scanned Timesheet
																		{/* <br /> (File size should not be greater then 5MB) */}
																	</small>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																>
																	<Button
																		variant="outlined"
																		color="primary"
																		size="small"
																		startIcon={<CloudUploadIcon />}
																		onClick={handleClickOpen}
																		disabled={rightsToEdit}
																	>
																		Uploads
																	</Button>
																</Grid>
															</>
														)}
														<FileUploads
															type="timesheetFile"
															id={data._id}
															open={isOpen}
															handleClose={handleModalClose}
															handleFiles={handleFiles}
															validFiles={validFiles}
															unsupportedFiles={unsupportedFiles}
															handleOnSubmit={uploadFiles}
															handleRemove={removeFile}
															percentage={percentage}
															message={message}
														/>
													</Grid>
												)}
												{/* document lists */}
												{/* <List dense={true}>
                          {fileList === undefined
                            ? null
                            : fileList
                              .map((d) => {
                                console.log('filelist', d.fileType)
                                return (
                                  <ListItem button key={d.fileName} onClick={() => openImageModal(d.fileName)} filetype={d.fileType}>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <AttachmentIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <Typography variant="caption" display="block" gutterBottom>
                                          {d.orginalFile}
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                );
                              })
                              .filter((d) => d.props.filetype === "timesheetFile")}
                        </List> */}

												<Grid
													item
													xs={12}
												>
													<Typography
														variant="subtitle2"
														gutterBottom
														style={{ fontWeight: "600", paddingBottom: "5px" }}
														color="primary"
													>
														Documents
													</Typography>

													{/* {fileList?.length === 0 ?
                            <NoDocument />
                            : <List style={{ height: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
                              {
                                fileList?.map((d) => {
                                  return (
                                    <ListItem button key={d.fileName} onClick={() => openImageModal(d.fileName)} filetype={d.fileType}>
                                      <ListItemAvatar>
                                        <Avatar>
                                          <AttachmentIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Typography variant="caption" display="block" gutterBottom>
                                            {d.orginalFile}
                                          </Typography>
                                        }
                                      />
                                      <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => deleteDocumentHandler({ id: user._id, fileName: d.fileName })}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })
                                  .filter((d) => d.props.filetype === "timesheetFile")
                              }
                            </List>} */}

													{documentList}
												</Grid>

												{/* for client */}
												{submitType === "Approve" && (
													<FormControlLabel
														control={
															<Checkbox
																name="timesheet.noClientInvoice"
																color="primary"
															/>
														}
														inputRef={register}
														label="Do not generate client invoice"
														defaultValue
													/>
												)}
											</Paper>
										</form>
									</Grid>
								</Grid>
							</>
						)}
					</MuiPickersUtilsProvider>
				</DialogContent>
				{!rightsToEdit && (
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						{submitType === "Approve" && (
							<>
								<Button
									type="submit"
									variant="outlined"
									size="small"
									color="primary"
									onClick={handleSubmit(onUpdate)}
									disabled={!isValid && isDirty && rightsToEdit}
								>
									{isSubmitting ? "Processing..." : "Save"}
								</Button>
							</>
						)}
						<Button
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							onClick={handleSubmit(onSubmit)}
							disabled={!isValid && isDirty && rightsToEdit}
							// disabled={rightsToEdit && !isDirty && !isValid}
						>
							{isSubmitting ? "Processing..." : submitType}
						</Button>
						&nbsp;
					</DialogActions>
				)}
			</Dialog>
			<ViewFile
				previewFile={viewDoc}
				open={isModal}
				handleClose={toggleModal}
			/>
			<Loader
				open={isLoading}
				handleClose={backdropCloseHandler}
			/>
		</React.Fragment>
	);
});
