import React, { createContext, useReducer, useContext } from 'react';
import AccountStatementReducer from '../../reducers/reports/accountStatementReducer';
import axios from '../../../axios';
import { AlertContext } from '../alertState';

const initialState = {
    loading: false,
    accounts: [],
    paidJobs: [],
    refunds: [],
    totalAcAmount: 0,
    totalIpAmount: 0,
    totalPaidJobs: 0,
    totalvat: 0, 
    totalService : 0,
    lilAmount: 0,
    confirmedJobs: [],
    clients: [],
    error: null
}

export const AccountStatementContext = createContext(initialState);
export const AccountStatementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AccountStatementReducer, initialState);
    // const { alertDispatch } = useContext(AlertContext);

    const getReportsHandler = async (id) => {
        // dispatch({ type: 'PROCESS_START' })
        try {
            const result = await axios.get(`/api/v1/bookings/acstatement${id}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_RECORDS', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    const getPaidJobsHandler = async (id) => {
        try {
            const result2 = await axios.get(`/api/v1/bookings/paidjobs${id}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_PAID_RECORED', payload: result2.data })
        } catch (error) {
            console.error(error)
        }
    }

    const getConfirmedJobsHandler = async (id) => {
        // try {
        //     const result3 = await axios.get(`/api/v1/bookings/confirmedjobs?companyId=${id}`)
        //     // alertDispatch({ type: 'open', message: 'Done' });
        //     dispatch({ type: 'FETCH_CONFIRMED_RECORED', payload: result3.data })
        // } catch (error) {
        //     console.error(error)
        // }
    }

    const getClientsHandler = async (company) => {
        try {
            const result = await axios.get(`/api/v1/clients?companyName=${company}`);
            dispatch({ type: 'FETCH_CLIENT_RECORD', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    const getRefundHandler = async (company) => {
        try {
            const result = await axios.get(`/api/v1/payments/refunds?companyName=${company}`);
            dispatch({ type: 'FETCH_REFUNDS_RECORED', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    const clearStateHandler = () => {
        dispatch({ type: 'PROCESS_START' })
    }

    return (
        <AccountStatementContext.Provider
            value={{
                isloading: state.loading,
                reports: state.accounts,
                paidJobs: state.paidJobs,
                refunds: state.refunds,
                totalAcAmount: state.totalAcAmount,
                totalIpAmount: state.totalIpAmount,
                totalPaidJobs: state.totalPaidJobs,
                totalvat: state.totalvat, 
                totalService : state.totalService,
                lilAmount: state.lilAmount,
                clients: state.clients,
                confirmedJobs: state.confirmedJobs,
                error: state.error,
                getReportsHandler,
                getClientsHandler, getPaidJobsHandler, getConfirmedJobsHandler, getRefundHandler,
                clearStateHandler
            }}>
            {children}
        </AccountStatementContext.Provider>
    )
}