export default (state, action) => {
    switch (action.type) {
        case 'PROCESS_START':
            return {
                ...state,
                loading: true,
                accounts: [],
                paidJobs: [],
                refunds: [],
                totalAcAmount: 0,
                totalIpAmount: 0,
                totalPaidJobs: 0,
                totalvat: 0, 
                totalService : 0,
                lilAmount: 0,
                confirmedJobs: [],
                clients: [],
                error: null
            }

        case 'FETCH_RECORDS':
            return {
                ...state,
                loading: false,
                accounts: action.payload.data,
                totalAcAmount: action.payload.total,
                totalIpAmount: action.payload.totalIP,
                totalvat: action.payload.totalvat, 
                totalService : action.payload.totalService,
                lilAmount: action.payload.lilAmount
            }

        case 'FETCH_PAID_RECORED':
            return {
                ...state,
                loading: false,
                paidJobs: action.payload.data,
                totalPaidJobs: action.payload.total
            }

        case 'FETCH_CONFIRMED_RECORED':
            return {
                ...state,
                loading: false,
                confirmedJobs: action.payload.data,
            }

        case 'FETCH_REFUNDS_RECORED':
            return {
                ...state,
                loading: false,
                refunds: action.payload.data,
            }

        case 'FETCH_CLIENT_RECORD':
            return {
                ...state,
                clients: action.payload.data
            }

        case 'ERROR_CATCH':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}