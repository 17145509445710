import React, { useContext, useEffect } from 'react'
import { InterpreterContext } from '../../../../../context/states/interpreterState'

import { makeStyles } from '@material-ui/core/styles';
import { Paper, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Grid, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
    }
  }));

export const Hours = ({ data }) => {
    const classes = useStyles();
    const { getIPLanguageHandler, getHoursHandler, ipLanguages, hours } = useContext(InterpreterContext)

    const [language, setLanguage] = React.useState('');

    const handleChange = (event) => {
        setLanguage(event.target.value);
        getHoursHandler(data.id, event.target.value)
    };

    useEffect(() => {
        getIPLanguageHandler(data.id)
        getHoursHandler(data.id, language)

        return () => {
            getHoursHandler(data.id, '')
        }
    }, [data.id])


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" fullWidth size="small" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Select Language</InputLabel>
                            <Select
                                label="Select Language"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={language}
                                onChange={handleChange}>
                                {ipLanguages.map(lang => <MenuItem value={lang}>{lang}</MenuItem>)}
                            </Select>
                    </FormControl>
                    </Grid>
                        </Paper>
                    <Paper variant="outlined" elevation={3}>
                        <Grid item xs={12}>

                            <TableContainer component={Paper}>
                                <Table aria-label="serice one" size="small">
                                    <caption>Interpretation hours: {hours?.startDate} to present</caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Service</TableCell>
                                            <TableCell align="left">No of bookings</TableCell>
                                            <TableCell align="left">No of hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell align="left">Telephone</TableCell>
                                            <TableCell align="left">{hours?.telephoneNo || 0 } </TableCell>
                                            <TableCell align="left">{hours?.telephoneHours || 0 }</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="left">Video</TableCell>
                                            <TableCell align="left">{hours?.videoNo || 0} </TableCell>
                                            <TableCell align="left">{hours?.videoHours || 0}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="left">Onsite</TableCell>
                                            <TableCell align="left">{hours?.onsiteNo || 0}</TableCell>
                                            <TableCell align="left">{hours?.onsiteHours || 0}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell align="left">{hours?.telephoneNo + hours?.videoNo + hours?.onsiteNo || 0}</TableCell>
                                            <TableCell align="left">{hours?.totalHours}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Paper>

                   
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Paper variant="outlined" elevation={3}>
                        <Grid item xs={12} >
                            <TableContainer component={Paper}>
                                <Table aria-label="service two" size="small">
                                <caption>Interpretation hours: {hours?.startDate} to present</caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Service</TableCell>
                                            <TableCell align="left">No of bookings</TableCell>
                                            <TableCell align="left">Total Word count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell align="left">Translation</TableCell>
                                            <TableCell align="left">{hours?.translationNo} </TableCell>
                                            <TableCell align="left">{hours?.translationTwc}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell align="left">Transcription</TableCell>
                                            <TableCell align="left">{hours?.transcriptionNo} </TableCell>
                                            <TableCell align="left">{hours?.transcriptionTwc}</TableCell>
                                        </TableRow>


                                        <TableRow>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell align="left">{hours?.totalJobs}</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
