import React from 'react';
import { TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const headCells = [
  { id: 'bookingRef', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'dateOfAttendance', numeric: false, disablePadding: false, label: 'Date of Booking' },
  { id: 'language', numeric: false, disablePadding: false, label: 'Language' },
  { id: 'company', numeric: false, disablePadding: false, label: 'Company' },
  { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
  { id: 'service', numeric: false, disablePadding: false, label: 'Service' },
  { id: 'paymentDate', numeric: false, disablePadding: true, label: 'Payment Date' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
];

export const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    // numSelected, rowCount, onSelectAllClick
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};
