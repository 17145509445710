import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";

// import moment from 'moment';
import { makeStyles, lighten } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  CircularProgress,
  Chip,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // FormControlLabel,
  // Switch,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  getComparator,
  stableSort,
} from "../../../../../libs/headerSortMethods";

import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { SubmitModal } from "./SubmitModal";
import { PaymentNotes } from "./PaymentNotes";

import { AlertContext } from "../../../../../context/states/alertState";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  cellHeaderWidth: {
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "100px",
    // width: '100%',
    padding: "12px 2px",
    background: "#f9f9f9",
  },
  callWidth: {
    fontSize: "12px",
    minWidth: "110px",
    padding: "10px 2px",
    // width: '100%',
  },
  nagPayment: {
    float: "right",
    fontSize: "20px",
    background: lighten(theme.palette.secondary.light, 0.7),
    padding: "3px 6px",
    borderRadius: "4px",
    color: "#333",
  },
  payment: {
    float: "right",
    fontSize: "20px",
    padding: "3px 6px",
    background: lighten(theme.palette.primary.light, 0.7),
    borderRadius: "4px",
  },
  inputLabel: {
    color: "#4f4f4f",
    fontWeight: 600,
    padding: "6px 7px",
    marginBottom: "10px",
  },
  // paymentInput: { color: '#4f4f4f', fontWeight: 600, padding: '6px 7px', marginBottom: '10px', width: '120px' },
  borderLess: {
    border: "none",
    background: "transparent",
    width: "100%",
    maxWidth: "160px",
  },
}));

export const DueDetails = React.memo((props) => {
  const {
    open,
    handleClose,
    isLoading,
    pData,
    companyData,
    submitHandler,
    approvedBy,
    submitteData,
    closeSubmitHandle,
  } = props;
  const data = pData !== undefined ? pData : "";
  const { register, handleSubmit, watch, errors, control } = useForm();
  const { alertDispatch } = useContext(AlertContext);

  const actionWatch = watch("action");
  const balanceWatch = watch("balance", "number");
  const changeFields = watch("details");
  const refundWatch = watch("refundAmount", "number");

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("booking_ref");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [isSubmitData, setIsSubmitData] = useState({});
  const [isModal, setIsModal] = useState(false);
  // const [isCPayment, setIsCPayment] = useState(0);
  const [isNoteModal, setIsNoteModal] = useState(false);

  const handleRequestSort = useCallback((event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data?.map((n) => n);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [data]
  );

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [setSelected, selected]
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = useCallback(
    (name) => selected.indexOf(name) !== -1,
    [selected]
  );

  // const selectedAllocated = pData.details?.map(item => +item.allocated).reduce((sum, total) => sum + total, 0)
  const selectedAllocated = changeFields
    ?.filter((item) => selected.some((field) => item.bookingId === field._id))
    .map((item) => parseFloat(item?.allocated))
    .reduce((sum, total) => sum + total, 0);
  // const selectedTotalAllocatedAmount = changeFields?.filter(item => selected.some(field => item.bookingId === field._id)).map(item => parseFloat(item?.allocated))
  const calBalance = balanceWatch - selectedAllocated || 0;

  const comfirmModal = (v) => {
    if (actionWatch === "refund") {
      const refund = isNaN(+refundWatch) || +refundWatch === 0;
      if (refund) {
        alertDispatch({
          type: "open",
          message: `Refund amount is ${refund ? 0 : +refundWatch}`,
        });
        return;
      }
      if (+refundWatch > +balanceWatch) {
        console.log("insufficient balance");
        console.log(refundWatch, balanceWatch);
        alertDispatch({ type: "open", message: `Insufficient balance` });
        return;
      }
    }

    let obj = {};
    obj.id = companyData.id;
    obj.companyId = companyData.companyId;
    obj.company = companyData.name;
    obj.paymentDate = v.paymentDate;
    obj.balance = +calBalance.toFixed(2);
    obj.action = actionWatch;

    if (actionWatch === "allocate") {
      obj.allocatedAmount = parseFloat(selectedAllocated.toFixed(2));
      obj.details = v.details.filter((item) =>
        selected.some((field) => item.bookingId === field._id)
      );
    } else if (actionWatch === "refund") {
      obj.refundAmount = +refundWatch;
      obj.balance = balanceWatch - +refundWatch;
    } else {
      delete obj.detail;
    }

    console.log(obj);
    try {
      setIsSubmitData(obj);
      setIsModal(true);
      setSelected([]);
    } catch (error) {
      setIsSubmitData(obj);
      setIsModal(true);
    }
  };

  const closeSubmitHandler = useCallback(() => {
    setIsModal(false);
    setSelected([]);
  }, [setSelected, setIsModal]);

  const modalHandleClose = useCallback(() => {
    handleClose();
    setSelected([]);
  }, [handleClose, handleClose]);

  const SubmitModalMemo = React.useMemo(
    () => (
      <SubmitModal
        open={isModal}
        data={isSubmitData}
        handleClose={closeSubmitHandler}
        submitHandler={submitHandler}
        closeSubmitHandle={closeSubmitHandle}
        approvedBy={approvedBy}
        modalHandleClose={modalHandleClose}
      />
    ),
    [
      isModal,
      approvedBy,
      closeSubmitHandle,
      isSubmitData,
      submitHandler,
      modalHandleClose,
    ]
  );

  const paymentToggleHandler = useCallback(
    () => setIsNoteModal((isNoteModal) => (isNoteModal = !isNoteModal)),
    [setIsNoteModal]
  );

  const onChangeHandler = (e) => {
    console.log("changed", e.target);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs>
              {companyData.name}{" "}
              <Chip
                size="small"
                color="primary"
                label={"Outstanding Client Payment"}
              />
            </Grid>
            <Grid item xs={2}>
              {/* <span className={+balanceWatch >= selectedAllocated ? classes.payment : classes.nagPayment}>£ {calBalance?.toFixed(2)}</span> */}
              {/* <span className={classes.payment}>£ {calBalance?.toFixed(2)}</span> */}
              <span
                className={
                  balanceWatch >= selectedAllocated
                    ? classes.payment
                    : classes.nagPayment
                }
              >
                £ {calBalance?.toFixed(2)}
              </span>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {/* <DevTool control={control} /> */}
            {isLoading && (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Payment Reference"
                    variant="outlined"
                    size="small"
                    // onChange={(event) => setIsCPayment(event.target.value)}
                    defaultValue={data?.paymentRef}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={actionWatch === "refund" ? 2 : 3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          autoOk
                          format="dd/MM/yyyy"
                          label="Date of Payment"
                          size="small"
                          variant="inline"
                          fullWidth
                          inputVariant="outlined"
                          error={!!errors.paymentDate}
                          helperText={
                            errors.paymentDate && errors.paymentDate.message
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          readOnly={true}
                        />
                      }
                      control={control}
                      name="paymentDate"
                      rules={{ required: true }}
                      error={!!errors.paymentDate}
                      defaultValue={data?.paymentDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={actionWatch === "refund" ? 2 : 3}>
                  <TextField
                    fullWidth
                    id="balanceamount"
                    label="Balance Amount"
                    type="number"
                    variant="outlined"
                    size="small"
                    name="balance"
                    inputRef={register({
                      required: true,
                    })}
                    InputProps={{
                      readOnly: true,
                    }}
                    error={!!errors.balance}
                    defaultValue={data?.balance || 0}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="action-label">Action</InputLabel>
                    <Controller
                      defaultValue={""}
                      as={
                        <Select labelId="action-label" label="Action">
                          <MenuItem value={""}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"allocate"}>Allocate</MenuItem>
                          <MenuItem value={"refund"}>Refund</MenuItem>
                          <MenuItem value={"writeOff"}>WriteOff</MenuItem>
                        </Select>
                      }
                      control={control}
                      name="action"
                      rules={{ required: true }}
                      error={!!errors.action}
                      onChange={onChangeHandler}
                    />
                  </FormControl>
                </Grid>

                {actionWatch === "refund" && (
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      id="refundamount"
                      label="Refund Amount"
                      type="number"
                      variant="outlined"
                      size="small"
                      name="refundAmount"
                      inputRef={register({
                        required: true,
                      })}
                      error={!!errors.refundAmount}
                      defaultValue={data?.refundAmount || 0}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Paper className={classes.paper}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                title="Jobs"
                paymentToggleHandler={paymentToggleHandler}
                notesCounts={pData?.paymentNotes?.length}
              />
              <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={data?.details?.length}
                    />
                    <TableBody>
                      {isLoading &&
                        stableSort(
                          data["details"],
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const detail = `details[${index}]`;
                            const isItemSelected = isSelected(row);

                            const labelId = `enhanced-table-checkbox-${index}`;
                            const {
                              bookingRef,
                              invoiceNo,
                              invoiceDate,
                              clientName,
                              dateOfAttendance,
                              invTotal,
                              outstanding,
                              allocated,
                              notes,
                            } = row;

                            const isOutstanding =
                              +outstanding - +allocated > 0 ? false : true;
                            const paidBookings =
                              row.bookingId === undefined ? true : false; // if booking is paid

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={detail}
                                selected={isItemSelected}

                                // onClick={(event) => handleClick(event, row)}
                              >
                                <TableCell padding="checkbox">
                                  {paidBookings && (
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      color="primary"
                                      onClick={(event) =>
                                        handleClick(event, row)
                                      }
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  id={labelId}
                                  padding="checkbox"
                                  className={classes.callWidth}
                                >
                                  {/* {bookingReference} */}
                                  <input
                                    defaultValue={row._id}
                                    name={`${detail}.bookingId`}
                                    ref={register}
                                    type="hidden"
                                  />
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.bookingRef`}
                                    ref={register}
                                    defaultValue={bookingRef}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell className={classes.callWidth}>
                                  {/* {invoiceNo} */}
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.invoiceNo`}
                                    ref={register}
                                    defaultValue={invoiceNo}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell className={classes.callWidth}>
                                  {/* {moment(invoiceDate).format('DD-MM-Y')} */}
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.invoiceDate`}
                                    ref={register}
                                    defaultValue={invoiceDate}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell className={classes.callWidth}>
                                  {/* {clientName} */}
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.clientName`}
                                    ref={register}
                                    defaultValue={clientName}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell className={classes.callWidth}>
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.dateOfAttendance`}
                                    ref={register}
                                    defaultValue={dateOfAttendance}
                                    readOnly
                                  />
                                  {/* {moment(dateOfAttendance).format('DD-MM-Y')} */}
                                </TableCell>
                                <TableCell
                                  className={classes.callWidth}
                                  align="left"
                                >
                                  {/* {invTotal?.toFixed(2)} */}
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.invTotal`}
                                    ref={register}
                                    defaultValue={+invTotal}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.callWidth}
                                  align="left"
                                >
                                  <input
                                    className={classes.borderLess}
                                    type="text"
                                    name={`${detail}.outstanding`}
                                    ref={register}
                                    defaultValue={(
                                      +outstanding - +allocated
                                    ).toFixed(2)}
                                    readOnly
                                  />
                                </TableCell>
                                <TableCell
                                  className={classes.callWidth}
                                  align="left"
                                >
                                  {!paidBookings ? (
                                    <input
                                      style={{ width: 75 }}
                                      className={classes.borderLess}
                                      type="text"
                                      name={`${detail}.allocated`}
                                      ref={register}
                                      defaultValue={+allocated || 0}
                                      readOnly={isOutstanding}
                                    />
                                  ) : (
                                    <TextField
                                      style={{ width: 75 }}
                                      label="Amount"
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputRef={register}
                                      name={`${detail}.allocated`}
                                      defaultValue={+allocated || 0}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                  style={{ width: "100%" }}
                                  padding="none"
                                >
                                  {!paidBookings ? (
                                    <input
                                      className={classes.borderLess}
                                      type="text"
                                      name={`${detail}.notes`}
                                      ref={register}
                                      defaultValue={notes}
                                      readOnly
                                    />
                                  ) : (
                                    <TextField
                                      fullWidth
                                      label="Notes"
                                      inputRef={register}
                                      name={`${detail}.notes`}
                                      defaultValue={notes}
                                      variant="outlined"
                                      size="small"
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })
                          .filter((item) => item.key !== submitteData.bookID)}
                      {!isLoading && (
                        <TableRow>
                          <TableCell colSpan="9" align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </form>
              {isLoading && (
                <TablePagination
                  rowsPerPageOptions={[100, 150, 200, 500]}
                  component="div"
                  count={data["details"].length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Paper>
            {SubmitModalMemo}
            {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalHandleClose} color="primary">
            Cancel
          </Button>
          {actionWatch === "allocate" ? (
            <Button
              onClick={handleSubmit(comfirmModal)}
              color="primary"
              variant="contained"
              disabled={
                selected.length !== 0 && balanceWatch >= selectedAllocated
                  ? false
                  : true
              }
              autoFocus
            >
              Generate
            </Button>
          ) : (
            <Button
              onClick={handleSubmit(comfirmModal)}
              color="primary"
              variant="contained"
              disabled={actionWatch === "allocate" ? true : false}
              autoFocus
            >
              {actionWatch || "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {isNoteModal && (
        <PaymentNotes
          open={isNoteModal}
          handleClose={paymentToggleHandler}
          notes={pData.paymentNotes}
        />
      )}
    </>
  );
});
