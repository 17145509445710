import React, { createContext, useReducer, useContext } from 'react';
import InterpreterReducer from '../reducers/interpreterReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  imgLoading: false,
  nLoading: false,
  fLoading: false,
  docLoad: false,
  pagination: {},
  total: 0,
  count: 0,
  progressValue: 0,
  interpreters: [],
  interpreter: {},
  profileImage: '',
  profileNotes: {},
  fileList: {},
  languages: [],
  dialects: [],
  error: null,
  viewDoc: '',
  pageLimit: null,
  advSearchObj: null,
  advSearchQuery: null,
  ipLanguages: [],
  hours: {}
};

// creating context
export const InterpreterContext = createContext(initialState);

// provider component
export const InterpreterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InterpreterReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All interpreter data */
  const getInterpretersHandler = async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Interpreters + '?' + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New interpreter data */
  const addInterpreterHandler = async (interpreter) => {
    const result = await axios.post(urlRequest.Interpreters, interpreter);
    dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Interpreter created' });
    }
    return await result;
  };

  /* Fetch Single Interpreter Data by id */
  const getInterpreterHandler = async (id) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(`${urlRequest.Interpreters}/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Update interpreter by id */
  const updateInterpreterHandler = async (interpreter) => {
    try {
      const result = await axios.put(`${urlRequest.Interpreters}/${interpreter._id}`, interpreter);
      dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Interpreter updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Update interpreter languaage by id */
  const updateInterpreteLanguagesrHandler = async (interpreter) => {
    try {
      const result = await axios.put(`${urlRequest.InterpreterLanguages}/${interpreter._id}`, interpreter);
      dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Interpreter updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* upload profile image for interperter by id and pass photo */
  const uploadProImageHandler = async (file) => {
    dispatch({ type: actionTypes.FILE_UPLOAD_START });
    try {
      const result = await axios.put(`${urlRequest.Interpreters}/${file.id}/photo`, file.file);
      dispatch({ type: actionTypes.UPDATE_PROFILE_IMAGE, payload: result.data.data });
      getProfileHandler(result.data.data);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Profile image uploaded' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Get Profile image for interpreter by id */
  const getProfileHandler = async (filename) => {
    dispatch({ type: actionTypes.FILE_UPLOAD_START });
    try {
      const photo = await axios.get(`${urlRequest.Interpreters}/${filename}/photo`);
      dispatch({ type: actionTypes.GET_PROFILE_IMAGE, payload: photo.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Update profile notes passing the interpreter by ID */
  const updateProfileNotesHandler = async (interpreter) => {
    try {
      const result = await axios.put(`${urlRequest.InterpreterProNotes}/${interpreter.id}`, interpreter);
      dispatch({ type: actionTypes.ADD_PROFILE_NOTES, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Profile note added' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Upload documents for interpreter qualification */
  const uploadDocumentHandler = async (file) => {
    dispatch({ type: actionTypes.UPLOAD_START });
    try {
      const result = await axios.put(`${urlRequest.Interpreters}/${file.id}/doc`, file.formData, {
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          dispatch({ type: actionTypes.PROGRESS_VALUE, payload: uploadPercentage });
        },
      });
      dispatch({ type: actionTypes.DOCUMENT_UPLOAD, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Document uploaded' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Fetch All Languages data */
  const getLangugagesHandler = async () => {
    try {
      const result = await axios.get(urlRequest.Languages);
      dispatch({ type: actionTypes.GET_ALL_LANGUGAGES, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch All Dialects data */
  const getDialectsHandler = async () => {
    try {
      const result = await axios.get(urlRequest.Dialects);
      dispatch({ type: actionTypes.GET_ALL_DIALECTS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Get Documents for interpreter by id */
  // const getDocumentHandler = async (filename, type) => {
  //   dispatch({ type: 'DOC_LOAD' });
  //   try {
  //     const result = await axios.get(`/api/v1/interpreters/${filename}/${type}`);
  //     dispatch({ type: 'GET_DOCUMENT_IMAGE', payload: result.config.url });
  //   } catch (error) {
  //     console.log(error);
  //     dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
  //   }
  // };

  const getDocument2Handler = async (type, filename) => {
    dispatch({ type: actionTypes.DOC_LOAD });
    try {
      const result = await axios.get(`${urlRequest.Interpreters}/${type}/${filename}`);
      dispatch({ type: actionTypes.GET_DOCUMENT_IMAGE, payload: result.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const mailHandler = async (data) => {
    try {
      const result = await axios.post(urlRequest.mailSend, data);
      console.log(result);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Confirmation mail sent' });
      }
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Oops Something went wrong!' });
    }
  };

  const getIPLanguageHandler = async (id) => {
    try {
      const result = await axios.get(`${urlRequest.interpreterIPLanguages}/${id}`)
      dispatch({ type: 'INTERPRETER_IPLANGUAGES', payload: result.data.data });
      console.log(result)
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  }
  
  const getHoursHandler = async (id, language) => {
    try {
      const result = await axios.get(`${urlRequest.interpreterHours}/${id}/${language}`)
      dispatch({ type: 'INTERPRETER_HOURS', payload: result.data.data });
      console.log(result)
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  }

  const sendMailToInterpretersHandler = async (data) => {
    try {
      const result = await axios.post(urlRequest.mailSend + '/ipmail', data);
      // console.log(result);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Mail sent to interpreter(s)' });
      }
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Oops Something went wrong!' });
    }
  };

  return (
    <InterpreterContext.Provider
      value={{
        loading: state.loading,
        imgLoading: state.imgLoading,
        error: state.error,
        data: state.interpreters,
        interpreter: state.interpreter,
        total: state.total,
        count: state.count,
        pagination: state.pagination,
        profileImage: state.profileImage,
        profileNotes: state.profileNotes,
        progressValue: state.progressValue,
        fileList: state.fileList,
        isLanguages: state.languages,
        isDialects: state.dialects,
        viewDoc: state.viewDoc,
        docLoad: state.docLoad,
        hours: state.hours,
        ipLanguages: state.ipLanguages,
        docDispatch: dispatch,
        getInterpretersHandler,
        getInterpreterHandler,
        updateInterpreteLanguagesrHandler,
        getProfileHandler,
        getLangugagesHandler,
        getDialectsHandler,
        addInterpreterHandler,
        updateInterpreterHandler,
        uploadProImageHandler,
        updateProfileNotesHandler,
        uploadDocumentHandler,
        // getDocumentHandler,
        getDocument2Handler,
        mailHandler,
        advSearchObject: state.advSearchObj,
        getHoursHandler,
        sendMailToInterpretersHandler,
        getIPLanguageHandler
      }}
    >
      {children}
    </InterpreterContext.Provider>
  );
};
