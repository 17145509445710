import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import StickyNote from '../../assets/images/icons/sticky-note.png';


const useStyles = makeStyles((theme) => ({
    paper: {
        height: '24vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f1f1f1'
    },
    image: {
        width: '10rem'
    }
}));


const NoNotes = () => {
    const classes = useStyles()
    return (
        <div className={classes.paper}>
            <img src={StickyNote} alt="Document Not Available" className={classes.image} />
        </div>
    )
}

export default NoNotes