import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';

import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';

import LanguageMasters from './LanguageMasters';
import DialectMasters from './DialectMasters';
import UserMaster from './UserMaster';
import TermsAndConditionsMaster from './TermsAndConditionsMaster';
import RateStructuresMaster from './RateStructuresMaster';
import ClientMasters from './ClientMasters';

const Index = () => {
  let { url } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute path={`${url}/languages`}>
        <LanguageMasters />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${url}/dialects`}>
        <DialectMasters />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${url}/termsAndConditions`}>
        <TermsAndConditionsMaster />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${url}/users`}>
        <UserMaster />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${url}/rateStructures`}>
        <RateStructuresMaster />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${url}/clients`}>
        <ClientMasters />
      </AuthenticatedRoute>
      {/* <Route path="">
        <LanguageMasters />
      </Route> */}
    </Switch>
  );
};

export default Index;
