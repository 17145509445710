import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: 'calc(80% - 12px)',
    // height: '50%',
  },
  container: {
    height: "calc(72vh - 20px)",
  },
  container2: {
    height: "calc(80vh - 20px)",
  },
  table: {
    minWidth: "100vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  paper: {
    margin: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    // height: 'calc(85% - 1vh)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: "#fff",
  },
}));
