import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Switch, useRouteMatch } from 'react-router-dom';

import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';

import { TimesheetProvider } from '../../../context/states/timeSheetState';
import { IBookings } from '../../../components/pages/ibookings/IBookings';
import { FileProvider } from '../../../context/states/fileState';
import { InvoiceProvider } from '../../../context/states/invoiceState';
import { Invoice } from '../../../components/includes/Invoice/Invoice';

const IBookingTracker = () => {
  let { path } = useRouteMatch();
  const int = localStorage.getItem('intID');

  return (
    <React.Fragment>
      <TimesheetProvider>
        <FileProvider>
          <InvoiceProvider>
            <Grid item xs={12}>
              <Switch>
                <AuthenticatedRoute exact path={path}>
                  <IBookings title="Timesheet Tracker" intID={int} />
                </AuthenticatedRoute>
                <AuthenticatedRoute path={`${path}/invoice/:id/:iType/:clientInvoice`}>
                  <Invoice />
                </AuthenticatedRoute>
              </Switch>
            </Grid>
          </InvoiceProvider>
        </FileProvider>
      </TimesheetProvider>
    </React.Fragment>
  );
};

export default IBookingTracker;
