import * as actionTypes from "../actionTypes";

export default (state, action) => {
	switch (action.type) {
		case actionTypes.PROCESS_START:
			return {
				...state,
				loading: true,
				progressValue: 0,
				viewDoc: "",
				error: null,
				interpreter: {},
				profileImage: "",
				profileNotes: {},
				fileList: [],
				pagination: {},
				imgLoading: false,
				ipLanguages: [],
			};

		case actionTypes.FETCH_RECORDS:
			return {
				...state,
				loading: false,
				error: null,
				interpreters: action.payload.data,
				total: action.payload.total,
				count: action.payload.count,
				pagination: action.payload.pagination,
				// interpreter: {},
				// profileImage: '',
				// profileNotes: {},
			};

		case "ADVANCE_SEARCH":
			console.log("red", action.payload);
			return {
				...state,
				pageLimit: action.payload.pageLimit,
				advSearchQuery: action.payload.advSearchQuery,
				advSearchObj: action.payload.obj,
			};

		case actionTypes.ADD_RECORD:
			return {
				...state,
				interpreters: [action.payload.data, ...state.interpreters],
				total: state.total + 1,
			};

		case actionTypes.FETCH_RECORD:
			console.log("fileList reducer", action.payload);

			return {
				...state,
				loading: false,
				error: null,
				interpreter: action.payload,
				profileNotes: action.payload.profileNotes,
				fileList: action.payload.fileList,
			};

		case actionTypes.UPDATE_RECORD:
			return {
				...state,
				interpreter: action.payload,
				interpreters: state.interpreters.map((interpreter) => {
					return interpreter.id === action.payload._id ? action.payload : interpreter;
				}),
			};

		case actionTypes.FILE_UPLOAD_START:
			return {
				...state,
				imgLoading: true,
				error: null,
				profileImage: "",
			};

		case actionTypes.UPDATE_PROFILE_IMAGE:
			return {
				...state,
				imgLoading: false,
				profile: action.payload,
			};

		case actionTypes.GET_PROFILE_IMAGE:
			return {
				...state,
				imgLoading: false,
				profileImage: action.payload,
			};

		case actionTypes.ADD_PROFILE_NOTES:
			return {
				...state,
				profileNotes: action.payload,
			};

		case actionTypes.UPLOAD_START:
			return {
				...state,
				progressValue: 0,
				error: null,
			};

		case actionTypes.PROGRESS_VALUE:
			return {
				...state,
				progressValue: action.payload,
			};

		case actionTypes.DOCUMENT_UPLOAD:
			return {
				...state,
				fileList: [action.payload, ...state.fileList],
			};

		case actionTypes.GET_ALL_LANGUGAGES:
			return {
				...state,
				languages: action.payload,
			};

		case actionTypes.GET_ALL_DIALECTS:
			return {
				...state,
				dialects: action.payload,
			};

		case "DOC_LOAD":
			return {
				...state,
				docLoad: true,
				viewDoc: "",
			};

		case actionTypes.GET_DOCUMENT_IMAGE:
			return {
				...state,
				docLoad: false,
				viewDoc: action.payload,
			};

		case actionTypes.FETCH_ERRORS:
			return {
				...state,
				loading: false,
				imgLoading: false,
				error: action.payload,
				hours: {},
			};

		case "DOCUMENT_DELETED":
			// console.log(action.payload);
			return {
				...state,
				fileList: [...state.fileList.filter((doc) => doc.fileName !== action.payload)],
			};

		case "INTERPRETER_HOURS":
			return {
				...state,
				hours: action.payload,
			};

		case "INTERPRETER_IPLANGUAGES":
			return { ...state, ipLanguages: action.payload };
		default:
			return state;
	}
};
