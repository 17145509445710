import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .female': {
      textTransform: 'capitalize',
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.7),
    },
    '& .male': {
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.7),
    },
    '& .other': {
      textTransform: 'capitalize',
      color: '#fafafa',
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
    },
  },
}));

const styles = {
  id: {
    width: '10%',
  },
  fullName: {
    width: '25%',
    textTransform: 'capitalize',
  },
  role: {
    width: '10%',
    textTransform: 'capitalize',
  },
  gender: {
    width: '10%',
    textTransform: 'capitalize',
  },
  mobile: {
    width: '15%',
  },
  createdAt: {
    withd: '20%',
  },
  edit: {
    width: '10%',
  },
};

export const UserLists = React.memo((props) => {
  console.log('RENDER LISTS');
  const classes = useStyles();
  const {
    data,
    loading,
    stableSort,
    getComparator,
    order,
    orderBy,
    isSelected,
    handleEditModal,
    //handleClick, isCheckbox
    page,
    rowsPerPage
  } = props;

  let tableLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableLists = (
      <>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <span>No Record</span>
            </TableCell>
          </TableRow>
        ) : (
          stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row?._id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                role="checkbox"
                key={row._id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                className={classes.root}
              >
                {/* <TableCell padding="checkbox">
                  {isCheckbox && (
                    <Checkbox onClick={(event) => handleClick(event, row._id)} checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                  )}
                </TableCell> */}
                <TableCell
                  scope="row"
                  align="left"
                  id={labelId}
                  // style={styles.id}
                  style={{ borderLeft: row.isActive ? '6px solid #81c784' : '6px solid #e33371' }}
                >
                  {index + 1 + (page) * rowsPerPage}
                </TableCell>
                <TableCell style={styles.fullName}>{row.fullName}</TableCell>
                <TableCell style={styles.role}>{row.role}</TableCell>
                <TableCell style={styles.gender}>{row.gender}</TableCell>
                <TableCell style={styles.mobile}>{row.mobile}</TableCell>
                <TableCell style={styles.createdAt}>{moment(row.createdAt).format('DD-MM-yyyy')}</TableCell>
                <TableCell style={styles.edit} className={classes.activeInterpreter}>
                  <Tooltip title={`Edit: ${row.fullName}`}>
                    <IconButton aria-label="edit" size="small" onClick={() => handleEditModal(row._id)}>
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </>
    );
  }
  return <>{tableLists}</>;
});

UserLists.propTypes = {
  data: PropTypes.array.isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  stableSort: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEditModal: PropTypes.func.isRequired,
};
