import React, { useMemo } from "react";
import { TableToolbar } from "../table/TableToolbar";

function MemoizedToolbar({
  selected,
  title,
  isCheckbox,
  enableCheckboxHandler,
  openFormModalHandler,
  count,
  isSearchObj,
  setIsSearchObj,
  clearAdvancedSearchHandler,
  setPage,
  isPage,
}) {
  return useMemo(() => {
    return (
      <TableToolbar
        numSelected={selected.length}
        selectedEmails={selected}
        tableTitle={title}
        isCheckbox={isCheckbox}
        handleRefresh={clearAdvancedSearchHandler}
        handleCheckbox={enableCheckboxHandler}
        handleFormModal={openFormModalHandler}
        listCount={count}
        isSearchObj={isSearchObj}
        setIsSearchObj={setIsSearchObj}
        clearAdvancedSearchHandler={clearAdvancedSearchHandler}
        setPage={setPage}
        isPage={isPage}
      />
    );
  }, [
    selected,
    title,
    isCheckbox,
    enableCheckboxHandler,
    openFormModalHandler,
    count,
    isSearchObj,
    setIsSearchObj,
    clearAdvancedSearchHandler,
    setPage,
    isPage,
  ]);
}

export default MemoizedToolbar;
