export default (state, action) => {
    switch (action.type) {
        case 'PROCESS_START':
            return {
                ...state,
                loading: true,
                jobsOutstanding: [],
                totalOutstanding: 0,
                paidJobs: [],
                totalPaidJobs: 0,
                confirmedJobs: [],
                confirmedTotal: 0,
                clients: [],
                error: null
            }

        case 'FETCH_RECORDS':
            return {
                ...state,
                loading: false,
                jobsOutstanding: action.payload.data,
                totalOutstanding: action.payload.total
            }

        case 'FETCH_PAID_RECORED':
            return {
                ...state,
                loading: false,
                paidJobs: action.payload.data,
                totalPaidJobs: action.payload.total
            }

        case 'FETCH_CONFIRMED_RECORED':
            return {
                ...state,
                loading: false,
                confirmedJobs: action.payload.data,
                confirmedTotal: action.payload.confirmedTotal
            }
        case 'FETCH_CLIENT_RECORD':
            return {
                ...state,
                clients: action.payload.data
            }

        case 'ERROR_CATCH':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}