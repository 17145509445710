import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';
import { FileProvider } from '../../../context/states/fileState';

import Grid from '@material-ui/core/Grid';

import { Interpreters } from '../../../components/pages/interpreters/Interpreters';
import { InterpreterProvider } from '../../../context/states/interpreterState';
import EditInterpreter from '../../../components/pages/interpreters/interpreterForms/EditInterpreter';

const InterpreterTracker = () => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <InterpreterProvider>
        <FileProvider>
          <Grid item xs={12}>
            <Switch>
              <AuthenticatedRoute exact path={path}>
                <Interpreters title="Interpreter Tracker" />
              </AuthenticatedRoute>
              <AuthenticatedRoute path={`${path}/edit/:id`}>
                <EditInterpreter />
              </AuthenticatedRoute>
            </Switch>
          </Grid>
        </FileProvider>
      </InterpreterProvider>
    </React.Fragment>
  );
};

export default InterpreterTracker;
