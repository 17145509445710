import React, { useContext, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BookingContext } from '../../../../../context/states/bookingState';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, AppBar, Toolbar, Typography, IconButton, Chip, Avatar, CircularProgress, Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EditForm } from './EditForm';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(2, 0),
  },
  paper: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },

  appBar: {
    marginBottom: '14px',
  },
  title: {
    // textAlign: 'center',
    flexGrow: 1,
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
  amountInput: {
    width: 120,
  },
  borderLess: {
    border: 'none',
    width: 80,
    textAlign: 'right',
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    fontSize: '13px',
  },
}));

const EditBooking = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const { getBookingHandler, updateBookingHandler, booking, load, error, isDepartments, getDepartmentOptionHandler, getCaseTyoeOptionHandler } = useContext(BookingContext);

  async function fetchData() {
    await getBookingHandler(id);
  }

  useEffect(() => {
    let umounted = true;

    if (umounted) {
      fetchData();
      // getDepartmentOptionHandler()
    }

    return () => umounted = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const goBack = () => {
    // history.goBack();
    history.push({ pathname: '/BookingTracker', state: { page: 'booking' } });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <div className={classes.root} >
          {/* <Container maxWidth="lg"> */}
          <AppBar position="sticky" color="default" style={{ top: '3.6em' }} elevation={2}>
            <Toolbar variant="dense">
              <IconButton aria-label="Bast to list" onClick={goBack} color="inherit" edge="start">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {!load ? (
                  <>
                    {booking.clientName} <Chip avatar={<Avatar>R</Avatar>} label={booking.booking_ref} clickable color="primary" size="small" />
                  </>
                ) : (
                  <small>Loading....</small>
                )}
              </Typography>
              <Typography variant="h6">{booking.businessType}</Typography>&nbsp;|&nbsp;
              <Typography variant="subtitle2">{booking.serviceType}</Typography>
            </Toolbar>
          </AppBar>

          <Card className={classes.root}>
            <CardContent>
              {error ? (
                <Typography variant="h6">{error}</Typography>
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {load ? (
                    <Grid container direction="column" justify="center" alignItems="center">
                      <Grid item xs={12}>
                        <CircularProgress style={{ margin: '12rem' }} />
                      </Grid>
                    </Grid>
                  ) : (
                    <EditForm booking={booking} classes={classes} handleUpdate={updateBookingHandler} handleHistory={history} handleBack={goBack} isDepartments={isDepartments} />
                  )}
                </MuiPickersUtilsProvider>
              )}
            </CardContent>
          </Card>
          {/* </Container> */}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default EditBooking;
