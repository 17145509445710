import * as actionTypes from "../actionTypes";

export default (state, action) => {
	switch (action.type) {
		case actionTypes.PROCESS_START:
			return {
				...state,
				loading: true,
				client: {},
				rateStructures: [],
			};

		case actionTypes.FETCH_RECORDS:
			return {
				...state,
				loading: false,
				clients: action.payload.data,
				count: action.payload.count,
				total: action.payload.total,
			};

		case "SINGLE_FETCH":
			return {
				...state,
				load: true,
			};

		case actionTypes.FETCH_RECORD:
			return {
				...state,
				load: false,
				client: action.payload,
				clientNotes: action.payload.clientNotes,
				fileList: action.payload.fileList,
			};

		case actionTypes.ADD_RECORD:
			return {
				...state,
				clients: [action.payload.data, ...state.clients],
				total: state.total + 1,
			};

		case actionTypes.UPDATE_RECORD:
			return {
				...state,
				clientNotes: [action.payload, ...state.clientNotes],
				client: action.payload,
				clients: state.clients.map((client) => {
					return client._id === action.payload._id ? action.payload : client;
				}),
			};

		case "CLIENT_NOTES":
			return {
				...state,
				clientNotes: action.payload,
			};

		case actionTypes.DOCUMENT_UPLOAD:
			return {
				...state,
				fileList: [action.payload, ...state.fileList],
			};

		case "DOC_LOAD":
			return {
				...state,
				docLoad: true,
				viewDoc: "",
			};

		case actionTypes.GET_DOCUMENT_IMAGE:
			return {
				...state,
				docLoad: false,
				viewDoc: action.payload,
			};

		case actionTypes.FETCH_ERRORS:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case "GET_RATE_STRUCTURES":
			return {
				...state,
				rateStructures: action.payload,
			};

		default:
			return state;
	}
};
