import { makeStyles, lighten } from '@material-ui/core/styles';
import { blueGrey, deepPurple, indigo, lightBlue, cyan, teal, green, grey, red } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '90px',
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& h6': {
            fontSize: 12,
            fontWeight: 400,
            textTransform: 'capitalize',

        },
        '& span': {
            fontSize: 35,
            fontWeight: 100,
            fontFamily: 'Montserrat, sans-serif'
        }
    },
    count: {
        fontWeight: 300,
        fontFamily: 'Montserrat, sans-serif'
    },
    nameDiv: {
        display: 'inline-block',
        width: '125px',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis'
    },
    new: {
        color: blueGrey[500],
        backgroundColor: blueGrey[100],
    },
    booking: {
        color: deepPurple[500],
        backgroundColor: deepPurple[100],
    },
    quotation: {
        color: indigo[500],
        backgroundColor: indigo[100],
    },
    confirmed: {
        color: lightBlue[500],
        backgroundColor: lightBlue[100],
    },
    timesheetSubmitted: {
        color: cyan[500],
        backgroundColor: cyan[100]
    },
    approved: {
        color: teal[500],
        backgroundColor: teal[100]
    },
    paid: {
        color: green[500],
        backgroundColor: green[100]
    },
    quoteSupplied: {
        color: grey[500],
        backgroundColor: grey[100]
    },
    cancel: {
        color: red[500],
        backgroundColor: red[100]
    },
    currentDayBookings: {
        backgroundColor: lighten(theme.palette.success.light, 0.9),
        // height: 'calc(40vh - 1rem)'  

    },
    upcomingBookings: {
        backgroundColor: lighten(theme.palette.info.light, 0.9),
        // height: 'calc(40vh - 1rem)'
    },
}));