import React, { createContext, useReducer } from 'react';
import DashboardReducer from '../reducers/dashboardReducer';
import axios from '../../axios';

import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';

const initialState = {
  loading: false,
  bookingCounts: [],
  todayBookings: [],
  tomorrowBookings: [],
  error: null
};

export const DashboardContext = createContext(initialState);
export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DashboardReducer, initialState);

  const getDashboardsHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Bookings + "/countbookings");
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getTodayBookingsHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Bookings + "/todays");
      dispatch({ type: 'FETCH_TODAY_BOOKINGS', payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getTomorrowBookingsHandler = async (date) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Bookings + `/todays/${date}`);
      dispatch({ type: 'FETCH_TOMORROW_BOOKINGS', payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        loading: state.loading,
        data: state.bookingCounts,
        todayBookings: state.todayBookings,
        tomorrowBookings: state.tomorrowBookings,
        error: state.error,
        getDashboardsHandler,
        getTodayBookingsHandler,
        getTomorrowBookingsHandler
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
