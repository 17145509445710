import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Card, CircularProgress, Grid, Typography, Chip, Avatar, Tabs, Tab, IconButton } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { EditForm } from './EditForm';
import { InterpreterContext } from '../../../../context/states/interpreterState';
import { AuthContext } from '../../../../context/states/authState';

// import LangTest from './interpreterFields/langTest';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  paper: {
    padding: theme.spacing(2, 1.5),
    margin: theme.spacing(1, 0),
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
  tbody: {
    width: '150px',
    color: theme.palette.text.primary,
  },
  urgent:
    theme.palette.type === 'light' || 'dark'
      ? {
        color: theme.palette.success.main,
        backgroundColor: lighten(theme.palette.success.light, 0.7),
        width: 100,
      }
      : {
        color: theme.palette.text.success,
        backgroundColor: lighten(theme.palette.success.light, 0.7),
        width: 100,
      },

  nonUrgent:
    theme.palette.type === 'light' || 'dark'
      ? {
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.light, 0.7),
        width: 100,
      }
      : {
        color: theme.palette.text.info,
        backgroundColor: lighten(theme.palette.info.light, 0.7),
        width: 100,
      },
  amountSuffix: {
    marginTop: '12px',
    marginLeft: '-50px',
  },
}));

const EditInterpreter = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [tabValue, setTabValue] = useState(0);
  const tabChangeHandler = (event, newValue) => setTabValue(newValue);

  const goBack = () => {
    history.push({ pathname: '/interpreterTracker', state: { page: 'interpreter' } });
  };

  const { loading, getInterpreterHandler, interpreter, getProfileHandler, profileImage, getHoursHandler} = useContext(InterpreterContext);
  const { userData } = useContext(AuthContext);
  const { role, user } = userData

  const isAccessTo = role === 'admin' ? true : false;


  async function fetchData() {
    await getInterpreterHandler(id);
    // await getHoursHandler(id)
  }

  useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, [id]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar position="sticky" color="default" style={{ top: '3.6em' }} elevation={2}>
          <Toolbar variant="dense">
            <IconButton aria-label="Bast to list" onClick={goBack} color="inherit" edge="start">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {!loading ? (
                <>
                  {interpreter.fullname} <Chip avatar={<Avatar>ID</Avatar>} label={interpreter.int_id} clickable color="primary" size="small" />
                </>
              ) : (
                <small>Loading....</small>
              )}
            </Typography>
          </Toolbar>
        </AppBar>
        <Card>
          {!loading ? (
            <Tabs value={tabValue} onChange={tabChangeHandler} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
              <Tab label="Personal Details" />
              <Tab label="Languages" />
              <Tab label="Qualifications" />
              <Tab label="Notes" />
              <Tab label="Rates" />
              <Tab label="Payment Details" />
              <Tab label="Hours" />
              <Tab label="Timesheets" />
            </Tabs>
          ) : (
            <small>....</small>
          )}
          {loading ? (
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item xs={12}>
                <CircularProgress style={{ margin: '12rem' }} />
              </Grid>
            </Grid>
          ) : (
            <EditForm
              classes={classes}
              interpreter={interpreter}
              profileImg={profileImage}
              handleGetProfileImage={getProfileHandler}
              handleBack={goBack}
              handleTabValue={tabValue}
              intID={id}
              isAccessTo={isAccessTo}
              username={user}
              role={role}
            />
          )}
        </Card>
      </div>
    </React.Fragment>
  );
};

export default EditInterpreter;
