import React, { useCallback, useState } from 'react'
import moment from 'moment';
import { TableRow, TableCell, Table, TableContainer, TableBody, Typography, CircularProgress, Card, CardContent, Tooltip } from '@material-ui/core';
import { EnhancedTableHead } from './index';

const confirmHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'companyName', numeric: false, disablePadding: false, label: 'Company' },
    { id: 'bookking_ref', numeric: false, disablePadding: false, label: 'Booking Ref' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name / File Ref' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Customer' },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language / Service' },
    { id: 'interpreter', numeric: false, disablePadding: false, label: 'Interpreter' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Attendance' },
    { id: 'invTotal', numeric: false, disablePadding: false, label: 'Invoice (£)' },
    { id: 'outstanding-emp', numeric: false, disablePadding: false, label: 'Outstanding (£)' },
];

const unpaidHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'companyName', numeric: false, disablePadding: false, label: 'Company' },
    { id: 'bookking_ref', numeric: false, disablePadding: false, label: 'Booking Ref' },
    { id: 'invoiceNo', numeric: false, disablePadding: false, label: 'Invoice No / Date' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name / File Ref' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Customer' },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language / Service' },
    { id: 'interpreter', numeric: false, disablePadding: false, label: 'Interpreter' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Attendance' },
    { id: 'invTotal', numeric: false, disablePadding: false, label: 'Invoice (£)' },
    { id: 'balanceAmount', numeric: false, disablePadding: false, label: 'Outstanding (£)' },
    { id: 'ServiceCharge', numeric: false, disablePadding: false, label: 'VAT' },
];

const paidHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'companyName', numeric: false, disablePadding: false, label: 'Company' },
    { id: 'bookking_ref', numeric: false, disablePadding: false, label: 'Booking Ref' },
    { id: 'invoiceNo', numeric: false, disablePadding: false, label: 'Invoice No / Date' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name / File Ref' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Customer' },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language / Service' },
    { id: 'interpreter', numeric: false, disablePadding: false, label: 'Interpreter' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Attendance' },
    { id: 'invTotal', numeric: false, disablePadding: false, label: 'Invoice (£)' },
    { id: 'balanceAmount', numeric: false, disablePadding: false, label: 'Outstanding (£)' },
    { id: 'paiddate', numeric: false, disablePadding: false, label: 'Date paid' },
    { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' },
];

const styles = {
    fontSize: '13px',
    padding: '6px'
}

const styles2 = {
    background: 'gainsboro',
    padding: '0px 12px',
}
const CardItem = ({ title, active, classes, data, isLoading, matches, paid, unpaid, confirm, unpaidTotal, paidTotal, confirmTotal }) => {

    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('company');
    const [dense] = useState(true);

    const handleRequestSort = useCallback((event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [setOrder, setOrderBy, orderBy]);

    const handleSelectAllClick = useCallback((event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }, [setSelected]);

    return (
        <>
            <Card className={active}>
                <CardContent style={{ padding: '8px 6px' }}>
                    <Typography variant="body1" noWrap>
                        {title}
                    </Typography>
                    <TableContainer className={matches ? classes.container2 : classes.container} >
                        <Table stickyHeader aria-label="sticky table" className={classes.table, 'custable '} size={dense ? 'small' : 'medium'}>
                            {confirm && <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={confirmHeadCells}
                            />}
                            {unpaid && <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={unpaidHeadCells}
                            />}
                            {paid && <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={paidHeadCells}
                            />}
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : data.length === 0 ? (
                                    <TableRow hover>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            No record
                                        </TableCell>
                                    </TableRow>
                                ) : (

                                    data.map((row, index) => {
                                        return (
                                            <TableRow hover key={row._id + index}>
                                                <TableCell style={styles} align="left">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell style={styles} component="th" scope="row">
                                                    {row.companyName ? row.companyName : 'Private'}
                                                </TableCell>
                                                <TableCell style={styles} align="left">
                                                    {row.booking_ref}
                                                </TableCell>
                                                {confirm && (
                                                    <TableCell style={styles} align="left">
                                                        {row.status}
                                                    </TableCell>
                                                )}
                                                {!confirm && (
                                                <TableCell style={styles} align="left">
                                                    {/* {row.invoiceNo} <br />*/}
                                                    {row.invoiceNo ? 
                                                        (<>
                                                            <Tooltip title={`Click to view`}>
                                                                <span className={classes.crChip}>
                                                                    <a style={{ textDecoration: 'none' }}href={`${process.env.REACT_APP_OTHER_LINK_ENDPOING}/approve/invoice.html?id=${row._id}&service=${row.serviceType}&view=${process.env.REACT_APP_MODE}&no=${row?.invoiceNo}`} target="_blank">
                                                                        {row?.invoiceNo}
                                                                    </a>
                                                                    <br />
                                                                </span>
                                                            </Tooltip>
                                                            <small>{moment(row.invoiceDate).format('DD/MM/YYYY')}</small>
                                                        </>)
                                                    : <small>{moment(row.invoiceDate).format('DD/MM/YYYY')}</small>}
                                                </TableCell>
                                                )}
                                                <TableCell style={styles} align="left">
                                                    <strong>{row.clientName}</strong> <br />
                                                    <small>{row.fileRef}</small>
                                                </TableCell>
                                                <TableCell style={styles} align="left">{row.name}</TableCell>
                                                <TableCell style={styles} align="left">
                                                    <strong>{row.language}</strong> <br />
                                                    <small>{row.serviceType}</small>
                                                </TableCell>
                                                <TableCell style={styles} align="left">{row.interpreterName}</TableCell>
                                                <TableCell style={styles} align="left">{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell style={styles} align="left">{row?.invTotal?.toFixed(2)}</TableCell>
                                                <TableCell style={styles} align="left">{row?.balanceAmount?.toFixed(2)}</TableCell>
                                                {unpaid && <TableCell style={styles} align="left"> {row?.ServiceCharge} </TableCell>}
                                                {paid && <TableCell style={styles} align="left"> {moment(row?.paiddate).format('DD/MM/YYYY')} </TableCell>}
                                                {paid && <TableCell style={styles} align="left"> {row?.notes} </TableCell>}
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                <Typography variant="subtitle1" style={styles2} noWrap>
                    {unpaid && '£ ' + unpaidTotal?.toFixed(2)}
                    {paid && '£ ' + paidTotal?.toFixed(2)}
                    {confirm && '£ ' + confirmTotal?.toFixed(2)}
                </Typography>
            </Card>
        </>
    )
}

export default React.memo(CardItem)
