import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { Grid, Typography, Paper, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { ClientUploads } from '../../../../../../fileUpload/UploadFiles/clientUploads';
import { FileContext } from '../../../../../../../context/states/fileState';
import { FileLists } from './FileLists';

export const ClientDocuments = (props) => {
  const { classes, data, list, handleOnSubmit } = props;
  const { handleFiles, unsupportedFiles, validFiles, uploadClientDocumentHandler, removeFile, percentage, message } = useContext(FileContext);
  const [open, setOpen] = useState(false);

  const formValue = {
    isRegistered: data?.isRegistered,
    regNotes: data?.regNotes,
  };
  const { register, handleSubmit, errors, formState } = useForm({ mode: 'onChange', defaultValues: formValue });

  const onSubmit = (data) => {
    handleOnSubmit(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Registration Notes "
                    size="small"
                    fullWidth
                    multiline
                    variant="outlined"
                    name="regNotes"
                    inputRef={register({
                      required: true,
                      minLength: { value: 5, message: 'Must have at least 5 letters' },
                      maxLength: { value: 500, message: 'Maximum characters limit is 500' },
                    })}
                    error={!!errors.regNotes}
                    helperText={errors.regNotes && errors.regNotes.message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    inputRef={register}
                    control={<Checkbox name="isRegistered" color="primary" defaultChecked={data !== undefined ? data.isRegistered : false} />}
                    label="Registered"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" justify="flex-end" alignItems="flex-end" spacing={4}>
                <Grid item xs={1}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isDirty}
                    onClick={handleSubmit(onSubmit)}
                  >
                    save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <ClientUploads
                    open={open}
                    classes={classes}
                    id={data._id}
                    handleClose={handleModalClose}
                    handleFiles={handleFiles}
                    validFiles={validFiles}
                    unsupportedFiles={unsupportedFiles}
                    handleOnSubmit={uploadClientDocumentHandler}
                    handleRemove={removeFile}
                    percentage={percentage}
                    message={message}
                  />
                  <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
                    Upload Doc
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                    Client Documents
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <pre>{JSON.stringify(list, null, 2)}</pre> */}
                    <FileLists items={list} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};
