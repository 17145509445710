import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './middlewares/AuthenticatedRoute';
import UnauthenticatedRoute from './middlewares/UnauthenticatedRoute';

import Login from './containers/login/Login';
/* Admins */
import Dashboard from './containers/admin/dashboard/Dashboard';
import BookingTracker from './containers/admin/bookingTracker/BookingTracker';
import InterpreterTracker from './containers/admin/interpreterTracker/InterpreterTracker';
import Index from './containers/admin/masters/Index';
import Payments from './containers/admin/payments/Payments';
import Reports from './containers/admin/reports/index'

/* Interpreters */
import IBookingTracker from './containers/interpreter/IBookingTracker/IBookingTracker';
import Remittance from './containers/interpreter/payments/Remittance';
// import IDashboard from './containers/interpreter/dashboard/IDashboard';

import UserProfile from './containers/userProfile/UserProfile';

import NotFound from './containers/notFound/NotFound';

import { SearchProvider } from './context/states/search/searchState';
import { BookingProvider } from './context/states/bookingState';
import BookingsTracker from './containers/admin/bookingsTracker/BookingsTracker';

import { DashboardProvider  } from './context/states/dashboardState';

export default function Routes() {
  let routes = 'loading....';

  routes = (
    <SearchProvider>
      <Switch>
        <UnauthenticatedRoute exact path="/">
          <Login />
        </UnauthenticatedRoute>

        {/* <AuthenticatedRoute exact path="/booking-tracker" roles={['interpreter', 'superadmin', 'admin']}>
          <BookingsProvider>

            <BookingsTracker />

          </BookingsProvider>
        </AuthenticatedRoute> */}
        <AuthenticatedRoute exact path="/dashboard" roles={['superadmin', 'ip-admin', 'admin']}>
          <DashboardProvider>
            <Dashboard />
          </DashboardProvider>
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/interpreterTracker" roles={['superadmin', 'ip-admin', 'admin']}>
          <InterpreterTracker />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/BookingTracker" roles={['superadmin', 'ip-admin', 'admin']}>
          <BookingProvider>
            <BookingTracker />
          </BookingProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/masters" roles={['superadmin', 'ip-admin', 'admin']}>
          <Index />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/payments" roles={['superadmin', 'ip-admin', 'admin']}>
          <Payments />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/reports" roles={['superadmin', 'ip-admin', 'admin']}>
          <Reports />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/timesheetTracker" roles={['interpreter']}>
          <IBookingTracker />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/remittances" roles={['interpreter']}>
          <Remittance />
        </AuthenticatedRoute>

        <AuthenticatedRoute path="/account/:id" roles={['interpreter', 'superadmin', 'ip-admin', 'admin']}>
          <UserProfile />
        </AuthenticatedRoute>

        {/* Finally, catch all unmatched routes */}
        <Route exact path="/">
          <NotFound />
        </Route>
      </Switch>
    </SearchProvider>
  );
  return <React.Fragment>{routes}</React.Fragment>;
}
