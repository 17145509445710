import React, { useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import clsx from "clsx";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Tooltip,
  Badge,
  Typography,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import EmailIcon from "@material-ui/icons/Email";
import QIcon from "../../../../assets/images/icons/qicon.png";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import RepeatIcon from "@material-ui/icons/Repeat";
import TimerIcon from "@material-ui/icons/Timer";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PinterestIcon from "@material-ui/icons/Pinterest";

import { TableSpanLoader } from "../../../tableSpanLoader/TableSpanLoader";

import { StatusHoverableDiv } from "./statusHoverableDiv";
import { useStyles } from "./style";
import "./BookingLists.css";
import CustomChip from "../../../customChip/customChip";
import poundIcon from "../../../../assets/images/icons/pound.svg";

export const BookingLists = React.memo((props) => {
  // console.log('RENDER LISTS');
  const classes = useStyles();
  const history = useHistory();
  let { url } = useRouteMatch();
  const {
    data,
    loading,
    stableSort,
    getComparator,
    order,
    orderBy,
    isSelected,
    handleClick,
    isCheckbox,
    // handleViewRequest,
    handleNoteRequest,
    handleCancel,
    handleSendQuote,
    handleInvoice,
    handleTimesheet,
    handleAssignTo,
    // handlePayment,
    handleQuoteView,
    proformaInvoice,
  } = props;

  const [isActions, setIsActions] = React.useState(false);

  function editHandler(id) {
    history.push(`${url}/edit/${id}`);
  }

  const viewBookingHandler = useCallback((row) => {
    window.open(
      `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/booking/view.html?id=${row.booking_ref}&token=${row.randToken}&view=${process.env.REACT_APP_MODE}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, []);

  const viewInvoiceHandler = useCallback((row, i) => {
    window.open(
      `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/approve/invoice.html?id=${row._id}&service=${row.serviceType}&view=${process.env.REACT_APP_MODE}&no=${i?.invoiceNo}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, []);

  const viewProformaInvoiceHandler = useCallback((row) => {
    window.open(
      `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/approve/proforma.html?id=${row._id}&service=${row.serviceType}&view=${process.env.REACT_APP_MODE}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, []);

  const assignToIPHandler = useCallback((row) => {
    handleAssignTo({
      postcode: row.serviceType !== "Onsite" ? "EC1V 2NX" : row.postcode,
      language: row.language,
      dialect: row.dialect,
      appointment: row.date,
      bookingRef: row.booking_ref,
      bookingId: row._id,
      serviceType: row.serviceType,
    });
  }, []);

  const viewCertificateHandler = useCallback((row) => {
    window.open(
      `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/certificate/view.html?id=${row.booking_ref}&token=${row.randToken}&view=${process.env.REACT_APP_MODE}&user=true `,
      "_blank",
      "noopener,noreferrer"
    );
  }, []);

  const viewRemittanceHandler = useCallback((row) => {
    console.log("remittance: ", row);
    window.open(
      `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/remittanceslips/view.html?id=${row.remittanceRef}&token=${row.remittanceDetails.randToken}&view=${process.env.REACT_APP_MODE}&user=true`,
      "_blank",
      "noopener,noreferrer"
    );
  }, []);

  const isCertificatesHandler = (row) => {
    let status = false;
    // if (row.isApprove || row?.status === 'Paid' || row.status === 'Confirmed') {
    if (
      row.serviceType === "Transcription" ||
      row.serviceType === "Translation"
    ) {
      return (status = true);
    }
    return (status = false);
    // }
    // return status
  };

  const isConfrmationHandler = (row) => {
    let status = false;
    if (row.isSendQuote) {
      if (row.status === "Booking" || row.status === "Quotation") {
        if (row?.interpreterName !== "") {
          return true;
        }
        return (status = false);
      }
      return (status = false);
    }
    return status;
  };

  const isTimesheetHandler = (row) => {
    let status = false;
    if (row.isTimesheet) {
      status = true;
    }
    return status;
  };

  const isApproveHandler = (row) => {
    let status = false;
    if (row?.isApprove) {
      status = true;
    }
    return status;
  };

  const isRemittanceHandler = (row) => {
    let status = false;
    if (row?.status === "Paid") {
      return (status = true);
    }

    return status;
  };

  const isAssignToHandler = (row) => {
    let status = false;
    if (row?.status === "Booking" || row?.status === "Quotation") {
      return (status = true);
    }
    return status;
  };

  const handleProformaInvoice = (row) => {
    proformaInvoice(row);
  };
  React.useEffect(() => {
    setTimeout(() => {
      setIsActions(true);
    }, 1500);
  }, [loading]);

  let tableLists = <TableSpanLoader col={13} loader={true} />;

  if (!loading)
    tableLists = (
      <>
        {data.length === 0 ? (
          <TableSpanLoader
            col={13}
            loader={false}
            message="Bookings not available!"
          />
        ) : (
          stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row._id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const urgent =
              row.serviceType === "Transcription" ||
              row.serviceType === "Translation"
                ? row?.urgency === "urgent" && (
                    <TimerIcon
                      style={{ fontSize: "2vh" }}
                      title="Transcription/Translation"
                    />
                  )
                : null;
            const attachment =
              row?.fileList?.length > 0 ? (
                <AttachFileIcon
                  style={{ fontSize: "2vh" }}
                  title="File attached"
                />
              ) : null;
            return (
              <TableRow
                role="checkbox"
                key={row._id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                // className={clsx(
                //   `${classes.root}  ${row?.status === 'Quote Supplied' ? 'Quote-Supplied' : row?.status}`
                // )}
                // title={row.serviceType}
              >
                {isCheckbox && (
                  <TableCell className={classes.tableBorder} padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row?._id)}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                )}

                <TableCell
                  className={classes.tableBorder}
                  id={labelId}
                  align="center"
                >
                  <span className={classes.tableCellStyle}>
                    {row.booking_ref}
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span className={classes.tableCellStyle}>
                    <div>{moment(row?.createdAt).format("DD/MM/YYYY")} </div>
                    <small>{moment(row?.createdAt).format("HH:mm")}</small>
                  </span>
                </TableCell>

                <TableCell
                  // className={classes.tableBorder}
                  className={clsx(
                    `${classes.tableBorder} ${classes.root} ${
                      row?.status === "Quote Supplied"
                        ? "Quote-Supplied"
                        : row?.status
                    }`
                  )}
                  align="center"
                >
                  <StatusHoverableDiv
                    classDot={classes.dot}
                    status={row?.status}
                    balanceAmount={row?.balanceAmount}
                    bookingStatus={row?.bookingStatus}
                  />
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span className={classes.tableCellStyle}>
                    &nbsp;
                    <div>{moment(row?.date).format("DD/MM/YYYY")}</div>
                    <small title="Start Time">
                      {moment(row?.startTime).format("HH:mm")}
                    </small>
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span
                    className={classes.tableCellStyle}
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    &nbsp;{row?.language}
                  </span>
                </TableCell>

                <TableCell
                  // className={classes.tableBorder}
                  className={clsx(
                    `${classes.tableBorder} ${classes.serviceRoot} ${
                      row?.serviceType === "Transcription"
                        ? row?.serviceType + "_" + row?.transcriptionType
                        : row?.serviceType
                    } `
                  )}
                  align="center"
                >
                  <span
                    className={classes.tableCellServiceStyle}
                    style={{ fontWeight: 600 }}
                    // className={clsx(
                    //   `${classes.tableCellServiceStyle} ${classes.serviceRoot} ${row?.serviceType === 'Transcription' ? row?.serviceType + '_' + row?.transcriptionType : row?.serviceType}-span `
                    // )}
                  >
                    &nbsp;{row?.serviceType}{" "}
                    <div>
                      <span title="Urgent">{urgent}</span>{" "}
                      <span title="Attachment">{attachment}</span>
                    </div>
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <div className={classes.advancePayment}>
                    {row?.advancePayment !== 0 ? (
                      <Tooltip
                        title={`Advance Payment: ${row?.advancePayment}`}
                      >
                        <img
                          style={{ width: "22px", height: "22px" }}
                          src={poundIcon}
                        />
                      </Tooltip>
                    ) : null}
                    <span className={classes.tableCellStyle}>
                      &nbsp;
                      {row.companyName !== undefined ? (
                        row?.companyName
                      ) : (
                        <CustomChip title="Private" />
                      )}
                    </span>
                  </div>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span className={classes.tableCellStyle}>
                    &nbsp;
                    <Tooltip title={row?.name}>
                      <span className={classes.customerName}>{row?.name}</span>
                    </Tooltip>
                    <small>
                      {row?.serviceType === "Onsite"
                        ? `Post: ${row?.postcode}`
                        : ""}
                    </small>
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span className={classes.tableCellStyle}>
                    &nbsp;{row?.phone}
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span className={classes.tableCellStyle}>
                    &nbsp;
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>{row?.clientName}</b>
                      <br />
                      <span className={classes.fileRef}>
                        File Ref:{row?.fileRef}
                      </span>
                    </Typography>
                  </span>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    {row?.proformaNo && (
                      <span
                        className={classes.piChip}
                        onClick={() => viewProformaInvoiceHandler(row)}
                      >
                        {" "}
                        {row?.proformaNo}{" "}
                      </span>
                    )}
                    {isActions ? (
                      row?.invoice?.cInvoice?.map((b, i) => (
                        <div
                          className={classes.tableCellStyle}
                          key={i}
                          onClick={() => viewInvoiceHandler(row, b)}
                        >
                          <Tooltip
                            title={`Date: ${moment(b?.invoiceDate).format(
                              "DD/MM/Y"
                            )} (Click to view)`}
                          >
                            <span className={classes.crChip}>
                              {b?.invoiceNo}
                            </span>
                          </Tooltip>
                        </div>
                      ))
                    ) : (
                      <img
                        src="assets/icons/menu.gif"
                        alt="loader"
                        width="40px"
                      />
                    )}

                    {row?.isSplitInvoice && (
                      <span
                        className={classes.isSplitChip}
                        onClick={() => viewProformaInvoiceHandler(row)}
                      >
                        Split Invoice
                      </span>
                    )}
                  </div>
                </TableCell>

                <TableCell className={classes.tableBorder} align="center">
                  <span
                    className={classes.tableCellStyle}
                    style={{ marginTop: "4px" }}
                  >
                    {isActions ? (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {row?.interpreterName?.length !== 0 &&
                          row.interpreterName && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 600,
                                marginLeft: "3px",
                              }}
                            >
                              {row?.interpreterName}
                            </div>
                          )}
                        {row?.interpreterPostcode?.length !== 0 &&
                          row.interpreterPostcode && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <PhoneAndroidIcon style={{ fontSize: "2vh" }} />{" "}
                              {row?.interpreterMobile}
                            </div>
                          )}
                        {row?.interpreterPostcode?.length !== 0 &&
                          row?.interpreterPostcode && (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <PersonPinCircleIcon
                                style={{ fontSize: "2vh" }}
                              />{" "}
                              {row?.interpreterPostcode}
                            </div>
                          )}
                      </Typography>
                    ) : (
                      <img
                        src="assets/icons/menu.gif"
                        alt="loader"
                        width="40px"
                      />
                    )}
                  </span>
                </TableCell>

                {isActions ? (
                  <TableCell className={classes.tableBorder} align="left">
                    <div className={classes.tableCellActionStyle}>
                      <Tooltip title={`View Request: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => viewBookingHandler(row)}
                        >
                          <FindInPageIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={`Update: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => editHandler(row?._id)}
                        >
                          <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={`Notes: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleNoteRequest(row?._id)}
                        >
                          {row?.bookingNotes?.length === 0 ? (
                            <PostAddIcon fontSize="small" color="primary" />
                          ) : (
                            <PlaylistAddCheckIcon
                              fontSize="small"
                              color="primary"
                            />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={`Cancel Request: ${row?.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleCancel(row?._id)}
                        >
                          <Badge
                            color="secondary"
                            variant="dot"
                            invisible={row.status !== "Cancel"}
                          >
                            <CancelIcon fontSize="small" color="primary" />
                          </Badge>
                        </IconButton>
                      </Tooltip>

                      {/* if quotation */}
                      {row?.isQuoteAccepted && (
                        <Tooltip title={`Quotation: ${row?.booking_ref}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleQuoteView(row)}
                          >
                            <img src={QIcon} alt="Is Quotation" width="17" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {isAssignToHandler(row) && (
                        <Tooltip title={`Assign to:  ${row.booking_ref}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => assignToIPHandler(row)}
                          >
                            <Badge
                              color="secondary"
                              variant="dot"
                              invisible={row?.status !== "Cancel"}
                            >
                              <PersonAddIcon fontSize="small" color="primary" />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      )}

                      {isConfrmationHandler(row) ? (
                        <Tooltip
                          title={`${
                            row?.status !== "Quotation"
                              ? "Send Booking Confirmation"
                              : "Send Quotation"
                          }: ${row?.booking_ref}`}
                        >
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleSendQuote(row?._id)}
                          >
                            <SendIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                      ) : null}

                      {isTimesheetHandler(row) && (
                        <Tooltip title={`Timesheet: ${row.booking_ref}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleTimesheet(row)}
                          >
                            <AccessTimeIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {isApproveHandler(row) && (
                        <>
                          <Tooltip title={`Send Invoice: ${row?.booking_ref}`}>
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => handleInvoice(row)}
                            >
                              <EmailIcon fontSize="small" color="primary" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={`Credit Note: ${row?.booking_ref}`}>
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() =>
                                history.push({
                                  pathname: `${url}/invoice/${row?.id}/client/yes`,
                                  search: `?crNote=${true}`,
                                })
                              }
                            >
                              <LibraryBooksIcon
                                fontSize="small"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}

                      {isCertificatesHandler(row) ? (
                        <Tooltip title={`Certificates: ${row?.booking_ref}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => viewCertificateHandler(row)}
                          >
                            <VerifiedUserIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : null}

                      {isRemittanceHandler(row) && (
                        <Tooltip
                          title={`Remittance Slip: ${row?.remittanceRef}`}
                        >
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => viewRemittanceHandler(row)}
                          >
                            <RepeatIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* {row.isBooked && (
                      <Tooltip title={`Booked: ${row.booking_ref}`}>
                        <IconButton aria-label="edit" size="small" onClick={() => {}}>
                          <AssignmentTurnedInIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                    )} */}

                      {/* <Tooltip title={`Payment: ${row.booking_ref}`}>
                      <IconButton aria-label="edit" size="small" onClick={() => handlePayment(row)}>
                        <PaymentIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip> */}
                      {(row.status === "Confirmed" ||
                        row.status === "Quote Supplied") && (
                        <Tooltip title={`Proforma: ${row.booking_ref}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleProformaInvoice(row)}
                          >
                            <PinterestIcon fontSize="small" color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                ) : (
                  <TableCell>
                    <img
                      src="assets/icons/menu.gif"
                      alt="loader"
                      width="40px"
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })
        )}
      </>
    );

  return <>{tableLists}</>;
});

BookingLists.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  stableSort: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleTimesheet: PropTypes.func.isRequired,
  handleAssignTo: PropTypes.func.isRequired,
  handlePayment: PropTypes.func.isRequired,
  // dense: PropTypes.bool.isRequired,
  // handleEditModal: PropTypes.func.isRequired,
};
