import React, { useState, useEffect, useContext } from 'react';

import { Paper, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './style';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { EnhancedTableToolbar, CardItem } from '../../../components/pages/reports/accountStatement/index';
import { AccountStatementContext } from '../../../context/states/reports/accountStatementState';

const initialState = {
    isSearch: false,
    companyId: '',
    account: true,
    refund: true,
    company: '',
    fromDate: null,
    toDate: null
};
const AccountStatement = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:1920px)');

    const {
        reports,
        getReportsHandler,
        paidJobs, 
        refunds,
        isloading,
        getClientsHandler,
        clients,
        clearStateHandler,
        getPaidJobsHandler,
        totalAcAmount,
        totalIpAmount,
        totalPaidJobs,
        totalvat, totalService, lilAmount,
        getRefundHandler
    } = useContext(AccountStatementContext);

    const [values, setValues] = useState({ ...initialState });
    const [dense] = useState(true);

    // useEffect(() => {
    //     if (values.account) {
    //         console.log('Account');
    //         getReportsHandler(values.companyId);
    //     }
    // }, [values.account]);

    // useEffect(() => {
    //     if (values.refund) {
    //         console.log('Refund');
    //         getPaidJobsHandler(values.companyId);
    //     }
    // }, [values.refund]);

    useEffect(() => {
        getReportsHandler('');
        getPaidJobsHandler('')
        getRefundHandler('')
    }, [])

    // console.log(!isloading && reports, paidJobs, clients)

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
    };

    const handleChangeAutoComplete = (name) => (event, value) => {
        console.log('event', name, value);
        if (value === null) {
            setValues({ ...initialState });
            clearStateHandler();
            return;
        }
        if (name === 'company') {
            setValues({ ...values, [name]: value, companyId: value._id });
        }
    };

    const handleInputChange = (event) => {
        if (event.target.value === '') return;
        getClientsHandler(event.target.value);
    };

    const handleFromDateChange = event => {
        const date = new Date(event).toISOString()
        console.log('date', date)
        setValues({ ...values, fromDate: date });
    }

    const handleToDateChange = event => {
        const date = new Date(event).toISOString()
        console.log('date', date)
        setValues({ ...values, toDate: date });
    }

    const searchHandler = () => {
        console.log('submit')
        setValues({ ...values, isSearch: true })
        console.log('com', values.companyId)
        let query = '?'
        if (values.companyId !== '') {
            query += `companyId=${values.companyId}`
        }
        let filteredDate = '';

        if (values.fromDate === null) {
            filteredDate = ''
        } else if (values.fromDate !== null) {
            filteredDate = `&fromDate=${values.fromDate}`
            if (values.toDate !== null) {
                console.log('to date', values.toDate)
                filteredDate += `&toDate=${values.toDate}`
            }
        }

        if (values.account) {
            console.log('account')
            getReportsHandler(`${query}${filteredDate}`);
        } else {
            getReportsHandler('')
        }

        if (values.refund) {
            console.log('refund')
            getRefundHandler(`${query}${filteredDate}`)
        } else {
            getRefundHandler('')
        }
    }

    const clearSearchHandler = () => {
        setValues({ ...initialState })
        getReportsHandler('')
        getRefundHandler(``)
    }

    return (
        <React.Fragment>
            <div classes={classes.rootM} style={{ width: '100%' }}>
                <Paper className={classes.paper}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <EnhancedTableToolbar
                            handleChange={handleChange}
                            values={values}
                            isClients={clients}
                            handleInputChange={handleInputChange}
                            handleChangeAutoComplete={handleChangeAutoComplete}
                            totalPaidAmount={totalPaidJobs}
                            totalAcAmount={totalAcAmount}
                            totalIpAmount={totalIpAmount}
                            totalService={totalService}
                            totalvat={totalvat}
                            lilAmount={lilAmount}
                            title={'LIL Statement'}
                            handleFromDateChange={handleFromDateChange}
                            handleToDateChange={handleToDateChange}
                            searchHandler={searchHandler}
                            clearSearchHandler={clearSearchHandler}
                        />
                    </MuiPickersUtilsProvider>
                    {values.account && (
                        <CardItem
                            title="LIL Account Statement"
                            active={classes.paidStage}
                            classes={classes}
                            isLoading={isloading}
                            data={reports}
                            dense={dense}
                            matches={matches}
                            account={true}
                            refund={false}
                        />
                    )}
                    {values.refund && (
                        <CardItem
                            title="Refund Statement"
                            active={classes.pendingStage}
                            classes={classes}
                            isLoading={isloading}
                            data={refunds}
                            dense={dense}
                            matches={matches}
                            account={false}
                            refund={true}
                        />
                        // <h2 style={{ margin: '1em' }}>Refund</h2>
                    )}
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default AccountStatement;
