import React from 'react';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Toolbar, Typography, TextField, AppBar, Tooltip, Button, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import PropTypes from 'prop-types';

const useToolbarStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbarMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  secondSession: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '80%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, handleChangeAutoComplete, values, handleInputChange, interpreters, handleFromDateChange, handleToDateChange, searchHandler, clearSearchHandler } = props;

  return (
    <div className={classes.grow}>
      <AppBar position="sticky" color="default" elevation={1}>
        <Toolbar className={classes.toolbarMobile}>
          <Tooltip title={title}>
            <Typography className={classes.title} variant="h6" noWrap>
              {title}
            </Typography>
          </Tooltip>
          <div className={classes.search}>
            <Autocomplete
              id="interpreter"
              name="interpreter"
              options={interpreters}
              getOptionLabel={(option) => option?.fullname || ""}
              onChange={handleChangeAutoComplete('interprter')}
              value={values.interprter ? values.interprter : ''}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} fullWidth label="Interpreter Name" size="small" variant="outlined"
                onChange={handleInputChange} value={values.interprter ? values.interprter : ''}
              />}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}

              variant="inline"
              inputVariant="outlined"
              label="From"
              onChange={handleFromDateChange}
              value={values.fromDate}
            />
            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"

              inputVariant="outlined"
              label="To"
              onChange={handleToDateChange}
              value={values.toDate}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;
              <Button size="small" endIcon={<SearchIcon />} onClick={searchHandler}>
                Search
              </Button>
              {!values.isSearch ?
                null
                :
                <Tooltip title={`Clear search`}>
                  <IconButton color="primary" aria-label="clear search inputs" onClick={clearSearchHandler}>
                    <ClearAllIcon />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div >
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
