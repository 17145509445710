import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        fontSize: 12
    },
    container: {
        // maxHeight: 240,
        maxHeight: 'calc(32vh - 1rem)'
    },
    tableCellHeader: {
        fontSize: 10,
        fontWeight: 600
    },
    tableCell: {
        fontSize: 12
    },
    textTruncation: {
        display: 'inline-block',
        width: 115,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 12

    }
});

export const BookingList = ({ data, loading, active, title }) => {
    const classes = useStyles();

    let bookings;
    if (loading) {
        bookings = 'loading'
    } else {
        if (data.length !== 0) bookings = data?.map(booking => <BookingList booking={booking} key={booking?._id} />)
        else bookings = <NoBookings />
    }

    return (
        <Card className={active}>
            <CardContent style={{ padding: '8px 6px' }}>
                <Typography variant="body1" style={{ marginBottom: '6px', fontWeight: 700 }} noWrap>
                    {title} {data.length !== 0 ? '(' + data.length + ')' : null }
                </Typography>
                <TableContainer className={classes.container} component={Paper}>
                    <Table className={classes.table} stickyHeader size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow >
                                <TableCell className={classes.tableCellHeader} align="left">Booking Ref</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Status</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Date</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Language</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Service</TableCell>
                                <TableCell className={classes.tableCellHeader}>Company Name</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Client</TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">Interpreter Name</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );

    function BookingList({ booking }) {
        return (
            <>
                <TableRow>
                    <TableCell className={classes.tableCell}  align="left">{booking?.booking_ref}</TableCell>
                    <TableCell className={classes.tableCell}  align="left">{booking?.status}</TableCell>
                    <TableCell className={classes.tableCell}  align="left">
                        {moment(booking?.date).format('DD/MM/YYYY')}&nbsp;
                        {moment(booking?.startTime).format('HH:mm')}
                    </TableCell>
                    <TableCell className={classes.tableCell}  align="left">{booking?.language}</TableCell>
                    <TableCell className={classes.tableCell}  align="left">{booking?.serviceType}</TableCell>
                    <TableCell   component="th" scope="row">
                        <span className={classes.textTruncation} title={booking?.companyName}>{booking?.companyName}</span>
                    </TableCell>
                    <TableCell align="left">
                    <span className={classes.textTruncation} title={booking?.clientName}>{booking?.clientName}</span>
                    </TableCell>
                    <TableCell align="left">
                    <span className={classes.textTruncation} title={booking?.interpreterName}>{booking?.interpreterName || 'Not assigned'}</span></TableCell>

                </TableRow>
            </>
        )
    }

    function NoBookings() {
        return (
            <TableRow>
                <TableCell colSpan="8" align="center">No Bookings</TableCell>
            </TableRow>
        )
    }
}
