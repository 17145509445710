import { lighten, makeStyles } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    paperRoot: {
        width: 450,
        maxWidth: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(4),
    },
    minPaperRoot: {
        width: 400,
        maxWidth: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    advanceCard: {
        // margin: theme.spacing(0.4, 0),
        marginLeft: theme.spacing(4),
        // padding: theme.spacing(1),
        position: 'absolute',
        width: 450,
        maxWidth: '100%',
        overflow: 'auto',
        zIndex: 3,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '50%',
        },
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    formControl: {
        minWidth: 190,
    },
    pincodeControl: {
        minWidth: 140,
    },
    listCount: {
        fontSize: '12px',
        fontWeight: 600,
        maxidth: '100%',
        padding: '4px 8px',
        borderRadius: '4px',
        color: theme.palette.text.disabled,
        backgroundColor: lighten(theme.palette.action.disabled, 0.9),
    },
}));