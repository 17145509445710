// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

// curd
export const PROCESS_START = 'PROCESS_START';
export const ADD_RECORD = 'ADD_RECORD';
export const FETCH_RECORDS = 'FETCH_RECORDS';
export const FETCH_RECORD = 'FETCH_RECORD';
export const REMOVE_RECORD = 'REMOVE_RECORD';
export const FETCH_ERRORS = 'FETCH_ERRORS';
export const UPDATE_RECORD = 'UPDATE_RECORD';

export const LOADED = 'LOADED';
export const REMOVE = 'REMOVE';
export const INIT = 'INIT';
export const PENDING = 'PENDING';
export const FILES_UPLOADED = 'FILES_UPLOADED';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';

export const FILE_UPLOAD_START = 'FILE_UPLOAD_START';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const GET_PROFILE_IMAGE = 'GET_PROFILE_IMAGE';
export const ADD_PROFILE_NOTES = 'ADD_PROFILE_NOTES';

export const DOC_LOAD = 'DOC_LOAD';
export const GET_DOCUMENT_IMAGE = 'GET_DOCUMENT_IMAGE';

export const UPLOAD_START = 'UPLOAD_START';
export const PROGRESS_VALUE = 'PROGRESS_VALUE';
export const DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD';

export const GET_ALL_LANGUGAGES = 'GET_ALL_LANGUGAGES';
export const GET_ALL_DIALECTS = 'GET_ALL_DIALECTS';
