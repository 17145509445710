import React from 'react';
import Grid from '@material-ui/core/Grid';
import { LanguageProvider } from '../../../context/states/languageState';
import { Dialects } from '../../../components/pages/masters/Dialects/Dialects';

const DialectMasters = () => {
  return (
    <React.Fragment>
      <LanguageProvider>
        <Grid item xs>
          <Dialects title="Dialects" />
        </Grid>
      </LanguageProvider>
    </React.Fragment>
  );
};

export default DialectMasters;
