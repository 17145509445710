import React from 'react';

import { Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Onsite from './template/Onsite';
import Telephone from './template/Telephone';
import Video from './template/VideoCall';
import Translation from './template/Translation';
import Transcription from './template/Transcription';

const useStyles = makeStyles((theme) => ({
  borderLess: {
    border: 'none',
    width: 80,
    textAlign: 'right',
  },
}));

const InvoiceTable = React.memo((props) => {
  const { data, classes, invoiceType, updateInvoice } = props;
  const classes2 = useStyles();

  return (
    <>
      <Grid container>
        <Card className={classes.root} variant="outlined">
          {data?.service === 'Onsite' && (
            <Onsite header={classes.head} data={data} customerInvoice={invoiceType === 'yes'} updateInvoice={updateInvoice} classes={classes2} />
          )}
          {data?.service === 'Telephone' && (
            <Telephone header={classes.head} data={data} customerInvoice={invoiceType === 'yes'} updateInvoice={updateInvoice} classes={classes2} />
          )}
          {data?.service === 'Video Call' && (
            <Video header={classes.head} data={data} customerInvoice={invoiceType === 'yes'} updateInvoice={updateInvoice} classes={classes2} />
          )}
          {data?.service === 'Translation' && (
            <Translation header={classes.head} data={data} customerInvoice={invoiceType === 'yes'} updateInvoice={updateInvoice} classes={classes2} />
          )}
          {data?.service === 'Transcription' && (
            <Transcription
              header={classes.head}
              data={data}
              customerInvoice={invoiceType === 'yes'}
              updateInvoice={updateInvoice}
              classes={classes2}
            />
          )}
        </Card>
      </Grid>
    </>
  );
});

export default InvoiceTable;
