import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from "react-router-dom";
import { Table, TableBody, TableContainer, Paper, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { BookingContext } from '../../../context/states/bookingState';
import { SendQuoteContext } from '../../../context/states/sendQuoteState';
import { AuthContext } from '../../../context/states/authState';
import { SearchContext } from '../../../context/states/search/searchState'

import Loader from '../../UI/Loader';
// importing components
import Pagination from '../../Pagination/Pagination';
import { ColorPatter } from './colorPatter';
import { useStyles } from './style'
import { headCells } from './HeadCells';

import MemoizedAddBooking from './memos/MemoizedAddBooking';
import MemoizedToolbar from './memos/MemoizedToolbar';
import MemoizedHeader from './memos/MemoizedHeader';
import MemoizedList from './memos/MemoizedList'
import MemoizedCancelBooking from './memos/MemoizedCancelBooking';
import MemoizedInvoiceModal from './memos/MemoizedInvoiceModal';
import MemoizedAddNotes from './memos/MemoizedAddNotes';
import MemoizedSendQuote from './memos/MemoizedSendQuote';
import MemoizedTimesheet from './memos/MemoizedTimesheet';
import MemorizedAssignTo from './memos/MemorizedAssignTo';
import MemoizedQuotationPreview from './memos/MemoizedQuotationPreview'

export const Bookings = (props) => {
  const { title } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1920px)');
  let location = useLocation()

  const {
    newBookingFormReset,
    getBookingsHandler,
    data,
    loading,
    total,
    count,
    getBookingHandler,
    booking,
    load,
    updateBookingHandler,
    updateBookingNotesHandler,
    timesheetApprovedHandler,
    timesheetupdatedHandler,
    mailHandler,
    isInterpreters,
    getInterpretersHandler,
    fileList,
    sendProformaInvoiceHandler
  } = useContext(BookingContext);
  const { getSendQuoteHandler, qLoad } = useContext(SendQuoteContext);
  const { userData } = useContext(AuthContext);
  const { bookingSearchHandler, bookingSearch } = useContext(SearchContext);

  const [isSearchObj, setIsSearchObj] = useState({
    sn: bookingSearch.sn,
    name: bookingSearch.name,
    fileRef: bookingSearch.fileRef,
    interpreterName: bookingSearch.interpreterName,
    serviceType: bookingSearch.serviceType,
    language: bookingSearch.language,
    dialect: bookingSearch.dialect,
    companyName: bookingSearch.companyName,
    clientName: bookingSearch.clientName,
    status: bookingSearch.status,
    page: bookingSearch.page,
    limit: bookingSearch.limit,
    private: bookingSearch.businessType
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isBooking, setIsBooking] = useState(false)
  const [isCancelBooking, setIsCancelBooking] = useState(false)
  const [isInvoiceObj, setIsInvoiceObj] = useState({})
  const [isSendInvoice, setIsSendInvoice] = useState(false)
  const [isBookingNotes, setIsBookingNotes] = useState(false)
  const [isSendQuote, setIsSendQuote] = useState(false)
  const [isTimesheet, setIsTimesheet] = useState(false)
  const [isAssign, setIsAssign] = useState(false)
  const [isAssignObj, setIsAssignObj] = useState({})
  const [isQPreview, setIsQPreview] = useState(false)
  const [isQuotationObj, setIsQuotationObj] = useState({})

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('int_id');
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [isPage, setPage] = useState(isSearchObj.page);
  const [rowsPerPage, setRowsPerPage] = useState(isSearchObj.limit);

  useEffect(() => {
    if (location.state?.page !== 'booking') {
      clearAdvancedSearchHandler()
    }
  }, [location])

  useEffect(() => {
    console.log('re-render')
    let unmounted = true;
    var queryParams = bookingSearchHandler(isSearchObj)
    if (unmounted) {
      getBookingsHandler(queryParams)
    }

    return () => {
      unmounted = false;
    };

  }, [isSearchObj])

  const clearAdvancedSearchHandler = useCallback(() => {
    setIsSearchObj({
      sn: '',
      name: '',
      fileRef: '',
      interpreterName: '',
      serviceType: '',
      language: '',
      dialect: '',
      companyName: '',
      clientName: '',
      status: '',
      page: 1,
      limit: 25,
    })
  }, [])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    },
    [setSelected, selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage + 1);
    setIsSearchObj({ ...isSearchObj, page: newPage + 1, limit: rowsPerPage })
  }, [rowsPerPage]);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setIsSearchObj({ ...isSearchObj, limit: parseInt(event.target.value, 10) })
    setPage(1);

  }, []);

  const handleChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, [setDense]);

  const isSelected = useCallback((name) => selected.indexOf(name) !== -1, [selected]);

  /****
   * Add Booking Modal
   * Booking Handlers
   * Update Handler for Booking
   * Update Handler for Booking Notes
   * Cancel Booking Vidw Modal
   * Send Invoice handler
   * Get Booking Notes
   * Get and Send Quotation
   * Timesheet handler
   * Timesheet submit handler
   * Assigning to interpreter handler
   * Preview Quotation Handler
   * ***/

  const onSubmitHandler = useCallback((booking) => {
    updateBookingHandler(booking)
  }, [updateBookingHandler])

  const bookingOpenModal = useCallback(() => {
    newBookingFormReset()
    setIsBooking(true)
  }, [])

  const closeBookingModal = useCallback(() => {
    setIsBooking(false)
    setIsLoading(false)
  }, [])

  const bookingNoteHandler = useCallback((note) => {
    updateBookingNotesHandler(note)
  }, [updateBookingNotesHandler])

  const cancelBooingRequestHandler = useCallback((id) => {
    getBookingHandler(id).then(e => setIsCancelBooking(true))
  }, [])

  const cancelBookingModalClose = () => {
    setIsCancelBooking(false)
  }

  const sendInvoiceHandler = useCallback((data) => {
    const { _id, serviceType, booking_ref, fileRef, language, name, invoiceTo, email } = data;
    setIsInvoiceObj({ _id, serviceType, booking_ref, fileRef, language, name, invoiceTo, email })
    setIsSendInvoice(true)
  }, [setIsInvoiceObj])

  const sendProformaInvoice = useCallback((data) => {
    console.log('proforma invoice: ', data)
    sendProformaInvoiceHandler(data._id)
  }, [])

  const invoiceMailHandler = useCallback((data) => {
    try {
      mailHandler(data);
    } catch (error) {
      console.error(error)
    }
  }, [])

  const sendInvoiceCloseHandler = useCallback(() => {
    setIsSendInvoice(false)
  }, [])

  const getBookingNotesHandler = useCallback((id) => {
    getBookingHandler(id).then(e => setIsBookingNotes(true))
  }, []);

  const closeBookingNoteModal = useCallback(() => {
    setIsBookingNotes(false)
  }, [])

  const sendQuotationHandler = useCallback((id) => {
    getSendQuoteHandler(id)
    setIsSendQuote(true)
  }, [])

  const closeQuotationModal = useCallback(() => {
    setIsSendQuote(false)
  }, [])

  const timesheethandler = useCallback((data) => {
    getBookingHandler(data.id).then(d => setIsTimesheet(true))
  }, [])

  const closeTimesheetModal = useCallback(() => {
    setIsTimesheet(false)
  }, [])

  const timesheetSubmitHandler = useCallback((timesheet) => {
    timesheet.status = 'Approved';
    timesheet.approvedBy = userData.user;
    timesheetApprovedHandler(timesheet)
  }, [timesheetApprovedHandler])


  const updateTimesheetHandler = useCallback((timesheet) => {
    timesheet.approvedBy = userData.user;
    console.log('update timesheet', timesheet)
    timesheetupdatedHandler(timesheet)
  }, [timesheetupdatedHandler])

  const assignToHandler = useCallback((fData) => {
    const { postcode, language, dialect, appointment, serviceType } = fData;
    const query = `dist?postcode=${postcode}&language=${language}&date=${appointment}&service=${serviceType}`;
    try {
      setIsAssignObj(fData)
      getInterpretersHandler(query);
      setIsAssign(true)
    } catch (error) {
      console.error(error)
    }
  }, [getInterpretersHandler])

  const closeAssignModal = useCallback(() => {
    setIsAssignObj({})
    setIsAssign(false)
  }, [])

  const quotationPreviewHandler = useCallback((quote) => {
    setIsQPreview(true)
    setIsQuotationObj(quote)
  }, [])

  const closeQPreviewModal = useCallback(() => {
    setIsQPreview(false)
  }, [])

  return (
    <div className={classes.root}>
      <Loader open={load || qLoad} handleClose={() => setIsLoading(false)} />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <MemoizedToolbar
            selected={selected}
            title={title}
            isCheckbox={false}
            isEnable={false}
            openFormModalHandler={bookingOpenModal}
            enableCheckboxHandler={() => { }}
            refreshDataHandler={clearAdvancedSearchHandler}
            fetchAllDataHandler={() => { }}
            isSearchObj={isSearchObj}
            setIsSearchObj={setIsSearchObj}
            clearAdvancedSearchHandler={clearAdvancedSearchHandler}
            count={count}
          />
          <TableContainer className={matches ? classes.container2 : classes.container}>
            <Table className={classes.table} aria-labelledby={title} size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
              <MemoizedHeader headCells={headCells} selected={selected} order={order} orderBy={orderBy} handleSelectAllClick={handleSelectAllClick} handleRequestSort={handleRequestSort} data={data} />
              <TableBody>
                <MemoizedList data={data}
                  loading={loading}
                  order={order}
                  orderBy={orderBy}
                  isPage={isPage}
                  rowsPerPage={rowsPerPage}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  cancelBookingHandler={cancelBooingRequestHandler}
                  sendInvoiceHandler={sendInvoiceHandler}
                  notesBookingHandler={getBookingNotesHandler}
                  sendQuoteHandler={sendQuotationHandler}
                  TimesheetHandler={timesheethandler}
                  AssignToHandler={assignToHandler}
                  viewQuoteHandler={quotationPreviewHandler}
                  PaymentHandler={() => { }}
                  sendProformaInvoice={sendProformaInvoice}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            isTotal={total}
            isPage={isPage - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
     {/* <ColorPatter classes={classes}/> */}
      {isBooking && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MemoizedAddBooking isOpen={isBooking} handleClose={closeBookingModal} />
        </MuiPickersUtilsProvider>
      )}
      {isCancelBooking && <MemoizedCancelBooking isOpen={isCancelBooking} closeHandler={cancelBookingModalClose} data={booking} handleOnSubmit={onSubmitHandler} handleBookingNote={bookingNoteHandler} user={userData} />}
      {isSendInvoice && <MemoizedInvoiceModal isOpen={isSendInvoice} load={load} isInvoice={isInvoiceObj} closeHandler={sendInvoiceCloseHandler} handleOnSubmit={invoiceMailHandler} />}
      {isBookingNotes && <MemoizedAddNotes isNotes={isBookingNotes} booking={booking} notesCloseHandler={closeBookingNoteModal} onNoteSubmitHandler={bookingNoteHandler} />}
      {isSendQuote && <MemoizedSendQuote isOpen={isSendQuote} load={qLoad} closeHandler={closeQuotationModal} handleOnSubmit={onSubmitHandler} />}
      {isTimesheet && <MemoizedTimesheet isOpen={isTimesheet} data={booking} handleClose={closeTimesheetModal} handleOnSubmit={timesheetSubmitHandler} handeOnUpdate={updateTimesheetHandler} type={'Approve'} view={true} fileList={fileList} />}
      {isAssign && <MemorizedAssignTo isOpen={isAssign} load={load} data={isAssignObj} iData={isInterpreters} handleClose={closeAssignModal} />}
      {isQPreview && <MemoizedQuotationPreview isOpen={isQPreview} data={isQuotationObj} handleClose={closeQPreviewModal} />}
    </div>
  );
};

Bookings.propTypes = {
  title: PropTypes.string,
};
