import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#fff',
  },
}));

export const ViewFile = (props) => {
  const classes = useStyles();
  const { open, handleClose, previewFile } = props;

  var extension = previewFile.split('.').pop().toLowerCase();

  let loadingModle = <CircularProgress color="inherit" />;

  if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
    loadingModle = (
      <img src={`${process.env.REACT_APP_BASE_URL}${previewFile}`} alt={previewFile} width="500px" height="auto" style={{ margin: '3em 0' }} />
    );
    // console.log('JPG');
  } else if (extension === 'pdf') {
    loadingModle = <embed type="application/pdf" src={`${process.env.REACT_APP_BASE_URL}${previewFile}`} width="90%" height="90%" />;
    // console.log('PGF');
  } else if (
    extension === 'doc' ||
    extension === 'docx' ||
    extension === 'xls' ||
    extension === 'xlsx' ||
    extension === 'mp3' ||
    extension === 'mp4' ||
    extension === 'zip'
  ) {
    loadingModle = (
      <Button variant="contained" startIcon={<GetAppIcon />}>
        <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_BASE_URL}${previewFile}`}>
          Download
        </a>
      </Button>
    );
    // console.log('Doc, Docx');
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        {loadingModle}
      </Backdrop>
    </React.Fragment>
  );
};

ViewFile.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  previewFile: PropTypes.string.isRequired,
};
