import React, { useContext, useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "../context/states/authState";
import Chip from "@material-ui/core/Chip";

export default function AuthenticatedRoute({ children, roles, ...rest }) {
	const { pathname, search } = useLocation();
	const history = useHistory();
	const { isAuthenticated, authHandler, userData } = useContext(AuthContext);

	useEffect(() => {
		authHandler(); // eslint-disable-next-line
	}, [isAuthenticated]);

	return (
		<Route {...rest}>
			{isAuthenticated ? (
				roles && roles.indexOf(userData !== null ? userData.role : "") === -1 ? (
					<Chip
						size="small"
						label="Access denied"
						onDelete={() => history.goBack()}
						color="secondary"
					/>
				) : (
					children
				)
			) : (
				<Redirect to={`/?redirect=${pathname}${search}`} />
			)}
		</Route>
	);
}
