export const headCells = [
    { id: 'int_id', numeric: false, disablePadding: false, label: 'ID', width: '7%' },
    { id: 'fullname', numeric: false, disablePadding: false, label: 'Name', width: '5%' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email', width: '10%' },
    { id: 'mobile', numeric: false, disablePadding: false, label: 'Phone', width: '7.5%' },
    { id: 'postcode', numeric: false, disablePadding: false, label: 'Postcode', width: '5.7%' },
    { id: 'interpretation', numeric: false, disablePadding: false, label: 'Interpretation', width: '12%' },
    { id: 'translation', numeric: false, disablePadding: false, label: 'Translation', width: '12%' },
    { id: 'transcription', numeric: false, disablePadding: false, label: 'Transcription', width: '12%' },
    { id: 'gender', numeric: false, disablePadding: false, label: 'Gender', width: '7.5%' },
    { id: 'qualificationSummary', numeric: false, disablePadding: false, label: 'Qualification Summary', width: '31%' },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action', width: '7.5%' },
];