import React, { useState, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Table, TableBody, TableContainer, TablePagination, Paper } from '@material-ui/core';

import { TimesheetContext } from '../../../context/states/timeSheetState';
import { AuthContext } from '../../../context/states/authState';

import Loader from '../../UI/Loader';
import { getComparator, stableSort } from '../../../libs/headerSortMethods'
// importing components
import { TablePaginationActions } from '../../UI/TablePaginationActions';
import { TableToolbar } from './Table/tableToolbar';
import { TableHeader } from './Table/tableHeader';

import { BookingLists } from './Lists/BookingLists';
import { Timesheet } from './Forms/Timesheet';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: 'calc(70vh - 20px)',
},
container2: {
    height: 'calc(80vh - 20px)',
},
  rowData: {
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  shape: {
    marginTop: '4px',
    marginRight: '4px',
    width: 12,
    height: 12,
  },
  shapeLabel: {
    fontSize: '12px',
    marginTop: '2px',
  },
}));

const headCells = [
  { id: 'booking_ref', numeric: false, disablePadding: false, label: 'Booking Ref', width: '100' },
  // { id: 'createdAt', numeric: false, disablePadding: false, label: 'Received', width: '100' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', width: '100' },
  { id: 'serviceType', numeric: false, disablePadding: false, label: 'Service / Language', width: '100' },
  { id: 'date', numeric: false, disablePadding: true, label: 'Date of Attendance', width: '100' },
  // { id: 'language', numeric: false, disablePadding: true, label: 'Language', width: '100' },
  // { id: 'serviceType', numeric: false, disablePadding: true, label: 'Service', width: '100' },
  // { id: 'companyName', numeric: false, disablePadding: true, label: 'Company Name', width: '100' },
  { id: 'name', numeric: false, disablePadding: true, label: 'Customer Name', width: '100' },
  // { id: 'phone', numeric: false, disablePadding: true, label: 'Telephone', width: '100' },
  { id: 'fileRef', numeric: false, disablePadding: true, label: 'File Reference', width: '100' },
  { id: 'clientName', numeric: false, disablePadding: true, label: 'Company / Client Name', width: '100' },
  { id: 'crNote', numeric: false, disablePadding: true, label: 'CR Note', width: '100' },
  // { id: 'interpreterName', numeric: false, disablePadding: true, label: 'Interpreter Name', width: '100' },
  { id: 'action', numeric: false, disablePadding: true, label: 'Action', width: '100' },
];


export const IBookings = (props) => {
  console.log('RENDER BOOKINGS');
  const { title, intID, /* interpreterTab */ } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1920px)');

  const { getTimesheetsHandler, data, loading, total, count, error, getTimesheetHandler, load, timesheet, fileList, updateTimesheetHandler } = useContext(
    TimesheetContext
  );
  const { userData } = useContext(AuthContext);
  // const { role } = userData;
  // const isAdmin = role !== 'admin' ? true : false
  const isAdmin = true
  const [isEnable] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);

  const enableCheckboxHandler = useCallback(() => setIsCheckbox(!isCheckbox), [isCheckbox]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('booking_ref');
  const [selected, setSelected] = useState([]);
  const [isPage, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchAllDataHandler = useCallback(
    async (searchInput) => {
      const query = isPage !== 0 ? `&page=${isPage + 1}&limit=${rowsPerPage}` : `&page=1&limit=${rowsPerPage}${searchInput}`;
      await getTimesheetsHandler(intID, query);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, isPage]
  );

  // Backdrop handler
  const [isLoading, setIsLoading] = useState(load);
  const backdropCloseHandler = () => setIsLoading(false);

  const [isTimesheet, setIsTimesheet] = useState(false);

  const toggleTimesheet = useCallback(
    async (id) => {
      await setIsLoading(true);
      try {
        await setIsLoading(false);
        getTimesheetHandler(id);
        setIsTimesheet((isTimesheet) => !isTimesheet);
      } catch (error) {
        await setIsLoading(false);
      }
    },
    [getTimesheetHandler]
  );

  const onSubmitHandler = async (data) => {
    setIsLoading(true);
    try {
      setIsLoading(false);
      await updateTimesheetHandler(data);
      await setIsTimesheet(false);
    } catch (error) {
      setIsLoading(false);
      await setIsTimesheet(false);
    }
  };

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [data]
  );

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = useCallback((name) => selected.indexOf(name) !== -1, [selected]);

  const refreshDataHandler = useCallback(() => fetchAllDataHandler(), [fetchAllDataHandler]);

  const tableToolbar = useMemo(
    () => (
      <TableToolbar
        numSelected={selected.length}
        tableTitle={title}
        isCheckbox={isCheckbox}
        isEnable={isEnable}
        handleCheckbox={enableCheckboxHandler}
        handleRefresh={refreshDataHandler}
        handleSearch={fetchAllDataHandler}
        listCount={count}
      />
    ),
    [selected.length, title, isCheckbox, isEnable, enableCheckboxHandler, refreshDataHandler, fetchAllDataHandler, count]
  );

  const tableHeader = useMemo(
    () => (
      <TableHeader
        headCells={headCells}
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={data.length}
        isEnable={isEnable}
      />
    ),
    [selected.length, order, orderBy, handleSelectAllClick, handleRequestSort, data.length, isEnable]
  );

  const tableData = useMemo(() => {
    return (
      <BookingLists
        data={data}
        loading={loading}
        error={error}
        stableSort={stableSort}
        getComparator={getComparator}
        order={order}
        orderBy={orderBy}
        page={isPage}
        rowsPerPage={rowsPerPage}
        isSelected={isSelected}
        handleClick={handleClick}
        isCheckbox={isCheckbox}
        timesheetHandler={toggleTimesheet}
        isAccessTo={isAdmin}
      />
    );
  }, [data, loading, error, order, orderBy, isPage, rowsPerPage, isSelected, handleClick, isCheckbox, toggleTimesheet]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {tableToolbar}
        <TableContainer className={matches ? classes.container2 : classes.container}>
          <Table className={classes.table} aria-labelledby={title} size={dense ? 'small' : 'medium'} stickyHeader aria-label="sticky table">
            {tableHeader}
            <TableBody>{tableData}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      {/* <React.Fragment>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense" />
          <Grid item>
            <Grid container direction="row">
              <div className={classes.shape} style={{ backgroundColor: '#00c853' }} />
              <span className={classes.shapeLabel}>Quote requested&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#bdbdbd' }} />{' '}
              <span className={classes.shapeLabel}>Quote supplied&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#64dd17' }} /> <span className={classes.shapeLabel}>Paid&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#78909c' }} /> <span className={classes.shapeLabel}>Approved&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#ffd600' }} />{' '}
              <span className={classes.shapeLabel}>Attendance sheet submitted&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#b388ff' }} /> <span className={classes.shapeLabel}>Booked&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#84ffff' }} />{' '}
              <span className={classes.shapeLabel}>cancelled- no fees apply&nbsp;</span>
              <div className={classes.shape} style={{ backgroundColor: '#d50000' }} /> <span className={classes.shapeLabel}>IP on holiday&nbsp;</span>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment> */}

      <Timesheet
        open={isTimesheet}
        handleClose={() => setIsTimesheet(false)}
        data={timesheet}
        loading={load}
        handleOnSubmit={onSubmitHandler}
        submitType="Save"
        clientView={false}
        fileList={fileList}
      />
      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </div>
  );
};
IBookings.propTypes = {
  title: PropTypes.string,
};
