import React from 'react';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import PropTypes from 'prop-types';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
  listCount: {
    fontSize: '12px',
    fontWeight: 600,
    maxidth: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    color: theme.palette.text.disabled,
    backgroundColor: lighten(theme.palette.action.disabled, 0.9),
  }
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { /*handleOpen, */ title, totalAmount } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title} | <span className={classes.listCount}>Total Amount: {totalAmount.toFixed(2)}</span>
      </Typography>

      {/* <Tooltip title="Add">
        <IconButton aria-label="add blog" color="primary" aria-haspopup="true" onClick={handleOpen}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
