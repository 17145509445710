import { makeStyles, lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cellHeaderWidth: {
        fontSize: '12px',
        fontWeight: 600,
        minWidth: '100px',
        // width: '100%',
        padding: '12px 2px',
        background: '#f9f9f9',
    },
    callWidth: {
        fontSize: '12px',
        minWidth: '110px',
        padding: '10px 2px',
        // width: '100%',
    },
    nagPayment: {
        float: 'right',
        fontSize: '20px',
        background: lighten(theme.palette.secondary.light, 0.7),
        padding: '3px 6px',
        borderRadius: '4px',
        color: '#333',
    },
    payment: { float: 'right', fontSize: '20px', padding: '3px 6px', background: lighten(theme.palette.primary.light, 0.7), borderRadius: '4px' },
    inputLabel: { color: '#4f4f4f', fontWeight: 600, padding: '6px 7px', marginBottom: '10px' },
    paymentInput: { color: '#4f4f4f', fontWeight: 600, padding: '6px 7px', marginBottom: '10px', width: '120px' },
    borderLess: { border: 'none', background: 'transparent', width: '100%', maxWidth: '160px' },
    modalTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    privateSelectionAlert: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.7),
        color: theme.palette.secondary.main,
        fontSize: 12,
        padding: '8px 6px',
        borderRedius: 4,
        fontWeight: 600
    }
}));