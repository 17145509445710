import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Chip, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { BookingContext } from '../../../../context/states/bookingState';
import { AuthContext } from '../../../../context/states/authState';

const useStyles = makeStyles((theme) => ({
  status: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.9),
  },
}));

export const AddNotes = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose, onSubmitHandler, data } = props;
  const { isBookingNotes } = useContext(BookingContext);
  const { userData } = useContext(AuthContext);

  // const scrollToBottom = () => {
  //   const chat = document.getElementById('noteList');
  //   chat.scrollTop = chat.scrollHeight;
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, []);

  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (formData) => {
    const { id, text, updatedBy } = formData;
    const note = { id, text: `Note: ${text}`, updatedBy };

    onSubmitHandler(note);
    // scrollToBottom();
    // handleClose();
    reset({ text: '' });
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="hidden" name="updatedBy" ref={register} defaultValue={userData.user} />
        <input type="hidden" name="id" ref={register} defaultValue={data._id} />
        <Dialog open={open} onClose={handleClose} aria-labelledby="note-title" aria-describedby="note-description" fullWidth maxWidth="sm">
          <DialogTitle id="note-title">
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item>Notes &nbsp;</Grid>
              <Grid item>
                <Chip color="primary" clickable size="small" label={'Request reference: ' + data.booking_ref} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <React.Fragment>
              {isBookingNotes.map((d) => {
                const checkStatus = d.text.split(':');
                return (
                  <Card key={d._id} style={{ marginBottom: '4px' }} className={checkStatus[0] !== 'Note' ? classes.status : ''}>
                    <CardContent>
                      <Typography variant="body2" color="textPrimary" component="p">
                        <strong>{checkStatus[0]}: </strong> {d.text.replace(checkStatus[0] + ':', '')}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary" component="small">
                        <span style={{ textTransform: 'capitalize' }}>{d.updatedBy}</span> |{' '}
                        {moment(d.updated).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
            </React.Fragment>
          </DialogContent>
          <div style={{ padding: '4px', marginLeft: '.8rem', marginRight: '.8rem' }}>
            <TextField
              id="add-note"
              label="Notes"
              variant="outlined"
              multiline
              rows={2}
              fullWidth
              name="text"
              inputRef={register({
                minLength: { value: 4, message: 'Must have at least 4 letters' },
                // maxLength: { value: 255, message: 'Maximum characters limit is 255' },
              })}
              error={!!errors.text}
              helperText={errors.text && errors.text.message}
            />
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!formState.isDirty || (formState.isDirty && !formState.isValid)}
              onClick={handleSubmit(onSubmit)}
            >
              Add Note
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </React.Fragment>
  );
});

// AddNotes.propTypes = {};
