import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_ALL_LANGUGAGES:
      return {
        ...state,
        languages: action.payload,
        loading: false,
      };

    case 'ADD_LANGUAGE':
      return {
        ...state,
        languages: [action.payload.data, ...state.languages],
      };

    case 'UPDATE_LANGUAGE':
      return {
        ...state,
        languages: state.languages.map((language) => {
          return language._id === action.payload._id ? action.payload : language;
        }),
      };

    case actionTypes.GET_ALL_DIALECTS:
      return {
        ...state,
        dialects: action.payload,
        loading: false,
      };

    case 'ADD_DIALECT':
      return {
        ...state,
        dialects: [action.payload.data, ...state.dialects],
      };

    case 'UPDATE_DIALECT':
      return {
        ...state,
        dialects: state.dialects.map((dialect) => {
          return dialect._id === action.payload._id ? action.payload : dialect;
        }),
      };

    default:
      return state;
  }
};
