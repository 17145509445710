import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from '@hookform/devtools';

// import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";

import { InterpreterContext } from "../../../../context/states/interpreterState";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  TextField,
  // OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  // FormHelperText,
  IconButton,
  Button,
  // InputAdornment,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Loader from "../../../UI/Loader";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: "100%",
    maxWidth: "100%",
  },
  input: {
    display: "none",
  },
  inputTitle: {
    fontWeight: "600",
    marginTop: ".1em",
    marginBottom: ".9em",
  },
}));

const AddInterpreter = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const { addInterpreterHandler } = useContext(InterpreterContext);

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  // const [isPassword, setisPassword] = useState({ showPassword: false });
  // const handleClickShowPassword = () => setisPassword({ ...isPassword, showPassword: !isPassword.showPassword });
  // const handleMouseDownPassword = (event) => event.preventDefault();

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    await addInterpreterHandler(data)
      .then((res) => {
        handleClose();
        backdropCloseHandler();
      })
      .catch((err) => {
        console.log(err);
        backdropCloseHandler();
      });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        backdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <RecordVoiceOverIcon color="primary" />
            <Typography variant="h6" gutterBottom>
              New Interpreter
            </Typography>

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <DevTool control={control} /> */}
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={1}>
              {/* Fields section */}
              <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined">
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                      color="primary"
                    >
                      Interpreter
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5} md={4}>
                        <TextField
                          id="fore-name"
                          label="Fore Name"
                          size="small"
                          fullWidth
                          name="fname"
                          variant="outlined"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.fname}
                          helperText={errors.fname && errors.fname.message}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} md={4}>
                        <TextField
                          id="middle-name"
                          label="Middle Name"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="mname"
                          inputRef={register({
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.mname}
                          helperText={errors.mname && errors.mname.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} md={4}>
                        <TextField
                          id="last-name"
                          label="Last Name"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="lname"
                          inputRef={register({
                            required: true,
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.lname}
                          helperText={errors.lname && errors.lname.message}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={4}>
                        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={isPassword.showPassword ? 'text' : 'password'}
                            name="password"
                            inputRef={register({
                              required: true,
                              minLength: { value: 4, message: 'Password must have at least 4 letters' },
                              maxLength: { value: 15, message: 'Password maximum characters limit is 15' },
                            })}
                            error={!!errors.password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {isPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            readOnly
                            labelWidth={70}
                            defaultValue="273003"
                          />
                          <FormHelperText style={{ color: 'red' }}>{errors.password && errors.password.message}</FormHelperText>
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                        >
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Controller
                            as={
                              <Select
                                labelId="gender-label"
                                id="interpreter-gender"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                                <MenuItem value={"other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="gender"
                            rules={{ required: true }}
                            error={!!errors.gender}
                            labelWidth={55}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            as={
                              <KeyboardDatePicker
                                autoOk
                                format="dd/MM/yyyy"
                                id="dob"
                                label="Date of birth"
                                size="small"
                                variant="inline"
                                fullWidth
                                inputVariant="outlined"
                                error={!!errors.dob}
                                helperText={errors.dob && errors.dob.message}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            }
                            control={control}
                            name="dob"
                            rules={{ required: true }}
                            error={!!errors.dob}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="origin"
                          label="Origin"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="origin"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.origin}
                          helperText={errors.origin && errors.origin.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="nationality"
                          label="Nationality"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="nationality"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.nationality}
                          helperText={
                            errors.nationality && errors.nationality.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                        >
                          <InputLabel id="drive-label">Drive</InputLabel>
                          <Controller
                            as={
                              <Select
                                labelId="drive-label"
                                id="interpreter-drive"
                                name="canDrive"
                                defaultValue={false}
                              >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="canDrive"
                            rules={{ required: false }}
                            error={!!errors.canDrive}
                            labelWidth={55}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper className={classes.paper} variant="outlined">
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                      color="primary"
                    >
                      Contact Details
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="mobile"
                          label="Mobile"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="mobile"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 10,
                              message: "Must have at least 10 digits",
                            },
                            maxLength: {
                              value: 20,
                              message: "Maximum digits limit is 20",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid mobile number",
                            },
                          })}
                          error={!!errors.mobile}
                          helperText={errors.mobile && errors.mobile.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="phone"
                          label="Phone"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="phone"
                          inputRef={register({
                            required: true,
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Must have at least 10 digits",
                            },
                            maxLength: {
                              value: 20,
                              message: "Maximum digits limit is 20",
                            },
                          })}
                          error={!!errors.phone}
                          helperText={errors.phone && errors.phone.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="other"
                          label="Skype / Others"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="skype_others"
                          inputRef={register({
                            required: false,
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.skype_others}
                          helperText={
                            errors.skype_others && errors.skype_others.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={3}>
                        <TextField
                          id="email"
                          label="Email"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="email"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 5,
                              message: "Must have at least 5 letters",
                            },
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid email-id",
                            },
                          })}
                          error={!!errors.email}
                          helperText={errors.email && errors.email.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={6}>
                        <TextField
                          id="address"
                          label="Address"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="address"
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 5,
                              message: "Must have at least 5 letters",
                            },
                            maxLength: {
                              value: 100,
                              message: "Maximum characters limit is 100",
                            },
                          })}
                          error={!!errors.address}
                          helperText={errors.address && errors.address.message}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <TextField
                          id="postcode"
                          label="Postcode"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="postcode"
                          inputRef={register({
                            required: true,
                            pattern: {
                              value:
                                /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                              message: "Invalid postcode",
                            },
                            minLength: {
                              value: 5,
                              message: "Must have at least 5 letters",
                            },
                            maxLength: {
                              value: 10,
                              message: "Maximum characters limit is 10",
                            },
                          })}
                          error={!!errors.postcode}
                          helperText={
                            errors.postcode && errors.postcode.message
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper className={classes.paper} variant="outlined">
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                      color="primary"
                    >
                      Bank Details
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField
                          id="acc-name"
                          label="Account Name"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="accountName"
                          inputRef={register({
                            required: true,
                            maxLength: {
                              value: 50,
                              message: "Maximum characters limit is 50",
                            },
                          })}
                          error={!!errors.accountName}
                          helperText={
                            errors.accountName && errors.accountName.message
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField
                          id="acc-no"
                          label="Account No"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="account_no"
                          inputRef={register({
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Invalid account no",
                            },
                            minLength: {
                              value: 5,
                              message: "Must have at least 10 digits",
                            },
                            maxLength: {
                              value: 30,
                              message: "Maximum characters limit is 30",
                            },
                          })}
                          error={!!errors.account_no}
                          helperText={
                            errors.account_no && errors.account_no.message
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField
                          id="sort-code"
                          label="Sort Code"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="sort_code"
                          inputRef={register({
                            required: true,
                            pattern: {
                              value: /^\d+$/,
                              message: "Invalid sort_code",
                            },
                            minLength: {
                              value: 5,
                              message: "Must have at least 5 digits",
                            },
                            maxLength: {
                              value: 30,
                              message: "Maximum characters limit is 30",
                            },
                          })}
                          error={!!errors.sort_code}
                          helperText={
                            errors.sort_code && errors.sort_code.message
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <TextField
                          id="branch"
                          label="Bank / Branch"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="branch"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 5,
                              message: "Must have at least 5 letters",
                            },
                            maxLength: {
                              value: 30,
                              message: "Maximum characters limit is 30",
                            },
                          })}
                          error={!!errors.branch}
                          helperText={errors.branch && errors.branch.message}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
});

export default AddInterpreter;
