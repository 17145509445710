import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  CardContent,
  CardActions,
  Button,
  MenuItem,
  Input,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';

import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '14px',
    color: '#757575',
    textAlign: 'left',
    marginTop: '4px',
  },
  formControl: {
    minWidth: 250,
  },
}));

export const AdvanceSearch = React.memo((props) => {
  const classes2 = useStyles();
  const { classes, handleToggle, isLanguages, isDialects, clearAdvancedSearchHandler, handleMinimize, isMinimize, setIsSearchObj, isSearchObj, setIsSearchMode } = props;

  const formValue = {
    name: isSearchObj?.name,
    fileRef: isSearchObj?.fileRef,
    interpreterName: isSearchObj?.interpreterName,
    serviceType: isSearchObj?.serviceType,
    language: isSearchObj?.language,
    dialect: isSearchObj?.dialect,
    companyName: isSearchObj?.companyName,
    clientName: isSearchObj?.clientName,
    status: isSearchObj?.status,
    private: isSearchObj?.private
  }

  const { register, handleSubmit, errors, control, setValue, reset } = useForm({ mode: 'onChange', defaultValues: formValue });

  const clearFieldsHandler = () => {
    reset({
      fname: '',
      language: '',
      dialect: '',
      gender: '',
      service: '',
      postcode: '',
      active: 'none',
      from: null,
      to: null,
      cfrom: null,
      cto: null
    })
    clearAdvancedSearchHandler();
  }

  const onSubmit = async (data) => {
    if(data.from) data = {...data, from: data.from.toISOString()}
    if(data.to) data = {...data, to: data.to.toISOString()}
    if(data.cfrom) data = {...data, cfrom: data.cfrom.toISOString()}
    if(data.cto) data = {...data, cto: data.cto.toISOString()}
    setIsSearchObj({ ...isSearchObj, ...data })
  };

  const handleChange = (e, targetName) => {
    console.log(e);
    setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, { shouldDirty: true });
  };

  return (
    <React.Fragment>
      <div style={{ display: isMinimize ? 'none' : 'block' }}>
        <Paper className={classes.advanceCard} elevation={3}>
          <form onSubmit={(e) => e.preventDefault()}>
            <CardContent>
              <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                <IconButton aria-label="close" size="small" onClick={handleToggle}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={2} className={classes2.label}>
                  <Typography variant="body2" gutterBottom>
                    Customer
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Input placeholder="Customer Name" inputProps={{ 'aria-label': 'name' }} fullWidth name="name" inputRef={register} defaultValue={formValue?.name} />
                </Grid>
                <Grid item xs={5}>
                  <Input placeholder="Company Name" inputProps={{ 'aria-label': 'name' }} fullWidth name="companyName" inputRef={register} defaultValue={formValue?.companyName} />
                </Grid>
                {/* Service type */}
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom>
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input placeholder="Interpreter Name" inputProps={{ 'aria-label': 'name' }} fullWidth name="interpreterName" inputRef={register} defaultValue={formValue?.interpreterName} />
                  </Grid>
                  <Grid item xs={5}>
                    <Input placeholder="Client Name" inputProps={{ 'aria-label': 'name' }} fullWidth name="clientName" inputRef={register} defaultValue={formValue?.clientName} />
                  </Grid>
                </Grid>
                {/* Service type */}
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom>
                      Select
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl size="small" className={classes2.formControl}>
                      {/* <InputLabel id="gender-label">Service</InputLabel> */}
                      <Controller
                        as={
                          <Select labelId="service-label" labe="Services" id="interpreter-service" style={{ marginTop: '4px' }}>
                            <MenuItem value={"none"} selected>
                              Services All
                            </MenuItem>
                            <MenuItem value="Telephone">Telephone </MenuItem>
                            <MenuItem value="Video Call">Video Call</MenuItem>
                            <MenuItem value="Onsite">Onsite</MenuItem>
                            <MenuItem value="Translation">Translation</MenuItem>
                            <MenuItem value="Transcription">Transcription</MenuItem>
                          </Select>
                        }
                        control={control}
                        name="serviceType"
                        error={!!errors.serviceType}
                        defaultValue={'none'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl size="small" className={classes2.formControl}>
                      {/* <InputLabel id="gender-label">Service</InputLabel> */}
                      <Controller
                        as={
                          <Select labelId="status-label" id="statusType" style={{ marginTop: '4px' }}>
                            <MenuItem value={"none"}>
                              Status
                            </MenuItem>
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                            <MenuItem value={'Booking'}>Booking</MenuItem>
                            <MenuItem value={'Quotation'}>Quotation</MenuItem>
                            <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                            {/* <MenuItem value={'Attended but no time sheet'}>Attended but no time sheet</MenuItem> */}
                            <MenuItem value={'Time Sheet Submitted'}>Time Sheet Submitted</MenuItem>
                            <MenuItem value={'Approved'}>Approved</MenuItem>
                            <MenuItem value={'Paid'}>Paid</MenuItem>
                            <MenuItem value={'Cancel'}>Cancelled</MenuItem>
                            <MenuItem value={'Quotation Request'}>Quote Requested</MenuItem>
                            <MenuItem value={'Quote Supplied'}>Quote Supplied</MenuItem>
                            {/* <MenuItem value={'Company Outstanding'}>Company Outstanding</MenuItem> */}
                          </Select>
                        }
                        control={control}
                        name="status"
                        error={!!errors.status}
                        defaultValue={'none'}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom>
                      Reference
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input placeholder="Invoice Reference" inputProps={{ 'aria-label': 'name' }} fullWidth name="invoiceRef" inputRef={register} defaultValue={formValue?.invoiceRef} />
                  </Grid>
                  <Grid item xs={5}>
                    <Input placeholder="File Reference" inputProps={{ 'aria-label': 'name' }} fullWidth name="fileRef" inputRef={register} defaultValue={formValue?.fileRef} />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom style={{ marginTop: '4px' }}>
                      Language
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Autocomplete
                      style={{ marginTop: '4px' }}
                      options={isLanguages}
                      filterSelectedOptions
                      onChange={(e, value) => handleChange(value !== null ? value.language : '', 'language')}
                      // defaultValue={{ language: data.languages[0] !== undefined ? data.languages[0].language : '' }}
                      defaultValue={{ language: formValue?.language }}
                      getOptionLabel={(option) => option.language || ""}
                      renderOption={(option) => <>{option.language}</>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder="Language"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'disabled',
                          }}
                        />
                      )}
                    />
                    <input style={{ display: 'none' }} name="language" ref={register} defaultValue={''} />
                  </Grid>
                  <Grid item xs={5}>
                    <Autocomplete
                      style={{ marginTop: '4px' }}
                      options={isDialects}
                      filterSelectedOptions
                      onChange={(e, value) => handleChange(value !== null ? value.dialect : '', 'dialect')}
                      // defaultValue={{ dialect: data.dialects[0] !== undefined ? data.dialects[0].dialect : '' }}
                      defaultValue={{ dialect: formValue?.dialect }}
                      getOptionLabel={(option) => option.dialect || ""}
                      renderOption={(option) => <>{option.dialect}</>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          placeholder="Dialect"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'disabled',
                          }}
                        />
                      )}
                    />
                    <input style={{ display: 'none' }} name="dialect" ref={register} defaultValue={''} />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom style={{ marginTop: '14px' }}>
                      Date within
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          size="small"
                          margin="normal"
                          format="dd/MM/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      }
                      control={control}
                      name="from"
                      defaultValue={formValue?.from}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          size="small"
                          margin="normal"
                          format="dd/MM/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      }
                      control={control}
                      name="to"
                      defaultValue={formValue?.to}
                    />
                  </Grid>
                  {/* <FormControlLabel
                    control={
                      <Controller
                        name="private"
                        color="primary"
                        defaultChecked={formValue?.private}
                        render={({ field: props }) => {
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}

                          />
                        }}
                      />
                    }
                    label="Private"
                  /> */}
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2} className={classes2.label}>
                    <Typography variant="body2" gutterBottom style={{ marginTop: '14px' }}>
                      Confirmed Date
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          size="small"
                          margin="normal"
                          format="dd/MM/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      }
                      control={control}
                      name="cfrom"
                      defaultValue={formValue?.cfrom}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          size="small"
                          margin="normal"
                          format="dd/MM/yyyy"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      }
                      control={control}
                      name="cto"
                      defaultValue={formValue?.cto}
                    />
                  </Grid>


                  <FormControlLabel
                    value={formValue?.private}
                    control={<Checkbox />}
                    name="private"
                    inputRef={register}
                    label="Private"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ marginTop: '12px' }}>
                <Button color="primary" onClick={clearFieldsHandler}>
                  Clear
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                  Search
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Paper>
      </div>
    </React.Fragment >
  );
});
