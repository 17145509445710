import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import {
  Grid,
  Hidden,
  InputBase,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";

// import { DevTool } from '@hookform/devtools';
import { InterpreterContext } from "../../../../context/states/interpreterState";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import EmailIcon from "@material-ui/icons/Email";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MoreIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearAllIcon from "@material-ui/icons/ClearAll";

import { AdvanceSearch } from "./AdvanceSearch";
import { useToolbarStyles } from "./toolbarStyles";
import MailerModal from "../../../mail-modal/index";

export const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    selectedEmails,
    tableTitle,
    isCheckbox,
    listCount,
    handleRefresh,
    handleCheckbox,
    handleFormModal,
    handleAdvanceSearchObj,
    clearAdvancedSearchHandler,
    setIsSearchObj,
    isSearchObj,
    setPage,
    isPage,
  } = props;
  const { getLangugagesHandler, getDialectsHandler, isLanguages, isDialects } =
    useContext(InterpreterContext);

  const [isMinimize, setIsMinimize] = useState(false);
  const minimizeHandler = () => setIsMinimize(!isMinimize);
  const [isOpen, setIsOpen] = useState(false);
  const [isMailerModal, setIsMailerModal] = useState(false);

  const advancedToggleHandler = () => {
    setIsOpen(true);
  };
  const closeAdvanceModelHandler = () => {
    setIsOpen(false);
  };

  const inputSearchRef = useRef();
  const [filterActive, setFilterActive] = useState(null);
  const handleFilterOpen = (event) => setFilterActive(event.currentTarget);
  const handleFilterClose = (event) => setFilterActive(null);

  const [isMenuList, setIsMenuList] = useState(null);
  const handleMenuList = (event) => setIsMenuList(event.currentTarget);
  const handleMenuListClose = () => setIsMenuList(null);

  useEffect(() => {
    getLangugagesHandler(); // eslint-disable-next-line
    getDialectsHandler(); // eslint-disable-next-line
  }, []);

  const clickToSearchHandler = useCallback(
    (event) => {
      var code = event.key;
      if (code === "Enter") {
        event.preventDefault();
        setTimeout(() => {
          setPage(isPage);
          setIsSearchObj({
            ...isSearchObj,
            page: isPage,
            fname: inputSearchRef.current?.value,
          });
        }, 1000);
      }
    },
    [isSearchObj]
  );

  const filterHandler = useCallback(
    (value) => {
      if (value === "active") {
        setIsSearchObj({ ...isSearchObj, active: true });
      } else if (value === "inactive") {
        setIsSearchObj({ ...isSearchObj, active: false });
      } else {
        setIsSearchObj({ ...isSearchObj, active: "" });
      }
    },
    [setIsSearchObj]
  );

  const clearMainSearchHandler = useCallback(() => {
    inputSearchRef.current.value = "";
    setIsSearchObj({ ...isSearchObj, fname: "" });
  }, [setIsSearchObj]);

  const mailModalHandler = () => setIsMailerModal(true);

  return (
    <>
      {/* <DevTool control={control} /> */}
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Toolbar title */}
          <Grid item>
            {numSelected > 0 ? (
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {tableTitle} |{" "}
                <span className={classes.listCount}>{listCount}</span>
              </Typography>
            )}
          </Grid>

          {numSelected > 0 ? (
            <Tooltip title="Email To">
              <IconButton aria-label="emailTo" onClick={mailModalHandler}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <React.Fragment>
              {/* Search bar */}
              <Grid item>
                <Hidden only={["sm", "xs"]}>
                  <Paper component="form" className={classes.paperRoot}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item md={1} className={classes.sectionDesktop}>
                        <IconButton aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={9}>
                        <InputBase
                          placeholder="Search"
                          style={{ width: "100%" }}
                          inputProps={{ "aria-label": "search" }}
                          inputRef={inputSearchRef}
                          defaultValue={isSearchObj?.fname}
                          onKeyPress={clickToSearchHandler}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <div
                          style={{
                            display: "flex",
                            gap: 0,
                            flexDirection: "row-reverse",
                          }}
                        >
                          <IconButton
                            aria-label="search"
                            size="small"
                            onClick={minimizeHandler}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                          {/* Object.values(isSearchObj).length > 2 */}
                          {isSearchObj.fname === "" && (
                            <Tooltip title="Clear Search">
                              <IconButton
                                aria-label="maximize modal"
                                color="primary"
                                size="small"
                                onClick={clearMainSearchHandler}
                              >
                                <ClearAllIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                  {/* Advance search */}
                  {/* {isOpen && ( */}
                  {isMinimize && (
                    <AdvanceSearch
                      classes={classes}
                      handleToggle={closeAdvanceModelHandler}
                      isLanguages={isLanguages}
                      isDialects={isDialects}
                      isMinimize={isMinimize}
                      handleMinimize={minimizeHandler}
                      handleAdvanceSearchObj={handleAdvanceSearchObj}
                      clearAdvancedSearchHandler={clearAdvancedSearchHandler}
                      setIsSearchObj={setIsSearchObj}
                      isSearchObj={isSearchObj}
                      isPage={isPage}
                      setPage={setPage}
                    />
                  )}
                  {/* )} */}
                </Hidden>
              </Grid>
              {/* Right action */}
              <Grid item>
                {/* Hide in small screen*/}
                <Hidden only={["sm", "xs"]}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    {/* Filter icon */}
                    <Grid item xs>
                      <Tooltip title="Filter active list">
                        <IconButton
                          aria-label="filter active list"
                          aria-haspopup="true"
                          onClick={handleFilterOpen}
                        >
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>

                      <Menu
                        id="filter-menu"
                        anchorEl={filterActive}
                        keepMounted
                        open={Boolean(filterActive)}
                        onClose={handleFilterClose}
                      >
                        <MenuItem onClick={() => filterHandler("")}>
                          All
                        </MenuItem>
                        <MenuItem onClick={() => filterHandler("active")}>
                          Active
                        </MenuItem>
                        <MenuItem onClick={() => filterHandler("inactive")}>
                          Inactive
                        </MenuItem>
                      </Menu>
                    </Grid>
                    {/* Refresh icon */}
                    <Grid item xs>
                      <Tooltip title="Refresh">
                        <IconButton
                          aria-label="Refresh Data"
                          color="primary"
                          aria-haspopup="true"
                          onClick={handleRefresh}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {/* Toggle icon */}
                    <Grid item xs>
                      <Tooltip
                        title={
                          isCheckbox ? "Disable Checkbox" : "Enable Checkbox"
                        }
                      >
                        {isCheckbox ? (
                          <IconButton
                            aria-label="Checkbox"
                            color="primary"
                            onClick={handleCheckbox}
                          >
                            <ToggleOnIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="Checkbox"
                            color="secondary"
                            onClick={handleCheckbox}
                          >
                            <ToggleOffIcon />
                          </IconButton>
                        )}
                      </Tooltip>
                    </Grid>
                    {/* Add icon */}
                    <Grid item xs>
                      <Tooltip title="Add">
                        <IconButton
                          aria-label="Add"
                          color="primary"
                          aria-haspopup="true"
                          onClick={handleFormModal}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Hidden>
                {/* Appear in small screen */}
                <Hidden mdUp>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Paper component="form" className={classes.minPaperRoot}>
                        <InputBase
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          inputRef={inputSearchRef}
                          defaultValue={isSearchObj?.fname}
                          name="fname"
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        aria-label="show more"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenuList}
                      >
                        <MoreIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={isMenuList}
                        keepMounted
                        open={Boolean(isMenuList)}
                        onClose={handleMenuListClose}
                      >
                        <MenuItem onClick={handleFormModal}>Add</MenuItem>
                        <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
      {isMailerModal && (
        <MailerModal
          title="Send Mail to Interpreter(s)"
          message="Hello"
          isOpen={isMailerModal}
          selectedEmails={selectedEmails}
          handleClose={() => setIsMailerModal(false)}
          confirmHandler={() => {}}
        />
      )}
    </>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selectedEmails: PropTypes.array.isRequired,
  tableTitle: PropTypes.string.isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
};
