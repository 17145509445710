import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";

import moment from "moment";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  CircularProgress,
  Chip,
  Grid,
  TextField,
  Typography,
  // FormControlLabel,
  // Switch,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  getComparator,
  stableSort,
} from "../../../../../libs/headerSortMethods";

import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { SubmitModal } from "./SubmitModal";
import { useStyles } from "./listStyles";

export const DueDetails = React.memo((props) => {
  const {
    open,
    handleClose,
    isLoading,
    data,
    companyData,
    submitHandler,
    approvedBy,
    submitteData,
    closeSubmitHandle,
    isPrivate,
  } = props;
  // console.log('Due Details', data);

  const { register, handleSubmit, watch, errors, control } = useForm();

  const changeFields = watch("details");
  const watchPaymentDate = watch("paymentDate");

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("booking_ref");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [isSubmitData, setIsSubmitData] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isCPayment, setIsCPayment] = useState(0);
  const [isTotalPrivate, setIsTotalPrivate] = useState(undefined);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data?.map((n) => n);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [data]
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    setIsTotalPrivate(undefined);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    if (!isPrivate) return;
    privateClientSelectorHandler(
      newSelected,
      newSelected[newSelected.length - 1]
    );
  };

  const privateClientSelectorHandler = (arr, selected) => {
    if (selected === undefined) return;
    const company = arr.map((item) => ({
      ...item,
      is: selected.companyName.includes(item.companyName),
    }));
    const isMatch = company.filter((f) => f.is !== false);
    setIsTotalPrivate(isMatch ? isMatch.length : undefined);
  };

  // const objectsEqual = (o1, o2) => {
  //   const d = o1.map(item => ({ ...item, is: o2.companyName.includes(item.companyName) }))
  //   // d.includes(o2.c)
  //   const f = d.filter(f => {
  //     console.log('selected val', f.is === true)
  //     return f.is !== false
  //   })
  //   console.log('select o1', d, o2.companyName, f)
  // }

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = useCallback(
    (name) => selected.indexOf(name) !== -1,
    [selected]
  );

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);
  // const allocatedAmount = selected.map((item) => parseFloat(item.inv[0].allocated)).reduce((sum, total) => sum + total, 0);
  // const allocatedAmount = selected.map((item) => item.inv[0].grossTotal).reduce((sum, total) => sum + total, 0);
  const selectedAllocated = changeFields
    ?.filter((item) => selected.some((field) => item.bookingId === field._id))
    .map((item) => parseFloat(item?.allocated))
    .reduce((sum, total) => sum + total, 0);
  const selectedTotalAllocatedAmount = changeFields
    ?.filter((item) => selected.some((field) => item.bookingId === field._id))
    .map((item) => parseFloat(item?.allocated));
  // console.log('selectedOne', selectedAllocated)
  const calBalance = isCPayment - selectedAllocated?.toFixed(2) || 0;

  // const enteredAmount = changeFields?.map((item) => parseFloat(item?.allocated)).reduce((sum, total) => sum + total, 0);
  // const allocatedAmount = selected.map((item) => parseInt(item?.allocated)).reduce((sum, total) => sum + total, 0);
  // console.log(allocatedAmount, enteredAmount)
  const comfirmModal = async (v) => {
    let data = {};
    // const remittedDetails = selected.map((item) => {
    //   const data = {};
    //   const { inv } = item;

    //   data.bookingId = item._id;
    //   data.bookingRef = inv[0].BookingRef;
    //   data.language = inv[0].language;
    //   data.service = inv[0].service;
    //   data.company = inv[0].company;
    //   data.name = inv[0].name;
    //   data.fileRef = inv[0].fileRef;
    //   data.clientName = inv[0].clientName;
    //   data.amount = inv[0].totalAmount === undefined ? inv[0].total : inv[0].totalAmount;
    //   data.dateOfAttendance = inv[0].dateOfAttendance;

    //   return data;
    // });

    data.totalAmount = parseFloat(isCPayment);
    data.paymentDate = watchPaymentDate;
    data.balance = +calBalance.toFixed(2);
    data.allocatedAmount = parseFloat(selectedAllocated.toFixed(2));
    data.details = v.details.filter((item) =>
      selected.some((field) => item.bookingId === field._id)
    );
    data.companyId = companyData.id;
    // data.company = isPrivate ? data?.details[0].company : companyData.name;
    if (isPrivate) {
      data.name = data?.details[0].name;
      delete data.company;
      delete data.companyId;
    } else {
      data.company = companyData.name;
    }
    try {
      setIsSubmitData(data);
      setIsModal(true);
      // setSelected([]);
    } catch (error) {
      setIsSubmitData(data);
      setIsModal(true);
    }
  };

  const closeSubmitHandler = useCallback(() => {
    setIsModal(false);
    // setSelected([]);
  }, [setSelected, setIsModal]);

  const modalHandleClose = useCallback(() => {
    handleClose();
    setSelected([]);
    setIsTotalPrivate(undefined);
  }, [handleClose, setSelected]);

  const SubmitModalMemo = React.useMemo(
    () => (
      <SubmitModal
        open={isModal}
        data={isSubmitData}
        handleClose={closeSubmitHandler}
        submitHandler={submitHandler}
        closeSubmitHandle={closeSubmitHandle}
        approvedBy={approvedBy}
        modalHandleClose={modalHandleClose}
      />
    ),
    [
      isModal,
      approvedBy,
      closeSubmitHandle,
      isSubmitData,
      submitHandler,
      modalHandleClose,
    ]
  );

  // const calBalance = isCPayment - allocatedAmount || 0

  function privateHandler() {
    let status = false;
    if (isPrivate) {
      if (isTotalPrivate === undefined) return (status = false);
      if (isTotalPrivate === selected?.length) {
        status = false;
      } else {
        status = true;
      }
    }

    return status;
    // {isPrivate && isTotalPrivate !== selected?.length ? <span className={classes.privateSelectionAlert}>Different Company of payments </span> : null}
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs>
            <div className={classes.modalTitle}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {isPrivate ? "Private Clients" : companyData.name}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Chip
                size="small"
                color="primary"
                label={`${!isPrivate ? "Allocate" : "Private"} Client Payment ${
                  selectedTotalAllocatedAmount !== undefined
                    ? "£ " + selectedTotalAllocatedAmount
                    : ""
                }`}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <span
              className={
                isCPayment >= selectedAllocated
                  ? classes.payment
                  : classes.nagPayment
              }
            >
              £ {calBalance?.toFixed(2)}
            </span>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          {/* <DevTool control={control} /> */}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={2}>
              {/* <label className={classes.inputLabel}>Amount Received: </label> */}
              <TextField
                id="client-pay"
                label="Amount Received"
                type="number"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                variant="outlined"
                size="small"
                onChange={(event) => setIsCPayment(event.target.value)}
                // className={classes.paymentInput}
                defaultValue={0}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  as={
                    <KeyboardDatePicker
                      autoOk
                      format="dd/MM/yyyy"
                      label="Payment Date"
                      size="small"
                      variant="inline"
                      fullWidth
                      inputVariant="outlined"
                      error={!!errors.paymentDate}
                      helperText={
                        errors.paymentDate && errors.paymentDate.message
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      // maxDate={new Date()}
                    />
                  }
                  control={control}
                  name="paymentDate"
                  rules={{ required: true }}
                  error={!!errors.paymentDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  {isLoading && (
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={data?.length}
                    />
                  )}
                  <TableBody>
                    {isLoading &&
                      stableSort(data, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const detail = `details[${index}]`;
                          const isItemSelected = isSelected(row);
                          // console.log(row._id)
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const {
                            bookingReference,
                            invoiceNo,
                            invoiceDate,
                            clientName,
                            name,
                            // language,
                            // service,
                            // interpreterName,
                            dateOfAttendance,
                            invTotal,
                            notes,
                            language,
                            service,
                          } = row.inv[0];
                          const { balanceAmount } = row;

                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row._id}
                              selected={isItemSelected}
                              padding={false}
                              // onClick={(event) => handleClick(event, row)}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                  color="primary"
                                  onClick={(event) => handleClick(event, row)}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {index + 1 + page * rowsPerPage}
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                {/* {bookingReference} */}
                                <input
                                  defaultValue={row._id}
                                  name={`${detail}.bookingId`}
                                  ref={register}
                                  type="hidden"
                                />
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.bookingRef`}
                                  ref={register}
                                  defaultValue={bookingReference}
                                />
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                {/* {invoiceNo} */}
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.invoiceNo`}
                                  ref={register}
                                  defaultValue={invoiceNo}
                                />

                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.invoiceDate`}
                                  ref={register}
                                  defaultValue={moment(invoiceDate).format(
                                    "DD-MM-Y"
                                  )}
                                />
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.service`}
                                  ref={register}
                                  defaultValue={service}
                                />
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.language`}
                                  ref={register}
                                  defaultValue={language}
                                />
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                {/* {clientName} */}
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.clientName`}
                                  ref={register}
                                  defaultValue={clientName}
                                />
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                {/* {clientName} */}
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.name`}
                                  ref={register}
                                  defaultValue={name}
                                />
                              </TableCell>
                              <TableCell className={classes.callWidth}>
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.dateOfAttendance`}
                                  ref={register}
                                  defaultValue={moment(dateOfAttendance).format(
                                    "DD-MM-Y"
                                  )}
                                />
                                {/* {moment(dateOfAttendance).format('DD-MM-Y')} */}
                              </TableCell>
                              <TableCell
                                className={classes.callWidth}
                                align="left"
                              >
                                {/* {invTotal?.toFixed(2)} */}
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.invTotal`}
                                  ref={register}
                                  defaultValue={invTotal?.toFixed(2)}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.callWidth}
                                align="left"
                              >
                                <input
                                  className={classes.borderLess}
                                  type="text"
                                  name={`${detail}.outstanding`}
                                  ref={register}
                                  defaultValue={+balanceAmount?.toFixed(2)}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.callWidth}
                                align="left"
                              >
                                <TextField
                                  style={{ width: 75 }}
                                  label="Amount"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputRef={register}
                                  name={`${detail}.allocated`}
                                  defaultValue={+balanceAmount?.toFixed(2) || 0}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                style={{ width: "100%" }}
                              >
                                <TextField
                                  fullWidth
                                  label="Notes"
                                  inputRef={register}
                                  name={`${detail}.notes`}
                                  defaultValue={notes}
                                  variant="outlined"
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                        .filter((item) => item.key !== submitteData.bookID)}
                    {!isLoading && (
                      <TableRow>
                        <TableCell colSpan="9" align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
            {isLoading && (
              <TablePagination
                rowsPerPageOptions={[100, 150, 200, 500]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Paper>
          {SubmitModalMemo}
          {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
        </div>
      </DialogContent>
      <DialogActions>
        {privateHandler() ? (
          <span className={classes.privateSelectionAlert}>
            Multiple clients selected{" "}
          </span>
        ) : null}

        <Button onClick={modalHandleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(comfirmModal)}
          color="primary"
          variant="contained"
          disabled={
            selected.length !== 0 &&
            isCPayment >= parseFloat(selectedAllocated?.toFixed(2))
              ? false
              : true
          }
          autoFocus
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
});
