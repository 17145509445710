import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        timesheets: action.payload.data,
        total: action.payload.total,
        count: action.payload.count,
      };

    case 'LOAD_DATA':
      return {
        ...state,
        load: true,
        error: null,
      };

    case actionTypes.FETCH_RECORD:
      return {
        ...state,
        load: false,
        error: null,
        timesheet: action.payload.data,
        fileList: action.payload.data.fileList,
      };

    case actionTypes.UPDATE_RECORD:
      return {
        ...state,
        timesheets: state.timesheets.map((timesheet) => {
          return timesheet.id === action.payload.id ? { ...timesheet, status: action.payload.status } : timesheet;
        }),
      };

    case 'DOC_LOAD':
      return {
        ...state,
        docLoad: true,
        viewDoc: '',
      };

    case actionTypes.GET_DOCUMENT_IMAGE:
      return {
        ...state,
        docLoad: false,
        viewDoc: action.payload,
      };

    case 'START_DOCUMENT_UPLOAD':
      return {
        ...state,
        isFileUpload: true,
      };

    case 'DOCUMENT_UPLOAD':
      const updatedItems = state.timesheet.fileList
      updatedItems.push(action.payload);

      return {
        ...state,
        fileList: updatedItems,
        isFileUpload: false,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
