import React from "react";
import PropTypes from "prop-types";

import { TableRow, TableCell, IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export const RateLists = (props) => {
	const { stableSort, getComparator, order, orderBy, data, loading, handleEdit } = props;

	let tableLists = (
		<TableRow>
			<TableCell
				colSpan={12}
				style={{ textAlign: "center" }}
			>
				<CircularProgress />
			</TableCell>
		</TableRow>
	);

	if (!loading) {
		tableLists = (
			<>
				{data.length === 0 ? (
					<TableRow>
						<TableCell
							colSpan={12}
							style={{ textAlign: "center" }}
						>
							<span>No Record</span>
						</TableCell>
					</TableRow>
				) : (
					stableSort(data, getComparator(order, orderBy))
						.map((row) => {
							return (
								<TableRow
									hover
									role="checkbox"
									key={row.rateName}
								>
									<TableCell
										style={{
											borderLeft: row.enabled
												? "6px solid #81c784"
												: "6px solid #e33371",
										}}
									>
										{row.rateName}
									</TableCell>
									<TableCell align="right">{row.attendance}</TableCell>
									<TableCell align="right">{row.travelPerhour}</TableCell>
									<TableCell align="right">{row.waitingPerHour}</TableCell>
									<TableCell align="right">{row.translationUrgent}</TableCell>
									<TableCell align="right">{row.translationNonUrgent}</TableCell>
									<TableCell align="right">{row.telephoneInterpreting}</TableCell>
									<TableCell align="right">{row.videoInterpreting}</TableCell>
									<TableCell align="right">{row.mileage}</TableCell>
									<TableCell align="right">{row.transcriptionUrgent}</TableCell>
									<TableCell align="right">
										{row.transcriptionNonUrgent}
									</TableCell>

									<TableCell align="right">
										<Tooltip title={`Edit: ${row.rateName}`}>
											<IconButton
												aria-label="edit"
												size="small"
												onClick={() => handleEdit(row)}
											>
												<EditIcon
													fontSize="small"
													color="primary"
												/>
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							);
						})
						.filter((item) => item.key !== "Select")
				)}
			</>
		);
	}
	return <>{tableLists}</>;
};

RateLists.propTypes = {
	stableSort: PropTypes.func.isRequired,
	getComparator: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	handleEdit: PropTypes.func.isRequired,
};
