import React from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
	Toolbar,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	AppBar,
	Tooltip,
	Button,
	IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
// import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const useToolbarStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolbarMobile: {
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: lighten(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: lighten(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(1),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
	},
	inputRoot: {
		color: "inherit",
	},
	secondSession: {
		display: "flex",
		flexDirection: "row-reverse",
		justifyContent: "space-between",
		alignItems: "center",
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row-reverse",
		},
	},
	clientTotalDiv: {
		background: "#fafafa",
		padding: "4px 8px",
		borderRadius: "4px",
		display: "flex",
		flexDirection: "row",
		lineHeight: "0.78em",
		fontSize: "12px",
		alignItems: "flex-end",
		margin: "10px",
		"& span": {
			marginRight: "1em",
			backgroundColor: lighten(theme.palette.info.light, 0.7),
			padding: "10px 6px",
			borderRadius: 4,
		},
		[theme.breakpoints.down("sm")]: {
			flexDirection: "row",
			marginBottom: "6px",
			lineHeight: "1.4em",
			fontSize: 10,
		},
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "80%",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		flexDirection: "row",
		paddingTop: 2,
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const {
		title,
		handleChange,
		handleFromDateChange,
		handleToDateChange,
		handleChangeAutoComplete,
		values,
		isClients,
		handleInputChange,
		totalOutstandingAmount,
		totalPaidAmount,
		searchHandler,
		clearSearchHandler,
	} = props;
	const toatlBillAmount = totalOutstandingAmount + totalPaidAmount;

	return (
		<div className={classes.grow}>
			<AppBar
				position="sticky"
				color="default"
				elevation={1}
			>
				<Toolbar className={classes.toolbarMobile}>
					<Tooltip title={title}>
						<Typography
							className={classes.title}
							variant="h6"
							noWrap
						>
							{title}
						</Typography>
					</Tooltip>
					<div className={classes.search}>
						<Autocomplete
							id="company"
							name="company"
							options={isClients}
							getOptionLabel={(option) => option?.companyName || ""}
							onChange={handleChangeAutoComplete("company")}
							value={values.company ? values.company : ""}
							style={{ width: 240 }}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									label="Company"
									size="small"
									variant="outlined"
									onChange={handleInputChange}
									value={values.company ? values.company : ""}
								/>
							)}
							disabled={values.private ? true : false}
						/>
					</div>
					<div className={classes.bookingInput}>
						<TextField
							fullWidth
							label="Client Name"
							size="small"
							variant="outlined"
							name="clientName"
							onChange={handleInputChange}
							value={values.clientName ? values.clientName : ""}
						/>
					</div>
					<div className={classes.bookingInput}>
						<TextField
							fullWidth
							label="File Ref"
							size="small"
							variant="outlined"
							name="fileRef"
							onChange={handleInputChange}
							value={values.fileRef ? values.fileRef : ""}
						/>
					</div>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.private}
									onChange={handleChange}
									name="private"
									color="primary"
								/>
							}
							label="Private"
						/>
						{/* <Grid item xs={2}> */}
						<KeyboardDatePicker
							autoOk
							style={{ width: 180 }}
							size="small"
							format="dd/MM/yyyy"
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							variant="inline"
							inputVariant="outlined"
							label="From"
							onChange={handleFromDateChange}
							value={values.fromDate}
						/>
						{/* </Grid> */}
						{/* <Grid item xs={2}> */}
						<KeyboardDatePicker
							autoOk
							style={{ width: 180 }}
							size="small"
							format="dd/MM/yyyy"
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							variant="inline"
							inputVariant="outlined"
							label="To"
							onChange={handleToDateChange}
							value={values.toDate}
						/>
						{/* </Grid> */}
						{/* <FormControlLabel
              control={
                <Checkbox
                  checked={values.unpaid}
                  onChange={handleChange}
                  name="unpaid"
                  color="primary"
                />
              }
              style={{ marginLeft: 2 }}
              label="Unpaid Jobs"
              disabled={isClients.length === 0 ? true : false}
            />
           
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.paid}
                  onChange={handleChange}
                  name="paid"
                  color="primary"
                />
              }
              label="Paid Jobs"
              // style={{ width: '20px', fontSize: '12px' }}
              disabled={isClients.length === 0 ? true : false}
            />
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.confirmed}
                  onChange={handleChange}
                  name="confirmed"
                  color="primary"
                />
              }
              label="Confirmed Jobs"
              style={{ fontSize: '12px' }}
              disabled={isClients.length === 0 ? true : false}
            />

                <Button variant="contained" size="small" endIcon={<SearchIcon />}>
                  Search
                </Button> */}

						<div style={{ display: "flex", alignItems: "center" }}>
							{/* <IconButton color="primary" variant="contained" size="small" onClick={searchHandler}>
                <Tooltip title={`Search`}>
                  <SearchIcon />
                </Tooltip>
              </IconButton> */}
							&nbsp;
							<Button
								size="small"
								endIcon={<SearchIcon />}
								onClick={searchHandler}
							>
								Search
							</Button>
							{values.isSearch && (
								<Tooltip title={`Clear search`}>
									<IconButton
										color="primary"
										aria-label="clear search inputs"
										onClick={clearSearchHandler}
									>
										<ClearAllIcon />
									</IconButton>
								</Tooltip>
							)}
						</div>
					</div>
					<div className={classes.sectionMobile}>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.unpaid}
									onChange={handleChange}
									name="unpaid"
									color="primary"
								/>
							}
							label="Unpaid Jobs"
							// disabled={isClients.length === 0 ? true : false}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.paid}
									onChange={handleChange}
									name="paid"
									color="primary"
								/>
							}
							label="Paid Jobs"
							// disabled={isClients.length === 0 ? true : false}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.confirmed}
									onChange={handleChange}
									name="confirmed"
									color="primary"
								/>
							}
							label="Confirmed Jobs"
							style={{ fontSize: "12px" }}
							// disabled={isClients.length === 0 ? true : false}
						/>
					</div>
				</Toolbar>
			</AppBar>
			{/* {isClients.length !== 0 ? */}
			<div className={classes.secondSession}>
				<div className={classes.sectionDesktop}>
					<FormControlLabel
						control={
							<Checkbox
								checked={values.unpaid}
								onChange={handleChange}
								name="unpaid"
								color="primary"
							/>
						}
						style={{ marginLeft: 2 }}
						label="Unpaid Jobs"
						// disabled={isClients.length === 0 ? true : false}
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={values.paid}
								onChange={handleChange}
								name="paid"
								color="primary"
							/>
						}
						label="Paid Jobs"
						// style={{ width: '20px', fontSize: '12px' }}
						// disabled={isClients.length === 0 ? true : false}
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={values.confirmed}
								onChange={handleChange}
								name="confirmed"
								color="primary"
							/>
						}
						label="Confirmed Jobs"
						style={{ fontSize: "12px" }}
						// disabled={isClients.length === 0 ? true : false}
					/>
				</div>
				{/* <div className={classes.sectionMobile}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.unpaid}
                onChange={handleChange}
                name="unpaid"
                color="primary"
              />
            }
            label="Unpaid Jobs"
            disabled={isClients.length === 0 ? true : false}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.paid}
                onChange={handleChange}
                name="paid"
                color="primary"
              />
            }
            label="Paid Jobs"
            disabled={isClients.length === 0 ? true : false}
          />
        </div> */}
				<div className={classes.clientTotalDiv}>
					<span>
						<strong>Total Billing</strong> {toatlBillAmount?.toFixed(2)}
					</span>
					<br />
					<span>
						<strong>Outstanding</strong> {totalOutstandingAmount?.toFixed(2)}
					</span>
					<br />
					<span>
						<strong>Paid Amount</strong> {totalPaidAmount?.toFixed(2)}
					</span>
				</div>
			</div>
			{/* : null} */}
		</div>
	);
};

export default React.memo(EnhancedTableToolbar);
