import React from "react";
import { withStyles, Tooltip, Typography, Divider, ClickAwayListener } from "@material-ui/core";
import moment from "moment";

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 320,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		margin: 0,
		textAlign: "left",
		padding: "4px 8px",
	},
}))(Tooltip);

export const StatusHoverableDiv = ({ status, balanceAmount, classDot, bookingStatus }) => {
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<HtmlTooltip
				// placement="right"
				PopperProps={{
					disablePortal: false,
				}}
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title={
					<React.Fragment>
						<Typography
							color="inherit"
							variant="caption"
							display="block"
							gutterBottom
						>
							Booking Tracker Status
						</Typography>
						<ul style={{ listStyle: "none", fontSize: 10, padding: 0 }}>
							{bookingStatus.length === 0
								? "No activity"
								: bookingStatus.map((bs, i) => (
										<li
											style={{
												backgroundColor: "rgba(0, 0, 0, 0.04)",
												padding: "4px",
												borderRadius: 4,
											}}
											key={i}
										>
											<span>{"Status"}</span>:{" "}
											<b>{bs.text !== undefined ? bs.text : <em>Amended</em>}</b>
											<br />
											<span>{"Date"}</span>:{" "}
											<small>{moment(bs.updated).format("DD/MM/YYYY HH:mm")}</small> |{" "}
											<span>{"Updated By"}</span>: <small>{bs.updatedBy}</small>
											<Divider />
										</li>
								  ))}
						</ul>
					</React.Fragment>
				}
			>
				<div
					className={status}
					style={{ position: "relative" }}
					onClick={handleTooltipOpen}
				>
					{status === "Cancel" ? "Cancelled" : status}&nbsp;
					{balanceAmount !== undefined ? (
						balanceAmount > 0 ? (
							<span className={classDot}></span>
						) : null
					) : null}
				</div>
			</HtmlTooltip>
		</ClickAwayListener>
	);
};
