import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Checkbox, IconButton, Tooltip, CircularProgress } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .expired': {
      textTransform: 'capitalize',
      color: theme.palette.secondary.main,
      padding: '8px 12px',
      backgroundColor: lighten(theme.palette.secondary.light, 0.9),
    },
    '& .running': {
      textTransform: 'capitalize',
      color: theme.palette.success.main,
      padding: '8px 12px',
      backgroundColor: lighten(theme.palette.success.light, 0.9),
    },
    '& .renewal': {
      textTransform: 'capitalize',
      color: theme.palette.warning.main,
      padding: '8px 12px',
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    '& .registered': {
    
     
    },
  },
  registered: {
    '& .active': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

    },
    color: theme.palette.info.main,
    backgroundColor: lighten(theme.palette.info.light, 0.89),
  },
}));

export const ClientLists = React.memo((props) => {
  console.log('RENDER LISTS');
  const classes = useStyles();
  const { data, loading, stableSort, getComparator, order, orderBy, isSelected, handleClick, isCheckbox, handleEditModal, page, rowsPerPage } = props;

  const checkRenewalHandler = (dueDate) => {
    let now = moment()
    let due = moment(dueDate)
    let status = ''
    if(now.diff(due, 'days') >= 0) {
      status = 'expired'
    } else if(now.format('DD-MM-yyyy') <= due.subtract(10, 'd').format('DD-MM-yyyy')) {
      console.log('due alert 10 days: ', due.subtract(10, 'd').format('DD-MM-yyyy'))
      status = 'renewal'
    } else {
      // console.log('due renewal', now.format('DD-MM-yyyy'), due.format('DD-MM-yyyy'))
      status = 'running'
    }
    return status
  }

  let tableLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableLists = (
      <>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <span>No Record</span>
            </TableCell>
          </TableRow>
        ) : (
          stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row._id);
            const labelId = `enhanced-table-checkbox-${index}`;
            
            return (
              <TableRow
                hover
                role="checkbox"
                key={row._id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                className={clsx(
                  `${classes.root} ${row.isRegistered ? classes.registered : null}`
                )}
              >
                <TableCell padding="checkbox">
                  {isCheckbox && (
                    <Checkbox onClick={(event) => handleClick(event, row._id)} checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                  )}
                </TableCell>
                <TableCell align="left" >
                  {index + 1 + (page) * rowsPerPage}
                </TableCell>
                <TableCell scope="row" align="left" id={labelId}>
                  {row.clientID}
                </TableCell>
                <TableCell >
                 <div className={row.isRegistered ? 'active' : null}>
                 {row.companyName}
                  {row.isRegistered && (
                    <Tooltip title={`Registered`}>
                      <IconButton aria-label="company" size="small">
                        <BusinessIcon fontSize="inherit" color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                 </div>
                </TableCell>
                <TableCell>{row.companyStatus}</TableCell>
                <TableCell>{row.contactPerson}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row?.email}</TableCell>
                <TableCell>{row.postcode}</TableCell>
                <TableCell className={checkRenewalHandler(row.renewalDueDate)}>
                  <Tooltip title={`${checkRenewalHandler(row.renewalDueDate).toLocaleUpperCase() }:  ${moment(row.renewalDueDate).format('DD-MM-yyyy')}`}>
                    <div style={{ cursor: 'pointer' }}>{moment(row.dor).format('DD-MM-yyyy')}</div>
                  </Tooltip>
                    {/* <span style={{ cursor: 'pointer', fontSize: 10 }}>Status: <strong>{checkRenewalHandler(row.renewalDueDate)}</strong></span> */}
                </TableCell>
                <TableCell className={classes.activeInterpreter}>
                  <Tooltip title={`Edit: ${row.companyName}`}>
                    <IconButton aria-label="edit" size="small" onClick={() => handleEditModal(row._id)}>
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </>
    );
  }
  return <>{tableLists}</>;
});

ClientLists.propTypes = {
  data: PropTypes.array.isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  stableSort: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleEditModal: PropTypes.func.isRequired,
};
