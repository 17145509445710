import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
        docLoad: false,
        progressValue: 0,
        booking: {},
        fileList: [],
        viewDoc: '',
        error: null,
        interpreters: [],
        clients: [],
        rateStructures: [],
        bookingNotes: [],
        bStatus: '',
        load: false
      };

    case 'EMPTY_STATE_OBJ_ARR':
      return {
        ...state,
        booking: {},
        viewDoc: '',
        fileList: [],
        interpreters: [],
        rateStructures: [],
        bookingNotes: [],
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        bookings: action.payload.data,
        total: action.payload.total,
        count: action.payload.count,
        bStatus: localStorage.getItem('bStatus'),
      };

    case actionTypes.ADD_RECORD:
      return {
        ...state,
        bookings: [action.payload, ...state.bookings],
        total: state.total + 1,
        fileList: [],
      };

    case actionTypes.UPDATE_RECORD:
      return {
        ...state,
        booking: action.payload,
        bookings: state.bookings.map((booking) => {
          return booking._id === action.payload._id ? action.payload : booking;
        }),
      };

    case 'TIMESHEET_UPDATED':
      return {
        ...state,
        bookings: state.bookings.map((timesheet) => {
          console.log(...timesheet.invoice.cInvoice);
          return timesheet.id === action.payload.id
            ? {
              ...timesheet,
              status: action.payload.status,
              isApprove: action.payload.isApprove,
              approvedBy: action.payload.approvedBy,
              // invoice: { ...timesheet.invoice, cInvoice: { ...timesheet.invoice.cInvoice, cInvoice: action.payload.invarr } },
              // invoice: { ...timesheet, invoice: { ...timesheet.invoice, cInvoice: action.payload.invarr } },
              invoice: {
                ...timesheet.invoice,
                cInvoice: [...timesheet.invoice.cInvoice, { cInvoice: action.payload.cInvoice }],
              },
            }
            : timesheet;
        }),
      };

    case 'UPDATE_ASSIGN':
      return {
        ...state,
        bookings: state.bookings.map((booking) => {
          return booking.id === action.payload.id
            ? {
              ...booking,
              interpreterMobile: action.payload.interpreterMobile,
              interpreterName: action.payload.interpreterName,
              interpreterPhone: action.payload.interpreterPhone,
              interpreterPostcode: action.payload.interpreterPostcode,
              interpreterRef: action.payload.interpreterRef,
            }
            : booking;
        }),
      };

    case 'LOAD_DATA':
      console.log('loaded');
      return {
        ...state,
        load: true,
        booking: {},
        fileList: [],
        error: null,
      };

    case actionTypes.FETCH_RECORD:
      console.log('file book reducer', action.payload.data.fileList)
      return {
        ...state,
        load: false,
        error: null,
        booking: action.payload.data,
        fileList: action.payload.data.fileList,
        bookingNotes: action.payload.data.bookingNotes,
      };

    case actionTypes.GET_ALL_LANGUGAGES:
      return {
        ...state,
        languages: action.payload,
      };

    case actionTypes.GET_ALL_DIALECTS:
      return {
        ...state,
        dialects: action.payload,
      };

    case actionTypes.UPLOAD_START:
      return {
        ...state,
        progressValue: 0,
        error: null,
      };

    case actionTypes.PROGRESS_VALUE:
      return {
        ...state,
        progressValue: action.payload,
      };

    case 'DOCUMENT_UPLOAD':
      console.log(action.payload);
      return {
        ...state,
        fileList: [action.payload, ...state.fileList],
      };

    case 'DOCUMENT_DELETED':
      // console.log(action.payload);
      return {
        ...state,
        fileList: [...state.fileList.filter((doc) => doc.fileName !== action.payload)],
      };

    case 'DOC_LOAD':
      return {
        ...state,
        docLoad: true,
        viewDoc: '',
      };

    case actionTypes.GET_DOCUMENT_IMAGE:
      return {
        ...state,
        docLoad: false,
        viewDoc: action.payload,
      };

    case 'LOAD_INTERRETER':
      return {
        ...state,
        load: true,
      };

    case 'GET_INTERPRETER_NAME':
      return {
        ...state,
        load: false,
        interpreters: action.payload,
      };

    case 'GET_CLIENTSNAME_NAME':
      return {
        ...state,
        clients: action.payload,
      };

    case 'GET_RATE_STRUCTURES':
      return {
        ...state,
        rateStructures: action.payload,
      };

    case actionTypes.ADD_PROFILE_NOTES:
      return {
        ...state,
        bookingNotes: action.payload,
      };

    case 'FETCH_INVOICE':
      return {
        ...state,
        load: false,
        error: null,
        invoice: action.payload.data,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        load: false,
        loading: false,
        error: action.payload.data,
      };

    case 'FETCH_DEPARTMENT':
      return {
        ...state,
        load: false,
        departments: action.payload.data
      }

    case 'FETCH_CASETYPE':
      return {
        ...state,
        load: false,
        caseTypes: action.payload.data
      }
    default:
      return state;
  }
};
