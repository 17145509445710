import React, { useState, useCallback } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core';

import { EnhancedTableHead } from './EnhancedTableHead';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  closeBtn: {
    float: 'right',
    margin: 'auto 8px',
  },
  hTable: {
    margin: '12px',
  },
  th: {
    width: '140px',
    textAlign: 'left',
    padding: '3px',
  },
  td: {
    minWidth: '200px',
    textAlign: 'left',
    padding: '3px',
  },
}));

export const ViewDetails = React.memo((props) => {
  const {
    open,
    handleClose,
    data,
    // , isloading
  } = props;
  // console.log('Due Details', data);

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('booking_ref');
  const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [isModal, setIsModal] = useState(false);

  // const [isOpen, setOpen] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = useCallback((event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }, [data]);

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }

  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  // const closeSubmitHandler = () => {
  //   setIsModal(false);
  //   setSelected([]);
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Grid item xs>
            {/* <Chip size="small" color="primary" label={data.interpreterName} /> */}
            Remittance Slip
          </Grid>
          <Grid item xs={2}>
            {/* Close Button */}
            <IconButton aria-label="close" className={classes.closeBtn} size="small" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
            {/* Print Button */}
            <Tooltip title={`Print: ${data.remittanceRef}`}>
              <IconButton
                aria-label="print"
                size="small"
                className={classes.closeBtn}
                color="primary"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/remittanceslips/view.html?id=${data.remittanceRef}&token=${data.randToken}&view=${process.env.REACT_APP_MODE}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                <PrintIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
              <Grid item xs={12}>
                <table className={classes.hTable}>
                  <tbody>
                    <tr>
                      <th className={classes.th}>Remittance No</th>
                      <td className={classes.td}>: {data.remittanceRef}</td>
                      <th className={classes.th}>Interpreter Name</th>
                      <td className={classes.td}>: {data.interpreterName}</td>
                    </tr>

                    <tr>
                      <th className={classes.th}>Total Jobs</th>
                      <td className={classes.td}>: {data?.details?.length}</td>
                      <th className={classes.th}>Approved By</th>
                      <td className={classes.td}>: {data.approvedBy}</td>
                    </tr>

                    <tr>
                      <th className={classes.th}>Total Amount</th>
                      <td className={classes.td}>: £{data?.totalAmount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className={classes.th}>Notes</th>
                      <td className={classes.td} colSpan="3">
                        : {data.notes}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data?.details?.map((row) => (
                    <TableRow key={row.bookingRef}>
                      <TableCell component="th" scope="row">
                        {row.bookingRef}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{moment(row.dateOfAttendance).format('DD-MM-y')}</TableCell>
                      <TableCell>{row.language}</TableCell>
                      <TableCell>{row.company}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.service}</TableCell>
                      <TableCell align="right">{moment(row.paymentDate).format('DD-MM-y')}</TableCell>
                      <TableCell align="left">{row.amount?.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={8} align="right">
                      <strong>Total Amount £</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{data.totalAmount?.toFixed(2)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
          </Paper>
          {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {}} color="primary" variant="contained" autoFocus>
          Generate
        </Button>
      </DialogActions> */}
    </Dialog>
  );
});
