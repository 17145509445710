import React, { useState, useEffect, useContext, useCallback } from "react";

import { Paper, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./style";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { EnhancedTableToolbar, CardItem } from "../../../components/pages/reports/bookings/index";

import { BookingReportContext } from "../../../context/states/reports/bookingReportState";

const initialState = {
	isSearch: false,
	status: "",
	fromDate: null,
	toDate: null,
	companyId: "",
	company: "",
};

let autoTimer;

const BookingsReport = () => {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:1920px)");

	const {
		bookings,
		clients,
		error,
		totalQuotes,
		totalInvs,
		totalOutstanding,
		isloading,
		getBookingReportHandler,
		clearStateHandler,
		getClientsHandler,
	} = useContext(BookingReportContext);

	const [values, setValues] = useState({ ...initialState });
	const [dense] = useState(true);

	useEffect(() => {
		getBookingReportHandler("");
	}, []);

	// console.log(!isloading && reports, paidJobs, clients)

	const handleChange = (event) => {
		setValues({ ...values, [event.target.name]: event.target.checked });
	};

	const handleChangeAutoComplete = (name) => (event, value) => {
		if (value === null) {
			setValues({ ...initialState });
			clearStateHandler();
			return;
		}
		if (name === "company") {
			setValues({ ...values, [name]: value, companyId: value._id });
		}
	};

	const handleChangeSelect = (event) => {
		const { value, name } = event.target;

		// if (value === null) {
		// 	setValues({ ...initialState });
		// 	clearStateHandler();
		// 	return;
		// }

		console.log("handleChangeSelect", values);
		setValues({ ...values, [name]: value, status: value });

		// if (value === "" && values.fromDate === null && values.toDate === null) {
		// 	setValues({ ...values, [name]: value, status: value, isSearch: false });
		// 	return;
		// }
	};

	const handleInputChange = ({ target }) => {
		clearTimeout(autoTimer);
		if (target.value === "" || target.value === null) return;

		autoTimer = setTimeout(() => {
			getClientsHandler(target?.value);
		}, 500);
	};

	const handleFromDateChange = (event) => {
		const date = new Date(event).toISOString();
		setValues({ ...values, fromDate: date });
	};

	const handleToDateChange = (event) => {
		const date = new Date(event).toISOString();
		setValues({ ...values, toDate: date });
	};

	const searchHandler = () => {
		let query = "";
		if (values.status !== "") {
			query += `status=${values.status}`;
		}

		if (values.companyId !== "") {
			query += `&companyId=${values.companyId}`;
		}

		let filteredDate = "";

		if (values.fromDate === null) {
			filteredDate = "";
		} else if (values.fromDate !== null) {
			filteredDate = `&fromDate=${values.fromDate}`;
			if (values.toDate !== null) {
				filteredDate += `&toDate=${values.toDate}`;
			}
		}

		if (
			values.companyId !== "" ||
			values.status !== "" ||
			values.fromDate !== null ||
			values.toDate !== null
		) {
			setValues({ ...values, isSearch: true });
			getBookingReportHandler(`${query}${filteredDate}`);
		}
	};

	const clearSearchHandler = () => {
		setValues({ ...initialState });
		clearStateHandler();
	};

	return (
		<React.Fragment>
			<div
				classes={classes.rootM}
				style={{ width: "100%" }}
			>
				<Paper className={classes.paper}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<EnhancedTableToolbar
							handleChange={handleChange}
							values={values}
							isClients={clients}
							handleInputChange={handleInputChange}
							handleChangeSelect={handleChangeSelect}
							handleChangeAutoComplete={handleChangeAutoComplete}
							totalInvoices={totalInvs}
							totalQuotes={totalQuotes}
							totalOutstanding={totalOutstanding}
							totalvat={0}
							totalPaid={0}
							chgTotal={0}
							title={"Bookings Report"}
							handleFromDateChange={handleFromDateChange}
							handleToDateChange={handleToDateChange}
							searchHandler={searchHandler}
							clearSearchHandler={clearSearchHandler}
						/>
					</MuiPickersUtilsProvider>

					<CardItem
						title="Bookings"
						active={classes.paidStage}
						classes={classes}
						isLoading={isloading}
						data={bookings}
						dense={dense}
						matches={matches}
					/>
				</Paper>
			</div>
		</React.Fragment>
	);
};

export default BookingsReport;
