import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      console.log('loaded');
      return {
        ...state,
        loading: true,
        error: null,
        outStandings: [],
        clients: [],
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        outStandings: action.payload.data,
        total: action.payload.total,
      };

    case 'LOAD_DUE':
      return {
        ...state,
        load: false,
        dues: {},
        error: null
      };

    case actionTypes.FETCH_RECORD:
      return {
        ...state,
        load: true,
        due: action.payload,
      };

    case actionTypes.ADD_RECORD:
      return {
        ...state,
        // due: [action.payload.data, ...state.due],
        submitteData: action.payload.data,
        due: [...state.due.filter((el) => el !== action.payload.data)],
        // total: state.total + 1,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        load: false,
        loading: false,
        error: action.payload,
      };

    case 'FETCH_CLIENT_RECORD':
      return {
        ...state,
        clients: action.payload.data
      }

    default:
      return state;
  }
};
