import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/states/authState';

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function UnauthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated, authHandler } = useContext(AuthContext);

  useEffect(() => {
    authHandler(); // eslint-disable-next-line
  }, [isAuthenticated]);

  const redirect = querystring('redirect');
  return <Route {...rest}>{!isAuthenticated ? children : <Redirect to={redirect === '' || redirect === null ? '/dashboard' : redirect} />}</Route>;
}
