import React, { createContext, useReducer, useContext } from 'react';
import TermReducer from '../reducers/termReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  terms: [],
  error: null,
};

// creating context
export const TermContext = createContext(initialState);

// provider component
export const TermProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TermReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All Terms data */
  const getTermsHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Terms);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Add New Terms data */
  const addTermHandler = async (data) => {
    const result = await axios.post(urlRequest.Terms, data);
    dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Term created' });
    }
    return await result;
  };

  /* Update interpreter by id */
  const updatedTermHandler = async (data) => {
    console.log(data);
    /* try {
      const result = await axios.put(`${urlRequest.Terms}/${data._id}`, data);
      dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
      if (result.data.success) {
        // alertDispatch({ type: 'open', message: 'Term updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    } */

    const result = await axios.put(`${urlRequest.Terms}/${data._id}`, data);
    dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Term updated' });
    }

    return await result;
  };

  return (
    <TermContext.Provider
      value={{
        loading: state.loading,
        isTerms: state.terms,
        getTermsHandler,
        addTermHandler,
        updatedTermHandler,
      }}
    >
      {children}
    </TermContext.Provider>
  );
};
