import React, { useState, useEffect, useContext } from "react";

import { Paper, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from "./style";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  EnhancedTableToolbar,
  CardItem,
} from "../../../components/pages/reports/invoicePayment/index";

import { InvoicePaymentContext } from "../../../context/states/reports/invoicePaymentState";

const initialState = {
  isSearch: false,
  companyId: "",
  account: true,
  refund: true,
  company: "",
  fromDate: null,
  toDate: null,
};
const InvoiceSummary = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1920px)");

  const {
    invoices,
    totalInvoices,
    count,
    error,
    isloading,
    clients,
    getPaymentInvoicesHandler,
    getClientsHandler,
    clearStateHandler,
    totalvat,
    totalService,
    totalhavt,
    totalPaid,
    chgTotal
  } = useContext(InvoicePaymentContext);

  const [values, setValues] = useState({ ...initialState });
  const [dense] = useState(true);

  useEffect(() => {
    getPaymentInvoicesHandler("");
  }, []);

  // console.log(!isloading && reports, paidJobs, clients)

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const handleChangeAutoComplete = (name) => (event, value) => {
    console.log("event", name, value);
    if (value === null) {
      setValues({ ...initialState });
      clearStateHandler();
      return;
    }
    if (name === "company") {
      setValues({ ...values, [name]: value, companyId: value._id });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value === "") return;
    getClientsHandler(event.target.value);
  };

  const handleFromDateChange = (event) => {
    const date = new Date(event).toISOString();
    setValues({ ...values, fromDate: date });
  };

  const handleToDateChange = (event) => {
    const date = new Date(event).toISOString();
    console.log("date", date);
    setValues({ ...values, toDate: date });
  };

  const searchHandler = () => {
    console.log("submit");
    setValues({ ...values, isSearch: true });
    // console.log('com', values.companyId)
    let query = "";
    if (values.companyId !== "") {
      query += `companyId=${values.companyId}`;
    }
    let filteredDate = "";

    if (values.fromDate === null) {
      filteredDate = "";
    } else if (values.fromDate !== null) {
      filteredDate = `&fromDate=${values.fromDate}`;
      if (values.toDate !== null) {
        console.log("to date", values.toDate);
        filteredDate += `&toDate=${values.toDate}`;
      }
    }

    if (values.account) {
      console.log("account");
      getPaymentInvoicesHandler(`${query}${filteredDate}`);
    } else {
      getPaymentInvoicesHandler("");
    }
  };

  const clearSearchHandler = () => {
    setValues({ ...initialState });
    getPaymentInvoicesHandler("");
  };

  return (
    <React.Fragment>
      <div classes={classes.rootM} style={{ width: "100%" }}>
        <Paper className={classes.paper}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <EnhancedTableToolbar
              handleChange={handleChange}
              values={values}
              isClients={clients}
              handleInputChange={handleInputChange}
              handleChangeAutoComplete={handleChangeAutoComplete}
              totalInvoices={totalInvoices}
              totalService={totalService}
              totalhavt={totalhavt}
              totalvat={totalvat}
              totalPaid={totalPaid}
              chgTotal={chgTotal}
              title={"Invoice Payment"}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              searchHandler={searchHandler}
              clearSearchHandler={clearSearchHandler}
            />
          </MuiPickersUtilsProvider>
          {values.account && (
            <CardItem
              title="Invoice Payment"
              active={classes.paidStage}
              classes={classes}
              isLoading={isloading}
              data={invoices}
              dense={dense}
              matches={matches}
            />
          )}
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default InvoiceSummary;
