import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { DueLists } from '../../../components/pages/payments/outstandingOverCPR/dueLists';
import { getComparator, stableSort } from '../../../libs/headerSortMethods'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  mainroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  container: {
    height: 'calc(70vh - 20px)',
  },
  container2: {
    height: 'calc(80vh - 20px)',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  privateSpan: {
    fontSize: 8,
    fontWeight: 600,
    background: '#262626',
    padding: '4px 6px',
    borderRadius: 4,
    color: 'white',
    marginLeft: 2
  }
}));

const ClientOutstandingOverCPR = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div classes={classes.mainroot} style={{ width: '100%' }}>
        <Paper className={classes.paper}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DueLists title="Outstanding/Over Client Payment Report" classes={classes} getComparator={getComparator} stableSort={stableSort} />
          </MuiPickersUtilsProvider>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default ClientOutstandingOverCPR;
