import React, { useState, useCallback, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Navbar from '../components/includes/Navbar/Navbar';
import SideDrawer from '../components/includes/Drawer/Drawer';

import { AuthContext } from '../context/states/authState';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    // height: 'calc(30% - 2vh)',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(0, 2),
  }
}));

const Layout = (props) => {
  const { children } = props;
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { isAuthenticated, userData, getProfileHandler, photo } = useContext(AuthContext);
  const [isToggle, setIsToggle] = useState(true);

  // Sidemenu
  const drawerOpenHandler = useCallback(() => {
    setIsToggle(!isToggle);
  }, [isToggle]);

  useEffect(() => {
    if (photo !== undefined) getProfileHandler(photo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isAuthenticated && (
        <React.Fragment>
          <Navbar toggle={drawerOpenHandler} />
          <SideDrawer open={isToggle} toggle={drawerOpenHandler} matches={matches} portalType={userData.role} />
        </React.Fragment>
      )}
      <main
        // className={classes.content}
        className={classes.content}
      >
        <div
          className={isAuthenticated ? classes.toolbar : null}
        // style={{ minHeight: '55px '}}
        />
        {isAuthenticated ? (
          <Container
            maxWidth="xl"
            className={classes.container}
          // style={{ position: 'relative', height: 'calc(100vh - 64px)', background: 'red', overflowY: 'scroll' }}
          >
            <Grid container>{props.children}</Grid>
          </Container>
        ) : (
          children
        )}
      </main>
    </div>
  );
};

export default Layout;
