import React, { useContext, useEffect } from 'react';

import { Container, Grid, Paper, Avatar, Typography, Divider } from '@material-ui/core';
import { useStyles } from './styles';
import { getAvataricon } from '../../../utils/getAvatarIcon';

import {  DashboardContext } from '../../../context/states/dashboardState';
import { BookingList } from '../../../components/Dashboard/BookingLists';
// import DashboardCard from '../../../components/pages/dashboard/DashboardCard';

const dashArr = [
  { name: 'New', cl: 'new', count: 23, icon: 'NoteIcon' },
  { name: 'Booking', cl: 'booking', count: 5, icon: 'BookIcon' },
  { name: 'Quotation', cl: 'quotation', count: 18, icon: 'FormatQuoteIcon' },
  { name: 'Confirmed', cl: 'confirmed', count: 75, icon: 'DoneIcon' },
  { name: 'Timesheet submitted', cl: 'timesheetSubmitted', count: 22, icon: 'ScheduleIcon' },
  { name: 'Approved', cl: 'approved', count: 100, icon: 'ThumbUpIcon' },
  { name: 'Paid', cl: 'paid', count: 400, icon: 'PaymentIcon' },
  { name: 'Quote supplied', cl: 'quoteSupplied', count: 150, icon: 'HelpOutlineIcon' },
  { name: 'Cancelled', cl: 'cancel', count: 75, icon: 'ClearIcon' }
]

 // Create new Date instance
 var date = new Date()
 // Add a day
 date.setDate(date.getDate() + 1)

const Dashboard = () => {
  // console.log('RENDER DASHBOARD');
  const classes = useStyles();
  const { getDashboardsHandler, loading, data, getTodayBookingsHandler, todayBookings, getTomorrowBookingsHandler, tomorrowBookings } = useContext(DashboardContext)

 
  useEffect(() => {
    getDashboardsHandler()
    getTodayBookingsHandler()
    // const tomorrowDate = '2022-09-05T13:32:00.000Z'
    const tomorrowDate = new Date(date).toISOString()
    getTomorrowBookingsHandler(tomorrowDate)
  }, [date])
  
  return (
    <React.Fragment>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
          >
            {
              data.map((d, i) => (
                <Grid item xs={12} md={2} key={i}>
                  <Paper elevation={1} className={classes.paper} title={d.name}>
                    <div className={classes.nameDiv}>
                      {/* <h6>{d.name}</h6> */}
                      <Typography variant="h6">{d.name}</Typography>
                      <Typography variant="h4" gutterBottom className={classes.count}>{d.count}</Typography>
                    </div>
                    <Avatar className={classes[d.cl]}>
                      {getAvataricon(d.icon)}
                    </Avatar>
                  </Paper>
                </Grid>
              ))
            }
            <Divider />

            <Grid item xs={12} md={12}>
              {!loading && <BookingList data={todayBookings} loading={loading} active={classes.currentDayBookings} title="Today's Bookings"/>}
            </Grid>

            <Grid item xs={12} md={12}>
            {!loading && <BookingList data={tomorrowBookings} loading={loading} active={classes.upcomingBookings} title="Tomorrow's Bookings"/>}
            </Grid>
          </Grid>
        </Container>
    </React.Fragment>
  );
};

export default Dashboard;
