import React from "react";

import DashboardIcon from "@material-ui/icons/Dashboard";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import BookIcon from "@material-ui/icons/Book";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FolderIcon from "@material-ui/icons/Folder";
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import TranslateIcon from "@material-ui/icons/Translate";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PolicyIcon from "@material-ui/icons/Policy";
import EuroIcon from "@material-ui/icons/Euro";

import RecentActorsIcon from "@material-ui/icons/RecentActors";
import PaymentIcon from "@material-ui/icons/Payment";
import PaymentSharpIcon from "@material-ui/icons/PaymentSharp";
import RepeatIcon from "@material-ui/icons/Repeat";
import AssessmentIcon from "@material-ui/icons/Assessment";

export const menu = [
	{
		icon: <DashboardIcon fontSize="small" />,
		title: "Dashboard",
		to: "/dashboard",
		items: [],
		user: ["admin", "superadmin", "ip-admin"],
		enabled: false,
	},
	{
		icon: <RecordVoiceOverIcon fontSize="small" />,
		title: "Interpreters",
		to: "/interpreterTracker",
		user: ["admin", "superadmin", "ip-admin"],
		enabled: false,
	},
	{
		icon: <BookIcon fontSize="small" />,
		title: "Bookings",
		to: "/BookingTracker",
		user: ["admin", "superadmin", "ip-admin"],
		enabled: false,
	},
	{
		icon: <FeaturedPlayListIcon fontSize="small" />,
		title: "Master",
		items: [
			{
				icon: <TranslateIcon fontSize="small" />,
				title: "Languages",
				to: "/masters/languages",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <GTranslateIcon fontSize="small" />,
				title: "Dialects",
				to: "/masters/dialects",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: null,
				title: "Rates",
				to: "/masters/rateStructures",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <BusinessCenterIcon fontSize="small" />,
				title: "Clients",
				to: "/masters/clients",
				user: ["superadmin", "admin", "ip-admin"],
				enabled: false,
			},
			{
				icon: <AssignmentIndOutlinedIcon fontSize="small" />,
				title: "Users",
				to: "/masters/users",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <PolicyIcon fontSize="small" />,
				title: "Terms",
				to: "/masters/termsAndConditions",
				user: ["superadmin"],
				enabled: false,
			},
		],
		user: ["superadmin", "admin", "ip-admin"],
		enabled: false,
	},
	{
		icon: <AccountBalanceWalletIcon fontSize="small" />,
		title: "Payments",
		items: [
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "Interpreter Dues",
				to: "/payments/interpreterdues",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <RepeatIcon fontSize="small" />,
				title: "Remittance Slips",
				to: "/payments/remittanceslips",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <PaymentIcon fontSize="small" />,
				title: "Client Payments",
				to: "/payments/clientpayments",
				user: ["superadmin", "ip-admin"],
				enabled: false,
			},
			{
				icon: <AssessmentIcon fontSize="small" />,
				title: "Outstanding/Over CPR",
				to: "/payments/outstandingCPR",
				user: ["superadmin"],
				enabled: false,
			},
		],
		user: ["superadmin", "ip-admin"],
		enabled: false,
	},
	{
		icon: <FolderIcon fontSize="small" />,
		title: "Reports",
		items: [
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "LIL Sales report",
				to: "/reports/statementOnAccount",
				user: ["superadmin", "ip-admin"],
				enabled: false,
			},
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "LIL Internal Audit Report",
				to: "/reports/accountStatement",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "LIL Invoice Report",
				to: "/reports/invoiceSummary",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "LIL Paid Report",
				to: "/reports/invoicePayment",
				user: ["superadmin"],
				enabled: false,
			},
			{
				icon: <RecentActorsIcon fontSize="small" />,
				title: "Booking Report",
				to: "/reports/booking",
				user: ["superadmin"],
				enabled: false,
			},
		],
		user: ["superadmin", "ip-admin"],
		enabled: false,
	},
];
