import React, { useMemo } from 'react';
import { TableToolbar } from '../Table/tableToolbar';

function MemoizedToolbar({ selected, title, isCheckbox, isEnable, openFormModalHandler, enableCheckboxHandler, refreshDataHandler, fetchAllDataHandler, count, setIsSearchObj, isSearchObj, clearAdvancedSearchHandler }) {
    return useMemo(() => {
        return (
            <TableToolbar
                numSelected={selected.length}
                tableTitle={title}
                isCheckbox={isCheckbox}
                isEnable={isEnable}
                handleFormModal={openFormModalHandler}
                handleCheckbox={enableCheckboxHandler}
                handleRefresh={refreshDataHandler}
                handleSearch={fetchAllDataHandler}
                listCount={count}
                isSearchObj={isSearchObj} setIsSearchObj={setIsSearchObj}
                clearAdvancedSearchHandler={clearAdvancedSearchHandler}
            />
        )
    }, [selected.length, title, isCheckbox, isEnable, openFormModalHandler, enableCheckboxHandler, refreshDataHandler, fetchAllDataHandler, count, setIsSearchObj, isSearchObj, clearAdvancedSearchHandler])
}

export default MemoizedToolbar;