import React, { useState, useContext } from 'react';
import moment from 'moment';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Tooltip } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { ClientContext } from '../../../../../../../context/states/clientState';
import { ViewFile } from '../../../../../../fileUpload/ViewFile';

const style = {
  heading: { fontWeight: 600, color: '#0000008A' },
  name: { width: '240px', textOverflow: 'ellipsis' },
  fileType: { width: '10%', fontSize: '12px', fontFamily: 'sans-serif' },
  description: { width: '50%', fontSize: '12px', fontFamily: 'sans-serif' },
  date: { width: '20%', fontSize: '12px', fontFamily: 'sans-serif' },
};
export const FileLists = (props) => {
  const { items } = props;

  const { getDocumentHandler, viewDoc } = useContext(ClientContext);

  const [isModal, setIsModal] = useState(false);
  const toggleModal = () => setIsModal(false);

  const openImageModal = (file) => {
    getDocumentHandler('doc', file);
    setIsModal(true);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={style.heading}>Documents</TableCell>
              <TableCell style={style.heading}>Type</TableCell>
              <TableCell style={style.heading}>Description</TableCell>
              <TableCell style={style.heading}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={12} style={{ textAlign: 'center', padding: '1rem 0' }}>
                  No Documents
                </TableCell>
              </TableRow>
            ) : (
              items.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row" style={style.name}>
                    <Tooltip title={row.orginalFile}>
                      <Chip
                        style={style.name}
                        label={row.orginalFile}
                        color="primary"
                        size="small"
                        icon={<AttachmentIcon />}
                        clickable
                        onClick={() => {
                          openImageModal(row.fileName);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell style={style.fileType}>{row.fileType}</TableCell>
                  <TableCell style={style.description}>{row.description}</TableCell>
                  <TableCell style={style.date}>{moment(row.updatedAt).format('dddd, MMMM Do YYYY, HH:mm:ss')}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ViewFile previewFile={viewDoc} open={isModal} handleClose={toggleModal} />
    </React.Fragment>
  );
};
