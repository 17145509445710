import { makeStyles, lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    rootM: {
        width: '100%',
        position: 'relative'
    },
    paper: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        // height: 'calc(70vh - 20px)',
    },
    container2: {
        // height: 'calc(80vh - 20px)',
    },
    table: {
        // minWidth: 800,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    heading: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        fontWeight: '600',
        fontSize: '11px',
    },
    pendingStage: {
        backgroundColor: lighten(theme.palette.warning.light, 0.9),
        margin: theme.spacing(1),
    },
    paidStage: {
        backgroundColor: lighten(theme.palette.info.light, 0.9),
        margin: theme.spacing(1),
    },
    confirmStage: {
        backgroundColor: lighten(theme.palette.success.light, 0.9),
        margin: theme.spacing(1),
    },
    crChip: {
        fontSize: '10px',
        fontWeight: 600,
        width: '100%',
        // padding: '4px 2px',
        padding: '3px',
        marginBottom: '1px',
        borderRadius: '4px',
        color: theme.palette.success.dark,
        backgroundColor: lighten(theme.palette.success.main, 0.7),
        cursor: 'pointer',
    }
}));
