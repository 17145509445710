import React from "react";
import { TableRow, TableCell, TableHead, TableSortLabel, Checkbox } from "@material-ui/core";
import PropTypes from "prop-types";

const headCells = [
	{ id: "Sl.No", numeric: false, disablePadding: true, label: "Sl No" },
	{ id: "BookingRef", numeric: true, disablePadding: true, label: "Booking Reference" },
	{ id: "dateOfAttendance", numeric: true, disablePadding: false, label: "Date of Booking" },
	{ id: "language", numeric: true, disablePadding: false, label: "Language" },
	{ id: "service", numeric: true, disablePadding: false, label: "Service" },
	{ id: "company", numeric: true, disablePadding: false, label: "Company" },
	{ id: "name", numeric: true, disablePadding: false, label: "Name" },
	{ id: "fileRef", numeric: true, disablePadding: false, label: "File Reference" },
	{ id: "clientName", numeric: true, disablePadding: false, label: "Client Name" },
	{ id: "totalAmount", numeric: true, disablePadding: false, label: "Amount" },
];

export const EnhancedTableHead = (props) => {
	const { classes, order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ "aria-label": "select all desserts" }}
						color="primary"
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
							style={{ fontWeight: "bolder" }}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
