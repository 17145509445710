import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
// import { DevTool } from '@hookform/devtools';
import { FileUploads } from '../../../../../fileUpload/UploadFiles/FileUploads';
import { ViewFile } from '../../../../../fileUpload/ViewFile';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  TextField,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  Button,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import NoDocument from '../../../../../icons/NoDocument'

import Notes from '../../../../../Notes';

import { UserContext } from '../../../../../../context/states/userState';
import { FileContext } from '../../../../../../context/states/fileState';
import { ProfileImage } from '../../../../../fileUpload/ProfileImage';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
  tableCell: {
    fontWeight: 600,
    backgroundColor: theme.palette.action.selected,
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  dateDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  }
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'calc(100% - 20rem)',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
let cTab;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  cTab = value;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      style={{ width: '90%' }}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} pt={0}>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {

  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const EditUser = React.memo((props) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { open, handleClose } = props;
  const { load, user, updatedUserHandler, notes, addNotesHandler, fileList, getDocumentHandler, viewDoc, getProfileHandler, profileImage, uploadProImageHandler } = useContext(UserContext);
  const { handleFiles, unsupportedFiles, validFiles, removeFile, percentage, message, uploadUserDocumentHandler, deleteDocumentHandler } = useContext(FileContext);
  // console.log('fileuploads', fileList)

  const formValue = {
    isActive: user.isActive
  };

  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: formValue
  });

  const [isPasswordToggle, setIsPasswordToggle] = useState(false);
  const [isPassword, setisPassword] = useState({ showPassword: false });
  // upload button
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOpen = () => setIsOpen(true);
  const handleModalClose = () => setIsOpen(false);
  const [isModal, setIsModal] = useState(false);
  const [detail, setDetail] = useState('detail')
  const toggleModal = () => setIsModal(false);

  const handleClickShowPassword = () => setisPassword({ ...isPassword, showPassword: !isPassword.showPassword });
  const handleMouseDownPassword = (event) => event.preventDefault();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Watch variable;
  const watchLeaveTotal = watch('leaveTotal');
  const watchLeaveTaken = watch('leaveTaken');
  const watchSickLeaveTotal = watch('sickLeaveTotal');
  const watchSickLeaveTaken = watch('sickLeaveTaken');
  const watchIsActive = watch ('isActive');

  const calcLeaveHandler = useCallback(() => {
    let leave = 0;
    leave = watchLeaveTotal - watchLeaveTaken
    
    return isNaN(leave) ? 0 : leave
  }, [watchLeaveTotal, watchLeaveTaken, open])

  const calcSickLeaveHandler = useCallback(() => {
    let leave = 0;
    leave = watchSickLeaveTotal - watchSickLeaveTaken
    return isNaN(leave) ? 0 : leave
  }, [watchSickLeaveTotal, watchSickLeaveTaken, open])

  const openImageModal = useCallback(
    (file) => {
      getDocumentHandler('doc', file);
      setIsModal(true);
    },
    [getDocumentHandler]
  );

  const fetchData = async () => {
    try {
      if (user.photo) await getProfileHandler(user.photo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [load])

  const saveNotesHandler = useCallback((noteData) => {
    try {
      noteData.isActive = watchIsActive === undefined ? user?.isActive : watchIsActive
      noteData.text = `[${watchIsActive ? 'Activate' : 'Deactivated' }] - ${noteData.text}`
      // console.log('note added:', noteData)
      addNotesHandler(noteData)
    } catch (err) {
        console.log('note error', err)
    }
  }, [watchIsActive, user])

  const onSubmit = async (data) => {
    data.leaveBalance = data.leaveTotal - data.leaveTaken
    try {
      await updatedUserHandler(data);
      await setIsPasswordToggle(false);
      await handleClickShowPassword();
    } catch (error) {
      console.log('user update error:', error)
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Typography variant="h6" gutterBottom>
              {!load ? <>{user.fullName}</> : <small>Loading...</small>}
            </Typography>
            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes2.root}>
          {load && user !== {} ? (
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item xs={12}>
                <CircularProgress style={{ margin: '5rem' }} />
              </Grid>
            </Grid>
          ) : (<>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes2.tabs}
            >
              <Tab label="User Details" {...a11yProps(0)} onClick={() => setDetail('detail')} />
              <Tab label="Notes" {...a11yProps(1)} onClick={() => setDetail('note')} />
              <Tab label="Documents" {...a11yProps(2)} onClick={() => setDetail('doc')} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper className={classes.paper} variant="outlined">
                    <Grid container direction="row" justify="space-around" alignItems="flex-start">
                      <Grid item xs={9}>
                        <ProfileImage classes={classes} loading={false} refId={user._id} uploadApi={uploadProImageHandler} img={profileImage} isAccessTo={false} />
                      </Grid>
                      <Grid item xs={3}>
                        <div className={classes.dateDiv}>
                          {!user?.isActive && <span style={{ color: '#ff0000'}}><strong>Deactivated:</strong> {moment(user?.deactivedAt).format('DD/MM/Y')}</span>}
                        </div>
                      </Grid>
                      {/* <Grid item xs={2}>
                        <FormControlLabel
                          inputRef={register}
                          control={<Checkbox name="isActive" color="primary" defaultChecked={user !== undefined ? user.isActive : false} />}
                          label="Active"
                        />
                      </Grid> */}
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                      <Paper className={classes.paper} variant="outlined">
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                            User Details
                          </Typography>

                          <input type="hidden" name="_id" ref={register} defaultValue={user !== undefined ? user._id : ''} />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={5} md={4}>
                              <TextField
                                id="fore-name"
                                label="Fore Name"
                                size="small"
                                fullWidth
                                name="fName"
                                variant="outlined"
                                inputRef={register({
                                  required: true,
                                  minLength: { value: 2, message: 'Must have at least 2 letters' },
                                  maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                                })}
                                error={!!errors.fName}
                                helperText={errors.fName && errors.fName.message}
                                defaultValue={user !== undefined ? user.fName : ''}
                                autoFocus
                              />
                            </Grid>

                            <Grid item xs={12} sm={5} md={4}>
                              <TextField
                                id="last-name"
                                label="Last Name"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="lName"
                                inputRef={register({
                                  required: true,
                                  maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                                })}
                                error={!!errors.lName}
                                helperText={errors.lName && errors.lName.message}
                                defaultValue={user !== undefined ? user.lName : ''}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl variant="outlined" size="small" className={classes.formControl}>
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Controller
                                  as={
                                    <Select labelId="gender-label" id="interpreter-gender">
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={'male'}>Male</MenuItem>
                                      <MenuItem value={'female'}>Female</MenuItem>
                                      <MenuItem value={'other'}>Other</MenuItem>
                                    </Select>
                                  }
                                  control={control}
                                  name="gender"
                                  rules={{ required: true }}
                                  error={!!errors.gender}
                                  labelWidth={55}
                                  defaultValue={user !== undefined ? user.gender : ''}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl variant="outlined" size="small" className={classes.formControl}>
                                <InputLabel id="role-label">Role</InputLabel>
                                <Controller
                                  as={
                                    <Select labelId="role-label" id="user-role" defaultValue="user">
                                      <MenuItem value={'admin'}>Admin</MenuItem>
                                      <MenuItem value={'ip-admin'}>
                                        IP Admin
                                      </MenuItem>
                                      <MenuItem value={'superadmin'}>Super Admin</MenuItem>
                                    </Select>
                                  }
                                  control={control}
                                  name="role"
                                  rules={{ required: true }}
                                  error={!!errors.role}
                                  labelWidth={55}
                                  defaultValue={user !== undefined ? user.role : ''}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Email"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="email"
                                inputRef={register({
                                  required: true,
                                  minLength: { value: 5, message: 'Must have at least 5 letters' },
                                  maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                                  pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<Grid>()w[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid email-id',
                                  },
                                })}
                                error={!!errors.email}
                                helperText={errors.email && errors.email.message}
                                defaultValue={user !== undefined ? user.email : ''}
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label="Position"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="position"
                                inputRef={register({
                                  required: true,
                                  minLength: { value: 2, message: 'Must have at least 2 letters' },
                                  maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                                })}
                                error={!!errors.position}
                                helperText={errors.position && errors.position.message}
                                defaultValue={user !== undefined ? user.position : ''}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                            <Controller
                            as={
                              <KeyboardDatePicker
                                autoOk
                                format="dd/MM/yyyy"
                                label="Date of Join"
                                size="small"
                                variant="inline"
                                fullWidth
                                inputVariant="outlined"
                                error={!!errors.createdAt}
                                helperText={errors.createdAt && errors.createdAt.message}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />
                            }
                            control={control}
                            name="createdAt"
                            rules={{ required: true }}
                            error={!!errors.createdAt}
                            defaultValue={user?.createdAt}
                          />
                            </Grid>

                            {isPasswordToggle && (
                              <Grid item xs={12} md={4}>
                                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small" fullWidth>
                                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={isPassword.showPassword ? 'text' : 'password'}
                                    name="password"
                                    inputRef={register({
                                      required: true,
                                      minLength: { value: 4, message: 'Password must have at least 4 letters' },
                                      maxLength: { value: 30, message: 'Password maximum characters limit is 30' },
                                    })}
                                    error={!!errors.password}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {isPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    labelWidth={70}
                                    defaultValue={user !== undefined ? user.password : ''}
                                  />
                                  <FormHelperText style={{ color: 'red' }}>{errors.password && errors.password.message}</FormHelperText>
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>

                      <Button color={!isPasswordToggle ? 'primary' : 'secondary'} onClick={(e) => setIsPasswordToggle(!isPasswordToggle)} size="small">
                        {!isPasswordToggle ? 'Reset Password' : 'Cancel Password Reset'}
                      </Button>

                      <Paper className={classes.paper} variant="outlined">
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                            Leave
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Total"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="leaveTotal"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                type="number"
                                error={!!errors.leaveTotal}
                                helperText={errors.leaveTotal && errors.leaveTotal.message}
                                defaultValue={user?.leaveTotal}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Taken"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="leaveTaken"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                type="number"
                                error={!!errors.leaveTaken}
                                helperText={errors.leaveTaken && errors.leaveTaken.message}
                                defaultValue={user?.leaveTaken}

                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Balance"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="leaveBalance"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                error={!!errors.leaveBalance}
                                helperText={errors.leaveBalance && errors.leaveBalance.message}
                                inputProps={
                                  { readOnly: true, }
                                }
                                value={calcLeaveHandler()}
                              />
                            </Grid>

                          </Grid>
                        </Grid>
                      </Paper>

                      <Paper className={classes.paper} variant="outlined">
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                            Sick Leave
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Total"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="sickLeaveTotal"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                type="number"
                                error={!!errors.sickLeaveTotal}
                                helperText={errors.sickLeaveTotal && errors.sickLeaveTotal.message}
                                defaultValue={user?.sickLeaveTotal}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Taken"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="sickLeaveTaken"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                type="number"
                                error={!!errors.sickLeaveTaken}
                                helperText={errors.sickLeaveTaken && errors.sickLeaveTaken.message}
                                defaultValue={user?.sickLeaveTaken}

                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <TextField
                                label="Balance"
                                size="small"
                                fullWidth
                                variant="outlined"
                                name="sickLeaveBalance"
                                inputRef={register({
                                  required: true,
                                  pattern: {
                                    value: /^[0-9]\d*(\.\d+)?$/,
                                    message: 'Invalid number',
                                  }
                                })}
                                error={!!errors.sickLeaveBalance}
                                helperText={errors.sickLeaveBalance && errors.sickLeaveBalance.message}
                                inputProps={
                                  { readOnly: true, }
                                }
                                value={calcSickLeaveHandler()}
                              />
                            </Grid>

                          </Grid>
                        </Grid>
                      </Paper>
                    </form>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
            <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
              User Status
            </Typography>
              <FormControlLabel
                inputRef={register}
                control={<Checkbox name="isActive" color="primary" defaultChecked={user?.isActive || false} />}
                label="Active"
              />
              <Notes onSave={saveNotesHandler} notes={notes} data={user} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<CloudUploadIcon />}
                    onClick={handleClickOpen}
                    disabled={false}
                  >
                    Uploads
                  </Button>
                  <FileUploads
                    type="userFile"
                    id={user._id}
                    open={isOpen}
                    handleClose={handleModalClose}
                    handleFiles={handleFiles}
                    validFiles={validFiles}
                    unsupportedFiles={unsupportedFiles}
                    handleOnSubmit={uploadUserDocumentHandler}
                    handleRemove={removeFile}
                    percentage={percentage}
                    message={message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                    Documents
                  </Typography>

                  {fileList?.length === 0 ?
                    <NoDocument />
                    : <List style={{ height: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
                      {
                        fileList?.map((d) => {
                          return (
                            <ListItem button key={d.fileName} onClick={() => openImageModal(d.fileName)} filetype={d.fileType}>
                              <ListItemAvatar>
                                <Avatar>
                                  <AttachmentIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="caption" display="block" gutterBottom>
                                    {d.orginalFile}
                                  </Typography>
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteDocumentHandler({ id: user._id, fileName: d.fileName })}>
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })
                          .filter((d) => d.props.filetype === "userFile")
                      }
                    </List>}
                </Grid>
              </Grid>
            </TabPanel>
          </>)}
        </DialogContent>
        {detail === 'detail' &&
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
          </DialogActions>}
      </Dialog>

      {isModal && <ViewFile previewFile={viewDoc} open={isModal} handleClose={toggleModal} />}
    </React.Fragment >
  );
});
