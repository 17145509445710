import React from 'react';

import Grid from '@material-ui/core/Grid';
import { LanguageProvider } from '../../../context/states/languageState';
import { Languages } from '../../../components/pages/masters/Languages/Languages';

const LanguageMasters = () => {
  return (
    <React.Fragment>
      <LanguageProvider>
        <Grid item xs>
          <Languages title="Languages" />
        </Grid>
      </LanguageProvider>
    </React.Fragment>
  );
};

export default LanguageMasters;
