import React, { useMemo } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Card, CardContent, Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { TabPanel } from '../../../tabPanel/TabPanel';
import { PersonalDetails } from './interpreterFields/PersonalDetails';
import { Qualifications } from './interpreterFields/Qualifications';
import { Notes } from './interpreterFields/Notes';
import { Rates } from './interpreterFields/Rates';
import { Languages } from './interpreterFields/Languages';
import { Hours } from './interpreterFields/Hours';

import AuthenticatedRoute from '../../../../middlewares/AuthenticatedRoute';
import { IBookings } from '../../../../components/pages/ibookings/IBookings';
import { Invoice } from '../../../../components/includes/Invoice/Invoice';
import { RemittanceLists } from '../../../../components/pages/payments/iRemittance/RemittanceLists'

import { TimesheetProvider } from '../../../../context/states/timeSheetState';
import { FileProvider } from '../../../../context/states/fileState';
import { InvoiceProvider } from '../../../../context/states/invoiceState';
import { RemittanceProvider } from '../../../../context/states/remittanceState';

export const EditForm = React.memo((props) => {
  const { classes, interpreter, handleBack, handleTabValue, handleGetProfileImage, intID, isAccessTo, username, role } = props;
  let { path } = useRouteMatch();
  // const { id } = useParams();

  const fetchData = async () => {
    try {
      if (interpreter.photo) await handleGetProfileImage(interpreter.photo);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, []);

  const personalDetailForm = useMemo(() => <PersonalDetails classes={classes} data={interpreter} handleClose={handleBack} isAccessTo={isAccessTo} username={username} />, [
    classes,
    interpreter,
    handleBack,
    isAccessTo,
    username
  ]);

  const languageForm = useMemo(() => <Languages classes={classes} data={interpreter} handleClose={handleBack} isAccessTo={isAccessTo} />, [classes, interpreter, handleBack, isAccessTo]);

  const qualificationForm = useMemo(() => <Qualifications classes={classes} data={interpreter} handleClose={handleBack} isAccessTo={isAccessTo} />, [
    classes,
    interpreter,
    handleBack,
    isAccessTo
  ]);

  const notesForm = useMemo(() => <Notes classes={classes} data={interpreter} handleClose={handleBack} isAccessTo={isAccessTo} username={username} role={role} />, [classes, interpreter, handleBack, isAccessTo, username, role]);

  const ratesForm = useMemo(() => <Rates classes={classes} data={interpreter} handleClose={handleBack} isAccessTo={isAccessTo} />, [classes, interpreter, handleBack, isAccessTo]);
  const payments = <RemittanceLists adminUser={false} />
  return (
    <>
      <Card >
        <CardContent style={{ padding: '10px 6px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TabPanel value={handleTabValue} index={0}>
              {/* <PersonalDetails classes={classes} data={interpreter} handleClose={handleBack} /> */}
              {personalDetailForm}
            </TabPanel>
            <TabPanel value={handleTabValue} index={1}>
              {/* <Languages classes={classes} data={interpreter} handleClose={handleBack} /> */}
              {languageForm}
            </TabPanel>
            <TabPanel value={handleTabValue} index={2}>
              {/* <Qualifications classes={classes} data={interpreter} handleClose={handleBack} /> */}
              {qualificationForm}
            </TabPanel>
            <TabPanel value={handleTabValue} index={3}>
              {/* <Notes classes={classes} data={interpreter} handleClose={handleBack} /> */}
              {notesForm}
            </TabPanel>
            <TabPanel value={handleTabValue} index={4}>
              {/* <Rates classes={classes} data={interpreter} handleClose={handleBack} /> */}
              {ratesForm}
            </TabPanel>
            <TabPanel value={handleTabValue} index={5}>
              <RemittanceProvider>
                {payments}
              </RemittanceProvider>
            </TabPanel>
            <TabPanel value={handleTabValue} index={6}>
              <Hours data={interpreter}/>
            </TabPanel>
            <TabPanel value={handleTabValue} index={7}>
              <TimesheetProvider>
                <FileProvider>
                  <InvoiceProvider>
                    <Grid item xs={12}>
                      <Switch>
                        <AuthenticatedRoute exact path={path}>
                          <IBookings title="Timesheet Tracker" intID={intID} interpreterTab={true} />
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={`${path}/invoice/:id/:iType/:clientInvoice`}>
                          <Invoice />
                        </AuthenticatedRoute>
                      </Switch>
                    </Grid>
                  </InvoiceProvider>
                </FileProvider>
              </TimesheetProvider>
            </TabPanel>
          </MuiPickersUtilsProvider>
        </CardContent>
      </Card>
    </>
  );
});
