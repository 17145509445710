import React, { createContext, useReducer, useContext } from 'react';
import TimesheetReducer from '../reducers/timeSheetReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  load: false,
  error: null,
  timesheets: [],
  total: 0,
  count: 0,
  timesheet: {},
  isFileUpload: false,
  fileList: [],
  docLoad: false,
  viewDoc: '',

};

// creating context
export const TimesheetContext = createContext(initialState);

// provider component
export const TimesheetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TimesheetReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  const getTimesheetsHandler = async (int, data) => {
    dispatch({ type: actionTypes.PROCESS_START });

    try {
      // const int = localStorage.getItem('intID');
      const result = await axios.get(urlRequest.Timesheets + `?interpreter=${int}&isBooked=${true}` + data);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const getTimesheetHandler = async (id) => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(urlRequest.Bookings + `/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const updateTimesheetHandler = async (newTimesheet) => {
    try {
      const result = await axios.put(urlRequest.Timesheet + newTimesheet.id, newTimesheet);
      dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.timesheet });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Timesheet Updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const getDocumentHandler = async (path, filename) => {
    dispatch({ type: actionTypes.DOC_LOAD });
    try {
      const result = await axios.get(`${urlRequest.Bookings}/${path}/${filename}`);
      dispatch({ type: actionTypes.GET_DOCUMENT_IMAGE, payload: result.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <TimesheetContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        data: state.timesheets,
        total: state.total,
        count: state.count,
        load: state.load,
        timesheet: state.timesheet,
        viewDoc: state.viewDoc,
        fileList: state.fileList,
        docLoad: state.docLoad,
        isFileUpload: state.isFileUpload,
        getTimesheetsHandler,
        getTimesheetHandler,
        updateTimesheetHandler,
        timesheetFileDispatch: dispatch,
        getDocumentHandler,
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};
