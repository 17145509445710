import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem } from '@material-ui/core';

import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  isActive: {
    color: theme.palette.text.primary,
    backgroundColor: lighten(theme.palette.primary.light, 0.75),
    borderRadius: '4px',
  },
  isLink: {
    marginTop: theme.spacing(0.2),
    marginBottom: theme.spacing(0.2),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
}));

export const ListItemLink = (props) => {
  const classes = useStyles();

  return <ListItem button component={NavLink} {...props} className={classes.isLink} activeClassName={classes.isActive} />;
};
