import React, { useContext, useEffect } from 'react';
import axios from './axios';
import { withRouter } from 'react-router-dom';

import { AlertContext } from './context/states/alertState';
import { AuthContext } from './context/states/authState';
import withErrorHandler from './middlewares/withErrorHandler';
import Layout from './layouts/Layout';
import Routes from './Routes';
// import Routes2 from './Routes2';

import { Snackbar, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

function App() {
  const { open, message, alertSnackbarCloseHandle } = useContext(AlertContext);
  const { token } = useContext(AuthContext);

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  // useEffect(() => {
  //   return () => {

  //   window.addEventListener("beforeunload", function(e) {
  //     let confirmationMessage = "hey";
  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('user');
  //     localStorage.removeItem('intID');
  //     localStorage.removeItem('interpreterRef');
  //     localStorage.removeItem('loggedIn');
  //     console.log("logout !");
  //     return confirmationMessage; //Webkit, Safari, Chrome
  //   });
  //  }

  // });

  useEffect(() => {
    console.log('beforeun')
    if(process.env.REACT_APP_MODE !== 'prod') return
    window.addEventListener('beforeunload', keepOnPage);
    return () => {

      if(process.env.REACT_APP_MODE !== 'prod') return
      window.removeEventListener('beforeunload', keepOnPage);
    }
  }, [window])

  function keepOnPage(e) {
    var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    e.returnValue = message;

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('intID');
    localStorage.removeItem('interpreterRef');
    localStorage.removeItem('loggedIn');
    return message;
  }

  return (
    <React.Fragment>
      <Layout>
        <Routes />
      </Layout>
      {open &&
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          message={message}
          autoHideDuration={3000}
          onClose={alertSnackbarCloseHandle}
          TransitionComponent={SlideTransition}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={alertSnackbarCloseHandle}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />}
    </React.Fragment>
  );
}

export default withErrorHandler(withRouter(App), axios);
