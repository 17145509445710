import React, { createContext, useReducer, useEffect, useContext, useCallback } from 'react';
import FileReducer from '../reducers/fileReducer';
import axios from '../../axios';
import * as urlRequest from '../urls';

import { AlertContext } from './alertState';
import { BookingContext } from './bookingState';
import { InterpreterContext } from './interpreterState';
import { ClientContext } from './clientState';
import { TimesheetContext } from './timeSheetState';
import { UserContext } from './userState';

// InitialState
const initialState = {
  selectedFiles: [],
  validFiles: [],
  unsupportedFiles: [],
  fileList: [],
  percentage: 0,
  message: '',
};

// creating context
export const FileContext = createContext(initialState);

// provider component
export const FileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FileReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);
  const { fileDispatch } = useContext(BookingContext);
  const { docDispatch } = useContext(InterpreterContext);
  const { clientDocDispatch } = useContext(ClientContext);
  const { timesheetFileDispatch } = useContext(TimesheetContext);
  const { userDocsDispatch } = useContext(UserContext)

  useEffect(() => {
    let mounted = true;

      let filteredArray = state.selectedFiles.reduce((file, current) => {
        const x = file.find((item) => item.name === current.name);
        if (!x) {
          return file.concat([current]);
        } else {
          return file;
        }
      }, []);
      if (mounted) {
        dispatch({ type: 'VALID_FILES', payload: filteredArray });
      }

    return () => mounted = false;
  }, [state.selectedFiles]);

  const handleFiles = (files) => {
    dispatch({ type: 'PROCESS_BEGIN' });
    for (let i = 0; i < files.length; i++) {
      // checking valid types
      if (validateFile(files[i])) {
        dispatch({ type: 'FILES_SELECTED', payload: files[i] });
      } else {
        files[i]['invalid'] = true;
        dispatch({ type: 'FILES_INVALID', payload: files[i] });
        dispatch({ type: 'FILES_ERROR', payload: 'File type not premitted' });
        dispatch({ type: 'FILES_UNSUPPORTED', payload: files[i] });
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'audio/mpeg',
      'audio/AMR',
      'audio/AMR-WB',
      'audio/amr-wb+',
      'application/audio',
      'application/video',
      'video/mp4',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
    ];
    console.log(file.type);
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = useCallback(
    (name) => {
      // find the index of the item
      // remove the item from array
      const validFileIndex = state.validFiles.findIndex((e) => e.name === name);
      state.validFiles.splice(validFileIndex, 1);

      const selectedFileIndex = state.selectedFiles.findIndex((e) => e.name === name);
      state.selectedFiles.splice(selectedFileIndex, 1);

      dispatch({ type: 'REMOVE_VALID_FILES', payload: validFileIndex });
      dispatch({ type: 'REMOVE_VALID_FILES', payload: selectedFileIndex });
      const unsupportedFileIndex = state.unsupportedFiles.findIndex((e) => e.name === name);
      if (unsupportedFileIndex !== -1) {
        state.unsupportedFiles.splice(unsupportedFileIndex, 1);
        dispatch({ type: 'REMOVE_VALID_FILES', payload: unsupportedFileIndex });
      }
    },
    [state.validFiles, state.selectedFiles, state.unsupportedFiles]
  );

  // // Booking and timesheet uploads
  // const uploadFiles = async (data, role) => {
  //   dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
  //   timesheetFileDispatch({ type: 'START_DOCUMENT_UPLOAD'});

  //   for (let i = 0; i < state.validFiles.length; i++) {
  //     const formData = new FormData();
  //     formData.append('file', state.validFiles[i]);
  //     formData.append('type', data.type);
  //     formData.append('comment', data.comment);

  //     axios
  //       .post(urlRequest.BookingDocUploads + `/${data.id}`, formData, {
  //         onUploadProgress: (progress) => {
  //           const { loaded, total } = progress;
  //           const percentageProgress = Math.floor((loaded / total) * 100);
  //           dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });

  //           if (percentageProgress === 100) {
  //             dispatch({ type: 'FILES_UPLOADED' });
  //             state.validFiles.length = 0;
  //           }
  //         },
  //       })
  //       .then((result) => {
  //         // fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
  //         //   dispatch({ type: 'UPLOADED_FILES', payload: result.data });
  //         if (result.data.data.fileType === 'bookingFile') {
  //           fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
  //         } else if (result.data.data.fileType === 'timesheetFile') {
  //           if (role === 'interpreter' || role === 'superadmin') {
  //             console.log('fileupload result', result.data.data.fileType, result)
  //             console.log('file role', role, result.data.data.fileType);
  //             timesheetFileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });

  //           } else {
  //             fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
  //           }
  //         }

  //         if (result.data.success) {
  //           alertDispatch({ type: 'open', message: 'Document uploaded' });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('file upload error', err)
  //         dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
  //       });
  //   }
  // };

  const uploadFiles = async (data, role) => {
    dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
    timesheetFileDispatch({ type: 'START_DOCUMENT_UPLOAD'});
  
    const uploadRequests = state.validFiles.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', data.type);
      formData.append('comment', data.comment);
  
      try {
        const response = await axios.post(
          `${urlRequest.BookingDocUploads}/${data.id}`,
          formData,
          {
            onUploadProgress: (progress) => {
              const { loaded, total } = progress;
              const percentageProgress = Math.floor((loaded / total) * 100);
              dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });
  
              if (percentageProgress === 100) {
                dispatch({ type: 'FILES_UPLOADED' });
              }
            },
          }
        );
  
        const { data: { data: { fileType } }, success } = response;
  
        if (fileType === 'bookingFile') {
          fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: response.data.data });
        } else if (fileType === 'timesheetFile' && (role === 'interpreter' || role === 'superadmin')) {
          console.log('fileupload result', fileType, response);
          console.log('file role', role, fileType);
          timesheetFileDispatch({ type: 'DOCUMENT_UPLOAD', payload: response.data.data });
        } else {
          fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: response.data.data });
        }
  
        if (success) {
          alertDispatch({ type: 'open', message: 'Document uploaded' });
        }
      } catch (error) {
        console.log('file upload error', error);
        dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
      }
    });
  
    try {
      await Promise.all(uploadRequests);
      // Clear validFiles array
      state.validFiles.length = 0;
    } catch (error) {
      console.log('Error in one or more upload requests:', error);
    }
  };
  

  // new form uploads
  const newUploadFiles = (data) => {
    dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
    for (let i = 0; i < state.validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', state.validFiles[i]);
      formData.append('type', data.type);

      axios
        .post(urlRequest.BookingNewDocUploads, formData, {
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });

            if (percentageProgress === 100) {
              dispatch({ type: 'FILES_UPLOADED' });
              state.validFiles.length = 0;
            }
          },
        })
        .then((result) => {
          console.log(result.data);
          // fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          //   dispatch({ type: 'UPLOADED_FILES', payload: result.data });

          if (result.data.data.fileType === 'bookingFile') {
            fileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          } else if (result.data.data.fileType === 'timesheetFile') {
            timesheetFileDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          }

          if (result.data.success) {
            alertDispatch({ type: 'open', message: 'Document uploaded' });
          }
        })
        .catch(() => {
          dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
        });
    }
  };

  // Interpreter document upload handler
  const uploadDocumentHandler = (file) => {
    dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
    for (let i = 0; i < state.validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', state.validFiles[i]);
      formData.append('type', file.type);
      axios
        .put(`${urlRequest.Interpreters}/${file.id}/doc`, formData, {
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });

            if (percentageProgress === 100) {
              dispatch({ type: 'FILES_UPLOADED' });
              state.validFiles.length = 0;
            }
          },
        })
        .then((result) => {
          // console.log(result.data.data);
          docDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          //   dispatch({ type: 'UPLOADED_FILES', payload: result.data });
          if (result.data.success) {
            alertDispatch({ type: 'open', message: 'Document uploaded' });
          }
        })
        .catch(() => {
          dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
        });
    }
  };

  // Client document upload handler
  const uploadClientDocumentHandler = (file) => {
    console.log(file);
    dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
    for (let i = 0; i < state.validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', state.validFiles[i]);
      formData.append('type', file.type);
      formData.append('description', file.description);
      axios
        .put(`${urlRequest.Clients}/${file.id}/doc`, formData, {
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });

            if (percentageProgress === 100) {
              dispatch({ type: 'FILES_UPLOADED' });
              state.validFiles.length = 0;
            }
          },
        })
        .then((result) => {
          console.log(result.data.data);
          clientDocDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          if (result.data.success) {
            alertDispatch({ type: 'open', message: 'Document uploaded' });
          }
        })
        .catch(() => {
          dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
        });
    }
  };

  const bookingDocumentDeleteHandler = async (data) => {
    // console.log(data);
    try {
      const result = await axios.put(urlRequest.Bookings + '/deletefile', data);
      await dispatch({ type: 'DELETE_FILE', payload: result.data });
      await fileDispatch({ type: 'DOCUMENT_DELETED', payload: data.filename });
      await alertDispatch({ type: 'open', message: result.data.msg });
    } catch (error) {
      console.log(error);
      dispatch({ type: 'FILES_ERROR', payload: error });
    }
  };

  const interpreterFileDeleteHandler = async (data) => {
    try {
      const result = await axios.put(urlRequest.Interpreters + '/deletefile', data);
      await dispatch({ type: 'DELETE_FILE', payload: result.data });
      await docDispatch({ type: 'DOCUMENT_DELETED', payload: data.filename });
      await alertDispatch({ type: 'open', message: result.data.msg });
    } catch (error) {
      console.log(error);
      dispatch({ type: 'FILES_ERROR', payload: error });
    }
  }

  const deleteDocumentHandler = async (data) => {
    try {
      const result = await axios.put(`${urlRequest.User}/deletefile`, data);
      dispatch({ type: 'DELETE_FILE', payload: result.config.url });
      userDocsDispatch({ type: 'DOCUMENT_DELETED', payload: data.fileName })
    } catch (error) {
      console.log(error);
      dispatch({ type: 'FILES_ERROR', payload: error });
    }
  }

  // Interpreter document upload handler
  const uploadUserDocumentHandler = (file) => {
    dispatch({ type: 'UPLOADING_START', payload: 'Files(s) Uploading...' });
    for (let i = 0; i < state.validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', state.validFiles[i]);
      formData.append('type', file.type);
      axios
        .put(`${urlRequest.User}/${file.id}/doc`, formData, {
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({ type: 'PERCENTAGE_PROGRESS', payload: percentageProgress });

            if (percentageProgress === 100) {
              dispatch({ type: 'FILES_UPLOADED' });
              state.validFiles.length = 0;
            }
          },
        })
        .then((result) => {
          console.log('fileuploads', result.data.data);
          userDocsDispatch({ type: 'DOCUMENT_UPLOAD', payload: result.data.data });
          if (result.data.success) {
            alertDispatch({ type: 'open', message: 'Document uploaded' });
          }
        })
        .catch(() => {
          dispatch({ type: 'FILES_ERROR', payload: 'Error Uploading File(s)' });
        });
    }
  };

  return (
    <FileContext.Provider
      value={{
        percentage: state.percentage,
        selectedFiles: state.selectedFiles,
        unsupportedFiles: state.unsupportedFiles,
        validFiles: state.validFiles,
        fileList: state.fileList,
        message: state.message,
        handleFiles,
        uploadFiles,
        newUploadFiles,
        removeFile,
        uploadDocumentHandler,
        uploadClientDocumentHandler,
        bookingDocumentDeleteHandler,
        interpreterFileDeleteHandler,
        uploadUserDocumentHandler,
        deleteDocumentHandler
      }}
    >
      {children}
    </FileContext.Provider>
  );
};
