import React, { useMemo } from 'react';
import { TableHeader } from '../Table/tableHeader';

function MemoizedHeader({ headCells, selected, order, orderBy, handleSelectAllClick, handleRequestSort, data }) {
    return useMemo(() => {
        return (
            <TableHeader
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
            />
        )
    }, [headCells, selected.length, order, orderBy, handleSelectAllClick, handleRequestSort, data.length])
}

export default MemoizedHeader;