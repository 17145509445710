import React from 'react';

import { lighten, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Toolbar, Typography, TextField, FormControlLabel, Checkbox, AppBar, Button, IconButton, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
// import PropTypes from 'prop-types';

const useToolbarStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,

  },
  toolbarMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  secondSession: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  clientTotalDiv: {
    background: '#fafafa',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '0.78em',
    fontSize: '12px',
    alignItems: 'flex-end',
    margin: '10px',
    '& span': {
      marginRight: '1em',
      backgroundColor: lighten(theme.palette.info.light, 0.7),
      padding: '10px 6px',
      borderRadius: 4
    },
    '& .nagativeAmount': {
      marginRight: '1em',
      backgroundColor: lighten(theme.palette.secondary.light, 0.7),
      padding: '10px 6px',
      borderRadius: 4
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginBottom: '6px',
      lineHeight: '1.4em',
      fontSize: 10
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '80%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, handleChange, handleChangeAutoComplete, values, isClients, handleInputChange, handleFromDateChange, handleToDateChange, searchHandler, totalPaidAmount, totalAcAmount, totalIpAmount, clearSearchHandler, totalvat, totalService, lilAmount } = props;
  const toatlBillAmount = totalAcAmount - totalIpAmount;
  return (
    <div className={classes.grow}>
      <AppBar position="sticky" color="default" elevation={1}>
        <Toolbar className={classes.toolbarMobile}>
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          <div className={classes.search}>
            <Autocomplete
              id="company"
              name="company"
              options={isClients}
              getOptionLabel={(option) => option?.companyName || ""}
              onChange={handleChangeAutoComplete('company')}
              value={values.company ? values.company : ''}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Company" size="small" variant="outlined" onChange={handleInputChange} value={values.company ? values.company : ''} />}
            />
          </div>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
          <FormControlLabel
              control={
                <Checkbox
                  checked={values.private}
                  onChange={handleChange}
                  name="private"
                  color="primary"
                />
              }
              label="Private"
            />
            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}

              variant="inline"
              inputVariant="outlined"
              label="From"
              onChange={handleFromDateChange}
              value={values.fromDate}
            />
            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"

              inputVariant="outlined"
              label="To"
              onChange={handleToDateChange}
              value={values.toDate}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;
              <Button size="small" endIcon={<SearchIcon />} onClick={searchHandler}>
                Search
              </Button>
              {values.isSearch &&
                <Tooltip title={`Clear search`}>
                  <IconButton color="primary" aria-label="clear search inputs" onClick={clearSearchHandler}>
                    <ClearAllIcon />
                  </IconButton>
                </Tooltip>
              }
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.unpaid}
                  onChange={handleChange}
                  name="unpaid"
                  color="primary"
                />
              }
              label="Unpaid Jobs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.paid}
                  onChange={handleChange}
                  name="paid"
                  color="primary"
                />
              }
              label="Paid Jobs"
            />
            {/* {isClients.length !== 0 ?
              <div className={classes.clientTotalDiv}>
                <span><strong>Total Billing</strong> {toatlBillAmount?.toFixed(2)}</span><br />
                <span><strong>Paid Amount</strong> {totalPaidAmount?.toFixed(2)}</span>
              </div>
              : null} */}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.secondSession}>
        <div className={classes.sectionDesktop}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.account}
                onChange={handleChange}
                name="account"
                color="primary"
              />
            }
            label="Account"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.refund}
                onChange={handleChange}
                name="refund"
                color="primary"
              />
            }
            label="Refund"
          />
        </div>
        <div className={classes.clientTotalDiv}>
          <span><strong>Total Billing</strong> {totalAcAmount?.toFixed(2)}</span><br />
          <span><strong>IP Amount</strong> {totalIpAmount?.toFixed(2)}</span>
          <span className="nagativeAmount"><strong>LIL Amount</strong> {lilAmount?.toFixed(2)}</span><br />
          <span><strong>Total Service Charges</strong> {totalService?.toFixed(2)}</span>
          <span><strong>Total VAT</strong> {totalvat?.toFixed(2)}</span>
        </div>
      </div>
    </div >
  );
};

export default React.memo(EnhancedTableToolbar)