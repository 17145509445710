import React, { createContext, useReducer, useContext } from 'react';
import ClientDueReducer from '../reducers/clientDuesReducer';
import axios from '../../axios';

import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import { AlertContext } from './alertState';

const initialState = {
  loading: false,
  load: false,
  clients: [],
  due: {},
  submitteData: {},
  total: 0,
  count: 0,
  outstanding: {},
  clientlist: [],
  totalAmount: 0
};

export const ClientDueContext = createContext(initialState);
export const ClientDueProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ClientDueReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  const getDuesHandler = async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.ClientDues + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getDueHandler = async (id) => {
    dispatch({ type: 'LOAD_DUE' });
    try {
      const result = await axios.get(`${urlRequest.companyDues}${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const addClientAllocatePaymentHandler = async (data) => {
    const result = await axios.post(urlRequest.ClientAllocatePayment, data);
    // dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Payment Submitted' });
    }
    return await result;
  };

  /* OUTSTANDING / OVER CLIENT PAYMENT REPORT */
  // const getOutstandingDuesHandler = async (query) => {
  //   dispatch({ type: actionTypes.PROCESS_START });
  //   try {
  //     const result = await axios.get(urlRequest.OutstandingOverCPR + query);
  //     dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
  //   } catch (error) {
  //     dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
  //   }
  // };

  // const getOutstandingDueHandler = async (id) => {
  //   dispatch({ type: 'LOAD_DUE' });
  //   try {
  //     const result = await axios.get(`${urlRequest.OutstandingOverCPR}/${id}`);
  //     dispatch({ type: 'FETCH_OUTSTANDING_RECORD', payload: result.data.data });
  //   } catch (error) {
  //     console.log(error);
  //     dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
  //   }
  // };

  const getClientsHandler = async (name) => {
    console.log(name, 'queryname')
    try {
      const result = await axios.get(urlRequest.ClientsName + '/' + name);
      dispatch({ type: 'GET_CLIENTSNAME_NAME', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  // Create payment through client payments
  // const createClientPaymentHandler = async (data) => {
  //   const result = await axios.post(urlRequest.CreateClientPayment, data);
  //   // dispatch({ type: 'PAYMENT_RECORD_CREATED', payload: result.data });
  //   if (result.data.success) {
  //     alertDispatch({ type: 'open', message: 'Payment created' });
  //   }
  //   return await result;
  // };

  /*** Priavte Client payment */
  const getPrivateClientDuesHandler = async () => {
    dispatch({ type: 'LOAD_DUE' });
    try {
      const result = await axios.get(urlRequest.PrivateClientDues);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <ClientDueContext.Provider
      value={{
        loading: state.loading,
        data: state.clients,
        total: state.total,
        load: state.load,
        dues: state.due,
        totalAmount: state.totalAmount,
        submitteData: state.submitteData,
        getDuesHandler,
        getDueHandler,
        addClientAllocatePaymentHandler,
        // getOutstandingDuesHandler,
        // outstanding: state.outstanding,
        // getOutstandingDueHandler,
        getClientsHandler,
        isClients: state.clientlist,
        // createClientPaymentHandler
        getPrivateClientDuesHandler
      }}
    >
      {children}
    </ClientDueContext.Provider>
  );
};
