import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { Grid, Typography, Paper, TextField, Button, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

export const ClientOffices = (props) => {
  const { classes, handleClose, data, handleOnSubmit } = props;
  // const [indexes, setIndexes] = React.useState([]);
  // const [counter, setCounter] = React.useState(0);

  // const addBranchField = () => {
  //   setIndexes((prevIndexes) => [...prevIndexes, counter]);
  //   setCounter((prevCounter) => prevCounter + 1);
  // };

  // const removeBranchField = (index) => () => {
  //   setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
  //   setCounter((prevCounter) => prevCounter - 1);
  // };

  const formValue = {
    _id: data._id,
    branches: data.branches,
  };

  const { register, handleSubmit, control, formState } = useForm({ mode: 'onChange', defaultValues: formValue });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'branches',
  });
  const onSubmit = (data) => {
    handleOnSubmit(data);
  };
  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                  Branch Address
                </Typography>
                <Grid container spacing={1}>
                  {/* {indexes.map((index) => {
                    const fieldName = `branches[${index}]`;
                    return (
                      <Grid container direction="row" justify="space-between" alignItems="center" key={index} spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            label="Office Name"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`${fieldName}.officeName`}
                            inputRef={register({
                              required: 'Forename is required',
                              minLength: { value: 2, message: 'Must have at least 2 letters' },
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                            })}
                            error={!!errors.fieldName}
                            helperText={errors.fieldName && errors.fieldName.message}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                          <TextField
                            label="Office Address"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`${fieldName}.officeAddress`}
                            inputRef={register({
                              required: true,
                              minLength: { value: 5, message: 'Must have at least 5 letters' },
                              maxLength: { value: 100, message: 'Maximum characters limit is 100' },
                            })}
                            error={!!errors.fieldName}
                            helperText={errors.fieldName && errors.fieldName.message}
                          />
                        </Grid>
                        <Grid item xs={10} sm={4} md={2}>
                          <TextField
                            label="Postcode"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`${fieldName}.postcode`}
                            inputRef={register({
                              required: true,
                              pattern: {
                                value: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                                message: 'Invalid postcode',
                              },
                            })}
                            error={!!errors.fieldName}
                            helperText={errors.fieldName && errors.fieldName.message}
                          />
                        </Grid>
                        <Grid item xs={2} sm={2} md={1}>
                          <IconButton aria-label="delete" color="secondary" onClick={removeBranchField(index)}>
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })} */}
                  {fields.map((item, index) => {
                    return (
                      <Grid container direction="row" justify="space-between" alignItems="center" key={item._id} spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            label="Office Name"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`branches[${index}].officeName`}
                            inputRef={register({
                              required: 'Forename is required',
                              minLength: { value: 2, message: 'Must have at least 2 letters' },
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                            })}
                            defaultValue={item.officeName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                          <TextField
                            label="Office Address"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`branches[${index}].officeAddress`}
                            inputRef={register({
                              required: true,
                              minLength: { value: 5, message: 'Must have at least 5 letters' },
                              maxLength: { value: 100, message: 'Maximum characters limit is 100' },
                            })}
                            defaultValue={item.officeAddress}
                          />
                        </Grid>
                        <Grid item xs={10} sm={4} md={2}>
                          <TextField
                            label="Postcode"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name={`branches[${index}].postcode`}
                            inputRef={register({
                              required: true,
                              pattern: {
                                value: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                                message: 'Invalid postcode',
                              },
                            })}
                            defaultValue={item.postcode}
                          />
                        </Grid>
                        <Grid item xs={2} sm={2} md={1}>
                          <IconButton aria-label="delete" color="secondary" onClick={() => remove(index)}>
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                {/* <Button onClick={addBranchField}>Add Branch</Button> */}
                <Button
                  type="button"
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    append({
                      officeName: '',
                      officeAddress: '',
                      postcode: '',
                    });
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
      {/* end right */}
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        <div className={classes.actionBtn}>
          <Button size="small" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" size="small" variant="contained" color="primary" disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
};
