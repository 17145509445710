import React, { useMemo } from 'react';
import { CancelBooking } from '../Forms/CancelBooking';

function MemoizedCancelBooking({ isOpen, closeHandler, data, user, handleOnSubmit, handleBookingNote }) {
    console.log('isOpen', isOpen)
    return useMemo(() => {
        return (<CancelBooking open={isOpen} handleClose={closeHandler} data={data} user={user} handleOnSubmit={handleOnSubmit} handleBookingNote={handleBookingNote} />)
    }, [isOpen, closeHandler, data, user, handleOnSubmit, handleBookingNote])
}

export default MemoizedCancelBooking;