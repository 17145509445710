import React, { useCallback, useState, useEffect } from 'react';

import Datatable from './DataTable';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, InputBase, Paper, Toolbar, Typography, Tooltip, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperRoot: {
    width: 450,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
  minPaperRoot: {
    width: 400,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const DataSource = (props) => {
  const classes = useToolbarStyles();
  const { source, data, searchItem, tableName, loading, handleEdit, handleAdd } = props;
  const [q, setQ] = useState('');
  const [searchColumns] = useState([searchItem]);

  const fetchData = async () => {
    await source();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = useCallback(
    (rows) => {
      return rows.filter((row) => searchColumns.some((column) => row[column].toString().toLowerCase().indexOf(q.toLocaleLowerCase()) > -1));
    },
    [q, searchColumns]
  );

  // const columns = data[0] && Object.keys(data[0]);
  return (
    <React.Fragment>
      {/* <input type="text" value={q} onChange={(e) => setQ(e.target.value)} />
      {columns &&
        columns.map((column, index) => (
          <label key={index}>
            <input
              type="checkbox"
              checked={searchColumns.includes(column)}
              onChange={(e) => {
                const checked = searchColumns.includes(column);
                setSearchColumns((prev) => (checked ? prev.filter((sc) => sc !== column) : [...prev, column]));
              }}
            />
            {column}
          </label>
        ))} */}
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            {/* Toolbar title */}
            <Grid item>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {tableName}
              </Typography>
            </Grid>

            {/* Search bar */}
            <Grid item>
              <Hidden only={['sm', 'xs']}>
                <Paper component="form" className={classes.paperRoot}>
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item md={1} className={classes.sectionDesktop}>
                      <IconButton aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={11}>
                      <InputBase
                        placeholder="Search"
                        style={{ width: '100%' }}
                        inputProps={{ 'aria-label': 'search' }}
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Hidden>
            </Grid>
            {/* Right action */}
            <Grid item>
              <Hidden only={['sm', 'xs']}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                  {/* Refresh icon */}
                  <Grid item xs>
                    <Tooltip title="Refresh">
                      <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={fetchData}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip title="Add">
                      <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={handleAdd}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  {/* <Grid item xs>
                    {columns &&
                      columns.map((column, index) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) => (checked ? prev.filter((sc) => sc !== column) : [...prev, column]));
                            }}
                          />
                          {column}
                        </label>
                      ))}
                  </Grid> */}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
        <Datatable data={search(data)} loading={loading} handleEdit={handleEdit} />
      </Paper>
    </React.Fragment>
  );
};
