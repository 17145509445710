import React, { useState, useContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import { TableToolbar } from "./Table/TableToolbar";
import { TableHeader } from "./Table/TableHeader";
import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { getComparator, stableSort } from "../../../../libs/headerSortMethods";

// importing context
import { UserContext } from "../../../../context/states/userState";
import { UserLists } from "./userLists";
import { AddUser } from "./Forms/AddUser/AddUser";
import { EditUser } from "./Forms/EditUser/EditUser";
import { FileProvider } from "../../../../context/states/fileState";
const headCells = [
  {
    id: "slno",
    numeric: false,
    disablePadding: false,
    label: "Sl.No",
    width: "100%",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "100%",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "100%",
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
    width: "100%",
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile",
    width: "100%",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created on",
    width: "100%",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    width: "100%",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
  table: {
    minWidth: "100vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  paper: {
    margin: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const Users = React.memo((props) => {
  console.log("RENDER INTERPRETERS");
  const classes = useStyles();

  const { title } = props;
  const { getUsersHandler, loading, isUsers, total, getUserHandler } =
    useContext(UserContext);

  const [isCheckbox, setIsCheckbox] = useState(false);
  const enableCheckboxHandler = () => setIsCheckbox(!isCheckbox);

  const [isAddModel, setIsAddModel] = useState(false);
  const openFormModalHandler = useCallback(() => setIsAddModel(true), []);
  const closeFormModalHandler = useCallback(() => setIsAddModel(false), []);

  const [isEditModal, setIsEditModal] = useState(false);
  const openEditModalHandler = useCallback((id) => {
    setIsEditModal(true);
    getUserHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeEditModalHandler = useCallback(() => {
    setIsEditModal(false);
  }, [setIsEditModal]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("_id");
  const [selected, setSelected] = useState([]);
  const [isPage, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchAllDataHandler = useCallback(
    async (searchInput) => {
      console.log(searchInput);
      const query =
        isPage !== 0
          ? `?page=${isPage + 1}&limit=${rowsPerPage}`
          : `?page=1&limit=${rowsPerPage}${searchInput}`;
      await getUsersHandler(query);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, isPage]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = isUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = useCallback(
    (name) => selected.indexOf(name) !== -1,
    [selected]
  );

  const refreshDataHandler = useCallback(
    () => fetchAllDataHandler(),
    [fetchAllDataHandler]
  );

  const tableData = useMemo(() => {
    return (
      <UserLists
        data={isUsers}
        loading={loading}
        stableSort={stableSort}
        getComparator={getComparator}
        order={order}
        orderBy={orderBy}
        page={isPage}
        rowsPerPage={rowsPerPage}
        isSelected={isSelected}
        handleClick={handleClick}
        isCheckbox={isCheckbox}
        handleEditModal={openEditModalHandler}
      />
    );
  }, [
    isUsers,
    loading,
    order,
    orderBy,
    isPage,
    rowsPerPage,
    isSelected,
    handleClick,
    isCheckbox,
    openEditModalHandler,
  ]);

  return (
    <>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={selected.length}
          tableTitle={title}
          isCheckbox={isCheckbox}
          enableCheckbox={false}
          handleRefresh={refreshDataHandler}
          handleCheckbox={enableCheckboxHandler}
          handleFormModal={openFormModalHandler}
          handleSearch={fetchAllDataHandler}
        />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHeader
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={isUsers.length}
              isCheckbox={isCheckbox}
            />
            <TableBody>{tableData}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense"
      />
      {isAddModel && (
        <AddUser open={isAddModel} handleClose={closeFormModalHandler} />
      )}
      <FileProvider>
        {isEditModal && (
          <EditUser open={isEditModal} handleClose={closeEditModalHandler} />
        )}
      </FileProvider>
    </>
  );
});

Users.propTypes = {
  title: PropTypes.string.isRequired,
};
