import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";

import "./BookingLists.css";

import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
  female:
    theme.palette.type === "light" || "dark"
      ? {
          textTransform: "capitalize",
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.7),
        }
      : {
          textTransform: "capitalize",
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.secondary.contrastText,
        },
  male:
    theme.palette.type === "light" || "dark"
      ? {
          textTransform: "capitalize",
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.7),
        }
      : {
          textTransform: "capitalize",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.contrastText,
        },
  updateNotify: {
    fontSize: "10px",
    fontWeight: 600,
    maxidth: "100%",
    padding: "4px 8px",
    borderRadius: "4px",
    color: theme.palette.warning.main,
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  newNotify: {
    fontSize: "10px",
    fontWeight: 600,
    maxidth: "100%",
    padding: "4px 8px",
    borderRadius: "4px",
    color: theme.palette.info.main,
    backgroundColor: lighten(theme.palette.info.light, 0.9),
  },
  tableBorder: {
    borderWidth: 0,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    fontSize: "13px",
  },
  root: {
    "& .Pending": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      // padding: '4px 8px',
      borderRadius: "4px",
      color: theme.palette.warning.main,
      // backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    "& .New": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      // padding: '4px 8px',
      borderRadius: "4px",
      color: "theme.palette.info.main",
      // backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    "& .Quotation": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#3f51b5",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Booking": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#3f51b5",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Confirmed": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#f345f3", //03a9f4
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Timesheet": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#00bcd4",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Approved": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#009688",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Paid": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#4caf50",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Cancel": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#f44336",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Quote": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#ff9800",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
    "& .Quote, .Supplied": {
      fontSize: "10px",
      fontWeight: 600,
      width: "100%",
      padding: "2px 4px",
      borderRadius: "4px",
      color: "#616161",
      backgroundColor: lighten(theme.palette.background.paper, 0.2),
    },
  },
}));

export const BookingLists = React.memo((props) => {
  console.log("RENDER LISTS");
  const classes = useStyles();
  const history = useHistory();
  let { url } = useRouteMatch();

  const {
    data,
    loading,
    // error,
    stableSort,
    getComparator,
    order,
    orderBy,
    isSelected,
    handleClick,
    isCheckbox,
    timesheetHandler,
    isAccessTo,
  } = props;

  /* const style = {
    bookRef: {
      width: '5%',
      padding: '4px',
    },
    status: {
      width: '5%',
      padding: '4px',
    },
    service: {
      width: '8%',
    },
    date: {
      width: '6%',
      padding: '4px',
    },
    ref: {
      width: '7%',
      padding: '4px',
    },
    name: {
      width: '7%',
      padding: '4px',
    },
    client: {
      width: '7%',
      padding: '4px',
    },
    crNote: {
      width: '5%',
      padding: '4px',
    },
    action: {
      width: '8%',
      padding: '4px',
    },
  }; */

  let tableLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: "center" }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading)
    tableLists = (
      <>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: "center" }}>
              <span>No Record</span>
            </TableCell>
          </TableRow>
        ) : (
          stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            const isItemSelected = isSelected(row._id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                role="checkbox"
                key={row.id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                // className={clsx(
                //   `${classes.root} ${row.serviceType === 'Transcription' ? row.serviceType + '_' + row.transcriptionType : row.serviceType} ${row.status
                //   }`
                // )}
              >
                {isCheckbox && (
                  <TableCell className={classes.tableBorder} padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row._id)}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableBorder}
                  id={labelId}
                  align="center"
                >
                  {row.booking_ref}
                </TableCell>

                <TableCell
                  className={clsx(
                    `${classes.tableBorder} ${classes.root} ${
                      row?.status === "Quote Supplied"
                        ? "Quote-Supplied"
                        : row?.status
                    }`
                  )}
                  align="center"
                >
                  <span className={row.status}>
                    {row.status === "Booking" || row.status === "Quotation"
                      ? row.status + " Pending"
                      : row.status === "Cancel"
                      ? "Cancelled"
                      : row.status}
                  </span>
                </TableCell>
                {/* className={classes.tableBorder} */}
                <TableCell
                  className={clsx(
                    `${classes.tableBorder} ${classes.serviceRoot} ${
                      row?.serviceType === "Transcription"
                        ? row?.serviceType + "_" + row?.transcriptionType
                        : row?.serviceType
                    } `
                  )}
                  align="center"
                >
                  <strong>{row.serviceType}</strong>
                  <br />
                  {row.language}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  {moment(row.date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  {row.fileRef}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  <span>{row?.companyName}</span> <br />
                  {row.clientName}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  {row.invoiceNo}
                </TableCell>

                <TableCell className={classes.tableBorder} align="left">
                  <React.Fragment>
                    <Tooltip title={`View Request: ${row.booking_ref}`}>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/booking/view.html?id=${row.booking_ref}&token=${row.randToken}&view=${process.env.REACT_APP_MODE}`,
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      >
                        <FindInPageIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Tooltip>

                    {isAccessTo && (
                      <Tooltip title={`Timesheet: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => timesheetHandler(row._id)}
                          disabled={row.status === "Cancel" ? true : false}
                        >
                          <AccessTimeIcon
                            fontSize="small"
                            color={
                              row.status === "Cancel" ? "secondary" : "primary"
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {row.isApprove && (
                      <Tooltip title={`View Invoice: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            history.push({
                              pathname: `${url}/invoice/${row.id}/interpreter/${row.booking_ref}`,
                            })
                          }
                        >
                          <AssignmentTurnedInIcon
                            fontSize="small"
                            color="primary"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {row.serviceType === "Onsite" && (
                      <Tooltip title={`Download Sheet`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/assets/files/ATTENDANCE%20SHEET.pdf`,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <ListAltIcon fontSize="small" color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* {(row.isApprove || row.status === 'Paid' || row.status === 'Confirmed') && (row.serviceType === 'Transcription' || row.serviceType === 'Translation') && (
                      <Tooltip title={`Certificates: ${row.booking_ref}`}>
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_OTHER_LINK_ENDPOING}/certificate/view.html?id=${row.booking_ref}&token=${row.randToken}&view=${process.env.REACT_APP_MODE}&name=${row.interpreterName}`,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }
                        >
                          <VerifiedUserIcon fontSize="small" color="secondary" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                  </React.Fragment>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </>
    );

  return <>{tableLists}</>;
});

BookingLists.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  stableSort: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  timesheetHandler: PropTypes.func.isRequired,
};
