import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
	Grid,
	Paper,
	Typography,
	TextField,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	FormHelperText,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { InterpreterContext } from "../../../../../context/states/interpreterState";

import AddIcon from "@material-ui/icons/Add";
import RecentActorsIcon from "@material-ui/icons/RecentActors";

import Loader from "../../../../UI/Loader";

export const Notes = React.memo((props) => {
	const { classes, data, handleClose, role, username, isAccessTo } = props;

	// let isAccessTo;
	// if (role === 'superadmin' || role === 'admin') {
	//   console.log('role', role)
	//   isAccessTo = false
	// } else {
	//   isAccessTo = true
	// }

	const { updateProfileNotesHandler, updateInterpreterHandler, profileNotes } =
		useContext(InterpreterContext);
	const today = moment().format();

	// Backdrop loader
	const [isLoading, setIsLoading] = useState(false);
	const backdropCloseHandler = () => setIsLoading(false);

	const formValue = {
		_id: data._id,
		bookingNotes:
			data.bookingNotes !== undefined
				? {
						text: data.bookingNotes.text,
						updated: data.bookingNotes.updated,
						updatedBy: data.bookingNotes.updatedBy,
				  }
				: null,
	};

	const { register, handleSubmit, setValue, errors, formState } = useForm({
		mode: "onChange",
		defaultValues: formValue,
	});

	useEffect(() => {
		setValue("bookingNotes.updatedBy", username);
		setValue("bookingNotes.updated", today);
	}, [register, setValue, today, username]);

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			await updateInterpreterHandler(data);
			backdropCloseHandler();
		} catch (error) {
			console.log(error);
			backdropCloseHandler();
		}
	};

	const [inputValue, setInputValue] = useState("");

	const addProfileNotes = async () => {
		const notes = {
			id: data._id,
			text: inputValue,
			updatedby: username,
		};

		try {
			await updateProfileNotesHandler(notes);
			setInputValue("");
		} catch (error) {
			console.log(error);
		}
	};

	const profilelist = profileNotes
		.sort((a, b) => new Date(b.updated) - new Date(a.updated))
		.map((p, i) => (
			<ListItem key={p._id}>
				<ListItemIcon>
					<RecentActorsIcon />
				</ListItemIcon>
				<ListItemText
					primary={p.text}
					secondary={p.updatedBy + " | " + moment(p.updated).format("DD/MM/yyyy, HH:mm")}
				/>
				{/* <Chip label={p.text} /> */}
			</ListItem>
		));

	return (
		<React.Fragment>
			<form onSubmit={(e) => e.preventDefault()}>
				<input
					ref={register}
					name="_id"
					style={{ display: "none" }}
				/>
				<input
					type="hidden"
					name="updatedAt"
					ref={register}
					defaultValue={today}
				/>
				<input
					type="hidden"
					name="updatedBy"
					ref={register}
					defaultValue={username}
				/>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="flex-start"
					spacing={1}
				>
					<Grid
						item
						xs={12}
						md={12}
					>
						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={12}
									md={12}
								>
									<TextField
										id="bookingNotes"
										label="Booking Notes"
										size="small"
										variant="outlined"
										multiline
										rows={3}
										name="bookingNotes.text"
										inputRef={register({
											required: false,
										})}
										fullWidth
										defaultValue={
											data.bookingNotes !== undefined
												? data.bookingNotes.text
												: null
										}
										error={!!errors.bookingNotes}
										helperText={
											errors.bookingNotes && errors.bookingNotes.text.message
										}
										disabled={isAccessTo}
									/>
									<FormHelperText>
										<>
											<span>
												{data.bookingNotes !== undefined
													? data.bookingNotes.updatedBy + " | "
													: ""}
											</span>
											<span>
												{data.bookingNotes !== undefined
													? moment(data.bookingNotes.updated).format(
															"dddd, MMMM Do YYYY, h:mm:ss a"
													  )
													: null}
											</span>
										</>
									</FormHelperText>
									<div style={{ display: "none" }}>
										<input
											ref={register}
											name="bookingNotes.updatedBy"
											defaultValue={
												data.bookingNotes !== undefined
													? data.bookingNotes.updatedBy
													: today
											}
										/>
										<input
											ref={register}
											name="bookingNotes.updated"
											defaultValue={
												data.bookingNotes !== undefined ? "user" : null
											}
										/>
									</div>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Typography
										variant="subtitle2"
										gutterBottom
										style={{ fontWeight: "600", paddingBottom: "5px" }}
										color="primary"
									>
										Profile Notes
									</Typography>
									<div
										style={{
											height: "auto",
											maxHeight: "300px",
											overflowX: "auto",
										}}
									>
										<List dense={true}>{profilelist}</List>
									</div>
								</Grid>
								{!isAccessTo && (
									<Grid
										item
										xs={12}
										md={12}
									>
										<Grid
											container
											direction="row"
											justify="flex-start"
											alignItems="flex-start"
										>
											<Grid
												item
												xs={11}
											>
												<TextField
													id="profile-note"
													label="Profile Notes"
													size="small"
													fullWidth
													variant="outlined"
													value={inputValue || ""}
													onChange={(e) => setInputValue(e.target.value)}
												/>
											</Grid>
											<Grid
												item
												xs={1}
											>
												<Button
													onClick={addProfileNotes}
													disabled={inputValue.length === 0}
												>
													<AddIcon />
													Add
												</Button>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				{!isAccessTo && (
					<Grid
						container
						direction="row"
						justify="flex-end"
						alignItems="flex-end"
					>
						<div className={classes.actionBtn}>
							<Button
								size="small"
								color="primary"
								onClick={handleClose}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								size="small"
								variant="contained"
								color="primary"
								disabled={
									!formState.isDirty || (formState.isDirty && !formState.isValid)
								}
								onClick={handleSubmit(onSubmit)}
							>
								Update
							</Button>
						</div>
					</Grid>
				)}
			</form>
			<Loader
				open={isLoading}
				handleClose={backdropCloseHandler}
			/>
		</React.Fragment>
	);
});

Notes.prototype = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
};
