import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { Grid, Typography, Paper, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import ConfirmationModal from '../../../../../../CustomModals/confirmationModal';

export const ClientActivation = (props) => {
  const { classes, handleClose, handleOnSubmit, data, mailHandler } = props;
  const [open, setOpen] = useState(false)

  const formValue = {
    contactReceived: data?.contactReceived,
    creditLimit: data?.creditLimit,
    approvePrivateRates: data?.approvePrivateRates,
    approvedSpecialRates: data?.approvedSpecialRates,
    isRegistered: data?.isRegistered,
    active: data?.active,
  };

  const { register, handleSubmit, formState } = useForm({ mode: 'onChange', defaultValues: formValue });

  const onSubmit = (d) => {
    if (d.active && data.isRegistered) setOpen(true);
    handleOnSubmit(d);
  };

  const activationMailHandler = useCallback(() => {
    const { _id } = data;
    mailHandler(_id)
    setOpen(false)
  }, [])

  const closeActiveModal = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <React.Fragment>
      <ConfirmationModal title={'Confirmation'} message={'Do you want to send client activation mail?'} isOpen={open} handleClose={closeActiveModal} confirmHandler={activationMailHandler} />
      {/* <DevTool control={control} /> */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                  Mandatory Checks before activating Client
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControlLabel
                  inputRef={register}
                  control={<Checkbox name="contactReceived" color="primary" defaultChecked={data !== undefined ? data.contactReceived : false} />}
                  label="Contract Received"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControlLabel
                  inputRef={register}
                  control={<Checkbox name="creditLimit" color="primary" defaultChecked={data !== undefined ? data.creditLimit : false} />}
                  label="Check Credit Limit"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControlLabel
                  inputRef={register}
                  control={
                    <Checkbox name="approvePrivateRates" color="primary" defaultChecked={data !== undefined ? data.approvePrivateRates : false} />
                  }
                  label="Approved Private Rates"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControlLabel
                  inputRef={register}
                  control={
                    <Checkbox name="approvedSpecialRates" color="primary" defaultChecked={data !== undefined ? data.approvedSpecialRates : false} />
                  }
                  label="Approved Special Rates"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <FormControlLabel
                  control={<Checkbox name="active" color="primary" defaultChecked={data?.active || false} />}
                  label="Active"
                  inputRef={register}
                />
                <input type="hidden" name="isRegistered" ref={register} defaultChecked={data?.isRegistered} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
      {/* end right */}
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        <div className={classes.actionBtn}>
          <Button size="small" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" size="small" variant="contained" color="primary" disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </div>
      </Grid>

    </React.Fragment>
  );
};
