import React, { useMemo } from 'react';
import { getComparator, stableSort } from '../../../../libs/headerSortMethods'
import { InterpreterLists } from '../InterpreterLists';

function MemoizedList({ data, loading, order, orderBy, isPage, rowsPerPage, isSelected, handleClick, isCheckbox }) {
    return useMemo(() => {
        return (
            <InterpreterLists
                data={data}
                loading={loading}
                stableSort={stableSort}
                getComparator={getComparator}
                order={order}
                orderBy={orderBy}
                page={isPage}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleClick={handleClick}
                isCheckbox={isCheckbox}
            />
        )
    }, [data, loading, order, orderBy, isPage, rowsPerPage, isSelected, handleClick, isCheckbox])
}

export default MemoizedList;