import React, { useMemo } from 'react';
import { TableHeader } from '../table/TableHeader';

function MemoizedHeader({ headCells, classes, selected, order, orderBy, handleSelectAllClick, handleRequestSort, data, isCheckbox }) {
    return useMemo(() => {
        return (
            <TableHeader
                headCells={headCells}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                isCheckbox={isCheckbox}
            />
        )
    }, [headCells, classes, selected, order, orderBy, handleSelectAllClick, handleRequestSort, data, isCheckbox])
}

export default MemoizedHeader;