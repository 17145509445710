import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	IconButton,
	Button,
	Paper,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

import { ClientContext } from "../../../../../../context/states/clientState";

const useStyles = makeStyles((theme) => ({
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		margin: theme.spacing(1, 0),
	},
	profileLarge: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	formControl: {
		width: "100%",
		maxWidth: "100%",
	},
	input: {
		display: "none",
	},
	inputTitle: {
		fontWeight: "600",
		marginTop: ".1em",
		marginBottom: ".9em",
	},
}));

export const AddClient = React.memo((props) => {
	const { open, handleClose, isRateStructures } = props;
	const classes = useStyles();
	const { addClientHandler, rateStructures } = useContext(ClientContext);

	const { register, handleSubmit, errors, control, formState } = useForm({ mode: "onChange" });
	const { isDirty, isValid } = formState;

	const onSubmit = async (data) => {
		await addClientHandler(data);
	};

	return (
		<React.Fragment>
			<Dialog
				maxWidth="md"
				open={open}
				onClose={handleClose}
				fullWidth={true}
				aria-labelledby="form-dialog-title"
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogTitle>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						<RecordVoiceOverIcon color="primary" />
						<Typography
							variant="h6"
							gutterBottom
						>
							New Client
						</Typography>

						<IconButton
							aria-label="close"
							size="small"
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid
								container
								spacing={1}
							>
								{/* Fields section */}
								<Grid
									item
									xs={12}
								>
									<Paper
										className={classes.paper}
										variant="outlined"
									>
										<Grid
											container
											direction="column"
											justify="flex-start"
											alignItems="flex-start"
										>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ fontWeight: "600", paddingBottom: "5px" }}
												color="primary"
											>
												Client Details
											</Typography>
											<Grid
												container
												spacing={1}
											>
												<Grid
													item
													xs={12}
													sm={5}
													md={3}
												>
													<TextField
														label="Company Name"
														size="small"
														fullWidth
														name="companyName"
														variant="outlined"
														inputRef={register({
															required: true,
															minLength: {
																value: 2,
																message:
																	"Must have at least 2 letters",
															},
															maxLength: {
																value: 100,
																message:
																	"Maximum characters limit is 100",
															},
														})}
														error={!!errors.companyName}
														helperText={
															errors.companyName &&
															errors.companyName.message
														}
														autoFocus
													/>
												</Grid>

												<Grid
													item
													xs={12}
													sm={6}
													md={3}
												>
													<Controller
														as={
															<KeyboardDatePicker
																autoOk
																format="dd/MM/yyyy"
																id="dor"
																label="Todays Date"
																size="small"
																variant="inline"
																fullWidth
																inputVariant="outlined"
																helperText={
																	errors.dor && errors.dor.message
																}
																KeyboardButtonProps={{
																	"aria-label": "change date",
																}}
															/>
														}
														control={control}
														name="dor"
														rules={{ required: true }}
														error={!!errors.dor}
														defaultValue={new Date()}
													/>
												</Grid>

												<Grid
													item
													xs={12}
													sm={5}
													md={3}
												>
													<TextField
														label="Contact Person"
														size="small"
														fullWidth
														variant="outlined"
														name="contactPerson"
														inputRef={register({
															required: true,
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
														})}
														error={!!errors.contactPerson}
														helperText={
															errors.contactPerson &&
															errors.contactPerson.message
														}
													/>
												</Grid>

												<Grid
													item
													xs={12}
													sm={5}
													md={3}
												>
													<TextField
														label="Position"
														size="small"
														fullWidth
														variant="outlined"
														name="position"
														inputRef={register({
															required: true,
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
														})}
														error={!!errors.position}
														helperText={
															errors.position &&
															errors.position.message
														}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Paper>

									<Paper
										className={classes.paper}
										variant="outlined"
									>
										<Grid
											container
											direction="column"
											justify="flex-start"
											alignItems="flex-start"
											spacing={1}
										>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ fontWeight: "600", paddingBottom: "5px" }}
												color="primary"
											>
												Contact Details
											</Typography>
											<Grid
												container
												spacing={1}
											>
												<Grid
													item
													xs={12}
													sm={4}
													md={6}
												>
													<TextField
														label="Email"
														size="small"
														fullWidth
														variant="outlined"
														name="email"
														inputRef={register({
															required: true,
															minLength: {
																value: 5,
																message:
																	"Must have at least 5 letters",
															},
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
															pattern: {
																value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
																message: "Invalid email-id",
															},
														})}
														error={!!errors.email}
														helperText={
															errors.email && errors.email.message
														}
													/>
												</Grid>

												<Grid
													item
													xs={12}
													sm={6}
													md={3}
												>
													<TextField
														id="mobile"
														label="Mobile"
														size="small"
														fullWidth
														variant="outlined"
														name="mobile"
														inputRef={register({
															required: true,
															minLength: {
																value: 10,
																message:
																	"Must have at least 10 digits",
															},
															maxLength: {
																value: 20,
																message:
																	"Maximum digits limit is 20",
															},
															pattern: {
																value: /^[0-9]+$/,
																message: 'Invalid mobile "number"',
															},
														})}
														error={!!errors.mobile}
														helperText={
															errors.mobile && errors.mobile.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={3}
												>
													<TextField
														id="phone"
														label="Phone"
														size="small"
														fullWidth
														variant="outlined"
														name="phone"
														inputRef={register({
															required: true,
															pattern: {
																value: /^[0-9]+$/,
																message: "Invalid phone number",
															},
															minLength: {
																value: 10,
																message:
																	"Must have at least 10 digits",
															},
															maxLength: {
																value: 20,
																message:
																	"Maximum digits limit is 20",
															},
														})}
														error={!!errors.phone}
														helperText={
															errors.phone && errors.phone.message
														}
													/>
												</Grid>

												<Grid
													item
													xs={12}
													sm={6}
													md={10}
												>
													<TextField
														id="address"
														label="Address"
														size="small"
														fullWidth
														variant="outlined"
														name="address"
														inputRef={register({
															required: true,
															minLength: {
																value: 5,
																message:
																	"Must have at least 5 letters",
															},
															maxLength: {
																value: 100,
																message:
																	"Maximum characters limit is 100",
															},
														})}
														error={!!errors.address}
														helperText={
															errors.address && errors.address.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={10}
													sm={4}
													md={2}
												>
													<TextField
														label="Postcode"
														size="small"
														fullWidth
														variant="outlined"
														name="postcode"
														inputRef={register({
															required: true,
															pattern: {
																value: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
																message: "Invalid postcode",
															},
														})}
														error={!!errors.postcode}
														helperText={
															errors.postcode &&
															errors.postcode.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={4}
												>
													<FormControl component="fieldset">
														<RadioGroup
															name="location"
															row
															defaultValue="In London"
														>
															<FormControlLabel
																value="In London"
																inputRef={register}
																control={<Radio color="primary" />}
																label="In London"
															/>
															<FormControlLabel
																value="Out of London"
																inputRef={register}
																control={<Radio color="primary" />}
																label="Out of London"
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
									</Paper>

									<Paper
										className={classes.paper}
										variant="outlined"
									>
										<Grid
											container
											direction="column"
											justify="flex-start"
											alignItems="flex-start"
										>
											<Typography
												variant="subtitle2"
												gutterBottom
												style={{ fontWeight: "600", paddingBottom: "5px" }}
												color="primary"
											>
												Accounts Contact:
											</Typography>
											<Grid
												container
												spacing={1}
											>
												<Grid
													item
													xs={12}
													sm={4}
													md={3}
												>
													<TextField
														label="Name"
														size="small"
														fullWidth
														variant="outlined"
														name="acName"
														inputRef={register({
															required: true,
															minLength: {
																value: 2,
																message:
																	"Must have at least 2 letters",
															},
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
														})}
														error={!!errors.acName}
														helperText={
															errors.acName && errors.acName.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
												>
													<TextField
														label="Email"
														size="small"
														fullWidth
														variant="outlined"
														name="acEmailOne"
														inputRef={register({
															required: true,
															minLength: {
																value: 5,
																message:
																	"Must have at least 5 letters",
															},
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
															pattern: {
																value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
																message: "Invalid email-id",
															},
														})}
														error={!!errors.acEmailOne}
														helperText={
															errors.acEmailOne &&
															errors.acEmailOne.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={4}
												>
													<TextField
														label="Email"
														size="small"
														fullWidth
														variant="outlined"
														name="acEmailTwo"
														inputRef={register({
															required: true,
															minLength: {
																value: 5,
																message:
																	"Must have at least 5 letters",
															},
															maxLength: {
																value: 50,
																message:
																	"Maximum characters limit is 50",
															},
															pattern: {
																value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
																message: "Invalid email-id",
															},
														})}
														error={!!errors.acEmailTwo}
														helperText={
															errors.acEmailTwo &&
															errors.acEmailTwo.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={4}
													md={3}
												>
													<TextField
														label="Phone"
														size="small"
														fullWidth
														variant="outlined"
														name="acNumber"
														inputRef={register({
															required: true,
															// pattern: { value: /^\d+$/, message: 'Invalid account no' },
															minLength: {
																value: 10,
																message:
																	"Must have at least 10 digits",
															},
															maxLength: {
																value: 20,
																message:
																	"Maximum digits limit is 20",
															},
														})}
														error={!!errors.acNumber}
														helperText={
															errors.acNumber &&
															errors.acNumber.message
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={3}
												>
													<FormControl
														variant="outlined"
														size="small"
														className={classes.formControl}
													>
														<InputLabel
															id="Company-Status"
															htmlFor="department-label"
														>
															Company Status
														</InputLabel>
														<Controller
															as={
																<Select
																	label="Company Status"
																	labelId="Company-Status"
																	id="company-status"
																	name="companyStatus"
																>
																	<MenuItem value="">
																		<em>None</em>
																	</MenuItem>
																	<MenuItem value={"Private"}>
																		Private
																	</MenuItem>
																	<MenuItem value={"Law Firm"}>
																		Law Firm
																	</MenuItem>
																</Select>
															}
															control={control}
															name="companyStatus"
															rules={{ required: true }}
															error={!!errors.companyStatus}
															labelWidth={55}
														/>
													</FormControl>
												</Grid>

												<Grid
													item
													xs={12}
													sm={6}
													md={3}
												>
													<FormControl
														variant="outlined"
														size="small"
														style={{ minWidth: 220 }}
														fullWidth
													>
														<InputLabel htmlFor="billing-rates-label">
															Billing Rates
														</InputLabel>
														<Controller
															as={
																<Select
																	label="Billing Rates"
																	inputProps={{
																		name: "billing-rates",
																		id: "billing-rates-label",
																	}}
																>
																	{rateStructures?.map((data) => (
																		<MenuItem
																			key={data._id}
																			value={data._id}
																		>
																			{data?.rateName}
																		</MenuItem>
																	))}
																</Select>
															}
															control={control}
															name="billingRate"
															rules={{ required: false }}
															error={!!errors.billingRate}
														/>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</form>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						type="submit"
						variant="contained"
						size="small"
						color="primary"
						disabled={!isDirty && isValid}
						onClick={handleSubmit(onSubmit)}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
});
