import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { DevTool } from '@hookform/devtools';

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Toolbar, Typography, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/Email';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoreIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';

// import { AdvanceSearch } from './AdvanceSearch';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paperRoot: {
    width: 450,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
  minPaperRoot: {
    width: 400,
    maxWidth: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  advanceCard: {
    margin: theme.spacing(0.4, 0),
    marginLeft: theme.spacing(4),
    padding: theme.spacing(1),
    position: 'fixed',
    width: 450,
    maxWidth: '100%',
    overflow: 'auto',
    zIndex: 3,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%',
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  formControl: {
    minWidth: 190,
  },
  pincodeControl: {
    minWidth: 140,
  },
}));

export const TableToolbar = React.memo((props) => {
  const classes = useToolbarStyles();
  const { numSelected, tableTitle, isCheckbox, handleRefresh, handleCheckbox, enableCheckbox, handleFormModal, handleSearch } = props;

  // const [isOpen, setIsOpen] = useState(false);
  // const advancedToggleHandler = () => setIsOpen(!isOpen);

  // const inputSearchRef = useRef();
  // const [enteredFilter, setEnteredFilter] = useState('');

  // const [filterActive, setFilterActive] = useState(null);
  // const handleFilterOpen = (event) => setFilterActive(event.currentTarget);
  // const handleFilterClose = (event) => setFilterActive(null);

  const [isMenuList, setIsMenuList] = useState(null);
  const handleMenuList = (event) => setIsMenuList(event.currentTarget);
  const handleMenuListClose = () => setIsMenuList(null);

  useEffect(() => {
    let unmounted = false;

    // const timer = setTimeout(() => {
    //   if (enteredFilter === inputSearchRef.current.value) {
    //     const query = enteredFilter.length === 0 ? '' : `&companyName=${enteredFilter}`;
    //     if (!unmounted) {
    //       handleSearch(query); // eslint-disable-next-line
    //     }
    //   }
    // }, 500);

    if (!unmounted) {
      handleSearch(); // eslint-disable-next-line
    }
    return () => {
      // clearTimeout(timer);
      unmounted = true;
    };
  }, [handleSearch]);

  // const onSearchHandler = useCallback(
  //   (data) => {
  //     handleSearch(data);
  //   },
  //   [handleSearch]
  // );

  // const filterHandler = useCallback(
  //   (value) => {
  //     if (value === 'Private') {
  //       handleSearch(`&companyStatus=Private`);
  //     } else if (value === 'Law Firm') {
  //       handleSearch(`&companyStatus=Law Firm`);
  //     } else {
  //       handleSearch('');
  //     }
  //     // handleFilterClose();
  //   },
  //   [handleSearch]
  // );

  return (
    <>
      {/* <DevTool control={control} /> */}
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container direction="row" justify="space-between" alignItems="center">
          {/* Toolbar title */}
          <Grid item>
            {numSelected > 0 ? (
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {tableTitle}
              </Typography>
            )}
          </Grid>

          {numSelected > 0 ? (
            <Tooltip title="Email To">
              <IconButton aria-label="emailTo">
                <EmailIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <React.Fragment>
              {/* Search bar */}
              <Grid item>
                {/* <Hidden only={['sm', 'xs']}>
                  <Paper component="form" className={classes.paperRoot}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                      <Grid item md={1} className={classes.sectionDesktop}>
                        <IconButton aria-label="search">
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                      <Grid item md={10}>
                        <InputBase
                          placeholder="Search"
                          style={{ width: '100%' }}
                          inputProps={{ 'aria-label': 'search' }}
                          inputRef={inputSearchRef}
                          value={enteredFilter}
                          onChange={(event) => setEnteredFilter(event.target.value)}
                        />
                      </Grid>
                      <Grid item md={1}>
                        <IconButton aria-label="search" onClick={advancedToggleHandler}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                  Advance search
                  {isOpen && (
                    <AdvanceSearch
                      classes={classes}
                      handleToggle={advancedToggleHandler}
                      isLanguages={isLanguages}
                      isDialects={isDialects}
                      handleSearch={onSearchHandler}
                    />
                  )}
                </Hidden> */}
              </Grid>
              {/* Right action */}
              <Grid item>
                {/* Hide in small screen*/}
                <Hidden only={['sm', 'xs']}>
                  <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                    {/* Filter icon */}
                    {/* <Grid item xs>
                      <Tooltip title="Filter active list">
                        <IconButton aria-label="filter active list" aria-haspopup="true" onClick={handleFilterOpen}>
                          <FilterListIcon />
                        </IconButton>
                      </Tooltip>

                      <Menu id="filter-menu" anchorEl={filterActive} keepMounted open={Boolean(filterActive)} onClose={handleFilterClose}>
                        <MenuItem onClick={() => filterHandler('')}>All</MenuItem>
                        <MenuItem onClick={() => filterHandler('Private')}>Private</MenuItem>
                        <MenuItem onClick={() => filterHandler('Law Firm')}>Law Firm</MenuItem>
                      </Menu>
                    </Grid> */}
                    {/* Refresh icon */}
                    <Grid item xs>
                      <Tooltip title="Refresh">
                        <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={handleRefresh}>
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    {/* Toggle icon */}
                    {enableCheckbox && (
                      <Grid item xs>
                        <Tooltip title={isCheckbox ? 'Disable Checkbox' : 'Enable Checkbox'}>
                          {isCheckbox ? (
                            <IconButton aria-label="Checkbox" color="primary" onClick={handleCheckbox}>
                              <ToggleOnIcon />
                            </IconButton>
                          ) : (
                            <IconButton aria-label="Checkbox" color="secondary" onClick={handleCheckbox}>
                              <ToggleOffIcon />
                            </IconButton>
                          )}
                        </Tooltip>
                      </Grid>
                    )}
                    {/* Add icon */}
                    <Grid item xs>
                      <Tooltip title="Add">
                        <IconButton aria-label="Add" color="primary" aria-haspopup="true" onClick={handleFormModal}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Hidden>
                {/* Appear in small screen */}
                <Hidden mdUp>
                  <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={6}>
                      {/* <Paper component="form" className={classes.minPaperRoot}>
                        <InputBase
                          placeholder="Search"
                          inputProps={{ 'aria-label': 'search' }}
                          inputRef={inputSearchRef}
                          value={enteredFilter}
                          onChange={(event) => setEnteredFilter(event.target.value)}
                        />
                      </Paper> */}
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton aria-label="show more" aria-haspopup="true" color="inherit" onClick={handleMenuList}>
                        <MoreIcon />
                      </IconButton>
                      <Menu id="simple-menu" anchorEl={isMenuList} keepMounted open={Boolean(isMenuList)} onClose={handleMenuListClose}>
                        <MenuItem onClick={handleFormModal}>Add</MenuItem>
                        <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Toolbar>
    </>
  );
});

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
  isCheckbox: PropTypes.bool.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  enableCheckbox: PropTypes.bool.isRequired,
};
