import React, { useState, useContext, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	Chip,
	Avatar,
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	CircularProgress,
	IconButton,
	Tabs,
	Tab,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";

import { TabPanel } from "../../../../../tabPanel/TabPanel";
import { PersonalDetails } from "./TabFields/personalDetails";
import { ClientOffices } from "./TabFields/clientOffices";
import { ClientNotes } from "./TabFields/clientNotes";
import { ClientActivation } from "./TabFields/clientActivation";
import { ClientDocuments } from "./TabFields/clientDocuments";
import { ClientRates } from "./TabFields/clientRates";

import { ClientContext } from "../../../../../../context/states/clientState";

const useStyles = makeStyles((theme) => ({
	paper: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		margin: theme.spacing(1, 0),
	},
	profileLarge: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},
	formControl: {
		width: "100%",
		maxWidth: "100%",
	},
	input: {
		display: "none",
	},
	inputTitle: {
		fontWeight: "600",
		marginTop: ".1em",
		marginBottom: ".9em",
	},
	tableCell: {
		fontWeight: 600,
		backgroundColor: theme.palette.action.selected,
	},
}));

export const EditClient = React.memo((props) => {
	const classes = useStyles();
	const { open, handleClose } = props;
	const {
		load,
		client,
		isNotes,
		updatedClientHandler,
		addClientNotesHandler,
		fileList,
		clientRegistrationMailHandler,
		getRateStructuresHandler,
		rateStructures,
	} = useContext(ClientContext);

	const [tabValue, setTabValue] = useState(0);
	const tabChangeHandler = (event, newValue) => setTabValue(newValue);

	useEffect(() => {
		setTabValue(0);
		// getRateStructuresHandler();
	}, [open]);

	const onSubmitHandler = async (data) => {
		const updateData = { _id: client._id, data };
		await updatedClientHandler(updateData);
	};

	const onSubmitNoteHandler = async (data) => {
		const updateData = { _id: client._id, data };
		await addClientNotesHandler(updateData);
	};

	return (
		<React.Fragment>
			<Dialog
				maxWidth="lg"
				open={open}
				onClose={handleClose}
				fullWidth={true}
				aria-labelledby="form-dialog-title"
				disableBackdropClick
				disableEscapeKeyDown
			>
				<DialogTitle>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="flex-start"
					>
						<Typography
							variant="h6"
							gutterBottom
						>
							{!load ? (
								<>
									{client.companyName}{" "}
									<Chip
										avatar={<Avatar>ID</Avatar>}
										label={client.clientID}
										clickable
										color="primary"
										size="small"
									/>{" "}
								</>
							) : (
								<small>Loading...</small>
							)}
						</Typography>
						<IconButton
							aria-label="close"
							size="small"
							onClick={handleClose}
						>
							<CloseIcon />
						</IconButton>
					</Grid>
					<Tabs
						value={tabValue}
						onChange={tabChangeHandler}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						aria-label="client scrollable"
					>
						<Tab label="Personal Details" />
						<Tab label="Rates" />
						<Tab label="Client Offices" />
						{/* <Tab label="Contact Persons" /> */}
						<Tab label="Notes" />
						<Tab label="Documents" />
						{/* <Tab label="Age Creditor Report" /> */}
						{/* <Tab label="Jobs/Invoices" /> */}
						<Tab label="Activation" />
					</Tabs>
				</DialogTitle>
				<DialogContent>
					{load ? (
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Grid
								item
								xs={12}
							>
								<CircularProgress style={{ margin: "12rem" }} />
							</Grid>
						</Grid>
					) : (
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TabPanel
								value={tabValue}
								index={0}
							>
								<PersonalDetails
									classes={classes}
									handleClose={handleClose}
									data={client}
									handleOnSubmit={onSubmitHandler}
									isRateStructures={rateStructures}
								/>
							</TabPanel>
							<TabPanel
								value={tabValue}
								index={1}
							>
								<ClientRates
									classes={classes}
									handleClose={handleClose}
									data={client}
									handleOnSubmit={onSubmitHandler}
								/>
							</TabPanel>
							<TabPanel
								value={tabValue}
								index={2}
							>
								<ClientOffices
									classes={classes}
									handleClose={handleClose}
									data={client}
									handleOnSubmit={onSubmitHandler}
								/>
							</TabPanel>
							{/* <TabPanel value={tabValue} index={3}>
                Contact Persons
              </TabPanel> */}
							<TabPanel
								value={tabValue}
								index={3}
							>
								<ClientNotes
									classes={classes}
									handleClose={handleClose}
									data={isNotes}
									handleOnSubmit={onSubmitNoteHandler}
								/>
							</TabPanel>
							<TabPanel
								value={tabValue}
								index={4}
							>
								<ClientDocuments
									classes={classes}
									handleClose={handleClose}
									data={client}
									handleOnSubmit={onSubmitHandler}
									list={fileList}
								/>
							</TabPanel>
							{/* <TabPanel value={tabValue} index={5}>
                Age Creditor Report
              </TabPanel> */}
							{/* <TabPanel value={tabValue} index={6}>
                Jobs / Invoices
              </TabPanel> */}
							<TabPanel
								value={tabValue}
								index={5}
							>
								<ClientActivation
									classes={classes}
									handleClose={handleClose}
									data={client}
									handleOnSubmit={onSubmitHandler}
									mailHandler={clientRegistrationMailHandler}
								/>
							</TabPanel>
						</MuiPickersUtilsProvider>
					)}
				</DialogContent>
				{/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={onSubmitHandler}>
            Save
          </Button>
        </DialogActions> */}
			</Dialog>
		</React.Fragment>
	);
});
