import React from 'react';
import Grid from '@material-ui/core/Grid';

import ConfirmPassword from '../../components/pages/confirmPassword/ConfirmPassword';
import { UserProvider } from '../../context/states/userState';
const UserProfile = () => {
  return (
    <React.Fragment>
      <UserProvider>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ConfirmPassword title="Change Password" />
        </Grid>
      </UserProvider>
    </React.Fragment>
  );
};

export default UserProfile;
