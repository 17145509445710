import React, { useMemo } from 'react'
import { Assign } from '../AssignTo/Assign';

const MemorizedAssignTo = ({ isOpen, load, handleClose, data, iData }) => {
    return useMemo(() => (
        <Assign open={isOpen} load={load} handleClose={handleClose} data={iData} booking={data} />
    ), [isOpen, handleClose, data, iData])
}

export default MemorizedAssignTo
