import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';

// pages
import InterpreterDues from './InterpreterDues';
import RemittanceSlips from './RemittanceSlips';
import ClientPayments from './ClientPayments';
import ClientOutstandingOverCPR from './OutstandingOverCPR.js'

// State
import { DuesProvider } from '../../../context/states/interpreterDueState';
import { ClientDueProvider } from '../../../context/states/clientDueState';
import { OutstandingDueProvider } from '../../../context/states/outstandingDueState';

const Payments = () => {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <AuthenticatedRoute exact path={`${path}/interpreterdues`}>
          <DuesProvider>
            <InterpreterDues />
          </DuesProvider>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/remittanceslips`}>
          <RemittanceSlips />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/clientpayments`}>
          <ClientDueProvider>
            <ClientPayments />
          </ClientDueProvider>
        </AuthenticatedRoute>

        <AuthenticatedRoute path={`${path}/outstandingCPR`}>
          <OutstandingDueProvider>
            <ClientOutstandingOverCPR />
          </OutstandingDueProvider>
        </AuthenticatedRoute>
      </Switch>
    </React.Fragment>
  );
};

export default Payments;
