import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

const Translation = React.memo((props) => {
  const { header, data, customerInvoice, updateInvoice, classes } = props;
  const serviceLabel = data.vat ? 'VAT': 'Service Charges'
  let { id } = useParams();
  console.log(id);
  const [isEdit, setIsEdit] = useState(true);
  const toEditHandler = () => {
    setIsEdit(false);
  };

  const formValue = {
    _id: data?._id,
    totalAmount: data?.total,
    discounts: data?.discounts,
    subTotal: data?.subTotal?.toFixed(2),
    serviceChargePercent: data?.adminPercent,
    invTotal: data?.invTotal?.toFixed(2),
    advancePayment: data?.advancePayment,
  };

  const { register, handleSubmit, watch } = useForm({
    mode: 'onChange',
    defaultValues: formValue,
  });

  const discountsWatch = watch('discounts');

  const subTotalAmount = useCallback(() => {
    let total = 0;
    let discount = parseFloat(discountsWatch) || 0;
    total = data?.total - discount;
    return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.total, discountsWatch]);

  const totalPercentage = useCallback(() => {
    let total = 0;
    total = parseFloat((subTotalAmount() * formValue.serviceChargePercent) / 100);
    return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalAmount]);

  const invTotal = useCallback(() => {
    let invTotal = 0.0;
    invTotal = totalPercentage() + subTotalAmount();
    return invTotal.toFixed(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.total, subTotalAmount]);

  const dueTotal = useCallback(() => {
    var advPayment = 0;
    var total = 0;

    advPayment = formValue.advancePayment || 0.0;
    total = invTotal() - advPayment;
    return total;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invTotal]);

  const onSubmit = async (data) => {
    // console.log(data)
    try {
      await updateInvoice(data, id);
      await setIsEdit(true);
    } catch (error) {
      console.log(error);
      await setIsEdit(false);
    }
  };

  return (
    <>
      <input type="hidden" value={formValue._id} ref={register} name="_id" readOnly />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={header}>Status</TableCell>
              <TableCell className={header} align="right">
                Date of Return
              </TableCell>
              <TableCell className={header} align="right">
                Rate per word
              </TableCell>
              <TableCell className={header} align="right">
                Number of pages
              </TableCell>
              <TableCell className={header} align="right">
                Word count
              </TableCell>
              <TableCell className={header} align="right">
                Amount (£)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {data.status}
              </TableCell>
              <TableCell align="right">{moment(data.dateOfReturn).format('DD/MM/Y')}</TableCell>
              <TableCell align="right">{data.costPerWord}p</TableCell>
              <TableCell align="right">{data.pageCount}</TableCell>
              <TableCell align="right">{data.wordCount}</TableCell>
              <TableCell align="right">{data.amount.toFixed(2)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right" colSpan={5}>
                {data.total.toFixed(2)}
              </TableCell>
            </TableRow>
            {customerInvoice && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Discounts
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    {/* {data.discounts.toFixed(2)} */}
                    {isEdit ? (
                      <IconButton aria-label="edit" style={{ marginRight: '16px' }} size="small" onClick={toEditHandler}>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="edit"
                        style={{ marginRight: '16px', marginTop: '12px' }}
                        size="small"
                        onClick={handleSubmit(onSubmit)}
                        color="primary"
                      >
                        <SaveIcon fontSize="inherit" />
                      </IconButton>
                    )}
                    {isEdit ? (
                      data.discounts.toFixed(2)
                    ) : (
                      <TextField
                        label="Amount"
                        size="small"
                        fullWidth
                        variant="filled"
                        type="number"
                        name="discounts"
                        inputRef={register}
                        defaultValue={data?.discounts || 0}
                        style={{ width: '80px' }}
                      />
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Sub Total</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    {/* {data.subTotal.toFixed(2)} */}
                    <input type="text" className={classes.borderLess} name="subTotal" ref={register} value={subTotalAmount().toFixed(2)} readOnly />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    {serviceLabel}
                  </TableCell>
                  <TableCell align="right" colSpan={4}>
                    @{data.adminPercent}%
                  </TableCell>
                  <TableCell align="right">
                    {/* {data.adminCharges.toFixed(2)} */}
                    <input
                      type="text"
                      className={classes.borderLess}
                      name="adminCharges"
                      ref={register}
                      value={totalPercentage().toFixed(2)}
                      readOnly
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Invoice Total</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    {/* {data.advancePayment.toFixed(2)} */}
                    <input type="text" className={classes.borderLess} name="invTotal" ref={register} value={invTotal()} readOnly />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Advance / Others
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    {data.advancePayment.toFixed(2)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <strong>Amount Due</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    {/* {data.grossTotal.toFixed(2)} */}
                    <input type="text" className={classes.borderLess} name="grossTotal" ref={register} value={dueTotal().toFixed(2)} readOnly />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default Translation;
