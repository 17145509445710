import React, { useMemo } from 'react';
import { InvoiceModal } from '../Views/Invoices/Customer/InvoiceModal';

function MemoizedInvoiceModal({ isOpen, load, isInvoice, closeHandler, handleOnSubmit }) {
    return useMemo(
        () => (
            <InvoiceModal open={isOpen} data={isInvoice} loading={load} handleClose={closeHandler} onSubmitHandler={handleOnSubmit} />
        ),
        [isOpen, load, isInvoice, closeHandler, handleOnSubmit]
    )
}

export default MemoizedInvoiceModal;