import React from 'react';

const NotFound = () => {
  return (
    <div style={{ marginTop: '12rem', marginRight: '3rem' }}>
      <h2>Not Found</h2>
    </div>
  );
};

export default NotFound;
