import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
// import { DevTool } from '@hookform/devtools';

import { useForm, Controller } from "react-hook-form";
import { InterpreterContext } from "../../../../../context/states/interpreterState";

import clsx from "clsx";
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
	Paper,
	TextField,
	Switch,
	Select,
	FormControl,
	InputAdornment,
	FormHelperText,
	OutlinedInput,
	MenuItem,
	InputLabel,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	IconButton,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DetailsIcon from "@material-ui/icons/Details";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { ProfileImage } from "../../../../fileUpload/ProfileImage";

import Loader from "../../../../UI/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

export const PersonalDetails = React.memo((props) => {
	const { updateInterpreterHandler, uploadProImageHandler, imgLoading, profileImage, mailHandler } =
		useContext(InterpreterContext);
	const { classes, data, handleClose, isAccessTo, username } = props;
	const today = moment().format();

	// Backdrop loader
	const [isLoading, setIsLoading] = useState(false);
	const backdropCloseHandler = () => setIsLoading(false);

	const [isClicked, setIsClicked] = useState(false);
	const clickPasswordBtn = () => setIsClicked(!isClicked);
	const [isPassword, setisPassword] = useState({ showPassword: false });
	const handleClickShowPassword = () =>
		setisPassword({ ...isPassword, showPassword: !isPassword.showPassword });
	const handleMouseDownPassword = (event) => event.preventDefault();

	const formValue = {
		_id: data._id,
		fname: data.fname,
		lname: data.lname,
		mname: data.mname,
		gender: data.gender,
		dob: data.dob,
		origin: data.origin,
		nationality: data.nationality,
		canDrive: data.canDrive,
		mobile: data.mobile,
		phone: data.phone,
		skype_others: data.skype_others,
		email: data.email,
		address: data.address,
		postcode: data.postcode,
		accountName: data.accountName,
		account_no: data.account_no,
		sort_code: data.sort_code,
		branch: data.branch,
		ua_from: data.ua_from,
		ua_to: data.ua_to,
		ua_reason: data.ua_reason,
		active: data.active,
		active_date: data.active_date,
		act_by: data.act_by,
		act_reason: data.act_reason,
		renewalDueDate: data?.renewalDueDate,
		dbsExpiry: data?.dbsExpiry,
		passportExpiry: data?.passportExpiry,
		vat: data?.vat,
		vatNo: data?.vatNo,
		active_date: data?.active_date,
		businessName: data?.businessName,
	};

	const { register, handleSubmit, errors, control, watch, setValue, formState } = useForm({
		mode: "onChange",
		defaultValues: formValue,
	});

	const activeDate = watch("active_date");
	const active = watch("active");
	const emailID = watch("email");

	// active confirmation mail
	const [isConfirm, setIsConfirm] = useState(false);

	const activeOnChange = (e) => {
		setValue("active", e);
		if (e) {
			setIsConfirm(true);
		}
	};

	const quotationContent = `
  <div style="border: 1px solid  #efefef; padding: 12px 10px;">
    <h4 style="color: #37474f !important; margin: 0; padding: 0; font-family: 'Roboto', sans-serif;">Dear ${data.fullname},</h4>
    <p style="line-height: 28px; font-size: 14px; font-family: Sans-Serif;">
      Thank you for your interest. <br />
      You are registered and active on our system as a freelance Interpreter/ Translator / Transcriber. <br />
      Please find your temporary login password below. You can reset a new password of your choice when you login for the first time. <br />
      <strong>Username:</strong> Use your email address.<br/>
      <strong>Temporary password:</strong> Use your mobile number as password. <br />
      You can login and check the confirmed bookings / submit your timesheets / view remittance slips and invoices for each job carried out. <span style="background: #FFCCCB; color: #000; padding: 2px 4px; border-radius: 3px;">Please submit your timesheets on the day as invoices MUST go out to the clients within 24hrs.</span><br />
      To Logon to your Personal Portal please visit our website https://app.language-interpreters.com/app. We will contact you when your services are required. <br />
      We welcome you on board and look forward to working with you. <br />Kind regards
      </p>
    <div style="font-size: 14px; font-family: Sans-Serif;"><strong>Language Interpreters</strong>
      <p style="line-height: 22px;">T- <a href="tel:020 8123 5556">020 8123 5556</a> <br />E- <a href="mailto:020 8123 5556">info@language-interpreters.com</a> <br />Website- <a href="https://language-interpreters.com/" target="_blank">www.language-interpreters.com </a> <br /></p>
      <img src="${process.env.REACT_APP_LOGO}" alt="logo" width="220" />
      <div style="margin: 20px 0px; border-top: 1px solid #dbdbdb;">
        <p style="font-size: 8px; font-weight: bold; color: #616d7e; text-align: justify;">DISCLAIMER: This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you are not the named addressee you should not disseminate, distribute, copy this e-mail or taking any action in reliance on the contents of this information is strictly prohibited. Please notify the sender immediately by e-mail if you have received this e-mail by mistake and delete from your system..<br />WARNING: Although Language Interpreters has taken reasonable precautions to ensure no viruses are present in this email, Language Interpreters cannot accept responsibility for any loss or damage arising from the use of this email or attachments.</p>
      </div>
    </div>
  </div>
  `;

	const onSendMailHandler = async () => {
		const mailData = {
			to: [{ email: emailID }],
			cc: process.env.REACT_APP_REGISTRATION_EMAIL,
			from: {
				email: process.env.REACT_APP_REGISTRATION_EMAIL,
				name: process.env.REACT_APP_REGISTRATION_EMAIL_SENDER_NAME,
			},
			subject: `Interpreter ${data.int_id} Activation`,
			content: [{ type: "text/html", value: quotationContent }],
		};
		await mailHandler(mailData);
		await onSubmit();
		setIsConfirm(false);
	};

	const closeConfirmHandler = async () => {
		await onSubmit();
		setIsConfirm(false);
	};

	useEffect(() => {}, [register, setValue]);

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			if (data.active) data = { ...data, active_date: new Date().toISOString() };
			await updateInterpreterHandler(data);
			backdropCloseHandler();
		} catch (error) {
			console.log(error);
			backdropCloseHandler();
		}
	};

	return (
		<React.Fragment>
			{/* <DevTool control={control} /> */}
			<form onSubmit={(e) => e.preventDefault()}>
				<Grid
					container
					spacing={2}
				>
					<input
						ref={register}
						name="_id"
						style={{ display: "none" }}
					/>
					{/* left */}
					<Grid
						item
						xs={12}
						md={3}
					>
						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="center"
								alignItems="center"
							>
								<ProfileImage
									classes={classes}
									loading={imgLoading}
									refId={data._id}
									uploadApi={uploadProImageHandler}
									img={profileImage}
									isAccessTo={isAccessTo}
								/>

								{!isAccessTo && (
									<Grid
										item
										xs={12}
									>
										<List>
											<ListItem>
												<ListItemIcon>
													<AccountBoxIcon />
												</ListItemIcon>
												<ListItemText
													id="switch-list-activation"
													primary={active ? "Activated" : "Deactivated"}
													style={{ marginRight: "1.5em" }}
												/>
												<ListItemSecondaryAction>
													{/* <Switch size="small" name="active" defaultChecked={active} inputRef={register} /> */}
													<Switch
														size="small"
														name="active"
														inputRef={register}
														onChange={(e) => activeOnChange(e.target.checked)}
														defaultChecked={active}
													/>
												</ListItemSecondaryAction>
											</ListItem>
											{/*  */}

											<ListItem>
												<ListItemIcon>
													<DetailsIcon />
												</ListItemIcon>
												<ListItemText
													primary={
														<React.Fragment>
															<Typography
																component="span"
																variant="body2"
																color="textPrimary"
															>
																{!active ? "De-activated by: " : "Activated by: "}
															</Typography>
															{/* {!active ? activeBy : activeBy} */}
															{data.act_by}
														</React.Fragment>
													}
													secondary={
														<React.Fragment>
															<Typography
																component="span"
																variant="body2"
																color="textPrimary"
															>
																{!active ? "Date: " : "Date: "}
															</Typography>
															{/* {!active ? activeDate : 'date'} */}
															{activeDate
																? moment(activeDate).format("DD-MM-yyyy")
																: moment(activeDate).format("DD-MM-yyyy")}
														</React.Fragment>
													}
												/>
											</ListItem>
										</List>
									</Grid>
								)}
								<Grid
									container
									direction="column"
									justify="center"
									alignItems="center"
									spacing={2}
								>
									<React.Fragment>
										{active ? (
											<Grid
												item
												xs={12}
											>
												{!isClicked && (
													<Button
														size="small"
														startIcon={<VpnKeyIcon />}
														variant="outlined"
														color="primary"
														onClick={clickPasswordBtn}
														disabled={isAccessTo}
													>
														Password
													</Button>
												)}
												{isClicked && (
													<FormControl
														className={clsx(classes.margin, classes.textField)}
														variant="outlined"
														size="small"
														fullWidth
													>
														<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
														<OutlinedInput
															id="outlined-adornment-password"
															type={isPassword.showPassword ? "text" : "password"}
															name="password"
															inputRef={register({
																required: true,
																minLength: {
																	value: 4,
																	message: "Password must have at least 4 letters",
																},
																maxLength: {
																	value: 15,
																	message: "Password maximum characters limit is 15",
																},
															})}
															error={!!errors.password}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		onMouseDown={handleMouseDownPassword}
																		edge="end"
																	>
																		{isPassword.showPassword ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
															}
															labelWidth={70}
														/>

														<FormHelperText style={{ color: "red" }}>
															{errors.password && errors.password.message}
														</FormHelperText>
													</FormControl>
												)}
											</Grid>
										) : (
											<Grid
												item
												xs={12}
											>
												<TextField
													id="filled-multiline-static"
													label="Reason for deactivation"
													multiline
													rows={3}
													variant="outlined"
													name="act_reason"
													inputRef={register({
														required: false,
														maxLength: { value: 255, message: "Maximum characters limit is 255" },
													})}
													fullWidth
												/>
											</Grid>
										)}
									</React.Fragment>
								</Grid>
							</Grid>
						</Paper>
						<input
							type="hidden"
							name="act_by"
							ref={register}
							defaultValue={username}
						/>
						<input
							type="hidden"
							name="updatedAt"
							ref={register}
							defaultValue={today}
						/>
						<Grid
							container
							direction="row"
							justify="space-between"
							alignItems="flex-start"
						>
							<Grid item>
								<small>Created on: {moment(data.createdAt).format("DD-MM-yyyy")}</small>
							</Grid>
							<Grid item>
								<small>Updated on: {moment(data.updatedAt).format("DD-MM-yyyy")}</small>
							</Grid>
						</Grid>
					</Grid>
					{/* end left */}
					{/* right */}
					<Grid
						item
						xs={12}
						md={9}
					>
						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingBottom: "5px" }}
									color="primary"
								>
									Interpreter
								</Typography>

								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
										sm={5}
										md={4}
									>
										<TextField
											id="fore-name"
											label="Fore Name"
											size="small"
											fullWidth
											variant="outlined"
											name="fname"
											inputRef={register({
												required: "Forename is required",
												minLength: { value: 2, message: "Must have at least 2 letters" },
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.fname}
											helperText={errors.fname && errors.fname.message}
											autoFocus
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={2}
										md={4}
									>
										<TextField
											id="middle-name"
											label="Middle Name"
											size="small"
											fullWidth
											variant="outlined"
											name="mname"
											inputRef={register({
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.mname}
											helperText={errors.mname && errors.mname.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={5}
										md={4}
									>
										<TextField
											id="last-name"
											label="Last Name"
											size="small"
											fullWidth
											variant="outlined"
											name="lname"
											inputRef={register({
												required: "Lastname is required",
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.lname}
											helperText={errors.lname && errors.lname.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<FormControl
											variant="outlined"
											size="small"
											className={classes.formControl}
										>
											<InputLabel id="gender-label">Gender</InputLabel>
											<Controller
												as={
													<Select
														labelId="gender-label"
														id="interpreter-gender"
														name="gender"
													>
														<MenuItem value="">
															<em>None</em>
														</MenuItem>
														<MenuItem value={"male"}>Male</MenuItem>
														<MenuItem value={"female"}>Female</MenuItem>
														<MenuItem value={"other"}>Other</MenuItem>
													</Select>
												}
												control={control}
												name="gender"
												rules={{ required: true }}
												error={!!errors.gender}
												labelWidth={55}
												disabled={isAccessTo}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="dob"
													label="Date of birth"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.dob}
													helperText={errors.dob && errors.dob.message}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="dob"
											rules={{ required: true }}
											error={!!errors.dob}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="origin"
											label="Origin"
											size="small"
											fullWidth
											variant="outlined"
											name="origin"
											inputRef={register({
												required: true,
												minLength: { value: 2, message: "Must have at least 2 letters" },
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.origin}
											helperText={errors.origin && errors.origin.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="nationality"
											label="Nationality"
											size="small"
											fullWidth
											variant="outlined"
											name="nationality"
											inputRef={register({
												required: true,
												minLength: { value: 2, message: "Must have at least 2 letters" },
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.nationality}
											helperText={errors.nationality && errors.nationality.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<FormControl
											variant="outlined"
											size="small"
											className={classes.formControl}
										>
											<InputLabel id="drive-label">Drive</InputLabel>
											<Controller
												as={
													<Select
														labelId="drive-label"
														id="interpreter-drive"
														name="canDrive"
														defaultValue={false}
													>
														<MenuItem value={true}>Yes</MenuItem>
														<MenuItem value={false}>No</MenuItem>
													</Select>
												}
												control={control}
												name="canDrive"
												rules={{ required: false }}
												error={!!errors.canDrive}
												labelWidth={55}
												disabled={isAccessTo}
											/>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="dob"
													label="Renewal Due Date"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.renewalDueDate}
													helperText={errors.renewalDueDate && errors.renewalDueDate.message}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="renewalDueDate"
											rules={{ required: true }}
											error={!!errors.renewalDueDate}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="businessName"
											label="Business Name"
											size="small"
											fullWidth
											variant="outlined"
											name="businessName"
											inputRef={register({
												required: false,
											})}
											error={!!errors.businessName}
											helperText={errors.businessName && errors.businessName.message}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="dbsExpiry"
													label="DBS Expiry"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.dbsExpiry}
													helperText={errors.dbsExpiry && errors.dbsExpiry.message}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="dbsExpiry"
											rules={{ required: true }}
											error={!!errors.dbsExpiry}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="passportExpiry"
													label="Passport Expiry"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													error={!!errors.passportExpiry}
													helperText={errors.passportExpiry && errors.passportExpiry.message}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="passportExpiry"
											rules={{ required: true }}
											error={!!errors.passportExpiry}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="vatNo"
											label="VAT No"
											size="small"
											fullWidth
											variant="outlined"
											name="vatNo"
											inputRef={register({
												required: false,
												minLength: { value: 1, message: "Must have at least 1 digits" },
												maxLength: { value: 20, message: "Maximum digits limit is 20" },
												pattern: {
													value: /^[0-9]+$/,
													message: "Invalid vatNo number",
												},
											})}
											error={!!errors.vatNo}
											helperText={errors.vatNo && errors.vatNo.message}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<FormControlLabel
											inputRef={register}
											control={
												<Checkbox
													name="vat"
													color="primary"
													defaultChecked={data !== undefined ? data?.vat : false}
												/>
											}
											label="VAT Registered"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>

						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
								spacing={1}
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingButton: "5px" }}
									color="primary"
								>
									Contact Details
								</Typography>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="mobile"
											label="Mobile"
											size="small"
											fullWidth
											variant="outlined"
											name="mobile"
											inputRef={register({
												required: true,
												minLength: { value: 10, message: "Must have at least 10 digits" },
												maxLength: { value: 20, message: "Maximum digits limit is 20" },
												pattern: {
													value: /^[0-9]+$/,
													message: "Invalid mobile number",
												},
											})}
											error={!!errors.mobile}
											helperText={errors.mobile && errors.mobile.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="phone"
											label="Phone"
											size="small"
											fullWidth
											variant="outlined"
											name="phone"
											inputRef={register({
												required: true,
												pattern: {
													value: /^[0-9]+$/,
													message: "Invalid phone number",
												},
												minLength: { value: 10, message: "Must have at least 10 digits" },
												maxLength: { value: 20, message: "Maximum digits limit is 20" },
											})}
											error={!!errors.phone}
											helperText={errors.phone && errors.phone.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
									>
										<TextField
											id="other"
											label="Skype / others"
											size="small"
											fullWidth
											variant="outlined"
											name="skype_others"
											inputRef={register({
												required: false,
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
											})}
											error={!!errors.others}
											helperText={errors.others && errors.others.message}
											disabled={isAccessTo}
										/>
									</Grid>

									<Grid
										item
										xs={12}
										sm={4}
										md={3}
									>
										<TextField
											id="email"
											label="Email"
											size="small"
											fullWidth
											variant="outlined"
											name="email"
											InputProps={{
												readOnly: false,
											}}
											inputRef={register({
												required: true,
												minLength: { value: 5, message: "Must have at least 5 letters" },
												maxLength: { value: 50, message: "Maximum characters limit is 50" },
												pattern: {
													value:
														/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: "Invalid email-id",
												},
											})}
											error={!!errors.email}
											helperText={errors.email && errors.email.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										md={6}
									>
										<TextField
											id="address"
											label="Address"
											size="small"
											fullWidth
											variant="outlined"
											name="address"
											inputRef={register({
												required: true,
												minLength: { value: 5, message: "Must have at least 5 letters" },
												maxLength: { value: 100, message: "Maximum characters limit is 100" },
											})}
											error={!!errors.address}
											helperText={errors.address && errors.address.message}
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										md={3}
									>
										<TextField
											id="postcode"
											label="Postcode"
											size="small"
											fullWidth
											variant="outlined"
											name="postcode"
											inputRef={register({
												required: true,
												pattern: {
													value:
														/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
													message: "Invalid postcode",
												},
												minLength: { value: 5, message: "Must have at least 5 letters" },
												maxLength: { value: 10, message: "Maximum characters limit is 10" },
												// validate: async (value) => (await postcodeHandler(value)) || 'error message',
											})}
											error={!!errors.postcode}
											helperText={errors.postcode && errors.postcode.message}
											disabled={isAccessTo}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
						{!isAccessTo && (
							<Paper
								className={classes.paper}
								variant="outlined"
							>
								<Grid
									container
									direction="column"
									justify="flex-start"
									alignItems="flex-start"
								>
									<Typography
										variant="subtitle2"
										gutterBottom
										style={{ fontWeight: "600", paddingBotton: "5px" }}
										color="primary"
									>
										Bank Details
									</Typography>
									<Grid
										container
										spacing={2}
									>
										<Grid
											item
											xs={12}
											sm={4}
											md={4}
										>
											<TextField
												id="acc-no"
												label="Account Name"
												size="small"
												fullWidth
												variant="outlined"
												name="accountName"
												inputRef={register({
													required: true,
													maxLength: { value: 50, message: "Maximum characters limit is 50" },
												})}
												error={!!errors.accountName}
												helperText={errors.accountName && errors.accountName.message}
												disabled={isAccessTo}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={4}
											md={4}
										>
											<TextField
												id="acc-no"
												label="Account No"
												size="small"
												fullWidth
												variant="outlined"
												name="account_no"
												inputRef={register({
													required: true,
													pattern: { value: /^\d+$/, message: "Invalid account no" },
													minLength: { value: 5, message: "Must have at least 10 digits" },
													maxLength: { value: 30, message: "Maximum digits limit is 30" },
												})}
												error={!!errors.account_no}
												helperText={errors.account_no && errors.accountNo.message}
												disabled={isAccessTo}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={4}
											md={4}
										>
											<TextField
												id="sort-code"
												label="Sort Code"
												size="small"
												fullWidth
												variant="outlined"
												name="sort_code"
												inputRef={register({
													required: true,
													pattern: { value: /^\d+$/, message: "Invalid sortcode" },
													minLength: { value: 5, message: "Must have at least 5 digits" },
													maxLength: { value: 30, message: "Maximum digits limit is 30" },
												})}
												error={!!errors.sort_code}
												helperText={errors.sort_code && errors.sort_code.message}
												disabled={isAccessTo}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={4}
											md={4}
										>
											<TextField
												id="branch"
												label="Bank / Branch"
												size="small"
												fullWidth
												variant="outlined"
												name="branch"
												inputRef={register({
													required: true,
													minLength: { value: 5, message: "Must have at least 5 letters" },
													maxLength: { value: 30, message: "Maximum characters limit is 30" },
												})}
												error={!!errors.branch}
												helperText={errors.branch && errors.branch.message}
												disabled={isAccessTo}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						)}
						<Paper
							className={classes.paper}
							variant="outlined"
						>
							<Grid
								container
								direction="column"
								justify="flex-start"
								alignItems="flex-start"
							>
								<Typography
									variant="subtitle2"
									gutterBottom
									style={{ fontWeight: "600", paddingBottom: "5px" }}
									color="primary"
								>
									Unavailability
								</Typography>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="fromDate"
													label="From"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="ua_from"
											rules={{ required: false }}
											error={!!errors.ua_from}
											defaultValue=""
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={6}
										md={3}
									>
										<Controller
											as={
												<KeyboardDatePicker
													autoOk
													format="dd/MM/yyyy"
													id="toDate"
													label="To"
													size="small"
													variant="inline"
													fullWidth
													inputVariant="outlined"
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
												/>
											}
											control={control}
											name="ua_to"
											rules={{ required: false }}
											error={!!errors.ua_to}
											defaultValue=""
											disabled={isAccessTo}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
									>
										<TextField
											id="unavail-reason"
											label="Reason"
											size="small"
											fullWidth
											variant="outlined"
											name="ua_reason"
											inputRef={register({
												required: false,
												maxLength: { value: 255, message: "Maximum characters limit is 255" },
											})}
											error={!!errors.ua_reason}
											helperText={errors.ua_reason && errors.ua_reason.message}
											disabled={isAccessTo}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				{/* end right */}
				{!isAccessTo && (
					<Grid
						container
						direction="row"
						justify="flex-end"
						alignItems="flex-end"
					>
						<div className={classes.actionBtn}>
							<Button
								size="small"
								color="primary"
								onClick={handleClose}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								size="small"
								variant="contained"
								color="primary"
								disabled={formState.isSubmitting}
								onClick={handleSubmit(onSubmit)}
							>
								Update
							</Button>
						</div>
					</Grid>
				)}
			</form>

			<Dialog
				open={isConfirm}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="interpreter-active"
				aria-describedby="activation-email-description"
			>
				<DialogTitle id="interpreter-active">{"Mail Send Confirmation"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="activation-email-description">
						Do you want to send activation mail?.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={closeConfirmHandler}
						color="primary"
					>
						No
					</Button>
					<Button
						onClick={onSendMailHandler}
						color="primary"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Loader
				open={isLoading}
				handleClose={backdropCloseHandler}
			/>
		</React.Fragment>
	);
});

PersonalDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
};
