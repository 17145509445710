import React from "react";

import DashboardIcon from '@material-ui/icons/Dashboard';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RepeatIcon from '@material-ui/icons/Repeat';

export const interpreterMenu = [
    // {
    //     icon: <DashboardIcon fontSize="small" />,
    //     title: "Dashboard",
    //     to: '/dashboard',
    //     user: ['interpreter']
    // },
    {
        icon: <AccessTimeIcon fontSize="small" />,
        title: 'Timesheet',
        to: '/timesheetTracker',
        user: ['interpreter']
    },
    {
        icon: <RepeatIcon fontSize="small" />,
        title: "Remittances",
        to: '/remittances',
        user: ['interpreter']
    },
];
