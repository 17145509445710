import React, { useCallback, useState } from 'react'
import moment from 'moment';
import { TableRow, TableCell, Table, TableContainer, TableBody, Typography, CircularProgress, Card, CardContent } from '@material-ui/core';
import { EnhancedTableHead } from './index';

const accountHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'company', numeric: false, disablePadding: false, label: 'Company Name' },
    { id: 'invoiceNo', numeric: false, disablePadding: false, label: 'Booking Ref' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Date of attendance' },
    { id: 'customer', numeric: false, disablePadding: false, label: 'Invoice No / Invoice Date' },
    { id: 'language', numeric: false, disablePadding: false, label: 'Language / Service' },
    { id: 'interpreter', numeric: false, disablePadding: false, label: 'Invoice Amount' },
    { id: 'attendance', numeric: false, disablePadding: false, label: 'Date Paid' },
    { id: 'invoiceAmount', numeric: false, disablePadding: false, label: 'Paid Amount (£)' },
    { id: 'outstanding', numeric: false, disablePadding: false, label: 'Outstanding (£)' },
    { id: 'ipPayment', numeric: false, disablePadding: false, label: 'IP Payment' },
    { id: 'ip', numeric: false, disablePadding: false, label: 'IP' },
    { id: 'serviceCharges', numeric: true, disablePadding: false, label: 'Service Charges' },
    { id: 'vatAmount', numeric: true, disablePadding: false, label: 'VAT' },
    { id: 'account', numeric: true, disablePadding: true, label: 'LIL Account (£)' },
];

const paidHeadCells = [
    { id: 'i', numeric: false, disablePadding: false, label: 'Sl.No' },
    { id: 'company', numeric: false, disablePadding: false, label: 'Company Name' },
    { id: 'paymentRef', numeric: false, disablePadding: false, label: 'Payment Ref' },
    { id: 'paymentDate', numeric: false, disablePadding: false, label: 'Payment Date' },
    { id: 'totalAmount', numeric: false, disablePadding: false, label: 'Total Amount (£)' },
    { id: 'refundAmount', numeric: false, disablePadding: false, label: 'Refund Amount (£)' },
    { id: 'refundDate', numeric: false, disablePadding: false, label: 'Refund Date' },
    { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' },
];

const styles = {
    fontSize: '13px',padding: '6px'
}

const CardItem = ({ title, active, classes, data, isLoading, matches, account, refund }) => {

    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('company');

    const handleRequestSort = useCallback((event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [setOrder, setOrderBy, orderBy]);

    const handleSelectAllClick = useCallback((event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }, [setSelected]);

    return (
        <>
            <Card className={active}>
                <CardContent style={{ padding: '8px 6px' }}>
                    <Typography variant="body1" noWrap>
                        {title}
                    </Typography>
                    <TableContainer className={matches ? classes.container2 : classes.container} >
                        <Table stickyHeader aria-label="sticky table" className={classes.table, 'custable '} size={'small'}>
                            {account && <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={accountHeadCells}
                            />}
                            {refund && <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={data.length}
                                headCells={paidHeadCells}
                            />}
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : data.length === 0 ? (
                                    <TableRow hover>
                                        <TableCell style={{ textAlign: 'center' }} rowSpan={6} colSpan={13}>
                                            No record
                                        </TableCell>
                                    </TableRow>
                                ) : (

                                    data.map((row, index) => {
                                        let amount = row?.invTotal - row?.balanceAmount
                                        let lilamount = row?.invTotal - row?.IPPaid - row?.vatAmount;
                                        return (
                                            <TableRow hover key={row._id + index}>
                                                <TableCell style={styles}>
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell style={styles} component="th" scope="row">
                                                    <b>{row.companyName || row.company}</b>
                                                </TableCell>
                                                
                                                {!refund && <TableCell style={styles}>
                                                    {row.booking_ref || row.bookingRef}
                                                </TableCell>}
                                                
                                                {!refund && <TableCell style={styles} align="left">
                                                    {moment(row.date).format('DD/MM/YYYY')}
                                                </TableCell>}

                                                {!refund && <TableCell style={styles} align="left">
                                                    {row.invoiceNo} <br />
                                                    <small>{moment(row.invoiceDate).format('DD/MM/YYYY')}</small>
                                                </TableCell>}

                                                {!refund && <TableCell style={styles} align="left">
                                                    <strong>{row.language}</strong> <br />
                                                    <small>{row.serviceType}</small>
                                                </TableCell>}
                                                
                                                {!refund && <TableCell style={styles} align="left">{row?.invTotal?.toFixed(2)}</TableCell>}
                                                {!refund && <TableCell style={styles} align="left">{moment(row.paiddate).format('DD/MM/YYYY')}</TableCell>}
                                                {!refund && <TableCell style={styles} align="left">{amount.toFixed(2)}</TableCell>}
                                                {!refund && <TableCell style={styles} align="left">{row?.balanceAmount?.toFixed(2)}</TableCell>}
                                                
                                                {account && <TableCell style={styles} align="left"> {row?.IPPaid?.toFixed(2)} </TableCell>}
                                                {account && <TableCell style={styles} align="left"> <b>{row?.interpreterName}</b> </TableCell>}
                                                {account && <TableCell style={styles} align="right"> {row?.serviceCharges.toFixed(2)} </TableCell>}
                                                {account && <TableCell style={styles} align="right"> {row?.vatAmount.toFixed(2)} </TableCell>}
                                                {account && <TableCell style={styles} align="right"> {lilamount.toFixed(2)} </TableCell>}
                                                
                                                {refund &&  <TableCell style={styles}>{row.paymentRef}</TableCell>}
                                                {refund && <TableCell style={styles} align="left"> {moment(row?.paymentDate).format('DD/MM/YYYY')} </TableCell>}
                                                {refund && <TableCell style={styles} align="left"> {row?.totalAmount.toFixed(2)} </TableCell>}
                                                {refund && <TableCell style={styles} align="left"> {row?.refundAmount.toFixed(2)} </TableCell>}
                                                {refund && <TableCell style={styles} align="left"> {moment(row?.refundDate).format('DD/MM/YYYY')} </TableCell>}
                                                {refund && <TableCell style={styles} align="left"> {row?.notes} </TableCell>}

                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </>
    )
}

export default React.memo(CardItem)
