import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      console.log('loaded');
      return {
        ...state,
        loading: true,
        bookingCounts: [],
        todayBookings: [],
        tomorrowBookings: [],
        error: null,
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        bookingCounts: action.payload.data,
      };

    case 'FETCH_TODAY_BOOKINGS':
      return {
        ...state,
        loading: false,
        error: null,
        todayBookings: action.payload.data,
      };

    case 'FETCH_TOMORROW_BOOKINGS':
      return {
        ...state,
        loading: false,
        error: null,
        tomorrowBookings: action.payload.data,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        load: false,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
