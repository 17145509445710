import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm } from 'react-hook-form';
import { Grid, Paper, Typography, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';

import { InterpreterContext } from '../../../../../context/states/interpreterState';

import Loader from '../../../../UI/Loader';
// multipleRates
export const Rates = (props) => {
  const { updateInterpreterHandler } = useContext(InterpreterContext);
  const { classes, data, handleClose, isAccessTo } = props;
  const today = moment().format();

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      _id: data._id,
      rates: data.rates,
      multipleRates: data.multipleRates
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await updateInterpreterHandler(data);
      backdropCloseHandler();
    } catch (error) {
      console.log(error);
      backdropCloseHandler();
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="hidden" name="updatedAt" ref={register} defaultValue={today} />
        <input ref={register} name="_id" style={{ display: 'none' }} />
        <Grid container spacing={1}>
          {/* Translation and Interpretation fields */}
          <Grid item xs={12} sm={6} md={4}>
            {/* Translations */}
            <Paper className={classes.paper} variant="outlined" elevation={3}>
              <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                  Translations
                </Typography>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                          Urgent
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="service-translation-urgentPerWord"
                          style={{ width: 100 }}
                          label="Per word"
                          variant="filled"
                          size="small"
                          name="rates.service.translation.rate.urgent.perWord"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.translation?.rate?.urgent?.perWord || 0.08}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="service-translation-urgentMinCharges"
                          style={{ width: 100 }}
                          label="Minimum"
                          variant="filled"
                          size="small"
                          name="rates.service.translation.rate.urgent.minCharges"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.translation?.rate?.urgent?.minCharges || 25.0}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                          Non-urgent
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="service-translation-nonUrgentPerWord"
                          style={{ width: 100 }}
                          label="Per word"
                          variant="filled"
                          size="small"
                          name="rates.service.translation.rate.nonUrgent.perWord"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.translation?.rate?.nonUrgent?.perWord || 0.06}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="service-translation-nonUrgentMinCharges"
                          style={{ width: 100 }}
                          label="Minimum"
                          variant="filled"
                          size="small"
                          name="rates.service.translation.rate.nonUrgent.minCharges"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.translation?.rate?.nonUrgent?.minCharges || 20.0}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Notes */}
                <Grid item xs={10}>
                  <Typography variant="caption" gutterBottom color="secondary">
                    Minimum document threshold 0-300 words <br />
                    Above 301 words - paid at 8p or 6p depending on the urgency.
                  </Typography>
                  <Typography variant="caption" gutterBottom color="secondary">
                    Urgent - with in 3 days
                  </Typography>
                  <br />
                  <Typography variant="caption" gutterBottom color="secondary">
                    Non urgent - after 5 days+
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            {/* Interpretation */}
            <Paper className={classes.paper} variant="outlined" elevation={3}>
              <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                  Interpretation
                </Typography>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                  {/* 0-5 */}
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      Rate
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      Waiting
                    </Typography>
                  </Grid>
                  {/* 5-10 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      Onsite
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-onsite-attendance"
                      style={{ width: 100 }}
                      label="Per hour"
                      variant="filled"
                      size="small"
                      name="rates.service.onsite.rate.attendance"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.onsite?.rate?.attendance || 15.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-onsite-waiting"
                      style={{ width: 100 }}
                      label="Per hour"
                      variant="filled"
                      size="small"
                      name="rates.service.onsite.rate.waiting"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.onsite?.rate?.waiting || 12.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  {/* 10-15 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      Video Call
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-video-rate"
                      style={{ width: 100 }}
                      label="Per hour"
                      variant="filled"
                      size="small"
                      name="rates.service.video"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.video || 15.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-video-waiting"
                      style={{ width: 100 }}
                      label="Per hour"
                      variant="filled"
                      size="small"
                      name="rates.service.vWaiting"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.vWaiting || 12.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  {/* 15-30 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      Telephone
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-telephone-rate"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.telephone"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.telephone || 0.3}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-telephone-waiting"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.tWaiting"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.tWaiting || 0.3}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                </Grid>
                {/* Notes */}
                <Grid item xs={10}>
                  <Typography variant="caption" gutterBottom color="secondary">
                    Telephone- Minimum 30 mins
                  </Typography>{' '}
                  <br />
                  <Typography variant="caption" gutterBottom color="secondary">
                    Onsite/Video - Minimum 1hour
                  </Typography>
                </Grid>
              </Grid>

              <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                {/* Onsite*/}
                <Grid item xs={4}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                    Travel in
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                    Travel out
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                    Mileage
                  </Typography>
                </Grid>

                {/* Travel in */}
                <Grid item xs={4}>
                  <TextField
                    id="service-onsite-travelIn"
                    style={{ width: 100 }}
                    label="Per hour"
                    variant="filled"
                    size="small"
                    name="rates.service.onsite.rate.travelIn"
                    inputRef={register}
                    defaultValue={data?.rates?.service?.onsite?.rate?.travelIn || 12.0}
                    type="number"
                    disabled={isAccessTo}
                  />
                </Grid>
                {/* Travel out */}
                <Grid item xs={4}>
                  <TextField
                    id="service-onsite-travelOut"
                    style={{ width: 100 }}
                    label="Per hour"
                    variant="filled"
                    size="small"
                    name="rates.service.onsite.rate.travelOut"
                    inputRef={register}
                    defaultValue={data?.rates?.service?.onsite?.rate?.travelOut || 12.0}
                    type="number"
                    disabled={isAccessTo}
                  />
                </Grid>
                {/* Mileage */}
                <Grid item xs={4}>
                  <TextField
                    id="service-onsite-mileage"
                    style={{ width: 100 }}
                    label="Per mile"
                    variant="filled"
                    size="small"
                    name="rates.service.onsite.rate.milage"
                    inputRef={register}
                    defaultValue={data?.rates?.service?.onsite?.rate?.milage || 0.35}
                    type="number"
                    disabled={isAccessTo}
                  />
                </Grid>
                {/* Notes */}
                <Grid item xs={10}>
                  <Typography variant="caption" gutterBottom color="secondary">
                    Parking / Fares - as incurred / based on evidence
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* Transcription and rates fields */}
          <Grid item xs={12} sm={6} md={4}>
            {/* Transcriptions */}
            <Paper className={classes.paper} variant="outlined" elevation={3}>
              <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                  Transciptions
                </Typography>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                          Urgent
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="service-transcription-perMin"
                          style={{ width: 100 }}
                          label="Per minute"
                          variant="filled"
                          size="small"
                          name="rates.service.transcription.urgent"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.transcription?.urgent || 8}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                          Non-urgent
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          style={{ width: 100 }}
                          label="Per minute"
                          variant="filled"
                          size="small"
                          name="rates.service.transcription.nonUrgent"
                          inputRef={register}
                          defaultValue={data?.rates?.service?.transcription?.nonUrgent || 6}
                          type="number"
                          disabled={isAccessTo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Notes */}
                <Grid item xs={10}>
                  <Typography variant="caption" gutterBottom color="secondary">
                    Urgent - with in 3 days
                  </Typography>{' '}
                  <br />
                  <Typography variant="caption" gutterBottom color="secondary">
                    Non urgent - after 5 days+
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {/* rates */}
            <Paper className={classes.paper} variant="outlined" elevation={3}>
              <div style={{ padding: '.73rem 0' }}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                  {/* 0-5 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      0-5 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-transcription-perMin"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins05.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins05?.urgent || 40.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-transcription-perMin"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins05.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins05?.nonUrgent || 30.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>

                  {/* 5-10 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      05-10 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-0510-urgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins510.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins510?.urgent || 80.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-0510-nonUrgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins510.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins510?.nonUrgent || 60.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>

                  {/* 10-15 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      10-15 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-1015-urgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins1015.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins1015?.urgent || 120.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-1015-nonUrgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins1015.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins1015?.nonUrgent || 90.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  {/* 15-30 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      15-30 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-1530-urgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins1530.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins1530?.urgent || 240.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-1530-nonUrgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins1530.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins1530?.nonUrgent || 180.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  {/* 30-45 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      30-45 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-3045-urgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins3045.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins3045?.urgent || 360.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-3045-nonUrgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.mins3045.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.mins3045?.nonUrgent || 270.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  {/* 45-60 */}
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="textSecondary">
                      45-60 mins
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-4501-urgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.hour4501.urgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.hour4501?.urgent || 480.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="service-4501-nonUrgent"
                      style={{ width: 100 }}
                      label="Per minute"
                      variant="filled"
                      size="small"
                      name="rates.service.transcription.rate.hour4501.nonUrgent"
                      inputRef={register}
                      defaultValue={data?.rates?.service?.transcription?.rate?.hour4501?.nonUrgent || 360.0}
                      type="number"
                      disabled={isAccessTo}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
                  <FormControlLabel
                    inputRef={register}
                    control={<Checkbox name="multipleRates" color="primary" defaultChecked={data !== undefined ? data.multipleRates : false} />}
                    label="Multiple Rates"
                  />
                </Grid>
        </Grid>
        {!isAccessTo &&
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <div className={classes.actionBtn}>
              <Button size="small" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" size="small" variant="contained" color="primary" disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
                Update
              </Button>
            </div>
          </Grid>
        }
      </form>
      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
};

Rates.prototype = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
