import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

export default function Preview(props) {
  const { open, handleClose, data } = props;
  let displayContent = 'loading';
  const serviceLabel = data?.vat ? 'Vat': 'Service Charges'
  if (data.serviceType === 'Telephone' || data.serviceType === 'Video Call') {
    displayContent = (
      <>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>Description</TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>
              {data?.serviceType === 'Video Call' && 'Hours (hh:mm)'}
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              {data?.serviceType === 'Telephone' ? 'Charges in £' : 'Minimum rate (£)'}
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Amount (£)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.serviceType === 'Telephone' && (
            <TableRow>
              <TableCell>Cost per minute</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">{data?.quotation?.costPerMin}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>{data?.serviceType === 'Telephone' ? 'Number of minutes' : 'Duration'}</TableCell>
            <TableCell>{data?.durationMin}</TableCell>
            <TableCell align="right">{data?.serviceType === 'Video Call' && data?.quotation?.costPerHour.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalDurationAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Waiting</TableCell>
            <TableCell>{data?.estimatedWaiting}</TableCell>
            <TableCell align="right">{data?.quotation?.waitingMinRate?.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalWaitingAmount?.toFixed(2)}</TableCell>
          </TableRow>
          {data?.serviceType === 'Telephone' && (
            <TableRow>
              <TableCell>Minimum charges & Duration costs</TableCell>
              <TableCell>{data?.quotation?.durationTeleAndVideoInMins}</TableCell>
              <TableCell align="right">{data?.quotation?.totalTeleAndVideoAmount?.toFixed(2)}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalAmount?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              Discounts
            </TableCell>
            <TableCell align="right">{data?.quotation?.discounts?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <strong>Sub Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.subTotalAmount?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>{serviceLabel}</TableCell>
            <TableCell align="right">@{data?.serviceChargePercent}%</TableCell>
            <TableCell align="right">{data?.quotation?.totalPercentage?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>Advance Payment</TableCell>
            <TableCell align="right">{data?.advancePayment?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>Grand Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.grandTotal?.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  } else if (data.serviceType === 'Onsite') {
    displayContent = (
      <>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>Description</TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>Hours (hh:mm)</TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Minimum rate per hour (£)
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Amount (£)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Attendance 1</TableCell>
            <TableCell>{data?.attendanceOne}</TableCell>
            <TableCell align="right">{data?.quotation?.attendanceOneMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalAttendanceOneAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Attendance 2</TableCell>
            <TableCell>{data?.attendanceTwo}</TableCell>
            <TableCell align="right">{data?.quotation?.attendanceTwoMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalAttendanceTwoAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Travel inward charges</TableCell>
            <TableCell>{data?.estimatedTravelIn}</TableCell>
            <TableCell align="right">{data?.quotation?.travelInMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalTravelInAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Travel outward charges</TableCell>
            <TableCell>{data?.estimatedTravelOut}</TableCell>
            <TableCell align="right">{data?.quotation?.travelOutMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalTravelOutAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Waiting time</TableCell>
            <TableCell>{data?.estimatedWaiting}</TableCell>
            <TableCell align="right">{data?.quotation?.waitingMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalWaitingAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Parking charges</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">As per evidence</TableCell>
            <TableCell align="right">{data?.parkingFee.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mileage</TableCell>
            <TableCell>{data?.estimatedMileage}</TableCell>
            <TableCell align="right">{data?.quotation?.mileageMinRate.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation.totalMileageAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Travel fares</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">As per evidence</TableCell>
            <TableCell align="right">{data?.estimatedFares.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalAmount?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              Discounts
            </TableCell>
            <TableCell align="right">{data?.quotation?.discounts?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={3}>
              <strong>Sub Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.subTotalAmount?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>{serviceLabel}</TableCell>
            <TableCell align="right">@{data?.serviceChargePercent}%</TableCell>
            <TableCell align="right">{data?.quotation?.totalPercentage?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>Advance Payment</TableCell>
            <TableCell align="right">{data?.advancePayment?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <strong>Grand Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.grandTotal?.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  } else if (data.serviceType === 'Transcription') {
    displayContent = (
      <>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>Status</TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right"></TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Hours (hh:mm)
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Minimum rate (£)
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Amount (£)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data.urgency === 'urgent' ? 'Urgent' : 'Non Urgent'}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{data?.quotation?.minimumUrgencyAndNonUrgencyCharge.toFixed(2)}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Transcription cost per min <small>(charged over 60mins)</small>
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{data?.durationMin}</TableCell>
            <TableCell align="right">{data?.quotation?.costPerMin.toFixed(2)}</TableCell>
            <TableCell align="right">{data?.quotation?.totalTransDurationAmount?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              Discount
            </TableCell>
            <TableCell align="right">{data?.quotation?.discounts.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              <strong>Sub Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.subTotalAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{serviceLabel}</TableCell>
            <TableCell align="right" colSpan={3}>
              @{data?.serviceChargePercent}%
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalPercentage.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>Advance Payment</TableCell>
            <TableCell align="right">{data?.advancePayment.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <strong>Grand Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.grandTotal.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  } else if (data.serviceType === 'Translation') {
    displayContent = (
      <>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }}>Status</TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Rate per word
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Number of pages
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Word count
            </TableCell>
            <TableCell style={{ fontSize: '12px', fontWeight: 600, width: '150px' }} align="right">
              Amount (£)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data.urgency === 'urgent' ? 'Urgent' : 'Non Urgent'}</TableCell>
            <TableCell align="right">{data?.quotation?.costPerWord}</TableCell>
            <TableCell align="right">{data?.pageCount}</TableCell>
            <TableCell align="right">{data?.wordCount}</TableCell>
            <TableCell align="right">{data?.quotation?.transTotalDurationAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              Discount
            </TableCell>
            <TableCell align="right">{data?.quotation?.discounts.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" colSpan={4}>
              <strong>Sub Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.subTotalAmount.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">{serviceLabel}</TableCell>
            <TableCell align="right" colSpan={3}>
              @{data?.serviceChargePercent}%
            </TableCell>
            <TableCell align="right">{data?.quotation?.totalPercentage.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>Advance Payment</TableCell>
            <TableCell align="right">{data?.advancePayment.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <strong>Grand Total</strong>
            </TableCell>
            <TableCell align="right">{data?.quotation?.grandTotal.toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={9}>
              <div style={{ fontSize: '16px', fontWeight: 700 }}>{data?.serviceType + ' Quotation Preview '}</div>
            </Grid>
            <Grid item xs={3}>
              <div style={{ fontSize: '16px', fontWeight: 700, paddingLeft: '7vh' }}>
                {data?.name}
                <span style={{ fontSize: '12px', marginLeft: '0.5vh' }}>{data?.booking_ref}</span>
                {/* <Tooltip
                  title={
                    <React.Fragment>
                      <div></div>
                    </React.Fragment>
                  }
                >
                  <InfoIcon style={{ fontSize: '16px', position: 'relative', bottom: '-3px', marginLeft: '6px' }} />
                </Tooltip> */}
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              {displayContent}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
