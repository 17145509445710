import React, { useCallback, useState } from "react";
import moment from "moment";
import {
	TableRow,
	TableCell,
	Table,
	TableContainer,
	TableBody,
	Typography,
	CircularProgress,
	Card,
	CardContent,
	Chip,
} from "@material-ui/core";
import { EnhancedTableHead } from "./index";
import CustomChip from "../../../customChip/customChip";

const bookingReportHeadCells = [
	{ id: "i", numeric: false, disablePadding: false, label: "Sl.No" },
	{ id: "createdAt", numeric: false, disablePadding: false, label: "Date Received" },
	{ id: "booking_ref", numeric: false, disablePadding: false, label: "Booking Ref" },
	{ id: "serviceType", numeric: false, disablePadding: false, label: "Service Type" },
	{ id: "companyName", numeric: false, disablePadding: false, label: "Company Name" },
	{ id: "date", numeric: false, disablePadding: false, label: "Attendance Date" },
	{ id: "status", numeric: false, disablePadding: false, label: "Status" },
	{
		id: "dateOfConfirmation",
		numeric: false,
		disablePadding: false,
		label: "Date Of Confirmation",
	},
	{ id: "quotationAmount", numeric: false, disablePadding: false, label: "Quotation amount" },
	{ id: "invoiceAmount", numeric: false, disablePadding: false, label: "Invoice Amount" },
	{ id: "outstandingAmount", numeric: false, disablePadding: false, label: "Outstanding" },
];

const styles = {
	fontSize: "13px",
	padding: "6px",
};

const CardItem = ({ title, active, classes, data, isLoading, matches }) => {
	const [selected, setSelected] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("company");

	const handleRequestSort = useCallback(
		(event, property) => {
			const isAsc = orderBy === property && order === "asc";
			setOrder(isAsc ? "desc" : "asc");
			setOrderBy(property);
		},
		[setOrder, setOrderBy, orderBy]
	);

	const handleSelectAllClick = useCallback(
		(event) => {
			if (event.target.checked) {
				const newSelecteds = data.map((n) => n.name);
				setSelected(newSelecteds);
				return;
			}
			setSelected([]);
		},
		[setSelected]
	);

	return (
		<>
			<Card className={active}>
				<CardContent style={{ padding: "8px 6px" }}>
					<TableContainer className={matches ? classes.container2 : classes.container}>
						<Table
							stickyHeader
							aria-label="sticky table"
							className={classes.table}
							size={"small"}
						>
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={data.length}
								headCells={bookingReportHeadCells}
							/>

							<TableBody>
								{isLoading}
								{isLoading ? (
									<TableRow>
										<TableCell
											style={{ textAlign: "center" }}
											rowSpan={6}
											colSpan={13}
										>
											<CircularProgress />
										</TableCell>
									</TableRow>
								) : data.length === 0 ? (
									<TableRow hover>
										<TableCell
											style={{ textAlign: "center" }}
											rowSpan={6}
											colSpan={13}
										>
											No record
										</TableCell>
									</TableRow>
								) : (
									data.map((row, index) => {
										return (
											<TableRow
												hover
												key={index}
											>
												<TableCell style={styles}>{index + 1}</TableCell>
												<TableCell style={styles}>
													{moment(row?.createdAt).format("DD/MM/YYYY")}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.booking_ref}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.serviceType}
												</TableCell>
												<TableCell
													style={styles}
													component="th"
													scope="row"
												>
													<b>
														{row?.companyName || (
															<CustomChip title={"Private"} />
														)}
													</b>
												</TableCell>

												<TableCell
													style={styles}
													align="left"
												>
													{moment(row?.date).format("DD/MM/YYYY")}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{row?.status}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{moment(row?.dateOfConfirmation).format(
														"DD/MM/YYYY"
													)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{parseFloat(row?.quotationAmount || 0).toFixed(
														2
													)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{parseFloat(row?.invoiceAmount || 0).toFixed(2)}
												</TableCell>
												<TableCell
													style={styles}
													align="left"
												>
													{parseFloat(
														row?.outstandingAmount || 0
													).toFixed(2)}
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</>
	);
};

export default React.memo(CardItem);
