import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        margin: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        height: 'calc(70vh - 20px)',
    },
    container2: {
        height: 'calc(80vh - 20px)',
    },
    rowData: {
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
    shape: {
        marginTop: '4px',
        marginRight: '4px',
        width: 12,
        height: 12,
    },
    shapeLabel: {
        fontSize: '12px',
        marginTop: '2px',
    },
}));