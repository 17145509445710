import React, { useState, useContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import { TableToolbar } from "./Table/TableToolbar";
import { TableHeader } from "./Table/TableHeader";
import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { getComparator, stableSort } from "../../../../libs/headerSortMethods";
// importing context
import { ClientContext } from "../../../../context/states/clientState";
import { ClientLists } from "./clientLists";
import { AddClient } from "./Forms/AddClient/AddClient";
import { EditClient } from "./Forms/EditClient/EditClient";

const headCells = [
  { id: "slno", numeric: false, disablePadding: false, label: "Sl.No" },
  {
    id: "clientId",
    numeric: false,
    disablePadding: false,
    label: "ID",
    width: "100%",
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Company",
    width: "100%",
  },
  {
    id: "companyStatus",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "100%",
  },
  {
    id: "contactPerson",
    numeric: false,
    disablePadding: false,
    label: "Contact",
    width: "100%",
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Phone",
    width: "100%",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "100%",
  },
  {
    id: "postcode",
    numeric: false,
    disablePadding: false,
    label: "Postcode",
    width: "100%",
  },
  {
    id: "dor",
    numeric: false,
    disablePadding: false,
    label: "Registered on",
    width: "100%",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    width: "100%",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "calc(100vh - 14rem)",
  },
  table: {
    minWidth: "100vh",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  paper: {
    margin: theme.spacing(0),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const Clients = React.memo((props) => {
  console.log("RENDER INTERPRETERS");
  const classes = useStyles();

  const { title } = props;
  const {
    getClientsHandler,
    loading,
    isClients,
    total,
    getClientHandler,
    count,
    getRateStructuresHandler,
    rateStructures,
  } = useContext(ClientContext);

  const [isCheckbox, setIsCheckbox] = useState(false);
  const enableCheckboxHandler = () => setIsCheckbox(!isCheckbox);

  const [isAddModel, setIsAddModel] = useState(false);
  const openFormModalHandler = useCallback(() => {
    getRateStructuresHandler();
    setIsAddModel(true);
  }, []);
  const closeFormModalHandler = useCallback(() => setIsAddModel(false), []);

  const [isEditModal, setIsEditModal] = useState(false);
  const openEditModalHandler = useCallback((id) => {
    setIsEditModal(true);
    getRateStructuresHandler();
    getClientHandler(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeEditModalHandler = useCallback(() => setIsEditModal(false), []);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("int_id");
  const [selected, setSelected] = useState([]);
  const [isPage, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const fetchAllDataHandler = useCallback(
    async (searchInput) => {
      console.log(searchInput);
      const query =
        isPage !== 0
          ? `?page=${isPage + 1}&limit=${rowsPerPage}`
          : `?page=1&limit=${rowsPerPage}${searchInput}`;
      await getClientsHandler(query);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, isPage]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = isClients.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    },
    [selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = useCallback(
    (name) => selected.indexOf(name) !== -1,
    [selected]
  );

  const refreshDataHandler = useCallback(
    () => fetchAllDataHandler(),
    [fetchAllDataHandler]
  );

  const tableData = useMemo(() => {
    return (
      <ClientLists
        data={isClients}
        loading={loading}
        stableSort={stableSort}
        getComparator={getComparator}
        order={order}
        orderBy={orderBy}
        page={isPage}
        rowsPerPage={rowsPerPage}
        isSelected={isSelected}
        handleClick={handleClick}
        isCheckbox={isCheckbox}
        handleEditModal={openEditModalHandler}
      />
    );
  }, [
    isClients,
    loading,
    order,
    orderBy,
    isPage,
    rowsPerPage,
    isSelected,
    handleClick,
    isCheckbox,
    openEditModalHandler,
  ]);

  return (
    <>
      <Paper className={classes.paper}>
        <TableToolbar
          numSelected={selected.length}
          tableTitle={title}
          isCheckbox={isCheckbox}
          enableCheckbox={false}
          handleRefresh={refreshDataHandler}
          handleCheckbox={enableCheckboxHandler}
          handleFormModal={openFormModalHandler}
          handleSearch={fetchAllDataHandler}
          isCount={count}
        />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHeader
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={isClients.length}
              isCheckbox={isCheckbox}
            />
            <TableBody>{tableData}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense"
      />
      <AddClient
        open={isAddModel}
        isRateStructures={rateStructures}
        handleClose={closeFormModalHandler}
      />
      <EditClient open={isEditModal} handleClose={closeEditModalHandler} />
    </>
  );
});

Clients.propTypes = {
  title: PropTypes.string.isRequired,
};
