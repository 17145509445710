import React, { createContext, useReducer, useContext } from 'react';
import AuthReducer from '../reducers/authReducer';
import axios from '../../axios';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import { AlertContext } from './alertState';


// InitialState
const initialState = {
  isAuthenticated: false,
  token: null,
  user: {},
  error: null,
  loading: false,
  photoLoad: false,
  photo: '',
  photoURL: null,
};

// creating context
export const AuthContext = createContext(initialState);

// provider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);
  
  // actions
  const handleLogin = async (user) => {
    dispatch({ type: actionTypes.AUTH_START });

    try {
      const result = await axios.post(urlRequest.Login, user);
      const userObj_serialized = JSON.stringify(result.data);
      const token = result.data.token;
      storeResponseDataHandler(token, userObj_serialized);

      if (result.data.success) {
        alertDispatch({ type: 'open', message: `Successfully logged in` });
        await getMeHandler();
      }
      return result.data.success;
    } catch (error) {
      await dispatch({ type: actionTypes.AUTH_FAIL, payload: 'logout failed' });
      console.log(error);
      return false;
    }
  };

  // actions
  const handleInterpreterLogin = async (user) => {
    dispatch({ type: actionTypes.AUTH_START });

    try {
      const result = await axios.post(urlRequest.InterpreterLogin, user);
      const userObj_serialized = JSON.stringify(result.data);
      const token = result.data.token;
      storeResponseDataHandler(token, userObj_serialized);
      
      if (result.data.success) {
        alertDispatch({ type: 'open', message: `Successfully logged in` });
        await getInterpreterMeHandler();
      }
      return result.data.success;
    } catch (error) {
      await dispatch({ type: actionTypes.AUTH_FAIL, payload: 'logout failed' });
      console.log(error);
      return false;
    }
  };

  const getInterpreterMeHandler = async () => {
    const { data } = await axios.get(urlRequest.InterpreterMe);

    localStorage.setItem('interpreterRef', data.data.int_id);
    localStorage.setItem('intID', data.data._id);
    localStorage.setItem('loggedIn', data.data._id);
  };

  const getMeHandler = async () => {
    const { data } = await axios.get(urlRequest.LoggedInMe);
    localStorage.setItem('loggedIn', data.data._id);
  };

  const storeResponseDataHandler = (token, response) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', response);
    const userObj_deserialized = JSON.parse(localStorage.getItem('user'));
    dispatch({ type: actionTypes.AUTH_SUCCESS, payload: userObj_deserialized.token });
    dispatch({ type: actionTypes.SET_USER_DETAILS, payload: userObj_deserialized });
  };

  const getAccessToken = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      return token;
    } else {
      return null;
    }
  };

  const getUserDetails = () => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      return user;
    } else {
      return null;
    }
  };

  const handleLogout = async () => {
    dispatch({ type: actionTypes.AUTH_START });
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('intID');
      localStorage.removeItem('interpreterRef');
      localStorage.removeItem('loggedIn');
      await dispatch({ type: actionTypes.AUTH_LOGOUT });
      alertDispatch({ type: 'open', message: `Successfully logged out` });
    } catch (error) {
      await dispatch({ type: actionTypes.AUTH_FAIL, payload: 'logout failed' });
    }
  };

  const authHandler = () => {
    let token = getAccessToken();
    let user = getUserDetails();

    if (!getAccessToken()) {
      // handleLogout()
    } else {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: token });
      dispatch({
        type: actionTypes.SET_USER_DETAILS,
        payload: user,
      });
    }
  };

  /* Get Profile image for interpreter by id */
  const getProfileHandler = async (filename) => {
    // console.log('photoname: ', filename);
    if (filename === '') return false;
    await dispatch({ type: 'FILE_UPLOAD_START' });
    try {
      const photo = await axios.get(`${urlRequest.Interpreters}/${filename}/photo`);
      dispatch({ type: 'INTERPRETER_USER_PHOTO', payload: photo.config.url });
      // console.log('PHOTO: ', photo);
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        isAuthenticated: state.isAuthenticated,
        token: state.token,
        userData: state.user,
        photo: state.photo,
        handleLogin,
        handleInterpreterLogin,
        handleLogout,
        authHandler,
        isLoad: state.photoLoad,
        photoURL: state.photoURL,
        getProfileHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
