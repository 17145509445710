import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Chip, Avatar } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import AccountCircle from '@material-ui/icons/AccountCircle';

import { AuthContext } from '../../../context/states/authState';

// styles
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: '1rem',
  },
  leftMargin: {
    marginLeft: 0.2 + 'em',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const Navbar = (props) => {
  // console.log('RENDER NAVBAR');
  const classes = useStyles();
  const history = useHistory();
  const { handleLogout, userData, photoURL } = useContext(AuthContext);
  const currentUserID = localStorage.getItem('loggedIn');
  const interpreterRef = localStorage.getItem('interpreterRef');

  const logoutHandler = async () => {
    await handleLogout();
    await history.push('/');
  };

  const profileHandler = () => {
    history.push(`/account/${currentUserID}`);
    handleClose();
  };

  const [anchorEl, setanchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setanchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  var userDetails = 'Loading....';

  switch (userData.role) {
    case 'superadmin':
      userDetails = userData.user
      break;
    case 'admin':
      userDetails = userData.user
      break;
    case 'ip-admin':
      userDetails = userData.user
      break;
    case 'interpreter':
      userDetails = interpreterRef === null ? '' : userData.user + ' ' + interpreterRef;
      break;

    default:
      userDetails = 'No'
  }
  // if (userData.role === 'superadmin') {
  //   userDetails = userData.user;
  // } else if (userData.role === 'admin') {
  //   userDetails = userData.user;
  // } else if (userData.role === 'interpreter') {
  //   userDetails = interpreterRef === null ? '' : userData.user + ' ' + interpreterRef;
  // }

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
        elevation={1}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.toggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: props.open,
            })}
          >
            {props.open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {process.env.REACT_APP_NAME}
          </Typography>

          <React.Fragment>
            {/* <IconButton aria-label="account of current user" aria-controls="user-menu" aria-haspopup="true" onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton> */}
            {/* {!isLoad && <Avatar className={classes.small} alt="Remy Sharp" src={process.env.REACT_APP_BASE_URL + photoURL} />} */}
            <Chip
              // icon={<AccountCircle />}
              icon={<Avatar className={classes.small} alt={userData.user} src={process.env.REACT_APP_BASE_URL + photoURL} />}
              deleteIcon={<ArrowDropDownIcon />}
              color="primary"
              label={userDetails}
              onClick={handleMenu}
              style={{ textTransform: 'capitalize' }}
            />
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {userData.role === 'interpreter' && <MenuItem onClick={profileHandler}>Change Password</MenuItem>}
              <MenuItem onClick={logoutHandler}>Logout</MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default React.memo(Navbar);
