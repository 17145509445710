import React from 'react';

import Grid from '@material-ui/core/Grid';
import { RateProvider } from '../../../context/states/rateState';
import RateStructures from '../../../components/pages/masters/RateStructures/RateStructures';

const RateStructuresMaster = () => {
  return (
    <React.Fragment>
      <RateProvider>
        <Grid item xs>
          <RateStructures title="Rate Structures" />
        </Grid>
      </RateProvider>
    </React.Fragment>
  );
};

export default RateStructuresMaster;
