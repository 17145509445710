import React, { useEffect, useContext, useState, useCallback } from "react";
import { DuesContext } from "../../../../context/states/interpreterDueState";
import { AuthContext } from "../../../../context/states/authState";

import {
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHead";

import VisibilityIcon from "@material-ui/icons/Visibility";

import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { DueDetails } from "./dueDetails/DueDetails";

const headCells = [
  { id: "slno", numeric: false, disablePadding: false, label: "Sl.No" },
  { id: "interpreterRef", numeric: false, disablePadding: false, label: "ID" },
  {
    id: "interpreterName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Number of Booking",
  },
  { id: "totalAmount", numeric: true, disablePadding: false, label: "Amount" },
  { id: "edit", numeric: false, disablePadding: false, label: "Action" },
];

export const DueLists = (props) => {
  const { classes, stableSort, getComparator, title } = props;
  const {
    loading,
    getInterpreterDuesHandler,
    data,
    total,
    getInterpreterDueHandler,
    dues,
    load,
    addRemittanceSlipHandler,
    submitteData,
    totalAmount,
  } = useContext(DuesContext);
  const { userData } = useContext(AuthContext);
  const matches = useMediaQuery("(min-width:1920px)");

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("interpreterName");
  const [dense, setDense] = useState(true);
  const [isDueModal, setIsDueModal] = useState(false);
  const [isName, setIsName] = useState({});

  const [isPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(150);

  useEffect(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getInterpreterDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, isPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // DueDetail model
  const closeDueModalHandler = useCallback(() => {
    setIsDueModal(false);
  }, []);

  const closeSubmitHandle = useCallback(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getInterpreterDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PendingDues = React.useMemo(
    () => (
      <DueDetails
        open={isDueModal}
        handleClose={closeDueModalHandler}
        closeSubmitHandle={closeSubmitHandle}
        isLoading={load}
        data={dues}
        interpreter={isName}
        submitHandler={addRemittanceSlipHandler}
        approvedBy={userData.user}
        submitteData={submitteData}
      />
    ),
    [
      isDueModal,
      dues,
      closeDueModalHandler,
      load,
      isName,
      addRemittanceSlipHandler,
      userData,
      submitteData,
      closeSubmitHandle,
    ]
  );
  const openDueModalHandler = useCallback((id, int) => {
    setIsDueModal(true);
    const interpreter = {
      id: int._id,
      name: int.interpreterName,
      ref: int.interpreterRef,
    };
    setIsName(interpreter);
    getInterpreterDueHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleOpen={() => {}}
        title={title}
        totalAmount={totalAmount}
      />
      <TableContainer
        className={matches ? classes.container2 : classes.container}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow hover>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  No record
                </TableCell>
              </TableRow>
            ) : (
              stableSort(data, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell></TableCell>
                      <TableCell align="left">
                        {index + 1 + isPage * rowsPerPage}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.interpreterRef}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.interpreterName}
                      </TableCell>
                      <TableCell align="right">{row.count}</TableCell>
                      <TableCell align="right">
                        {row?.totalAmount !== null
                          ? row.totalAmount.toFixed(2)
                          : null}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={`Pending Due: ${row.interpreterName}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => openDueModalHandler(row._id, row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[100, 150, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {PendingDues} {/* Interpreter Dues modal */}
    </React.Fragment>
  );
};
