import React from 'react'
import { TableRow, TableCell, CircularProgress } from '@material-ui/core';

export const TableSpanLoader = ({ col, loader, message }) => {
    return (
        <>
            <TableRow>
                <TableCell colSpan={col} style={{ textAlign: 'center' }}>
                    {loader ? <CircularProgress /> : <h5>{message}</h5>}
                </TableCell>
            </TableRow>
        </>
    )
}
