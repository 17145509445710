import React from 'react'

const styles = {
    privateSpan: {
    fontSize: 8,
    fontWeight: 600,
    background: '#262626',
    padding: '4px 6px',
    borderRadius: 4,
    color: 'white',
    marginLeft: 2
  }
}
const CustomChip = ({title}) => {
  return <span style={styles.privateSpan}>{title}</span>
}

export default CustomChip