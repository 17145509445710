import React, { createContext, useReducer } from "react";
import searchReducer from "../../reducers/search/searchReducer";

const initialState = {
  bookingsearch: {
    sn: "",
    name: "",
    fileRef: "",
    interpreterName: "",
    serviceType: "",
    language: "",
    dialect: "",
    companyName: "",
    clientName: "",
    status: "",
    page: 1,
    limit: 50,
  },
  interpreterSearch: {
    fname: "",
    language: "",
    dialect: "",
    gender: "",
    service: "",
    postcode: "",
    active: "",
    page: 1,
    limit: 50,
  },
  loading: false,
};

export const SearchContext = createContext(initialState);
export const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  function cleanObj(obj) {
    for (var propName in obj) {
      // if (obj[propName] === obj[propName]) { delete obj[propName] };
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName] === "none"
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const bookingSearchHandler = (obj) => {
    let paramsQuery;
    const params = cleanObj(obj);
    dispatch({ type: "SEARCH_BOOKINGS", payload: obj });
    paramsQuery = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
    console.log(paramsQuery);
    return paramsQuery;
  };

  const interpreterSearchHandler = (obj) => {
    let paramsQuery;
    const params = cleanObj(obj);
    dispatch({ type: "SEARCH_INTERPRETER", payload: obj });
    paramsQuery = Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
    return paramsQuery;
  };

  const clearBookingsSearchHandler = () => dispatch({ type: "CLEAR_BOOKING" });
  const clearInterpretersSearchHandler = () =>
    dispatch({ type: "CLEAR_ALL_INERPRETER" });

  return (
    <SearchContext.Provider
      value={{
        bookingSearch: state.bookingsearch,
        bookingSearchHandler,
        clearBookingsSearchHandler,
        interpreterSearch: state.interpreterSearch,
        interpreterSearchHandler,
        clearInterpretersSearchHandler,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
