import React, { createContext, useReducer, useContext } from 'react';
import BookingReducer from '../reducers/bookingReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  load: false,
  docLoad: false,
  error: null,
  bookings: [],
  booking: {},
  total: 0,
  count: 0,
  languages: [],
  dialects: [],
  progressValue: 0,
  fileList: [],
  viewDoc: '',
  interpreters: [],
  clients: [],
  rateStructures: [],
  bookingNotes: [],
  invoice: {},
  bStatus: '',
  departments: [],
  caseTypes: []
};

// creating context
export const BookingContext = createContext(initialState);

// provider component
export const BookingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BookingReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  const getBookingsHandler = React.useCallback(async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Bookings + '?' + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  }, []);

  const getInterpreterBookingsHandler = async (data) => {
    if (Object.keys(state.booking).length === 0) {
      dispatch({ type: actionTypes.PROCESS_START });
    }
    const int = localStorage.getItem('intID');
    try {
      const result = await axios.get(urlRequest.Timesheets + int + data);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const addBookingHandler = async (newBooking) => {
    const result = await axios.post(urlRequest.Bookings, newBooking);
    // dispatch({ type: actionTypes.ADD_RECORD, payload: result.data.data });
    getBookingsHandler('page=1&limit=25')
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Booking created' });
    }

    return await result;
  };

  const getBookingHandler = async (id) => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(`${urlRequest.Bookings}/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data });
      return result
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const updateBookingHandler = async (booking) => {
    try {
      const result = await axios.put(`${urlRequest.Bookings}/${booking._id}`, booking);
      dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Booking updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch All Languages data */
  const getLangugagesHandler = async () => {
    try {
      const result = await axios.get(urlRequest.Languages);
      dispatch({ type: actionTypes.GET_ALL_LANGUGAGES, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Fetch All Dialects data */
  const getDialectsHandler = async () => {
    try {
      const result = await axios.get(urlRequest.Dialects);
      dispatch({ type: actionTypes.GET_ALL_DIALECTS, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Upload documents for interpreter qualification */
  const uploadDocumentHandler = async (file) => {
    dispatch({ type: actionTypes.UPLOAD_START });
    try {
      const result = await axios.post(urlRequest.BookingDocUploads, file, {
        onUploadProgress: (progressEvent) => {
          const uploadPercentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          dispatch({ type: actionTypes.PROGRESS_VALUE, payload: uploadPercentage });
          alertDispatch({ type: 'open', message: `${uploadPercentage} Uploading...` });
        },
      });
      console.log(result.data.fileName);
      dispatch({ type: actionTypes.DOCUMENT_UPLOAD, payload: result.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Document uploaded' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentHandler = async (path, filename) => {
    dispatch({ type: actionTypes.DOC_LOAD });
    try {
      const result = await axios.get(`${urlRequest.Bookings}/${path}/${filename}`);
      dispatch({ type: actionTypes.GET_DOCUMENT_IMAGE, payload: result.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInterpretersHandler = async (query) => {
    dispatch({ type: 'LOAD_INTERRETER' });
    try {
      const result = await axios.get(urlRequest.Interpreters + '/' + query);
      dispatch({ type: 'GET_INTERPRETER_NAME', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInterpreterHandler = async (query) => {
    // dispatch({ type: 'LOAD_INTERRETER' });
    try {
      const result = await axios.get(urlRequest.Interpreters + '/' + query);
      dispatch({ type: 'GET_INTERPRETER_NAME', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getClientsHandler = async (name) => {
    try {
      const result = await axios.get(urlRequest.ClientsName + '/' + name);
      dispatch({ type: 'GET_CLIENTSNAME_NAME', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getRateStructuresHandler = async () => {
    try {
      const result = await axios.get(urlRequest.RateStructures);
      dispatch({ type: 'GET_RATE_STRUCTURES', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const updateBookingNotesHandler = async (booking) => {
    // console.log(booking);
    try {
      const result = await axios.put(`${urlRequest.BookingNotes}/${booking.id}`, booking);
      dispatch({ type: actionTypes.ADD_PROFILE_NOTES, payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Booking note added' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const timesheetApprovedHandler = async (booking) => {
    try {
      const result = await axios.post(`${urlRequest.BookingApprove}/${booking.id}`, booking);
      dispatch({ type: 'TIMESHEET_UPDATED', payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Timesheet Approved' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const timesheetupdatedHandler = async (booking) => {
    try {
      const result = await axios.put(`${urlRequest.BookingTimesheetSave}/${booking.id}`, booking);
      // dispatch({ type: 'TIMESHEET_UPDATED', payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Timesheet Updated' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInvoiceHandler = async (id) => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(`${urlRequest.IntInvoice}/${id}`);
      dispatch({ type: 'FETCH_INVOICE', payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const mailHandler = async (data) => {
    try {
      const result = await axios.post(urlRequest.mailSend, data);
      console.log(result);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Mail sent' });
      }
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Oops Something went wrong!' });
    }
  };

  const updateAssginToHandler = async (booking) => {
    try {
      const result = await axios.put(`${urlRequest.Bookings}/${booking.id}`, booking);
      dispatch({ type: 'UPDATE_ASSIGN', payload: result.data.data });
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Interpreter Assigned' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const newBookingFormReset = () => {
    dispatch({ type: 'EMPTY_STATE_OBJ_ARR' });
  };

  const getDepartmentOptionHandler = async () => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(`${urlRequest.Department}`);
      dispatch({ type: 'FETCH_DEPARTMENT', payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const getCaseTyoeOptionHandler = async () => {
    dispatch({ type: 'LOAD_DATA' });
    try {
      const result = await axios.get(`${urlRequest.CaseType}`);
      dispatch({ type: 'FETCH_CASETYPE', payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'Something went wrong' });
    }
  };

  const sendProformaInvoiceHandler = async (id) => {
    const result = await axios.post(`${urlRequest.Invoice}/createproforma/${id}`);
    // dispatch({ type: actionTypes.ADD_RECORD, payload: result.data.data });
    getBookingsHandler('page=1&limit=25')
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Booking created' });
    }

    return await result;
  };

  return (
    <BookingContext.Provider
      value={{
        loading: state.loading,
        load: state.load,
        error: state.error,
        data: state.bookings,
        booking: state.booking,
        total: state.total,
        count: state.count,
        isLanguages: state.languages,
        isDialects: state.dialects,
        progressValue: state.progressValue,
        fileList: state.fileList,
        docLoad: state.docLoad,
        viewDoc: state.viewDoc,
        isInterpreters: state.interpreters,
        isRateStructures: state.rateStructures,
        isBookingNotes: state.bookingNotes,
        isClients: state.clients,
        isInvoice: state.invoice,
        isStatus: state.bStatus,
        isDepartments: state.departments,
        isCaseTypes: state.caseTypes,
        getBookingsHandler,
        addBookingHandler,
        getBookingHandler,
        updateBookingHandler,
        getLangugagesHandler,
        getDialectsHandler,
        uploadDocumentHandler,
        fileDispatch: dispatch,
        getDocumentHandler,
        getInterpretersHandler,
        getRateStructuresHandler,
        updateBookingNotesHandler,
        getInterpreterBookingsHandler,
        newBookingFormReset,
        timesheetApprovedHandler,
        timesheetupdatedHandler,
        mailHandler,
        getClientsHandler,
        getInvoiceHandler,
        updateAssginToHandler,
        getDepartmentOptionHandler,
        getCaseTyoeOptionHandler,
        getInterpreterHandler,
        sendProformaInvoiceHandler
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
