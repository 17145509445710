import React, { createContext, useReducer, useContext } from 'react';
import SOAReportReducer from '../../reducers/reports/SOAReportReducer';
import axios from '../../../axios';
import { AlertContext } from '../alertState';

const initialState = {
    loading: false,
    jobsOutstanding: [],
    totalOutstanding: 0,
    paidJobs: [],
    totalPaidJobs: 0,
    confirmedJobs: [],
    confirmedTotal: 0,
    clients: [],
    error: null
}

export const SOAReportContext = createContext(initialState);
export const SOAReportProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SOAReportReducer, initialState);
    const { alertDispatch } = useContext(AlertContext);

    const getReportsHandler = async (id) => {
        // dispatch({ type: 'PROCESS_START' })
        try {
            const result = await axios.get(`/api/v1/bookings/clientoutstanding${id}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_RECORDS', payload: result.data })


        } catch (error) {
            console.error(error)
        }
    }

    const getPaidJobsHandler = async (id) => {
        try {
            const result2 = await axios.get(`/api/v1/bookings/paidjobs${id}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_PAID_RECORED', payload: result2.data })
        } catch (error) {
            console.error(error)
        }
    }

    const getConfirmedJobsHandler = async (id) => {
        try {
            const result3 = await axios.get(`/api/v1/bookings/confirmedjobs${id}`)
            // alertDispatch({ type: 'open', message: 'Done' });
            dispatch({ type: 'FETCH_CONFIRMED_RECORED', payload: result3.data })
        } catch (error) {
            console.error(error)
        }
    }

    const getClientsHandler = async (company) => {
        try {
            const result = await axios.get(`/api/v1/clients?companyName=${company}`);
            dispatch({ type: 'FETCH_CLIENT_RECORD', payload: result.data })
        } catch (error) {
            console.error(error)
        }
    }

    const clearStateHandler = () => {
        dispatch({ type: 'PROCESS_START' })
    }

    return (
        <SOAReportContext.Provider
            value={{
                isloading: state.loading,
                reports: state.jobsOutstanding,
                totalOutstanding: state.totalOutstanding,
                paidJobs: state.paidJobs,
                totalPaidJobs: state.totalPaidJobs,
                clients: state.clients,
                confirmedJobs: state.confirmedJobs,
                confirmedTotal: state.confirmedTotal,
                error: state.error,
                getReportsHandler,
                getClientsHandler, getPaidJobsHandler, getConfirmedJobsHandler,
                clearStateHandler
            }}>
            {children}
        </SOAReportContext.Provider>
    )
}