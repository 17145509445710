import React, { Component } from 'react';

import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const withErrorHandler = (WrappedComponent, axios) => {
  return class extends Component {
    state = {
      message: null,
      error: null,
      toggle: false,
    };

    componentDidMount() {
      this.reqInterceptor = axios.interceptors.request.use((config) => {
        this.setState({ error: null });
        return config;
      });
      this.resInterceptor = axios.interceptors.response.use(
        (res) => {
          return res;
        },

        (error) => {
          console.log();
          if (error.message === 'Network Error' && !error.repsonse) {
            this.setState({ error: error.message });
            this.setState({ toggle: true });
          }

          if (error.response.data.msg === {}) {
            console.log('Object EErr');
            this.setState({ error: 'Response is error' });
            this.setState({ toggle: true });
          }

          if (error.response.status === 401) {
            console.log('Unauthorized');
            this.setState({ error: error.response.data.msg });
            this.setState({ toggle: true });
          } else if (error.response.status === 404) {
            // console.log('Not Found');
            this.setState({ error: error.response.data?.msg });
            this.setState({ toggle: true });
          } else if (error.response.status === 400) {
            // console.log('Bad Request');
            this.setState({ error: error.response.data?.msg });
            this.setState({ toggle: true });
          } else if (error.response.status === 500) {
            // console.log('Internal Server Error');
            this.setState({ error: error.response.data.msg });
            this.setState({ toggle: true });
          }
        }
      );
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
      this.setState({ toggle: false });
    };

    render() {
      return (
        <React.Fragment>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.toggle}
            onClose={() => this.errorConfirmedHandler()}
            // message={this.state.error ? this.state.error.message : null}
            message={this.state.error ? this.state.error : null}
            autoHideDuration={6000}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.errorConfirmedHandler()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
};

export default withErrorHandler;
