import React, { createContext, useReducer, useContext } from "react";
import ClientReducer from "../reducers/clientReducer";
import * as actionTypes from "../actionTypes";
import * as urlRequest from "../urls";
import axios from "../../axios";

import { AlertContext } from "./alertState";

// InitialState
const initialState = {
	loading: false,
	load: false,
	docLoad: false,
	clients: [],
	client: {},
	clientNotes: [],
	fileList: {},
	viewDoc: "",
	total: 0,
	count: 0,
	error: null,
	rateStructures: [],
};

// creating context
export const ClientContext = createContext(initialState);

// provider component
export const ClientProvider = ({ children }) => {
	const [state, dispatch] = useReducer(ClientReducer, initialState);
	const { alertDispatch } = useContext(AlertContext);

	/* Fetch All Clients data */
	const getClientsHandler = async (query) => {
		dispatch({ type: actionTypes.PROCESS_START });
		try {
			const result = await axios.get(urlRequest.Clients + query);
			dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
		} catch (error) {
			console.log(error);
			dispatch({ type: actionTypes.FETCH_ERRORS, payload: "something went wrong" });
		}
	};

	/* Add New Clients data */
	const addClientHandler = async (data) => {
		// console.log(data);
		const result = await axios.post(urlRequest.Clients, data);
		console.log(result);
		if (result?.data.success) {
			dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
			alertDispatch({ type: "open", message: "Client created" });
		}
		return await result;
	};

	/* Fetch Single Client Data by id */
	const getClientHandler = async (id) => {
		dispatch({ type: "SINGLE_FETCH" });
		try {
			const result = await axios.get(`${urlRequest.Clients}/${id}`);
			dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
		} catch (error) {
			console.log(error);
			dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
		}
	};

	/* Update interpreter by id */
	const updatedClientHandler = async (data) => {
		const result = await axios.put(`${urlRequest.Clients}/${data._id}`, data.data);
		dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
		if (result.data.success) {
			alertDispatch({ type: "open", message: "Client updated" });
		}

		return await result;
	};

	/* Add clients notes by id */
	const addClientNotesHandler = async (data) => {
		console.log(data.data);
		const result = await axios.put(`${urlRequest.Clients}/notes/${data._id}`, data.data);
		dispatch({ type: "CLIENT_NOTES", payload: result.data.data });
		if (result.data.success) {
			alertDispatch({ type: "open", message: "Client note added" });
		}

		return await result;
	};

	// Get Client Documents
	const getDocumentHandler = async (type, filename) => {
		dispatch({ type: actionTypes.DOC_LOAD });
		try {
			const result = await axios.get(`${urlRequest.Clients}/${type}/${filename}`);
			dispatch({ type: actionTypes.GET_DOCUMENT_IMAGE, payload: result.config.url });
		} catch (error) {
			console.log(error);
			dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
		}
	};

	const clientRegistrationMailHandler = async (id) => {
		try {
			console.log("mail id", id);
			const result = await axios.post(`/api/v1/mailsend/clregconfmail/${id}`);
			if (result.data.success) {
				alertDispatch({ type: "open", message: "Activation mail sent" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getRateStructuresHandler = async () => {
		try {
			const result = await axios.get(urlRequest.RateStructures);
			dispatch({ type: "GET_RATE_STRUCTURES", payload: result.data.data });
		} catch (error) {
			console.log(error);
			dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
		}
	};

	return (
		<ClientContext.Provider
			value={{
				loading: state.loading,
				isClients: state.clients,
				total: state.total,
				count: state.count,
				load: state.load,
				client: state.client,
				isNotes: state.clientNotes,
				fileList: state.fileList,
				viewDoc: state.viewDoc,
				clientDocDispatch: dispatch,
				getClientsHandler,
				addClientHandler,
				getClientHandler,
				updatedClientHandler,
				addClientNotesHandler,
				getDocumentHandler,
				clientRegistrationMailHandler,
				rateStructures: state.rateStructures,
				getRateStructuresHandler,
			}}
		>
			{children}
		</ClientContext.Provider>
	);
};
