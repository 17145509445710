import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { RateContext } from '../../../../../context/states/rateState';
import Loader from '../../../../UI/Loader';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  appBar: {
    marginBottom: '12px',
  },
  title: {
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
}));

export const AddForm = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose, specialRate, clientID } = props;

  const { addRateHandler } = useContext(RateContext);

  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const formValue = {
    clientID: clientID,
  };

  const { register, handleSubmit, errors, control, watch } = useForm({ mode: 'onChange', defaultValues: formValue });

  const client = watch('clientID');
  // const rate = watch('rateName');

  const onSubmit = async (formData) => {
    setIsLoading(true);
    await addRateHandler(formData)
      .then((res) => {
        handleClose();
        backdropCloseHandler();
      })
      .catch((err) => {
        console.log(err);
        backdropCloseHandler();
      });
  };
  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Typography variant="h6" gutterBottom>
              New Rate Structure
            </Typography>

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  {specialRate ? (
                    <React.Fragment>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel id="rateName-label">Rates</InputLabel>
                        <Controller
                          as={
                            <Select labelId="rateName-label" id="company-status" name="rateName">
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={`In London_${client}`}>In London</MenuItem>
                              <MenuItem value={`Out of London_${client}`}>Out of London</MenuItem>
                            </Select>
                          }
                          control={control}
                          name="rateName"
                          rules={{ required: true }}
                          error={!!errors.rateName}
                          labelWidth={55}
                        />
                      </FormControl>
                      <TextField
                        style={{ display: 'none' }}
                        margin="normal"
                        label="Client ID"
                        variant="outlined"
                        size="small"
                        name="clientID"
                        inputRef={register}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextField fullWidth label="Rate Name" variant="outlined" size="small" name="rateName" inputRef={register} />
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* <TextField fullWidth label="Travel Per hour" variant="filled" size="small" name="specialRates" inputRef={register} type="number" /> */}
                  <table style={{ padding: '1.5em 0' }}>
                    <tbody>
                      <tr>
                        <td className={classes.tbody}>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Attendance
                          </Typography>
                        </td>
                        <td>
                          <TextField fullWidth label="Per minute" variant="filled" size="small" name="attendance" inputRef={register} type="number" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Travel
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="travelPerhour"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Waiting
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="waitingPerHour"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Mileage
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField fullWidth label="Per hour" variant="filled" size="small" name="mileage" inputRef={register} type="number" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Translation Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per word"
                            variant="filled"
                            size="small"
                            name="translationUrgent"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Tanslation Non Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per word"
                            variant="filled"
                            size="small"
                            name="translationNonUrgent"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Telephone
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per minute"
                            variant="filled"
                            size="small"
                            name="telephoneInterpreting"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Video
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="videoInterpreting"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Transcription Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="transcriptionUrgent"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600' }} color="primary">
                            Transcription Non Urgent
                          </Typography>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <TextField
                            fullWidth
                            label="Per hour"
                            variant="filled"
                            size="small"
                            name="transcriptionNonUrgent"
                            inputRef={register}
                            type="number"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField fullWidth label="Waiting Per hour" variant="filled" size="small" name="specialRates" inputRef={register} type="number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Translation Urgent"
                    variant="filled"
                    size="small"
                    name="specialRates"
                    inputRef={register}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Translation Non Urgent"
                    variant="filled"
                    size="small"
                    name="specialRates"
                    inputRef={register}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Telephone" variant="filled" size="small" name="specialRates" inputRef={register} type="number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Video" variant="filled" size="small" name="specialRates" inputRef={register} type="number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Mileage" variant="filled" size="small" name="specialRates" inputRef={register} type="number" />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Transcription" variant="filled" size="small" name="specialRates" inputRef={register} type="number" />
                </Grid> */}
              </Grid>
            </Paper>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
});
