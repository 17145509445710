import React, { createContext, useReducer, useContext } from "react";
import BookingReportReducer from "../../reducers/reports/BookingReportReducer";
import axios from "../../../axios";

const initialState = {
	loading: false,
	bookings: [],
	clients: [],
	totalInvs: 0,
	totalQuotes: 0,
	totalOutstanding: 0,
	error: null,
};

export const BookingReportContext = createContext(initialState);
export const BookingReportProvider = ({ children }) => {
	const [state, dispatch] = useReducer(BookingReportReducer, initialState);

	const getBookingReportHandler = async (query) => {
		let url = "";
		try {
			if (query) url = `?${query}`;

			const result = await axios.get(`/api/v1/bookings/bookingreport${url}`);
			if (result?.status) dispatch({ type: "FETCH_RECORDS", payload: result?.data });
		} catch (error) {
			console.error(error);
		}
	};

	const clearStateHandler = () => {
		dispatch({ type: "PROCESS_START" });
	};

	const getClientsHandler = async (company) => {
		try {
			const result = await axios.get(`/api/v1/clients?companyName=${company}`);
			dispatch({ type: "FETCH_CLIENT_RECORD", payload: result.data });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<BookingReportContext.Provider
			value={{
				isloading: state.loading,
				bookings: state.bookings,
				clients: state.clients,
				totalOutstanding: state.totalOutstanding,
				error: state.error,
				totalInvs: state.totalInvs,
				totalQuotes: state.totalQuotes,
				getBookingReportHandler,
				clearStateHandler,
				getClientsHandler,
			}}
		>
			{children}
		</BookingReportContext.Provider>
	);
};
