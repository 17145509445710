import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
// import { DevTool } from '@hookform/devtools';

import { Grid, Typography, Paper, TextField, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import { AuthContext } from '../../../../../../../context/states/authState';

export const ClientNotes = (props) => {
  const { classes, data, handleOnSubmit } = props;
  const { userData } = useContext(AuthContext);

  const formValue = {
    updatedby: userData.user,
  };
  const { register, handleSubmit, errors, reset } = useForm({ mode: 'onChange', defaultValues: formValue });

  const onSubmit = (data) => {
    handleOnSubmit(data);
    reset({ text: '' });
  };

  let noteLists = 'loading...';
  noteLists = data.map((p, i) => (
    <ListItem key={p._id}>
      <ListItemIcon>
        <RecentActorsIcon />
      </ListItemIcon>
      <ListItemText primary={p.text} secondary={p.updatedBy + ' | ' + moment(p.updated).format('DD-MM-yyyy')} />
      {/* <Chip label={p.text} /> */}
    </ListItem>
  ));

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                Client Notes
              </Typography>
              <div style={{ height: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
                <List dense={true}>{noteLists}</List>
              </div>

              <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                {/* <pre>{JSON.stringify(data)}</pre> */}

                <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
                  <Grid item xs={11} sm={5} md={11}>
                    <TextField
                      label="Notes"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="text"
                      inputRef={register({
                        required: true,
                        minLength: { value: 5, message: 'Must have at least 5 letters' },
                        maxLength: { value: 1000, message: 'Maximum characters limit is 1000' },
                      })}
                      error={!!errors.text}
                      helperText={errors.text && errors.text.message}
                    />
                    <input type="hidden" name="updatedby" ref={register} />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <IconButton aria-label="delete" color="primary" onClick={handleSubmit(onSubmit)}>
                      <PostAddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
      {/* end right */}
      {/* <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        <div className={classes.actionBtn}>
          <Button size="small" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" size="small" variant="contained" color="primary" disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </div>
      </Grid> */}
    </React.Fragment>
  );
};
