import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import {
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Loader from '../../../UI/Loader';

export const Field = (props) => {
  const { data, handleClose, handleOnSubmit } = props;

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: 'onChange',
  });

  const { isDirty, isValid } = formState;

  const onSubmit = async (formData) => {
    setIsLoading(true);
    await handleOnSubmit(formData);
    reset({ _id: '', dialect: '' });
    await handleClose();
  };

  return (
    <React.Fragment>
      <DialogTitle>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <Typography variant="h6" gutterBottom>
            Edit Dialect
          </Typography>

          <IconButton aria-label="close" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* <DevTool control={control} /> */}
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
              <TextField
                label="Dialect"
                size="small"
                fullWidth
                name="dialect"
                variant="outlined"
                inputRef={register({
                  required: true,
                  minLength: { value: 2, message: 'Must have at least 2 letters' },
                  maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                })}
                error={!!errors.dialect}
                helperText={errors.dialect && errors.dialect.message}
                defaultValue={data !== undefined ? data.dialect : ''}
                autoFocus
              />
            </Grid>
            <Grid item xs>
              <FormControlLabel
                inputRef={register}
                control={<Checkbox name="enabled" color="primary" defaultChecked={data !== undefined ? data.enabled : false} />}
                label="Enable"
              />
            </Grid>
            <input type="hidden" name="_id" ref={register} defaultValue={data !== undefined ? data._id : ''} />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)} disabled={!isDirty && !isValid}>
          Save
        </Button>
      </DialogActions>

      <Loader open={isLoading} handleClose={backdropCloseHandler} />
    </React.Fragment>
  );
};
