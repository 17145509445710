import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: false,
      };

    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case actionTypes.SET_USER_DETAILS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
        photo: action.payload.photo,
      };

    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
      };

    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case 'FILE_UPLOAD_START':
      return {
        ...state,
        photoLoad: true,
      };

    case 'INTERPRETER_USER_PHOTO':
      return {
        ...state,
        photoLoad: false,
        photoURL: action.payload,
      };

    default:
      return state;
  }
};
