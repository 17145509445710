import React from "react";

import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DoneIcon from "@material-ui/icons/Done";

export const SubmitModal = React.memo((props) => {
  const {
    open,
    data,
    handleClose,
    submitHandler,
    approvedBy,
    closeSubmitHandle,
    modalHandleClose,
  } = props;
  // console.log('submit', data)
  const formValue = {
    //   _id: data._id
    paymentDate: data?.paymentDate,
    notes: data?.notes,
  };

  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: "onChange",
    defaultValues: formValue,
  });
  const { isSubmitting } = formState;

  const onSubmit = async (form) => {
    data.details.forEach(
      (e) => (
        (e.paymentDate = data.paymentDate),
        (e.allocatedDate = form.allocatedDate)
      )
    );

    data.paymentDate = formValue.paymentDate;
    data.allocatedDate = form.allocatedDate;
    data.notes = form.notes;
    data.approvedBy = approvedBy;
    try {
      console.log("submitted: ", data);
      await submitHandler(data);
      await handleClose();
      await modalHandleClose();
      await closeSubmitHandle();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
          disableEscapeKeyDown
          disableBackdropClick
        >
          <DialogTitle id="alert-dialog-title">{"Generate Slip"}</DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item xs={12}>
                {data?.details?.map((d, i) => (
                  <Chip
                    key={i}
                    label={d.bookingRef}
                    deleteIcon={<DoneIcon />}
                    color="primary"
                    size="small"
                    onDelete={() => {}}
                    style={{ marginRight: "2px", marginBottom: "5px" }}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={
                      <KeyboardDatePicker
                        autoOk
                        format="dd/MM/yyyy"
                        id="dob"
                        label="Allocation Date"
                        size="small"
                        variant="inline"
                        fullWidth
                        inputVariant="outlined"
                        error={!!errors.allocatedDate}
                        helperText={
                          errors.allocatedDate && errors.allocatedDate.message
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        // maxDate={new Date()}
                      />
                    }
                    control={control}
                    name="allocatedDate"
                    rules={{ required: true }}
                    error={!!errors.allocatedDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Notes"
                  multiline
                  rows={8}
                  variant="outlined"
                  name="notes"
                  inputRef={register({
                    required: false,
                    maxLength: {
                      value: 2000,
                      message: "Maximum characters limit is 2000",
                    },
                  })}
                  error={!!errors.notes}
                  helperText={errors.notes && errors.notes.message}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              autoFocus
            >
              {isSubmitting ? "Please wait" : "Ok"}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
});
