import React, { useState, useContext } from 'react';
import moment from 'moment';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, IconButton } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { InterpreterContext } from '../../../../../context/states/interpreterState';
import { ViewFile } from '../../../../fileUpload/ViewFile';

import DeleteIcon from '@material-ui/icons/Delete';

const style = {
  heading: { fontWeight: 600, color: '#0000008A' },
};
export const FileLists = (props) => {
  const { items, documentDeleteHandler } = props;

  const { viewDoc, getDocument2Handler } = useContext(InterpreterContext);

  const [isModal, setIsModal] = useState(false);
  const toggleModal = () => setIsModal(false);

  const openImageModal = (file) => {
    // getDocumentHandler(file, 'doc');
    getDocument2Handler('doc', file);
    setIsModal(true);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={style.heading}>Documents</TableCell>
              <TableCell style={style.heading}>Type</TableCell>
              {/* <TableCell style={style.heading}>Filename</TableCell> */}
              <TableCell style={style.heading}>Date</TableCell>
              <TableCell style={style.heading}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items.length !== 0
              ? items.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell component="th" scope="row">
                    <Chip
                      label={row.orginalFile}
                      color="primary"
                      size="small"
                      icon={<AttachmentIcon />}
                      clickable
                      onClick={() => {
                        openImageModal(row.fileName);
                      }}
                    />
                  </TableCell>
                  <TableCell>{row.fileType}</TableCell>
                  {/* <TableCell>{row.fileName}</TableCell> */}
                  <TableCell>{moment(row.updatedAt).format('dddd, MMMM Do YYYY, HH:mm:ss')}</TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="delete" onClick={() => documentDeleteHandler(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
              : (<TableRow>
                <TableCell colSpan={4} align="center">
                  <span>No files uploaded here yet</span>
                </TableCell>
              </TableRow>)
              // .sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1))
            }
          </TableBody>
        </Table>
      </TableContainer>

      {/* {docLoad ? <CircularProgress color="inherit" /> : <img src={`${process.env.REACT_APP_DOMAIN}${viewDoc}`} width="90%" height="90%" />} */}

      <ViewFile previewFile={viewDoc} open={isModal} handleClose={toggleModal} />

      {/* <div style={{ pointer: 'cursor' }} ref={modalImageRef}></div> */}
    </React.Fragment>
  );
};
