import React from 'react'
import {
    Grid,
    Typography,
    ListItemSecondaryAction,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Tooltip,
} from '@material-ui/core';
import moment from 'moment';

import PersonIcon from '@material-ui/icons/Person';
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';

export const FileLists = React.memo((props) => {
    const { fileList, openImageModal, documentDeleteHandler } = props;
    return (
        <>
            <Grid item xs={6}>
                <Typography variant="h6" style={{ fontSize: '14px', fontWeight: 'bold', color: '#333' }}>
                    Documents
                </Typography>
                <List dense={true}>
                    {fileList.length !== 0
                        ? fileList
                            .map((d, i) => {
                                // const docFile = `files[${i}]`;
                                return (
                                    <ListItem button key={i} onClick={() => openImageModal(d.fileName)} filetype={d.fileType}>
                                        <ListItemAvatar>
                                            <Avatar style={{ background: d.comment !== undefined && '#3f51b5' }}>
                                                {d.comment === undefined ? <AttachmentIcon /> : <PersonIcon />}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Tooltip title={moment(d.updatedAt).format('dddd, MMMM Do YYYY, HH:mm:ss')}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        {d.orginalFile}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography variant="body1" display="block" gutterBottom noWrap>{d.comment}</Typography>}
                                            />
                                        </Tooltip>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => documentDeleteHandler(d.fileName)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        {/* <input type="hidden" name={`${docFile}`} ref={register} value={d.fileName} /> */}
                                    </ListItem>
                                );
                            })
                            .filter((x) => x.props.filetype === 'bookingFile')
                        : 'No files'}
                </List>
            </Grid>
        </>
    )
})
