import React, { useState, useCallback } from "react";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  CircularProgress,
  Chip,
  Grid,
  // FormControlLabel,
  // Switch,
} from "@material-ui/core";
import {
  getComparator,
  stableSort,
} from "../../../../../libs/headerSortMethods";

import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { SubmitModal } from "./SubmitModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  accountDetail: {
    display: "flex",
    // flexDirection: "column",
    fontSize: "12px",
    lineHeight: 1.2,
  },
}));

export const DueDetails = React.memo((props) => {
  const {
    open,
    handleClose,
    isLoading,
    data,
    interpreter,
    submitHandler,
    approvedBy,
    submitteData,
    closeSubmitHandle,
  } = props;
  // console.log("Due Details", data);

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("booking_ref");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(150);
  const [isSubmitData, setIsSubmitData] = useState({});
  const [isModal, setIsModal] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data?.map((n) => n);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [data]
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);
  const totalRemittance = selected
    .map((item) => item.inv[0].total)
    .reduce((sum, total) => sum + total, 0);

  const comfirmModal = () => {
    let data = {};
    const remittedDetails = selected.map((item) => {
      const data = {};
      const { inv } = item;

      data.bookingId = item._id;
      data.bookingRef = inv[0].BookingRef;
      data.language = inv[0].language;
      data.service = inv[0].service;
      data.company = inv[0].company;
      data.name = inv[0].name;
      data.fileRef = inv[0].fileRef;
      data.clientName = inv[0].clientName;
      data.amount =
        inv[0].totalAmount === undefined ? inv[0].total : inv[0].totalAmount;
      data.dateOfAttendance = inv[0].dateOfAttendance;

      return data;
    });

    data.interpreterId = interpreter.id;
    data.interpreterName = interpreter.name;
    data.totalAmount = totalRemittance;
    data.details = remittedDetails;
    data.bookID = remittedDetails._id;
    try {
      setIsSubmitData(data);
      setIsModal(true);
      // setSelected([]);
    } catch (error) {
      setIsSubmitData(data);
      setIsModal(true);
    }
  };

  const closeSubmitHandler = useCallback(() => {
    setIsModal(false);
    setSelected([]);
  }, []);

  const modalHandleClose = useCallback(() => {
    handleClose();
    setSelected([]);
  }, [handleClose]);

  const SubmitModalMemo = React.useMemo(
    () => (
      <SubmitModal
        open={isModal}
        data={isSubmitData}
        handleClose={closeSubmitHandler}
        submitHandler={submitHandler}
        closeSubmitHandle={closeSubmitHandle}
        approvedBy={approvedBy}
        modalHandleClose={modalHandleClose}
      />
    ),
    [
      isModal,
      approvedBy,
      isSubmitData,
      modalHandleClose,
      submitHandler,
      closeSubmitHandler,
      closeSubmitHandle,
    ]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title" style={{ padding: "8px 24px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs>
            {interpreter.name + " - " + interpreter.ref}{" "}
            <div className={classes.accountDetail}>
              <span>
                Account Name: <b>{data[0]?.accountName || "N/A"}</b>
              </span>{" "}
              &nbsp;&nbsp;
              <span>
                A/C No: <b>{data[0]?.account_no}</b>
              </span>{" "}
              &nbsp;&nbsp;
              <span>
                Sort Code: <b>{data[0]?.sort_code}</b>
              </span>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Chip
                size="small"
                color="primary"
                fontSize="12px"
                label={"Remittance Slip"}
              />
              <span style={{ float: "right", fontSize: "20px" }}>
                £ {totalRemittance.toFixed(2)}
              </span>
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                {isLoading && (
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data?.length}
                  />
                )}
                <TableBody>
                  {isLoading &&
                    stableSort(data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const {
                          BookingRef,
                          dateOfAttendance,
                          language,
                          service,
                          company,
                          name,
                          fileRef,
                          clientName,
                          totalAmount,
                          total,
                        } = row.inv[0];

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell align="right">{BookingRef}</TableCell>
                            <TableCell align="right">
                              {moment(dateOfAttendance).format("DD-MM-Y")}
                            </TableCell>
                            <TableCell align="right">{language}</TableCell>
                            <TableCell align="right">{service}</TableCell>
                            <TableCell align="right">{company}</TableCell>
                            <TableCell align="right">{name}</TableCell>
                            <TableCell align="right">{fileRef}</TableCell>
                            <TableCell align="right">{clientName}</TableCell>
                            <TableCell align="right">
                              {totalAmount?.toFixed(2) || total?.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                      .filter((item) => item.key !== submitteData.bookID)}
                  {!isLoading && (
                    <TableRow>
                      <TableCell colSpan="9" align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading && (
              <TablePagination
                rowsPerPageOptions={[100, 150, 200, 500]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Paper>
          {SubmitModalMemo}
          {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalHandleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={comfirmModal}
          color="primary"
          variant="contained"
          disabled={selected.length !== 0 ? false : true}
          autoFocus
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
});
