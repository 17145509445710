import React, { useState, useCallback, useContext, useEffect } from 'react';

import {
  Grid,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { AddForm } from '../../../../RateStructures/Forms/AddForm';
import { EditForm } from '../../../../RateStructures/Forms/EditForm';
import { RateContext } from '../../../../../../../context/states/rateState';

export const ClientRates = (props) => {
  const {
    classes,
    data,
    //handleClose,  handleOnSubmit
  } = props;
  const { getClientRateHandler, load, isClientRate } = useContext(RateContext);

  const [isAddOpen, setIsAddOpen] = useState(false);
  const addModel = () => setIsAddOpen(true);
  const addCloseModel = () => setIsAddOpen(false);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isRowData, setIsRowData] = useState({});
  const editModel = useCallback((data) => {
    setIsEditOpen(true);
    setIsRowData(data);
  }, []);
  const editCloseModel = () => setIsEditOpen(false);

  useEffect(() => {
    getClientRateHandler(data.clientID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.clientID]);

  let rateLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!load) {
    rateLists =
      isClientRate.length === 0 ? (
        <TableRow>
          <TableCell colSpan={12} style={{ textAlign: 'center', padding: '1rem 0' }}>
            <h3>Rates NA</h3>
          </TableCell>
        </TableRow>
      ) : (
        isClientRate.map((row) => (
          <TableRow hover key={row._id}>
            <TableCell component="th" scope="row" style={{ borderLeft: row.enabled ? '6px solid #81c784' : '6px solid #e33371' }}>
              {row.rateName.split('_')[0]}
            </TableCell>
            <TableCell align="right">{parseFloat(row.attendance).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.travelPerhour).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.waitingPerHour).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.mileage).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.translationUrgent).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.translationNonUrgent).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.telephoneInterpreting).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.videoInterpreting).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.transcriptionUrgent).toFixed(2)}</TableCell>
            <TableCell align="right">{parseFloat(row.transcriptionNonUrgent).toFixed(2)}</TableCell>
            <TableCell align="right">
              <Tooltip title={`Edit: ${row.rateName}`}>
                <IconButton aria-label="edit" size="small" onClick={() => editModel(row)}>
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))
      );
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper} variant="outlined">
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
          {/* <pre>{JSON.stringify(isClientRate, null, 2)}</pre> */}
          <Grid item>
            <Button size="small" color="primary" variant="outlined" onClick={addModel}>
              Add Rates
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Rate Name</TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Attendance
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Traver Per hour
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Waiting Per Hour
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Mileage
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Translation Urgent
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Translation Non Urgent
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Telephone
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Video
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Transcription Urgent
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Transcription Non Urgent
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{rateLists}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Paper>
      <AddForm open={isAddOpen} specialRate={true} clientID={data.clientID} handleClose={addCloseModel} />
      <EditForm open={isEditOpen} handleClose={editCloseModel} data={isRowData} />
    </React.Fragment>
  );
};
