import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { RateContext } from "../../../../context/states/rateState";
import { getComparator, stableSort } from "../../../../libs/headerSortMethods";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import { TableHeading } from "./Table/TableHead";
import { TableToolbar } from "./Table/TableToolbar";
import { RateLists } from "./rateLists";
import { AddForm } from "./Forms/AddForm";
import { EditForm } from "./Forms/EditForm";

const headCells = [
	{ id: "rateName", numeric: false, disablePadding: false, label: "Rate Name" },
	{ id: "attendance", numeric: false, disablePadding: true, label: "Attendance" },
	{ id: "travelPerhour", numeric: false, disablePadding: true, label: "Travel Per hour" },
	{ id: "waitingPerHour", numeric: false, disablePadding: true, label: "Waiting Per hour" },
	{ id: "translationUrgent", numeric: false, disablePadding: true, label: "Translation Urgent" },
	{
		id: "translationNonUrgent",
		numeric: false,
		disablePadding: true,
		label: "Translation Non Urgent",
	},
	{ id: "telephoneInterpreting", numeric: false, disablePadding: true, label: "Telephone" },
	{ id: "videoInterpreting", numeric: false, disablePadding: true, label: "Video" },
	{ id: "mileage", numeric: false, disablePadding: true, label: "Mileage" },
	{
		id: "transcriptionUrgent",
		numeric: false,
		disablePadding: true,
		label: "Transcription Urgent",
	},
	{
		id: "transcriptionNonUrgent",
		numeric: false,
		disablePadding: true,
		label: "Transcription Non Urgent",
	},
	{ id: "action", numeric: false, disablePadding: false, label: "Action" },
];

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
}));

const RateStructures = React.memo((props) => {
	const { title } = props;
	const classes = useStyles();
	const { loading, getRatesHandler, isRates } = useContext(RateContext);

	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("updatedAt");
	const [dense] = useState(true);
	const [isAddOpen, setIsAddOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isRowData, setIsRowData] = useState({});

	const addModel = () => setIsAddOpen(true);
	const addCloseModel = () => setIsAddOpen(false);
	const editModel = useCallback((data) => {
		setIsEditOpen(true);
		setIsRowData(data);
	}, []);
	const editCloseModel = () => setIsEditOpen(false);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	useEffect(() => {
		let unmounted = false;

		if (!unmounted) {
			getRatesHandler();
		}

		return () => {
			unmounted = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableRow = useMemo(
		() => (
			<RateLists
				data={isRates}
				loading={loading}
				stableSort={stableSort}
				getComparator={getComparator}
				order={order}
				orderBy={orderBy}
				handleEdit={editModel}
			/>
		),
		[isRates, loading, editModel, order, orderBy]
	);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<TableToolbar
					title={title}
					handleFormModal={addModel}
				/>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? "small" : "medium"}
						aria-label="enhanced table"
					>
						<TableHeading
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
						/>
						<TableBody>{tableRow}</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<AddForm
				open={isAddOpen}
				handleClose={addCloseModel}
			/>
			<EditForm
				open={isEditOpen}
				handleClose={editCloseModel}
				data={isRowData}
			/>
		</div>
	);
});

export default RateStructures;

RateStructures.propTypes = {
	title: PropTypes.string.isRequired,
};
