import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Paper,
  TextField,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { UserContext } from '../../../../../../context/states/userState';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  profileLarge: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  formControl: {
    width: '100%',
    maxWidth: '100%',
  },
  input: {
    display: 'none',
  },
  inputTitle: {
    fontWeight: '600',
    marginTop: '.1em',
    marginBottom: '.9em',
  },
}));

export const AddUser = React.memo((props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const { addUserHandler } = useContext(UserContext);

  const [isPassword, setisPassword] = useState({ showPassword: false });
  const handleClickShowPassword = () => setisPassword({ ...isPassword, showPassword: !isPassword.showPassword });
  const handleMouseDownPassword = (event) => event.preventDefault();

  const { register, handleSubmit, errors, control } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    try {
      await addUserHandler(data);
      handleClose()
    } catch (err) {
      console.log('err on user add', err)
    }
  };

  return (
    <React.Fragment>
      {/* <DevTool control={control} /> */}
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Typography variant="h6" gutterBottom>
              New User
            </Typography>

            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                {/* Fields section */}
                <Grid item xs={12}>
                  <Paper className={classes.paper} variant="outlined">
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                      <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                        User Details
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="fore-name"
                            label="Fore Name"
                            size="small"
                            fullWidth
                            name="fName"
                            variant="outlined"
                            inputRef={register({
                              required: true,
                              minLength: { value: 2, message: 'Must have at least 2 letters' },
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                            })}
                            error={!!errors.fname}
                            helperText={errors.fname && errors.fname.message}
                            autoFocus
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={2} md={4}>
                          <TextField
                            id="middle-name"
                            label="Middle Name"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="mName"
                            inputRef={register({
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                            })}
                            error={!!errors.mname}
                            helperText={errors.mname && errors.mname.message}
                          />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="last-name"
                            label="Last Name"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="lName"
                            inputRef={register({
                              required: true,
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                            })}
                            error={!!errors.lname}
                            helperText={errors.lname && errors.lname.message}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel id="gender-label">Gender</InputLabel>
                            <Controller
                              as={
                                <Select labelId="gender-label" id="interpreter-gender">
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={'male'}>Male</MenuItem>
                                  <MenuItem value={'female'}>Female</MenuItem>
                                  <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="gender"
                              rules={{ required: true }}
                              error={!!errors.gender}
                              labelWidth={55}
                            />
                          </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Controller
                              as={
                                <KeyboardDatePicker
                                  autoOk
                                  format="dd/MM/yyyy"
                                  id="dob"
                                  label="Date of birth"
                                  size="small"
                                  variant="inline"
                                  fullWidth
                                  inputVariant="outlined"
                                  error={!!errors.dob}
                                  helperText={errors.dob && errors.dob.message}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              }
                              control={control}
                              name="dob"
                              rules={{ required: true }}
                              error={!!errors.dob}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid> */}



                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl variant="outlined" size="small" className={classes.formControl}>
                            <InputLabel id="role-label">Role</InputLabel>
                            <Controller
                              as={
                                <Select labelId="role-label" id="user-role" defaultValue="user">
                                  <MenuItem value={'admin'}>Admin</MenuItem>
                                  <MenuItem value={'ip-admin'}>
                                    IP Admin
                                  </MenuItem>
                                  <MenuItem value={'superadmin'}>Super Admin</MenuItem>
                                </Select>
                              }
                              control={control}
                              name="role"
                              rules={{ required: true }}
                              error={!!errors.role}
                              labelWidth={55}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            label="Email"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="email"
                            inputRef={register({
                              required: true,
                              minLength: { value: 5, message: 'Must have at least 5 letters' },
                              maxLength: { value: 50, message: 'Maximum characters limit is 50' },
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()w[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Invalid email-id',
                              },
                            })}
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" size="small" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={isPassword.showPassword ? 'text' : 'password'}
                              name="password"
                              inputRef={register({
                                required: true,
                                minLength: { value: 4, message: 'Password must have at least 4 letters' },
                                maxLength: { value: 30, message: 'Password maximum characters limit is 30' },
                              })}
                              error={!!errors.password}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {isPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={70}
                            />
                            <FormHelperText style={{ color: 'red' }}>{errors.password && errors.password.message}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  {/* <Paper className={classes.paper} variant="outlined">
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                      <Typography variant="subtitle2" gutterBottom style={{ fontWeight: '600', paddingBottom: '5px' }} color="primary">
                        Leave
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Total"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="totalLeave"
                            inputRef={register({
                              required: true,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Invalid number',
                              }
                            })}
                            error={!!errors.totalLeave}
                            helperText={errors.totalLeave && errors.totalLeave.message}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Taken"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="leaveTaken"
                            inputRef={register({
                              required: true,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Invalid number',
                              }
                            })}
                            error={!!errors.leaveTaken}
                            helperText={errors.leaveTaken && errors.leaveTaken.message}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Balance"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="balanceLeave"
                            inputRef={register({
                              required: true,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Invalid number',
                              }
                            })}
                            error={!!errors.balanceLeave}
                            helperText={errors.balanceLeave && errors.balanceLeave.message}
                          />
                        </Grid>

                       
                      </Grid>
                    </Grid>
                  </Paper> */}

                   {/* <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="mobile"
                            label="Mobile"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="mobile"
                            inputRef={register({
                              required: true,
                              minLength: { value: 10, message: 'Must have at least 10 digits' },
                              maxLength: { value: 20, message: 'Maximum digits limit is 20' },
                              pattern: {
                                value: /^[0-9]+$/,
                                message: 'Invalid mobile number',
                              },
                            })}
                            error={!!errors.mobile}
                            helperText={errors.mobile && errors.mobile.message}
                          />
                        </Grid> */}

                </Grid>
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" size="small" color="primary" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});
