export default (state, action) => {
    switch (action.type) {
        case 'CLEAR_SEARCH':
            return {
                ...state,
                bookingsearch: {
                    sn: '',
                    name: '',
                    fileRef: '',
                    interpreterName: '',
                    serviceType: '',
                    language: '',
                    dialect: '',
                    companyName: '',
                    clientName: '',
                    status: '',
                    page: 1,
                    limit: 25,
                },
                interpreterSearch: {
                    fname: '',
                    language: '',
                    dialect: '',
                    gender: '',
                    service: '',
                    postcode: '',
                    active: '',
                    page: 1,
                    limit: 25
                },
                loading: true
            };

        case 'SEARCH_BOOKINGS':

            return {
                ...state,
                bookingsearch: {
                    sn: action.payload.sn,
                    name: action.payload.name,
                    fileRef: action.payload.fileRef,
                    interpreterName: action.payload.interpreterName,
                    serviceType: action.payload.serviceType,
                    language: action.payload.language,
                    dialect: action.payload.dialect,
                    companyName: action.payload.companyName,
                    clientName: action.payload.clientName,
                    status: action.payload.status,
                    page: action.payload.page,
                    limit: action.payload.limit,
                },
                loading: false
            }

        case 'SEARCH_INTERPRETER':
            return {
                ...state,
                interpreterSearch: {
                    fname: action.payload.fname,
                    language: action.payload.language,
                    dialect: action.payload.dialect,
                    gender: action.payload.gender,
                    service: action.payload.service,
                    postcode: action.payload.postcode,
                    active: action.payload.active,
                    page: action.payload.page,
                    limit: action.payload.limit
                },
                loading: false
            }

        case 'CLEAR_BOOKING':
            return {
                ...state,
                bookingsearch: {
                    page: 1,
                    limit: 25,
                    serviceType: '',
                    language: ''
                },
                loading: true
            }

        case 'CLEAR_ALL_INERPRETER':
            return {
                ...state,
                interpreterSearch: {
                    fname: '',
                    language: '',
                    dialect: '',
                    gender: '',
                    service: '',
                    postcode: '',
                    active: '',
                    page: 1,
                    limit: 25
                }
            }
    }
};
