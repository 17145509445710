import React, { useContext, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InvoiceContext } from '../../../context/states/invoiceState';

import { makeStyles, lighten } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Paper,
  Typography,
  Grid,
  IconButton,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Header from './Header';
import InvoiceDetail from './InvoiceDetail';
import InvoiceTable from './InvoiceTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(1, 0, 3),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
  },
  headerCard: {
    minWidth: 200,
  },
  cancellationCard: {
    minWidth: 200,
    backgroundColor: lighten(theme.palette.secondary.light, 0.8),
  },
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  invoiceTitle: {
    textTransform: 'capitalize',
  },
}));

export const Invoice = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { id, iType, clientInvoice } = useParams();
  const { search } = location;
  const { load, isInvoice, getInterpreterInvoiceHandler, getInvoiceHandler, updateCreditNoteHandler, updateInvoiceHandler } = useContext(
    InvoiceContext
  );

  let params = new URLSearchParams(search);
  const crNoteParams = params.get('crNote');

  const formValue = {
    id: id,
  };

  const { register, handleSubmit, errors, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: formValue,
  });

  const { isValid, isSubmitting } = formState;

  var item;
  if (iType === 'client' && clientInvoice === 'yes') {
    const { cInvoice } = isInvoice;
    item = cInvoice !== undefined ? cInvoice : '';
  } else {
    const { iInvoice } = isInvoice;
    item = iInvoice !== undefined ? iInvoice : '';
  }

  useEffect(() => {
    let isMounted = false;

    if (iType === 'client') {
      if (!isMounted) getInvoiceHandler(id);
    } else {
      if (!isMounted) getInterpreterInvoiceHandler(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      isMounted = true;
    };
  }, [id, iType, clientInvoice]);

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async (data) => {
    console.log(data);
    await updateCreditNoteHandler(data);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={10}>
        <AppBar position="sticky" color="default" style={{ top: '3.6em' }} elevation={2}>
          <Toolbar className={classes.title} variant="dense">
            <IconButton aria-label="Bast to list" onClick={goBack} color="inherit" edge="start" size="small">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {!load ? '' : <small>Loading....</small>}
            </Typography>
            <Typography variant="h6" className={classes.invoiceTitle}>
              {item?.iType}
            </Typography>
            &nbsp;|&nbsp;
            <Typography variant="subtitle2">{crNoteParams === 'true' ? item?.name : item?.fullname}</Typography>
          </Toolbar>
        </AppBar>
        <Paper className={classes.paper}>
          {load ? (
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
              <div style={{ margin: '2.5rem 0' }}>
                <CircularProgress />
              </div>
            </Grid>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Header data={item} invoiceType={clientInvoice} />
                </Grid>

                <Grid item xs={12}>
                  <InvoiceDetail classes={classes} data={item} invoiceType={clientInvoice} />
                </Grid>

                {item?.cancelled && (
                  <Grid item xs={12}>
                    <Card className={classes.cancellationCard} variant="outlined">
                      <CardContent>
                        <Typography variant="body2" component="p" style={{ textAlign: 'center', fontWeight: '600' }} color="secondary">
                          This invoice is cancelled please look for the Revised Invoice and the credit note for this Invoice
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12}>
                  {!load && <InvoiceTable classes={classes} data={item} invoiceType={clientInvoice} updateInvoice={updateInvoiceHandler} />}
                </Grid>
              </Grid>
              <br />
              {!item?.cancelled && crNoteParams === 'true' && (
                <form onSubmit={(e) => e.preventDefault()}>
                  <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                    <input type="hidden" name="id" ref={register} value={id} />
                    <input type="hidden" name="invoiceNo" ref={register} defaultValue={item?.invoiceNo} />
                    <Grid item xs={12} sm={4} md={3}>
                      <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="quotation-booking-label">CR Note</InputLabel>
                        <Controller
                          as={
                            <Select
                              label="CR Note"
                              inputProps={{
                                name: 'crNote',
                                id: 'invoice-cr-label',
                              }}
                            >
                              <MenuItem aria-label="None" value="">
                                None
                              </MenuItem>
                              <MenuItem value={'client'}>Client</MenuItem>
                              <MenuItem value={'interpreter'}>Interpreter</MenuItem>
                              <MenuItem value={'both'}>Both</MenuItem>
                            </Select>
                          }
                          control={control}
                          name="crNote"
                          rules={{ required: true }}
                          error={!!errors.crNote}
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={1} md={1}>
                      <Button
                        size="small"
                        style={{ marginTop: '12px' }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid || isSubmitting}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </div>
  );
};
