import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { DueLists } from '../../../components/pages/payments/clientPayments/dueLists';
import { getComparator, stableSort } from '../../../libs/headerSortMethods'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  container: {
    height: 'calc(70vh - 20px)',
  },
  container2: {
    height: 'calc(80vh - 20px)',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ClientPayments = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div classes={classes.root}>
        <Paper className={classes.paper}>
          <DueLists title="Client Payments" classes={classes} getComparator={getComparator} stableSort={stableSort} />
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default ClientPayments;
