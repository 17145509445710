export const headCells = [
    { id: 'booking_ref', numeric: false, disablePadding: false, label: 'Booking Ref', width: '100' },
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Received', width: '100' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status', width: '100' },
    { id: 'date', numeric: false, disablePadding: true, label: 'Date of Attendance', width: '100' },
    { id: 'language', numeric: false, disablePadding: true, label: 'Language', width: '100' },
    { id: 'serviceType', numeric: false, disablePadding: true, label: 'Service', width: '100' },
    { id: 'companyName', numeric: false, disablePadding: true, label: 'Company Name', width: '100' },
    { id: 'name', numeric: false, disablePadding: true, label: 'Customer Name', width: '100' },
    { id: 'phone', numeric: false, disablePadding: true, label: 'Telephone', width: '100' },
    { id: 'clientName', numeric: false, disablePadding: true, label: 'Client Name', width: '100' },
    { id: 'invoiceNo', numeric: false, disablePadding: true, label: 'Invoice No', width: '100' },
    { id: 'interpreterName', numeric: false, disablePadding: true, label: 'Interpreter Name', width: '100' },
    { id: 'action', numeric: false, disablePadding: true, label: 'Action', width: '100' },
];
