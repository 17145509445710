import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Datatable = (props) => {
  const classes = useStyles();
  const { data, loading, handleEdit } = props;

  const columns = data[0] && Object.keys(data[0]);

  const editHandler = (row) => {
    handleEdit(row);
  };

  let tableLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableLists = data.map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row" key={index}>
          {row[columns[2]]}
        </TableCell>

        <TableCell>
          <Tooltip title="Refresh">
            <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={() => editHandler(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {/* {data[0] &&
                columns.map((heading, index) => (
                  <TableCell style={{ fontWeight: 600, fontSize: '12px', textTransform: 'capitalize' }} key={index}>
                    {heading}
                  </TableCell>
                ))} */}

              <TableCell style={{ fontWeight: 600, fontSize: '12px', textTransform: 'capitalize' }}>Name</TableCell>
              <TableCell style={{ fontWeight: 600, fontSize: '12px', textTransform: 'capitalize' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {data.map((row, index) => (
              <TableRow key={index}> */}
            {/* {columns.map((column, index) => {
                  console.log(column));
                  return (
                    <>
                      <TableCell component="th" scope="row" key={index}>
                        {row[column]}
                      </TableCell>
                       
                      // <TableCell>
                      //   <Tooltip title="Refresh">
                      //     <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={() => {}}>
                      //       {row[column]}
                      //     </IconButton>
                      //   </Tooltip>
                      // </TableCell> 
                    </>
                  );
                })} */}

            {/* <TableCell component="th" scope="row" key={index}>
                  {row[columns[1]]}
                </TableCell>

                <TableCell>
                  <Tooltip title="Refresh">
                    <IconButton aria-label="Refresh Data" color="primary" aria-haspopup="true" onClick={() => editHandler(row[columns[0]])}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))} */}

            {tableLists}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default Datatable;
