import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { TableRow, TableCell, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const TermLists = (props) => {
  const { stableSort, getComparator, order, orderBy, data, loading, handleEdit } = props;

  let tableLists = (
    <TableRow>
      <TableCell colSpan={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!loading) {
    tableLists = (
      <>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={12} style={{ textAlign: 'center' }}>
              <span>No Record</span>
            </TableCell>
          </TableRow>
        ) : (
          stableSort(data, getComparator(order, orderBy)).map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                <TableCell align="left">{row.businessType}</TableCell>
                <TableCell align="left">{row.serviceType}</TableCell>
                {/* <TableCell align="left" dangerouslySetInnerHTML={{ __html: row.text }}></TableCell> */}
                <TableCell align="left">{moment(row.updatedAt).format('DD-MM-yyyy')}</TableCell>
                <TableCell align="right">
                  <Tooltip title={`Edit: ${row.businessType}`}>
                    <IconButton aria-label="edit" size="small" onClick={() => handleEdit(row)}>
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })
        )}
      </>
    );
  }
  return <>{tableLists}</>;
};

TermLists.propTypes = {
  stableSort: PropTypes.func.isRequired,
  getComparator: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
};
