import React, { createContext, useReducer } from 'react';
import RemittanceReducer from '../reducers/remittanceReducer';
import axios from '../../axios';

import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';

const initialState = {
  loading: false,
  remittances: [],
  total: 0,
  count: 0,
  interpreters: []
};

export const RemittanceContext = createContext(initialState);
export const RemittanceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(RemittanceReducer, initialState);

  const getRemittancesHandler = async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Remittances + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInterpreterRemittancesHandler = async (id, query) => {
    dispatch({ type: 'LOAD_DUE' });
    try {
      const result = await axios.get(`${urlRequest.Remittances}/${id}${query}`);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getInterpretersHandler = async (query) => {
    dispatch({ type: 'LOAD_INTERRETER' });
    try {
      const result = await axios.get(urlRequest.Interpreters + '/' + query);
      dispatch({ type: 'GET_INTERPRETER_NAME', payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <RemittanceContext.Provider
      value={{
        loading: state.loading,
        data: state.remittances,
        total: state.total,
        interpreters: state.interpreters,
        getRemittancesHandler,
        getInterpreterRemittancesHandler,
        getInterpretersHandler
      }}
    >
      {children}
    </RemittanceContext.Provider>
  );
};
