import React, { useEffect, useContext } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

// import Grid from '@material-ui/core/Grid';

import { BookingProvider, BookingContext } from '../../../context/states/bookingState';
import { TimesheetProvider } from '../../../context/states/timeSheetState';
import { FileProvider } from '../../../context/states/fileState';
import { SendQuoteProvider } from '../../../context/states/sendQuoteState';
import { RateProvider } from '../../../context/states/rateState';

import { Bookings } from '../../../components/pages/bookings/Bookings';
import EditBooking from '../../../components/pages/bookings/Forms/EditForm/EditBooking';
import { InvoiceProvider } from '../../../context/states/invoiceState';
import { Invoice } from '../../../components/includes/Invoice/Invoice';

import AuthenticatedRoute from '../../../middlewares/AuthenticatedRoute';

const BookingTracker = () => {
  // console.log('RENDER BOOKING TRACKER');
  let { path } = useRouteMatch();
  const { getDepartmentOptionHandler, getCaseTyoeOptionHandler } = useContext(BookingContext);

  // React.useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('bStatus');
  //   };
  // }, []);

  useEffect(() => {
    getCaseTyoeOptionHandler()
    getDepartmentOptionHandler()

    return () => {};
  }, [])

  return (
    <React.Fragment>
      {/* <BookingProvider> */}
      <TimesheetProvider>
        <RateProvider>
          <FileProvider>
            <SendQuoteProvider>
              <InvoiceProvider>
                <Switch>
                  <AuthenticatedRoute exact path={path}>
                    <Bookings title="Booking Tracker" />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path={`${path}/edit/:id`}>
                    <EditBooking />
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path={`${path}/invoice/:id/:iType/:clientInvoice`}>
                    <Invoice />
                  </AuthenticatedRoute>
                </Switch>
              </InvoiceProvider>
            </SendQuoteProvider>
          </FileProvider>
        </RateProvider>
      </TimesheetProvider>
      {/* </BookingProvider> */}
    </React.Fragment>
  );
};

export default BookingTracker;
