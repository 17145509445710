import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
        isQuote: [],
        iload: true,
        profileImage: '',
      };

    case 'FETCH_BOOKING':
      return {
        ...state,
        isQuote: action.payload,
        loading: false,
      };

    case 'FETCH_PROFILE_IMAGE':
      return {
        ...state,
        profileImage: action.payload.base64str,
        iload: false,
      };
    default:
      return state;
  }
};
