import React from 'react';

import { Grid, Card, CardContent, Typography } from '@material-ui/core';

const Header = (props) => {
  const { data, invoiceType } = props;
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          {invoiceType !== 'yes' ? '' : <h3>To:</h3>}
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <div style={{ paddingLeft: '1.5rem' }}>
                <Typography color="textSecondary">{invoiceType !== 'yes' ? data?.fullname : data?.name}</Typography>
                {invoiceType === 'yes' && (
                  <Typography variant="body2" component="p">
                    {data?.company}
                  </Typography>
                )}
                <Typography variant="body2" component="p">
                  {data?.address}
                </Typography>
                <Typography variant="body2" component="p">
                  {data?.postcode}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              {invoiceType !== 'yes' ? <h3>To:</h3> : ''}
              <div style={{ paddingLeft: '1.5rem' }}>
                <Typography color="textSecondary" variant="h6" component="h2">
                  Language Interpreters
                </Typography>
                <Typography variant="body2" component="p">
                  124 City Road, London, <br /> EC1V 2NX.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Header;
