import React, { createContext, useReducer, useContext } from 'react';
import SendQuoteReducer from '../reducers/sendQuoteReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  isQuote: [],
  iload: false,
  profileImage: '',
  error: null,
};

// creating context
export const SendQuoteContext = createContext(initialState);

// provider component
export const SendQuoteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SendQuoteReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All SendQuotes data */
  const getSendQuoteHandler = async (id) => {
    dispatch({ type: actionTypes.PROCESS_START });

    try {
      const result = await axios.get(`${urlRequest.Bookings}/${id}`);
      const response = result.data.data;
      console.log(response);
      try {
        const SendQuote = await axios.get(`${urlRequest.Terms}/${response.serviceType}/${response.status !== 'Quotation' ? 'Booking' : 'Quotation'}`);
        const response2 = SendQuote.data.data;
        const getResponse = { booking: response, term: response2 };
        dispatch({ type: 'FETCH_BOOKING', payload: getResponse });
      } catch (error) {
        console.log(error);
        dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
      }

      try {
        const interpreter = await axios.get(`${urlRequest.Interpreters}/baseimg/${response.interpreter}`);
        const response3 = interpreter.data;

        dispatch({ type: 'FETCH_PROFILE_IMAGE', payload: response3 });
      } catch (error) {
        console.log(error);
        dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const mailHandler = async (data) => {
    var response;
    try {
      const result = await axios.post(urlRequest.mailSend, data);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Mail sent' });
      }
      response = true;
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Oops Something went wrong!' });
      response = false;
    }
    return response;
  };

  return (
    <SendQuoteContext.Provider
      value={{
        loading: state.loading,
        isQuote: state.isQuote,
        iload: state.iload,
        qLoad: state.loading,
        profileImage: state.profileImage,
        getSendQuoteHandler,
        mailHandler,
      }}
    >
      {children}
    </SendQuoteContext.Provider>
  );
};
