import React from 'react';

import Grid from '@material-ui/core/Grid';
import { TermProvider } from '../../../context/states/termState';
import TermsAndConditions from '../../../components/pages/masters/TermsAndConditions/TermsAndConditions';

const TermsAndConditionsMaster = () => {
  return (
    <React.Fragment>
      <TermProvider>
        <Grid item xs>
          <TermsAndConditions title="Terms and Conditions" />
        </Grid>
      </TermProvider>
    </React.Fragment>
  );
};

export default TermsAndConditionsMaster;
