import React from 'react'
import NoteIcon from '@material-ui/icons/Note'; // for new
import BookIcon from '@material-ui/icons/Book'; // for booking
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'; // for quotation
import DoneIcon from '@material-ui/icons/Done'; // for confirmed
import ScheduleIcon from '@material-ui/icons/Schedule'; // for timesheet
import ThumbUpIcon from '@material-ui/icons/ThumbUp'; // for approved
import PaymentIcon from '@material-ui/icons/Payment'; // for paid
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'; // for quote supplied
import ClearIcon from '@material-ui/icons/Clear'; // for cencel

export const getAvataricon = (icon) => {
    switch (icon) {
        case 'NoteIcon':
            return <NoteIcon />

        case "BookIcon":
            return <BookIcon />;

        case "FormatQuoteIcon":
            return <FormatQuoteIcon />;

        case "DoneIcon":
            return <DoneIcon />;

        case 'ScheduleIcon':
            return <ScheduleIcon />;

        case 'ThumbUpIcon':
            return <ThumbUpIcon />;

        case 'PaymentIcon':
            return <PaymentIcon />;

        case 'HelpOutlineIcon':
            return <HelpOutlineIcon />;

        case 'ClearIcon':
            return <ClearIcon />;

        default:
            return null;
    }
};