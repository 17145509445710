import React, { createContext, useReducer, useContext } from 'react';
import UserReducer from '../reducers/userReducer';
import * as actionTypes from '../actionTypes';
import * as urlRequest from '../urls';
import axios from '../../axios';

import { AlertContext } from './alertState';

// InitialState
const initialState = {
  loading: false,
  load: false,
  users: [],
  user: {},
  total: 0,
  error: null,
  notes: [],
  fileList: [],
  profileImage: '',
};

// creating context
export const UserContext = createContext(initialState);

// provider component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  const { alertDispatch } = useContext(AlertContext);

  /* Fetch All users data */
  const getUsersHandler = async () => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.Users);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: 'something went wrong' });
    }
  };

  /* Add New users data */
  const addUserHandler = async (obj) => {
    const { data } = await axios.post(urlRequest.UserCreate, obj);
    try {
      if (data.success) {
        console.log(data)
        dispatch({ type: actionTypes.ADD_RECORD, payload: data.data });
        alertDispatch({ type: 'open', message: data.msg });
        return await data.data;
      }
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Something went wrong!' });
    }
    // if(response.data === undefined) {
    //   alertDispatch({ type: 'open', message: response?.msg });
    // } else {
    //   const { data } = response.data;
    //   console.log('user payload state'. data)
    //   dispatch({ type: actionTypes.ADD_RECORD, payload: data });
    //   alertDispatch({ type: 'open', message: 'User created' });
    //   return await data;
    // }
    // console.log(success, msg, data)
    // if(!success) {
    //   alertDispatch({ type: 'open', message: msg });

    // }
    // else {
    //   dispatch({ type: actionTypes.ADD_RECORD, payload: data });
    //   alertDispatch({ type: 'open', message: 'User created' });
    // }
    return await {};
  };

  /* Fetch Single user Data by id */
  const getUserHandler = async (id) => {
    dispatch({ type: 'SINGLE_FETCH' });
    try {
      const result = await axios.get(`${urlRequest.Users}/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  /* Update interpreter by id */
  const updatedUserHandler = async (data) => {
    const result = await axios.put(`${urlRequest.UserUpdate}/${data._id}`, data);
    dispatch({ type: actionTypes.UPDATE_RECORD, payload: result.data.data });
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'User updated' });
    }

    return await result;
  };

  const updatePasswordHandler = async (data) => {
    // console.log('CONTEXT: ', data);
    const result = await axios.put(`${urlRequest.Interpreters}/${data.id}`, data);
    if (result.data.success) {
      alertDispatch({ type: 'open', message: 'Password updated' });
    }
  };

  const addNotesHandler = async (note) => {
    // console.log('note', note)
    try {
      const {data} = await axios.put(`${urlRequest.UserNote}/${note.id}`, note);
      if (data.success) {
        dispatch({ type: 'USER_NOTE', payload: note });
        alertDispatch({ type: 'open', message: 'Note added' });
      }
    } catch (error) {
      alertDispatch({ type: 'open', message: 'Something went wrong!' });
    }
  }

  const getDocumentHandler = async (path, filename) => {
    dispatch({ type: actionTypes.DOC_LOAD });
    try {
      const result = await axios.get(`${urlRequest.User}/${path}/${filename}`);
      dispatch({ type: actionTypes.GET_DOCUMENT_IMAGE, payload: result.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const uploadProImageHandler = async (file) => {
    dispatch({ type: actionTypes.FILE_UPLOAD_START });
    try {
      const result = await axios.put(`${urlRequest.User}/${file.id}/photo`, file.file);
      dispatch({ type: actionTypes.UPDATE_PROFILE_IMAGE, payload: result.data.data });
      getProfileHandler(result.data.data);
      if (result.data.success) {
        alertDispatch({ type: 'open', message: 'Profile image uploaded' });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getProfileHandler = async (filename) => {
    dispatch({ type: actionTypes.FILE_UPLOAD_START });
    try {
      const photo = await axios.get(`${urlRequest.User}/${filename}/photo`);
      dispatch({ type: actionTypes.GET_PROFILE_IMAGE, payload: photo.config.url });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  return (
    <UserContext.Provider
      value={{
        loading: state.loading,
        isUsers: state.users,
        total: state.total,
        load: state.load,
        user: state.user,
        notes: state.notes,
        fileList: state.fileList,
        viewDoc: state.viewDoc,
        profileImage: state.profileImage,
        getUsersHandler,
        addUserHandler,
        getUserHandler,
        updatedUserHandler,
        updatePasswordHandler,
        addNotesHandler,
        userDocsDispatch: dispatch,
        getDocumentHandler,
        uploadProImageHandler,
        getProfileHandler
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
