export default (state, action) => {
  switch (action.type) {
    case 'PROCESS_BEGIN':
      return {
        ...state,
        percentage: 0,
        message: '',
      };

    case 'UPLOADING_START':
      return {
        ...state,
        message: action.payload,
      };

    case 'FILES_SELECTED':
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload],
      };

    case 'VALID_FILES':
      return {
        ...state,
        validFiles: action.payload,
      };

    case 'FILES_INVALID':
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload],
        validFiles: [...state.validFiles, action.payload],
      };

    case 'FILES_UNSUPPORTED':
      return {
        ...state,
        unsupportedFiles: [...state.unsupportedFiles, action.payload],
      };

    case 'PERCENTAGE_PROGRESS':
      return {
        ...state,
        percentage: action.payload,
      };

    case 'REMOVE_VALID_FILES':
      return {
        ...state,
        validFiles: state.validFiles.filter((item) => item.name !== action.payload),
        selectedFiles: state.selectedFiles.filter((item) => item.name !== action.payload),
        unsupportedFiles: state.unsupportedFiles.filter((item) => item.name !== action.payload),
        message: '',
      };

    case 'FILES_ERROR':
      return {
        ...state,
        // errorMessage: action.payload,
        message: action.payload,
      };

    case 'FILES_UPLOADED':
      return {
        ...state,
        message: 'File(s) Uploaded',
        validFiles: [],
        selectedFiles: [],
        unsupportedFiles: [],
      };

    case 'UPLOADED_FILES':
      return {
        ...state,
        fileList: [action.payload, ...state.fileList],
      };

    // case 'DELETE_FILE':
    //   console.log(action.payload);

    default:
      return state;
  }
};
