export default (state, action) => {
    switch (action.type) {
        case 'PROCESS_START':
            return {
                ...state,
                loading: false,
                invoices: [],
                totalInvoices: 0,
                totalvat: 0, 
                totalService : 0,
                totalhavt: 0,
                totalPaid: 0,
                clients: [],
                error: null
            }

        case 'FETCH_RECORDS':
            return {
                ...state,
                loading: false,
                invoices: action.payload.data,
                totalInvoices: action.payload.totalInvs,
                count: action.payload.count,
                totalPaid: action.payload.paidAmount,
                totalvat: action.payload.totalVat, 
                totalhavt: action.payload.hvatTotal, 
                totalService : action.payload.totalAdminCharges,
                chgTotal: action.payload.chgTotal,
            }

            case 'FETCH_CLIENT_RECORD':
                return {
                    ...state,
                    clients: action.payload.data
                }

        case 'ERROR_CATCH':
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}