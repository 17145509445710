import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
// import { DevTool } from '@hookform/devtools';
// import { timeConvertHandler, calOnsitePropHandler, onlyNumbersWithColon } from '../../../../../../libs/customHandler';

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TextField,
	Chip,
	Tooltip,
} from "@material-ui/core";

const styles = {
	colOne: {
		width: "40%",
	},
	colTwo: {
		width: "20%",
	},
	colThree: {
		width: "20%",
	},
	colFour: {
		width: "20%",
	},
	colTranOne: { width: "20%" },
	colTranTwo: { width: "20%" },
	colTranThree: { width: "20%" },
	colTranFour: { width: "20%" },
	colTranFive: { width: "20%" },
	tableStrap: { backgroundColor: "transparent" },
};

export const PrepareQuotation = React.memo((props) => {
	const { open, handleClose, classes, onSubmitHandler, data, isRate, isMinRates } = props;

	const timeConvertHandler = useCallback((s, e) => {
		var now = moment(s);
		var then = moment(e);

		var duration = moment.duration(then.diff(now));
		duration.add(1, "days");
		const { hours, minutes } = duration._data;
		const calTime = `${hours < 10 ? "0" + hours : hours}:${
			minutes < 10 ? "0" + minutes : minutes
		}`;

		console.log("date", calTime);

		return calTime;
	}, []);

	const formValue = {
		_id: data._id,
		serviceType: data.serviceType,
		bookingType: data.bookingType,
		fileRef: data.fileRef,
		serviceChargePercent: data.serviceChargePercent,
		urgency: data.urgency,
		startTime: data?.startTime || "",
		endTime: data?.endTime || "",
		isBooked: data?.isBooked || "",
		// durationMin: data?.durationMin || timeConvertHandler(data?.startTime, data?.endTime),
		// attendanceOne: data?.attendanceOne || timeConvertHandler(data?.startTime, data?.endTime),
		durationMin: timeConvertHandler(data?.startTime, data?.endTime),
		attendanceOne: timeConvertHandler(data?.startTime, data?.endTime),
		attendanceTwo: data?.attendanceTwo || "00:00",
		estimatedFares: data?.estimatedFares || 0,
		estimatedMileage: data?.estimatedMileage || 0,
		mileageMinRate: data?.mileageMinRate || 0,
		estimatedTravelIn: data?.estimatedTravelIn || "00:00",
		estimatedTravelOut: data?.estimatedTravelOut || "00:00",
		estimatedWaiting: data?.estimatedWaiting || "00:00",
		parkingFee: data?.parkingFee || 0,
		pageCount: data?.pageCount || 0,
		wordCount: data?.wordCount || 0,
		costPerMin: data?.quotation?.costPerMin || 0,
		totalDurationAmount: data?.quotation?.totalDurationAmount || "",
		totalTransDurationAmount: data?.quotation?.totalTransDurationAmount || 0,
		costPerWord: data?.quotation?.costPerWord || 0,
		transTotalDurationAmount: data?.quotation?.transTotalDurationAmount || 0,
		durationTeleAndVideoInMins: data?.quotation?.durationTeleAndVideoInMins || 0,
		durationTranscriptionInMins: data?.quotation?.durationTranscriptionInMins || "",
		totalTeleAndVideoAmount: data?.quotation?.totalTeleAndVideoAmount || "",
		totalTranscriptionMinCharges: data?.quotation?.totalTranscriptionMinCharges || 0,
		totalTranslationMinCharges: data?.quotation?.totalTranslationMinCharges || 0,
		attendanceOneMinRate: data?.quotation?.attendanceOneMinRate || 0,
		totalAttendanceOneAmount: data?.quotation?.totalAttendanceOneAmount || 0,
		attendanceTwoMinRate: data?.quotation?.attendanceTwoMinRate || 0,
		totalAttendanceTwoAmount: data?.quotation?.totalAttendanceTwoAmount || 0,
		travelInMinRate: data?.quotation?.travelInMinRate || 0,
		totalTravelInAmount: data?.quotation?.totalTravelInAmount || 0,
		travelOutMinRate: data?.quotation?.travelOutMinRate || 0,
		totalTravelOutAmount: data?.quotation?.totalTravelOutAmount || 0,
		waitingMinRate: data?.quotation?.waitingMinRate || 0,
		totalWaitingAmount: data?.quotation?.totalWaitingAmount || 0,
		minimumUrgencyAndNonUrgencyCharge: data?.quotation?.minimumUrgencyAndNonUrgencyCharge || 0,
		totalMileageAmount: data?.quotation?.totalMileageAmount || 0,
		status: data?.status || "",
	};
	console.log(data);
	const { register, handleSubmit, errors, watch, formState } = useForm({
		mode: "onChange",
		defaultValues: formValue,
	});

	const serviceLabel = data?.vat ? "VAT" : "Service Charges";
	const { isValid, isSubmitting } = formState;

	const sT = watch("serviceType");
	const status = watch("status");
	const minDurationWatch = watch("durationMin");
	const wordCountWatch = watch("wordCount");
	const attendanceOneWatch = watch("attendanceOne");
	const attendanceTwoWatch = watch("attendanceTwo");
	const estimatedMileageWatch = watch("estimatedMileage");
	const estimatedTravelInWatch = watch("estimatedTravelIn");
	const estimatedTravelOutWatch = watch("estimatedTravelOut");
	const estimatedTravelWaitingWatch = watch("estimatedWaiting");
	const estimatedFaresWatch = watch("estimatedFares");
	const parkingFeeWatch = watch("parkingFee");
	const discountsWatch = watch("quotation.discounts");

	// Onsubmit
	const onSubmit = async (formData) => {
		if (status === "Booking") {
			// When intperter assign for booking the status will be change as booked
			formData.status = "Booking";
			formData.isBooked = true;
		} else if (status === "Quotation") {
			formData.status = "Quotation";
			formData.isBooked = false;
		}
		await onSubmitHandler(formData);
		await handleClose();
	};

	const calOnsitePropHandler = (time, charges) => {
		let amount = 0;
		let rate = 0;

		const totalDuration = String(time).split(":");
		const duration = parseInt(totalDuration[0]) * 60 + parseInt(totalDuration[1]);
		rate = charges;

		if (duration === 0) {
			amount = 0;
		} else if (duration <= 60 && duration > 0) {
			amount = rate;
		} else {
			const cal = (rate / 60) * duration;
			amount = cal;
		}
		return amount;
	};

	const calOnsiteInAndOutWardHandler = (time, charges) => {
		let amount = 0;
		let rate = 0;

		const totalDuration = String(time).split(":");
		const duration = parseInt(totalDuration[0]) * 60 + parseInt(totalDuration[1]);
		rate = charges;

		if (duration === 0) {
			amount = 0;
		}
		// else if (duration <= 60 && duration > 0) {
		//   amount = rate;
		// }
		else {
			const cal = (rate / 60) * duration;
			amount = cal;
		}
		return amount;
	};

	const onlyNumbersWithColon = (e) => {
		if (e.keyCode !== 8 && e.target.value.length === 2) {
			e.target.value += ":";
		}
	};
	// Calculations Transcription Duration
	const transDurationHandler = useCallback(() => {
		const totalDuration = String(minDurationWatch).split(":");
		const duration = parseInt(totalDuration[0]) * 60 + parseInt(totalDuration[1]);
		const type = data.urgency;
		let amount = 0;
		let rate = 0;
		let time = 15;
		let minimumUrgencyAndNonUrgencyCharge = 0;
		let translationPerWord = 0;
		let translationThreshold = 0;
		let translationMinRate = 0;
		const minimumRate = isMinRates.filter((item) => item.service === sT);

		if (sT === "Transcription") {
			for (let i = 0; i < minimumRate.length; i++) {
				const takingRange = minimumRate[i].range;
				const minRateUrgent = minimumRate[i].urgent;
				const minRateNonUrgent = minimumRate[i].nonUrgent;
				const minRange = takingRange.split("-")[0];
				const maxRange = takingRange.split("-")[1];

				if (duration > maxRange) {
					if (type === "urgent") {
						time = duration;
						rate = isRate?.transcriptionUrgent;
						minimumUrgencyAndNonUrgencyCharge = minRateUrgent;
						amount = minRateUrgent + (duration - maxRange) * rate;
					} else if (type === "non-urgent") {
						time = duration;
						rate = isRate?.transcriptionNonUrgent;
						minimumUrgencyAndNonUrgencyCharge = minRateNonUrgent;
						amount = minRateNonUrgent + (duration - maxRange) * rate;
					}
				} else if (duration <= maxRange && duration > minRange) {
					if (type === "urgent") {
						rate = minRateUrgent;
						minimumUrgencyAndNonUrgencyCharge = minRateUrgent;
						amount = rate;
					} else if (type === "non-urgent") {
						rate = minRateNonUrgent;
						minimumUrgencyAndNonUrgencyCharge = minRateNonUrgent;
						amount = rate;
					}
				}
			}
		} else if (sT === "Translation") {
			if (isRate.translation === undefined) return;
			const { translation } = isRate;
			console.log("translation rate: ", translation);
			const { urgent, nonUrgent } = translation;

			let maxLenghtValue = 0;
			if (type === "urgent") maxLenghtValue = urgent?.threshold;
			else if (type === "non-urgent") maxLenghtValue = nonUrgent?.threshold;

			if (parseInt(wordCountWatch) > maxLenghtValue) {
				console.log("translation rate urgent:", urgent);
				if (type === "urgent") {
					translationThreshold = urgent?.threshold;
					translationMinRate = urgent?.MinimumRate;
					minimumUrgencyAndNonUrgencyCharge = urgent?.perWord;
					translationPerWord = urgent?.perWord;
					amount = urgent?.perWord * parseInt(wordCountWatch);
				} else if (type === "non-urgent") {
					translationThreshold = nonUrgent?.threshold;
					translationMinRate = nonUrgent?.MinimumRate;
					minimumUrgencyAndNonUrgencyCharge = nonUrgent?.perWord;
					translationPerWord = nonUrgent?.perWord;
					amount = nonUrgent?.perWord * parseInt(wordCountWatch);
				}
			} else {
				if (type === "urgent") {
					translationThreshold = urgent?.threshold;
					translationMinRate = urgent?.MinimumRate;
					minimumUrgencyAndNonUrgencyCharge = urgent?.MinimumRate;
					translationPerWord = urgent?.perWord;
					amount = urgent?.MinimumRate;
				} else if (type === "non-urgent") {
					translationThreshold = nonUrgent?.threshold;
					translationMinRate = nonUrgent?.MinimumRate;
					minimumUrgencyAndNonUrgencyCharge = nonUrgent?.MinimumRate;
					translationPerWord = nonUrgent?.perWord;
					amount = nonUrgent?.MinimumRate;
				}
			}
		}
		// console.log(amount, time, minimumUrgencyAndNonUrgencyCharge);
		return {
			amount: amount.toFixed(2),
			minimumUrgencyAndNonUrgencyCharge,
			time,
			translationThreshold,
			translationMinRate,
			translationPerWord,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minDurationWatch, isRate, data.urgency, wordCountWatch, isMinRates, sT]);

	// Calculation Telephone Duration
	const calDurationHandler = useCallback(() => {
		let amount = 0;
		let time = 0;
		let rate = 0;
		let minDurRate = 0;
		const totalDuration = String(minDurationWatch).split(":");
		const duration = parseInt(totalDuration[0]) * 60 + parseInt(totalDuration[1]);

		if (sT === "Telephone") {
			rate = isRate?.telephoneInterpreting;
			minDurRate = isRate?.telephoneInterpreting * 30;
			if (duration <= 30 && duration >= 0) {
				amount = 30 * rate;
				time = 30;
			} else {
				amount = duration * rate;
				time = duration;
			}
		} else if (sT === "Video Call") {
			rate = isRate?.videoInterpreting;
			minDurRate = isRate?.videoInterpreting;
			if (duration <= 60 && duration >= 0) {
				amount = rate;
				time = 60;
			} else {
				time = duration;
				const cal = (rate / 60) * duration;
				amount = cal;
			}
		}
		return { amount, time, minDurRate };
	}, [isRate, minDurationWatch, sT]);

	const calAttendanceOne = useCallback(() => {
		return calOnsitePropHandler(attendanceOneWatch, isRate?.attendance);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attendanceOneWatch, isRate?.attendance, calOnsitePropHandler]);

	const calAttendanceTwo = useCallback(() => {
		return calOnsitePropHandler(attendanceTwoWatch, isRate?.attendance);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attendanceTwoWatch, isRate?.attendance]);

	const calTravelInHandler = useCallback(() => {
		// return calOnsitePropHandler(estimatedTravelInWatch, isRate?.travelPerhour);
		return calOnsiteInAndOutWardHandler(estimatedTravelInWatch, isRate?.travelPerhour);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estimatedTravelInWatch, isRate?.travelPerhour]);

	const calTravelOutHandler = useCallback(() => {
		// return calOnsitePropHandler(estimatedTravelOutWatch, isRate?.travelPerhour);
		return calOnsiteInAndOutWardHandler(estimatedTravelOutWatch, isRate?.travelPerhour);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estimatedTravelOutWatch, isRate?.travelPerhour]);

	const calMileageHandler = useCallback(() => {
		let amount = 0;
		let rate = 0;

		rate = isRate?.mileage;
		amount = rate * estimatedMileageWatch;

		return amount;
	}, [isRate, estimatedMileageWatch]);

	// Calculation Telephone Duration
	const waitingTimeHandler = useCallback(() => {
		const totalDuration = String(estimatedTravelWaitingWatch).split(":");
		const duration = parseInt(totalDuration[0]) * 60 + parseInt(totalDuration[1]);
		let amount = 0;
		let rate = 0;
		if (sT === "Telephone") {
			rate = isRate?.telephoneInterpreting;
			// amount = rate * duration;
			// console.log("waittting", duration, isRate?.waitingPerHour);
			amount = (duration / 60) * isRate?.waitingPerHour;
		} else if (sT === "Video Call") {
			rate = isRate?.videoInterpreting;
			// amount = (rate / 60) * duration;
			amount = (duration / 60) * isRate?.waitingPerHour;
		} else if (sT === "Transcription") {
			rate = isRate?.videoInterpreting;
			amount = (rate / 60) * duration;
		} else if (sT === "Onsite" && typeof undefined !== isRate?.waitingPerHour) {
			amount = (duration / 60) * isRate?.waitingPerHour;
		}
		// console.log('WAITING: ', amount);
		return amount;
	}, [estimatedTravelWaitingWatch, isRate, sT]);

	const totalAmount = useCallback(() => {
		let total = 0;
		if (sT === "Telephone" || sT === "Video Call") {
			total = calDurationHandler().amount + waitingTimeHandler();
		} else if (sT === "Onsite") {
			total +=
				calAttendanceOne() +
				calAttendanceTwo() +
				calTravelInHandler() +
				calTravelOutHandler() +
				calMileageHandler() +
				parseFloat(estimatedFaresWatch) +
				parseFloat(parkingFeeWatch) +
				waitingTimeHandler();
		} else if (sT === "Transcription" || sT === "Translation") {
			total = transDurationHandler()?.amount;
		}

		return total;
	}, [
		sT,
		waitingTimeHandler,
		calAttendanceOne,
		calAttendanceTwo,
		calTravelInHandler,
		calTravelOutHandler,
		calMileageHandler,
		estimatedFaresWatch,
		parkingFeeWatch,
		calDurationHandler,
		transDurationHandler,
	]);

	const subTotalAmount = useCallback(() => {
		let total = 0;
		let discount = parseFloat(discountsWatch) || 0;
		total = parseFloat(totalAmount()) - discount;
		return total;
	}, [totalAmount, discountsWatch]);

	const totalPercentage = useCallback(() => {
		let total = 0;
		total = parseFloat((subTotalAmount() * formValue.serviceChargePercent) / 100);
		return total;
	}, [subTotalAmount, formValue.serviceChargePercent]);

	const grandTotal = useCallback(() => {
		var advPayment;
		var total;

		advPayment = formValue.status !== "Quotation" ? data?.advancePayment : 0;
		total = subTotalAmount() + totalPercentage() - advPayment;
		return total;
	}, [subTotalAmount, totalPercentage, formValue.status, data]);

	return (
		<React.Fragment>
			<form onSubmit={(e) => e.preventDefault()}>
				<input
					name="_id"
					ref={register}
					style={{ display: "none" }}
				/>
				<input
					style={{ display: "none" }}
					type="text"
					name="isSendQuote"
					ref={register}
					value={true}
					readOnly
				/>
				{/* <DevTool control={control} /> */}
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth="md"
					aria-labelledby="Quotation Form"
					aria-describedby="Quotation Form"
				>
					<DialogTitle id="alert-dialog-title">
						{formValue.status !== "Quotation" ? "Booking Confirmation" : "Quotation"}
						&nbsp;
						<Tooltip title={`Booking Reference`}>
							<Chip
								label={data?.bookingRef}
								clickable
								color="primary"
								size="small"
							/>
						</Tooltip>
					</DialogTitle>
					<DialogContent>
						<TableContainer>
							<Table
								className={classes.table}
								size="small"
								aria-label="a dense table"
							>
								{(sT === "Telephone" || sT === "Video Call" || sT === "Onsite") && (
									<>
										<TableHead>
											<TableRow>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "200px",
													}}
												>
													Description
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "200px",
													}}
													align="right"
												>
													{(sT === "Video Call" || sT === "Onsite") &&
														"Hours (hh:mm)"}
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "200px",
													}}
													align="right"
												>
													{sT === "Telephone" && "Charges in £"}
													{sT === "Video Call" && "Minimum rate (£)"}
													{sT === "Onsite" && "Minimum rate per hour (£)"}
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "200px",
													}}
													align="right"
												>
													Amount (£)
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{/* Telephone */}
											{sT === "Telephone" && (
												<>
													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Cost per minute {formValue.durationMin}
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.costPerMin"
																ref={register}
																value={
																	isRate?.telephoneInterpreting
																}
																readOnly
															/>
															p
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															align="right"
															style={styles.colThree}
														></TableCell>
														<TableCell
															align="right"
															style={styles.colFour}
														></TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Number of minutes
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Duration"
																size="small"
																fullWidth
																variant="filled"
																name="durationMin"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Minimum 30 minutes",
																	},
																})}
																error={!!errors.durationMin}
																helperText={
																	errors.durationMin &&
																	errors.durationMin.message
																}
																// defaultValue={timeConvertHandler(formValue?.startTime, formValue?.endTime)}
															/>
															{/* <input defaultValue={timeConvertHandler(formValue.startTime, formValue.endTime)} /> */}
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalDurationAmount"
																ref={register}
																value={parseFloat(
																	calDurationHandler().amount
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>
												</>
											)}

											{/* Video Call */}
											{sT === "Video Call" && (
												<TableRow>
													<TableCell
														className={classes.tableRightBorder}
														style={styles.colOne}
														component="th"
														scope="row"
													>
														Duration
													</TableCell>
													<TableCell
														className={classes.tableRightBorder}
														style={styles.colTwo}
														align="right"
													>
														<TextField
															className={classes.amountInput}
															label="Duration"
															size="small"
															fullWidth
															variant="filled"
															name="durationMin"
															onKeyUp={onlyNumbersWithColon}
															inputRef={register({
																required: false,
																pattern: {
																	value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																	message: "Invalid format",
																},
																validate: {
																	positive: (value) =>
																		parseInt(value) >= 0 ||
																		"Minimum 30 minutes",
																},
															})}
															error={!!errors.durationMin}
															helperText={
																errors.durationMin &&
																errors.durationMin.message
															}
															// defaultValue={timeConvertHandler(formValue.startTime, formValue.endTime)}
														/>
													</TableCell>
													<TableCell
														className={classes.tableRightBorder}
														style={styles.colThree}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.costPerHour"
															ref={register}
															value={parseFloat(
																isRate?.videoInterpreting
															).toFixed(2)}
															readOnly
														/>
													</TableCell>
													<TableCell
														style={styles.colFour}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.totalDurationAmount"
															ref={register}
															value={parseFloat(
																calDurationHandler().amount
															).toFixed(2)}
															readOnly
														/>
													</TableCell>
												</TableRow>
											)}

											{/* Onsite */}
											{sT === "Onsite" && (
												<>
													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Attendance 1
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Attendance 1"
																size="small"
																variant="filled"
																name="attendanceOne"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Minimum 30 minutes",
																	},
																})}
																error={!!errors.attendanceOne}
																helperText={
																	errors.attendanceOne &&
																	errors.attendanceOne.message
																}
																// defaultValue={timeConvertHandler(formValue?.startTime, formValue?.endTime)}
																// defaultValue="00:00"
															/>
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.attendanceOneMinRate"
																ref={register}
																value={parseFloat(
																	isRate?.attendance
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalAttendanceOneAmount"
																ref={register}
																value={parseFloat(
																	calAttendanceOne()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Attendance 2
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Attendance 2"
																size="small"
																variant="filled"
																name="attendanceTwo"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Minimum 30 minutes",
																	},
																})}
																error={!!errors.attendanceTwo}
																helperText={
																	errors.attendanceTwo &&
																	errors.attendanceTwo.message
																}
																defaultValue="00:00"
															/>
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.attendanceTwoMinRate"
																ref={register}
																value={parseFloat(
																	isRate?.attendance
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalAttendanceTwoAmount"
																ref={register}
																value={parseFloat(
																	calAttendanceTwo()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Travel inward charges
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Estimated In"
																placeholder="Per hour"
																size="small"
																variant="filled"
																name="estimatedTravelIn"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Per hour",
																	},
																})}
																error={!!errors.estimatedTravelIn}
																helperText={
																	errors.estimatedTravelIn &&
																	errors.estimatedTravelIn.message
																}
																defaultValue="00:00"
															/>
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.travelInMinRate"
																ref={register}
																value={parseFloat(
																	isRate?.travelPerhour
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalTravelInAmount"
																ref={register}
																value={parseFloat(
																	calTravelInHandler()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Travel outward charges
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Estimated Out"
																placeholder="Per hour"
																size="small"
																variant="filled"
																name="estimatedTravelOut"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Per hour",
																	},
																})}
																error={!!errors.estimatedTravelOut}
																helperText={
																	errors.estimatedTravelOut &&
																	errors.estimatedTravelOut
																		.message
																}
																defaultValue="00:00"
															/>
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.travelOutMinRate"
																ref={register}
																value={parseFloat(
																	isRate?.travelPerhour
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalTravelOutAmount"
																ref={register}
																value={parseFloat(
																	calTravelOutHandler()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>
												</>
											)}

											{/* Telephone, Video Call and Onsite */}
											{(sT === "Telephone" ||
												sT === "Video Call" ||
												sT === "Onsite") && (
												<TableRow>
													<TableCell
														style={styles.colOne}
														className={classes.tableRightBorder}
														component="th"
														scope="row"
													>
														Waiting time
													</TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													>
														<TextField
															className={classes.amountInput}
															label="Estimated"
															placeholder="Per hour"
															size="small"
															variant="filled"
															name="estimatedWaiting"
															onKeyUp={onlyNumbersWithColon}
															inputRef={register({
																required: false,
																pattern: {
																	value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																	message: "Invalid format",
																},
																validate: {
																	positive: (value) =>
																		parseInt(value) >= 0 ||
																		"Per hour",
																},
															})}
															error={!!errors.estimatedWaiting}
															helperText={
																errors.estimatedWaiting &&
																errors.estimatedWaiting.message
															}
															defaultValue="00:00"
														/>
													</TableCell>
													<TableCell
														style={styles.colThree}
														className={classes.tableRightBorder}
														align="right"
													>
														{sT === "Onsite" && (
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.waitingMinRate"
																ref={register}
																value={isRate?.waitingPerHour}
																readOnly
															/>
														)}
														{/* {(sT === 'Telephone' || sT === 'Video Call') && waitingTimeHandler().toFixed(2)} */}
														{sT === "Telephone" &&
															waitingTimeHandler().toFixed(2)}
													</TableCell>
													<TableCell
														style={styles.colFour}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.totalWaitingAmount"
															ref={register}
															value={waitingTimeHandler().toFixed(2)}
															readOnly
														/>
													</TableCell>
												</TableRow>
											)}

											{/* Onsite */}
											{sT === "Onsite" && (
												<>
													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Parking charges
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colTwo}
															align="right"
														></TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															As per evidence
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Parking Fee"
																placeholder="Per hour"
																size="small"
																variant="filled"
																type="number"
																name="parkingFee"
																inputRef={register({
																	required: false,
																})}
																error={!!errors.parkingFee}
																helperText={
																	errors.parkingFee &&
																	errors.parkingFee.message
																}
																defaultValue="0"
															/>
														</TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Mileage
														</TableCell>
														<TableCell
															style={styles.colTwo}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Estimated"
																placeholder=""
																size="small"
																name="estimatedMileage"
																variant="filled"
																type="number"
																inputRef={register({
																	required: false,
																	validate: {
																		positive: (value) =>
																			parseFloat(value) >=
																				0 || "",
																	},
																})}
																error={!!errors.estimatedMileage}
																helperText={
																	errors.estimatedMileage &&
																	errors.estimatedMileage.message
																}
																defaultValue="0"
															/>
														</TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.mileageMinRate"
																ref={register}
																value={isRate?.mileage}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalMileageAmount"
																ref={register}
																value={parseFloat(
																	calMileageHandler()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colOne}
															component="th"
															scope="row"
														>
															Travel fares
														</TableCell>
														<TableCell
															style={styles.colTwo}
															align="right"
														></TableCell>
														<TableCell
															className={classes.tableRightBorder}
															style={styles.colThree}
															align="right"
														>
															As per evidence
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Estimated "
																size="small"
																variant="filled"
																name="estimatedFares"
																inputRef={register({
																	required: false,
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Per hour",
																	},
																})}
																error={!!errors.estimatedFares}
																helperText={
																	errors.estimatedFares &&
																	errors.estimatedFares.message
																}
																defaultValue="0"
															/>
														</TableCell>
													</TableRow>
												</>
											)}

											{/* Telephone */}
											{sT === "Telephone" && (
												<TableRow>
													<TableCell
														style={styles.colOne}
														className={classes.tableRightBorder}
														component="th"
														scope="row"
													>
														Minimum charges & Duration costs
													</TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.durationTeleAndVideoInMins"
															ref={register}
															value="30mins"
															readOnly
														/>
													</TableCell>
													<TableCell
														style={styles.colThree}
														className={classes.tableRightBorder}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.totalTeleAndVideoAmount"
															ref={register}
															value={parseFloat(
																calDurationHandler().minDurRate
															).toFixed(2)}
															readOnly
														/>
													</TableCell>
													<TableCell
														style={styles.colFour}
														align="right"
													></TableCell>
												</TableRow>
											)}

											{/* Calculation */}
											{/* Telephone  */}
											{(sT === "Telephone" ||
												sT === "Video Call" ||
												sT === "Onsite") && (
												<>
													<TableRow>
														<TableCell
															style={styles.colOne}
															className={classes.tableRightBorder}
															component="th"
															scope="row"
														></TableCell>
														<TableCell
															style={styles.colTwo}
															className={classes.tableRightBorder}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colThree}
															className={classes.tableRightBorder}
															align="right"
														>
															<strong>Total</strong>
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalAmount"
																ref={register}
																value={parseFloat(
																	totalAmount()
																).toFixed(2)}
																readOnly
															/>
														</TableCell>
													</TableRow>

													{/* Telephone */}
													<TableRow>
														<TableCell
															style={styles.colOne}
															component="th"
															scope="row"
														></TableCell>
														<TableCell
															style={styles.colTwo}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colThree}
															className={classes.tableRightBorder}
															align="right"
														>
															Discounts
														</TableCell>
														<TableCell
															style={styles.colFour}
															align="right"
														>
															<TextField
																label="Amount"
																size="small"
																fullWidth
																variant="filled"
																type="number"
																name="quotation.discounts"
																inputRef={register}
																defaultValue={
																	data?.quotation?.discounts || 0
																}
															/>
														</TableCell>
													</TableRow>
												</>
											)}
											<TableRow>
												<TableCell style={styles.colOne}></TableCell>
												<TableCell style={styles.colTwo}></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													<strong>Sub Total</strong>
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.subTotalAmount"
														ref={register}
														value={subTotalAmount().toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												>
													{serviceLabel}
												</TableCell>
												<TableCell
													style={styles.colTwo}
													className={classes.tableRightBorder}
													align="right"
												></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													@{data?.serviceChargePercent}%
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.totalPercentage"
														ref={register}
														value={totalPercentage().toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>

											{formValue.status === "Booking" && (
												<TableRow>
													<TableCell
														style={styles.colOne}
														component="th"
														scope="row"
													>
														Advance Payment
													</TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													></TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													></TableCell>
													<TableCell
														style={styles.colFour}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="advancePayment"
															ref={register}
															value={data?.advancePayment?.toFixed(2)}
															readOnly
														/>
													</TableCell>
												</TableRow>
											)}

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												>
													<strong>Grand Total</strong>
												</TableCell>
												<TableCell
													style={styles.colTwo}
													align="right"
												></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												></TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.grandTotal"
														ref={register}
														value={grandTotal().toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>
										</TableBody>
									</>
								)}

								{/* Translation */}
								{(sT === "Translation" || sT === "Transcription") && (
									<>
										<TableHead>
											<TableRow>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "150px",
													}}
												>
													Status
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "150px",
													}}
													align="right"
												>
													{sT === "Translation" && "Rate per word"}
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "150px",
													}}
													align="right"
												>
													{sT === "Translation" && "Number of pages"}
													{sT === "Transcription" && "Hours (hh:mm)"}
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "150px",
													}}
													align="right"
												>
													{sT === "Translation" && "Word count"}
													{sT === "Transcription" && "Minimum rate (£)"}
												</TableCell>
												<TableCell
													style={{
														fontSize: "12px",
														fontWeight: 600,
														width: "150px",
													}}
													align="right"
												>
													Amount (£)
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{/* Translation */}
											{sT === "Translation" && (
												<TableRow>
													<TableCell
														style={styles.colTranOne}
														className={classes.tableRightBorder}
														component="th"
														scope="row"
													>
														<Tooltip title={isRate?.rateName}>
															<span>
																{data.urgency === "urgent"
																	? "Urgent"
																	: "Non Urgent"}
															</span>
														</Tooltip>
													</TableCell>
													<TableCell
														style={styles.colTranTwo}
														className={classes.tableRightBorder}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.costPerWord"
															ref={register}
															value={
																transDurationHandler()
																	?.translationPerWord
															}
															style={styles.tableStrap}
															readOnly
														/>
														<input
															type="hidden"
															ref={register}
															name="quotation.translationMinRate"
															value={
																transDurationHandler()
																	?.translationMinRate
															}
														/>
														<input
															type="hidden"
															ref={register}
															name="quotation.threshold"
															defaultValue={
																transDurationHandler()
																	?.translationThreshold
															}
														/>
													</TableCell>
													<TableCell
														style={styles.colTranThree}
														className={classes.tableRightBorder}
														align="right"
													>
														<TextField
															className={classes.amountInput}
															label="Page Count"
															size="small"
															variant="filled"
															type="number"
															name="pageCount"
															inputRef={register({
																required: false,
																validate: {
																	positive: (value) =>
																		parseInt(value) >= 1 ||
																		"Minimum 1 page",
																},
															})}
															error={!!errors.pageCount}
															helperText={
																errors.pageCount &&
																errors.pageCount.message
															}
															defaultValue={formValue.pageCount}
														/>
													</TableCell>
													<TableCell
														style={styles.colTranFour}
														className={classes.tableRightBorder}
														align="right"
													>
														<TextField
															className={classes.amountInput}
															label="Word Count"
															size="small"
															variant="filled"
															type="number"
															name="wordCount"
															inputRef={register({
																required: false,
																validate: {
																	positive: (value) =>
																		parseInt(value) > 0 ||
																		`Minimum document threshold 0-${
																			transDurationHandler()
																				.translationThreshold
																		} words`,
																},
															})}
															error={!!errors.wordCount}
															helperText={
																errors.wordCount &&
																errors.wordCount.message
															}
															defaultValue="0"
														/>
													</TableCell>
													<TableCell
														style={styles.colTranFive}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="quotation.transTotalDurationAmount"
															ref={register}
															value={transDurationHandler()?.amount}
															style={styles.tableStrap}
															readOnly
														/>
													</TableCell>
												</TableRow>
											)}

											{/* Transcription */}
											{sT === "Transcription" && (
												<>
													<TableRow>
														<TableCell
															style={styles.colTranOne}
															className={classes.tableRightBorder}
															component="th"
															scope="row"
														>
															{data.urgency === "urgent"
																? "Urgent"
																: "Non Urgent"}
														</TableCell>
														<TableCell
															style={styles.colTranTwo}
															className={classes.tableRightBorder}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colTranThree}
															className={classes.tableRightBorder}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colTranFour}
															className={classes.tableRightBorder}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.minimumUrgencyAndNonUrgencyCharge"
																ref={register}
																value={
																	transDurationHandler()
																		.minimumUrgencyAndNonUrgencyCharge ||
																	0
																}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colTranFive}
															align="right"
														></TableCell>
													</TableRow>

													<TableRow>
														<TableCell
															style={styles.colTranOne}
															className={classes.tableRightBorder}
															component="th"
															scope="row"
														>
															Transcription cost per min (charged over
															60mins)
														</TableCell>
														<TableCell
															style={styles.colTranTwo}
															className={classes.tableRightBorder}
															align="right"
														></TableCell>
														<TableCell
															style={styles.colTranThree}
															className={classes.tableRightBorder}
															align="right"
														>
															<TextField
																className={classes.amountInput}
																label="Duration"
																size="small"
																fullWidth
																variant="filled"
																name="durationMin"
																onKeyUp={onlyNumbersWithColon}
																inputRef={register({
																	required: false,
																	pattern: {
																		value: /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?$/,
																		message: "Invalid format",
																	},
																	validate: {
																		positive: (value) =>
																			parseInt(value) >= 0 ||
																			"Minimum 30 minutes",
																	},
																})}
																error={!!errors.durationMin}
																helperText={
																	errors.durationMin &&
																	errors.durationMin.message
																}
																defaultValue="00:00"
															/>
														</TableCell>
														<TableCell
															style={styles.colTranFour}
															className={classes.tableRightBorder}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.costPerMin"
																ref={register}
																defaultValue={
																	data?.urgency === "urgent"
																		? isRate?.transcriptionUrgent
																		: isRate?.transcriptionNonUrgent
																}
																readOnly
															/>
														</TableCell>
														<TableCell
															style={styles.colTranFive}
															align="right"
														>
															<input
																type="text"
																className={classes.borderLess}
																name="quotation.totalTransDurationAmount"
																ref={register}
																value={
																	transDurationHandler().amount ||
																	0
																}
																readOnly
															/>
														</TableCell>
													</TableRow>
												</>
											)}

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												></TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													<strong>Total</strong>
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.totalAmount"
														ref={register}
														value={parseFloat(totalAmount()).toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												></TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													Discounts
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<TextField
														className={classes.amountInput}
														label="Amount"
														size="small"
														fullWidth
														variant="filled"
														type="number"
														name="quotation.discounts"
														inputRef={register({
															required: false,
														})}
														defaultValue={
															data?.quotation?.discounts || 0
														}
													/>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												></TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													<strong>Sub Total</strong>
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.subTotalAmount"
														ref={register}
														value={subTotalAmount().toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												>
													{serviceLabel}
												</TableCell>

												<TableCell></TableCell>
												<TableCell
													className={classes.tableRightBorder}
												></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												>
													@{data.serviceChargePercent}%
												</TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.totalPercentage"
														ref={register}
														value={totalPercentage().toFixed(2)}
														style={styles.tableStrap}
														readOnly
													/>
												</TableCell>
											</TableRow>

											{formValue.status === "Booking" && (
												<TableRow>
													<TableCell
														style={styles.colOne}
														component="th"
														scope="row"
													>
														Advance Payment
													</TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													></TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													></TableCell>
													<TableCell
														style={styles.colTwo}
														className={classes.tableRightBorder}
														align="right"
													></TableCell>
													<TableCell
														style={styles.colFour}
														align="right"
													>
														<input
															type="text"
															className={classes.borderLess}
															name="advancePayment"
															ref={register}
															value={data?.advancePayment?.toFixed(2)}
															readOnly
														/>
													</TableCell>
												</TableRow>
											)}

											<TableRow>
												<TableCell
													style={styles.colOne}
													component="th"
													scope="row"
												>
													<strong>Grand Total</strong>
												</TableCell>

												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell
													style={styles.colThree}
													className={classes.tableRightBorder}
													align="right"
												></TableCell>
												<TableCell
													style={styles.colFour}
													align="right"
												>
													<input
														type="text"
														className={classes.borderLess}
														name="quotation.grandTotal"
														ref={register}
														value={grandTotal().toFixed(2)}
														readOnly
													/>
												</TableCell>
											</TableRow>
										</TableBody>
									</>
								)}
							</Table>
						</TableContainer>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color="primary"
						>
							Close
						</Button>
						<Button
							onClick={handleSubmit(onSubmit)}
							color="primary"
							variant="contained"
							size="small"
							autoFocus
							disabled={!isValid || isSubmitting}
						>
							{isSubmitting ? "Preparing" : "Confirm"}
						</Button>
					</DialogActions>
				</Dialog>
			</form>
		</React.Fragment>
	);
});
