import React, { useState, useContext, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Pagination from "../../Pagination/Pagination";

// importing context
import { SearchContext } from "../../../context/states/search/searchState";
import { InterpreterContext } from "../../../context/states/interpreterState";
import AddInterpreter from "./interpreterForms/AddInterpreter";
import MemoizedList from "./memos/MemoizedList";
import MemoizedToolbar from "./memos/MemoizedToolbar";
import MemoizedHeader from "./memos/MemoizedHeader";

import { useStyles } from "./styles";

import { headCells } from "./HeadCells";

export const Interpreters = React.memo(({ title }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1920px)");
  let location = useLocation();

  const { getInterpretersHandler, loading, data, total, count, pagination } =
    useContext(InterpreterContext);
  const { interpreterSearchHandler, interpreterSearch } =
    useContext(SearchContext);

  const [isSearchObj, setIsSearchObj] = useState({
    fname: interpreterSearch.fname,
    language: interpreterSearch.language,
    dialect: interpreterSearch.dialect,
    gender: interpreterSearch.gender,
    service: interpreterSearch.service,
    postcode: interpreterSearch.postcode,
    active: interpreterSearch.active,
    page: interpreterSearch.page,
    limit: interpreterSearch.limit,
  });
  const [isCheckbox, setIsCheckbox] = useState(false);
  const enableCheckboxHandler = () => setIsCheckbox(!isCheckbox);

  const [isAddModel, setIsAddModel] = useState(false);
  const openFormModalHandler = useCallback(() => setIsAddModel(true), []);
  const closeFormModalHandler = useCallback(() => setIsAddModel(false), []);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("pincode");
  const [selected, setSelected] = useState([]);
  const [isPage, setPage] = useState(isSearchObj.page);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(isSearchObj.limit);

  useEffect(() => {
    if (location.state?.page !== "interpreter") {
      clearAdvancedSearchHandler();
    }
  }, [location]);

  useEffect(() => {
    let unmounted = true;
    console.log("isSearchObj", isSearchObj);
    var queryParams = interpreterSearchHandler(isSearchObj);

    let filteredDate = `?page=${isSearchObj.page}&limit=${isSearchObj.limit}`;
    if (isSearchObj.fname && isSearchObj.fname !== "") {
      filteredDate += `&fname=${isSearchObj.fname}`;
    }
    if (isSearchObj.language && isSearchObj.language !== "")
      filteredDate += `&language=${isSearchObj.language}`;
    if (isSearchObj.dialect && isSearchObj.dialect !== "")
      filteredDate += `&dialect=${isSearchObj.dialect}`;
    if (isSearchObj.gender && isSearchObj.gender !== "")
      filteredDate += `&gender=${isSearchObj.gender}`;
    if (isSearchObj.service && isSearchObj.service !== "")
      filteredDate += `&service=${isSearchObj.service}`;
    if (isSearchObj.postcode && isSearchObj.postcode !== "")
      filteredDate += `&postcode=${isSearchObj.postcode}`;
    if (isSearchObj.active && isSearchObj.acitve !== "")
      filteredDate += `&acitve=${isSearchObj.acitve}`;

    console.log("isSearchObj filteredDate", filteredDate);
    if (unmounted) {
      getInterpretersHandler(queryParams);
    }

    return () => {
      unmounted = false;
    };
  }, [isSearchObj]);

  const clearAdvancedSearchHandler = useCallback(() => {
    setIsSearchObj({
      fname: "",
      language: "",
      dialect: "",
      gender: "",
      service: "",
      postcode: "",
      active: "",
      page: 1,
      limit: 25,
    });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(!isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = useCallback(
    (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      console.log("select: ", newSelected);
      setSelected(newSelected);
    },
    [setSelected, selected]
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      console.log("isSearchObj page", isSearchObj.page);
      setIsSearchObj((prevSearchObj) => ({
        ...prevSearchObj,
        page: newPage + 1,
        limit: rowsPerPage,
      }));

      setPage(newPage + 1);
      // setIsSearchObj({ ...isSearchObj, page: newPage + 1, limit: rowsPerPage });
    },
    [rowsPerPage, isSearchObj]
  );

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value));
    const newLimit = parseInt(event.target.value, 10);

    if (!isNaN(newLimit)) {
      setIsSearchObj((prevState) => ({
        ...prevState,
        page: 1,
        limit: newLimit,
      }));
    }
  }, []);

  const handleChangeDense = useCallback(
    (event) => {
      setDense(event.target.checked);
    },
    [setDense]
  );

  const isSelected = useCallback(
    (name) => selected.indexOf(name) !== -1,
    [selected]
  );

  return (
    <>
      <Paper className={classes.paper}>
        <MemoizedToolbar
          selected={selected}
          title={title}
          isCheckbox={isCheckbox}
          enableCheckboxHandler={enableCheckboxHandler}
          openFormModalHandler={openFormModalHandler}
          count={count}
          isSearchObj={isSearchObj}
          setIsSearchObj={setIsSearchObj}
          setPage={setPage}
          isPage={isPage}
          clearAdvancedSearchHandler={clearAdvancedSearchHandler}
        />
        <TableContainer
          className={matches ? classes.container2 : classes.container}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            aria-label="sticky table"
          >
            <MemoizedHeader
              headCells={headCells}
              classes={classes}
              selected={selected}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              data={data}
              isCheckbox={isCheckbox}
            />
            <TableBody>
              <MemoizedList
                data={data}
                loading={loading}
                order={order}
                orderBy={orderBy}
                isPage={isPage}
                rowsPerPage={rowsPerPage}
                isSelected={isSelected}
                handleClick={handleClick}
                isCheckbox={isCheckbox}
              />
            </TableBody>
          </Table>
        </TableContainer>
        {Object.keys(pagination).length !== 0 && (
          <Pagination
            isTotal={total}
            isPage={isPage - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense"
      />
      <AddInterpreter open={isAddModel} handleClose={closeFormModalHandler} />
    </>
  );
});

Interpreters.propTypes = {
  title: PropTypes.string.isRequired,
};
