import * as actionTypes from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      return {
        ...state,
        loading: true,
        user: {},
        notes: [],
        fileList: [],
        docLoad: false,
        imgLoading: false,
        profileImage: '',
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        total: action.payload.count,
      };

    case 'SINGLE_FETCH':
      return {
        ...state,
        load: true,
        user: {}
      };

    case actionTypes.FETCH_RECORD:
      return {
        ...state,
        load: false,
        user: action.payload,
        notes: action.payload.notes,
        fileList: action.payload.fileList,
      };

    case actionTypes.ADD_RECORD:
      return {
        ...state,
        users: [action.payload, ...state.users],
        total: state.total + 1,
      };

    case actionTypes.UPDATE_RECORD:
      return {
        ...state,
        users: state.users.map((user) => {
          return user._id === action.payload._id ? action.payload : user;
        }),
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        loading: false,
        imgLoading: false,
        error: action.payload,
      };

    case 'USER_NOTE':
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        users: state.users.map((user) => {
          return user._id === action.payload.id ? {...user, isActive: action.payload.isActive} : user;
        }),
      }

    case 'DOCUMENT_UPLOAD':
      return {
        ...state,
        fileList: [...state.user.fileList, action.payload],
      };

    case 'DOC_LOAD':
      return {
        ...state,
        docLoad: true,
        viewDoc: '',
      };

    case actionTypes.GET_DOCUMENT_IMAGE:
      return {
        ...state,
        docLoad: false,
        viewDoc: action.payload,
      };

    case 'DOCUMENT_DELETED':
      console.log('fileuploads deleted', action.payload);
      return {
        ...state,
        fileList: [...state.fileList.filter((doc) => doc.fileName !== action.payload)],
      };

    case actionTypes.FILE_UPLOAD_START:
      return {
        ...state,
        imgLoading: true,
        error: null,
        profileImage: '',
      };

    case actionTypes.UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        imgLoading: false,
        profile: action.payload,
      };

    case actionTypes.GET_PROFILE_IMAGE:
      return {
        ...state,
        imgLoading: false,
        profileImage: action.payload,
      };

    default:
      return state;
  }
};
