import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { AuthContext } from '../../context/states/authState';

export const LoginForm = () => {
  const history = useHistory()
  const { handleLogin, loading, handleInterpreterLogin } = useContext(AuthContext);

  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    const { email } = data;
    const admin = email.split('@')[1];

    if (admin === 'language-interpreters.com') {
      handleLogin(data);
    } else {
      handleInterpreterLogin(data).then(result => {
        history.push('/timesheetTracker')
        console.log(result)
      });
    }
  };

  return (
    <React.Fragment>
      <form noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email ID"
          size="small"
          name="email"
          inputRef={register({
            required: true,
            minLength: { value: 2, message: 'Must have at least 2 letters' },
            maxLength: { value: 50, message: 'Must have at least 50 letters' },
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Invalid email-id',
            },
          })}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Password"
          type="password"
          id="password"
          size="small"
          name="password"
          inputRef={register({
            required: true,
            minLength: { value: 4, message: 'Password must have at least 4 letters' },
            maxLength: { value: 15, message: 'Password maximum characters limit is 15' },
          })}
          error={!!errors.password}
          helperText={errors.password && errors.password.message}
          autoComplete="on"
        />
        <Button fullWidth type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={loading}>
          {loading ? 'Loading...' : 'Sign In'}
        </Button>
      </form>
    </React.Fragment>
  );
};
