import React from 'react';
import moment from 'moment';

import { Grid, Card, CardContent, Typography } from '@material-ui/core';

const InvoiceDetail = (props) => {
  const { classes, data, invoiceType } = props;
  return (
    <>
      <Card className={classes.headerCard} variant="outlined">
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {invoiceType !== 'yes' ? (
                // Interpreter details

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Company:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.company !== null ? data.company : 'Private'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>File Reference:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.fileRef}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Client Name:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.clientName}
                    </Typography>
                  </Grid>

                  {data !== undefined && data.service === 'Onsite' ? (
                    <>
                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          <strong>Place of Attendance:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          {data !== undefined && data.placeOfAttendance}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          <strong>Date of Attendance:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          {moment(data !== undefined && data.dateOfAttendance).format('DD/MM/Y')}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          <strong>{data.service === 'Telephone' || data?.service === 'Video Call' ? 'Date of Booking' : 'Date of Return'}:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          {moment(data?.dateOfAttendance).format('DD/MM/Y')}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                // Client details

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>File Reference:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.fileRef}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Client Name:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.clientName}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Booking Reference:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.bookingReference}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Case / Matter Type:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.caseType}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Location:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.location}
                    </Typography>
                  </Grid>
                  {data?.service === 'Onsite' && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          <strong>Attendance Location:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          {data !== undefined && data.place}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          <strong>Attendance Address:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="body2" component="p">
                          {data !== undefined && data.placeOfAttendance}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              {invoiceType !== 'yes' ? (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Interpreter ID:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.intRef}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Booking Ref:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data?.BookingRef}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Language:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.language}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Interpreter:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.interpreterName}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Interpreter Postcode:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data !== undefined && data.interpreterPostcode}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Language:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {data?.language}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      <strong>Date of Booking:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body2" component="p">
                      {moment(data?.dateOfBooking).format('DD/MM/Y')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    <strong>Invoice No:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    {data !== undefined && data.invoiceNo}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    <strong>Invoice Date:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    {moment(data !== undefined && data.invoiceDate).format('DD/MM/Y')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    <strong>Service Type:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" component="p">
                    {data !== undefined && data.service}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default InvoiceDetail;
